import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  getDropdownProvince,
  getDropdownCity,
  getDropdownSubdistrict,
  getDropdownVillage,
} from '../../../redux/slices/alamatUsaha/alamatUsahaThunk';
import { resetNextDropdown } from '../../../redux/slices/alamatUsaha/alamatUsahaSlice';
import {
  InformasiAlamatActiveDisplay as PageType,
  ADDRESS_LOCATION_DROPDOWN as Location,
} from '../../../utils/constants';
import {
  FormDataInformasiAlamat,
  ModalMessageProps,
  SelectedAddressType,
} from '../../../types/InformasiAlamatTypes';
import * as Assets from '../../../utils/assets';
import Label from '../../atom/Label';
import Select from 'react-select';
import FormGroup from '../../atom/FormGroup';
import PinpointLocation from '../../AlamatUsaha/PinpointLocation';
import { TemporaryStateUsahaType } from '../../../types/AlamatUsahaTypes';
import { getLocationByLatLong } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { getDropdown, saveDraft } from '../../../redux/slices/dealer/dealerThunk';
import { toast } from 'react-toastify';
import { featureFlag } from '../../../utils/helper';
import Alert from '../../atom/Alert';
import HeaderEvent from '../../Header/HeaderEvent';
import Modal from '../../atom/Modal';
import iconWarningOutOfArea from '../../../assets/svg/iconWarningOutOfArea.svg';
import iconWarningDisableLocation from '../../../assets/svg/iconWarningDisableLocation.svg';

const AlamatUsaha = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const { data: dealer, loading: isLoading } = useAppSelector(
    (state) => state.dealer.dealer
  );
  const dropdownProvince = useAppSelector(
    (state) => state.alamatUsaha.dropdownLocation.dropdownProvince
  );
  const dropdownCity = useAppSelector(
    (state) => state.alamatUsaha.dropdownLocation.dropdownCity
  );
  const dropdownSubdistrict = useAppSelector(
    (state) => state.alamatUsaha.dropdownLocation.dropdownSubdistrict
  );
  const dropdownVillage = useAppSelector(
    (state) => state.alamatUsaha.dropdownLocation.dropdownVillage
  );
  const dropdown = useAppSelector((state) => state.dealer.dropdown)
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [activePage, setActivePage] = useState<'form' | 'pinpoint'>('form');
  const [temporaryState, setTemporaryState] = useState<TemporaryStateUsahaType>(
    { lat: '', lng: '' }
  );
  const [triggerMap, setTriggerMap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<ModalMessageProps>({
    title: '',
    description: '',
  });
  const [isPermission, setIsPermission] = useState(false);
  const ownership_id = process.env.REACT_APP_OWNERSHIP_OTHER_ID || 15 

  const handleResetForm = () => {
    if (isSameAddress) {
      dispatch(resetNextDropdown('all'));
      setValue('province', null as any);
      setValue('city', null as any);
      setValue('subdistrict', null as any);
      setValue('village', null as any);
      setValue('address', null as any);
      setValue('postal_code', null as any);
      setValue('latitude', '');
      setValue('longitude', '');
    }
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormDataInformasiAlamat>();

  const handleChangeProvince = (event: any) => {
    setValue('province', event);
    //Trigger Next Dropdown
    dispatch(getDropdownCity(`levels=3&parent_ids=${event?.id}`));
    dispatch(resetNextDropdown('province'));
    //Reset Next Dropdown Value
    setValue('city', null as any);
    setValue('subdistrict', null as any);
    setValue('village', null as any);
    setValue('address', '');
    setValue('postal_code', '');
    setValue('latitude', '');
    setValue('longitude', '');
  };

  const handleChangeCity = (event: any) => {
    setValue('city', event);
    //Trigger Next Dropdown
    dispatch(getDropdownSubdistrict(`levels=4&parent_ids=${event?.id}`));
    dispatch(resetNextDropdown('city'));
    //Reset Next Dropdown Value
    setValue('subdistrict', null as any);
    setValue('village', null as any);
    setValue('address', '');
    setValue('postal_code', '');
    setValue('latitude', '');
    setValue('longitude', '');
  };

  const handleChangeSubdistrict = (event: any) => {
    setValue('subdistrict', event);
    //Trigger Next Dropdown
    dispatch(getDropdownVillage(`levels=5&parent_ids=${event?.id}`));
    //Reset Next Dropdown Value
    setValue('village', null as any);
    setValue('address', '');
    setValue('postal_code', '');
    setValue('latitude', '');
    setValue('longitude', '');
  };

  const handleChangeVillage = (event: any) => {
    setValue('village', event);
    //Reset Next Form Value
    setValue('address', '');
    setValue('postal_code', '');
    setValue('latitude', '');
    setValue('longitude', '');
  };

  const getLocation = (params: SelectedAddressType) => {
    setTriggerMap(true);
    const paramsGetDropdown = {
      queryParams: `excluded_ids=1&nested=child&latlng=${params.lat},${params.lng}`,
    };
    getLocationByLatLong(paramsGetDropdown)
      .then((res) => {
        const data = res.data.data;
        const dataProvince = data;
        const dataCity = data?.child;
        const dataSubdistrict = data?.child?.child;
        const dataVillage = data?.child?.child?.child;

        setValue('province', dataProvince);
        setValue('city', dataCity);
        setValue('subdistrict', dataSubdistrict);
        setValue('village', dataVillage);
        dispatch(getDropdownCity(`levels=3&parent_ids=${dataProvince?.id}`));
        dispatch(getDropdownSubdistrict(`levels=4&parent_ids=${dataCity?.id}`));
        dispatch(
          getDropdownVillage(`levels=5&parent_ids=${dataSubdistrict?.id}`)
        );
        setTimeout(() => {
          setTriggerMap(false);
        }, 2000);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSaveMaps = (params: SelectedAddressType) => {
    setValue('longitude', params.lng);
    setValue('latitude', params.lat);
    setValue('postal_code', params.postalCode);
    setValue('address', params.fullAddress);
    getLocation(params);
  };

  const onSubmit: SubmitHandler<FormDataInformasiAlamat> = async (data) => {
    const domicile = dealer?.alamat?.alamat_domisili;
    const payload = {
      alamat_usaha: {
        address: isSameAddress ? domicile?.address : data.address,
        province_id: isSameAddress ? domicile?.province_id : data.province.id,
        province_name: isSameAddress
          ? domicile?.province_name
          : data.province.name,
        city_id: isSameAddress ? domicile?.city_id : data.city.id,
        city_name: isSameAddress ? domicile?.city_name : data.city.name,
        subdistrict_id: isSameAddress
          ? domicile?.subdistrict_id
          : data.subdistrict.id,
        subdistrict_name: isSameAddress
          ? domicile?.subdistrict_name
          : data.subdistrict.name,
        village_id: isSameAddress ? domicile?.village_id : data.village.id,
        village_name: isSameAddress ? domicile?.village_name : data?.village.name,
        postal_code: isSameAddress ? domicile?.postal_code : data.postal_code,
        latitude: isSameAddress ? domicile?.latitude : data.latitude,
        longitude: isSameAddress ? domicile?.longitude : data.longitude,
        ...(featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? {
          ownership_id: data.ownership_id?.id,
          ownership_other: data.ownership_other,
        } : {})
      },
    };

    const res = await dispatch(
      saveDraft({ data: payload, uuid: uuid as string })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/dealer/registration/${uuid}/dokumen_pendukung`);
    } else {
      const errMessage = (res.payload as any)?.errors?.[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const checkIsSameAddress = () => {
    const dataDealerAlamatUsaha = dealer?.alamat_usaha;
    const dataDealerAlamatDomicile = dealer?.alamat?.alamat_domisili;

    const checkDataDealerAlamatUsaha = Object.assign({}, dataDealerAlamatUsaha);
    delete checkDataDealerAlamatUsaha.id;

    const checkDataDealerAlamatDomicile = Object.assign(
      {},
      dataDealerAlamatDomicile
    );
    delete checkDataDealerAlamatDomicile.id;

    if (
      JSON.stringify(checkDataDealerAlamatUsaha) ===
      JSON.stringify(checkDataDealerAlamatDomicile)
    ) {
      setIsSameAddress(true);
    } else {
      setIsSameAddress(false);
    }
  };

  useEffect(() => {
    const dataAlamatUsaha = dealer?.alamat_usaha;
    checkIsSameAddress();
    if (localStorage.getItem('token')) {
      dispatch(getDropdownProvince('levels=2'));
      if (dataAlamatUsaha?.id) {
        dispatch(
          getDropdownCity(`levels=3&parent_ids=${dataAlamatUsaha?.province_id}`)
        );
        dispatch(
          getDropdownSubdistrict(
            `levels=4&parent_ids=${dataAlamatUsaha?.city_id}`
          )
        );
        dispatch(
          getDropdownVillage(
            `levels=5&parent_ids=${dataAlamatUsaha?.subdistrict_id}`
          )
        );
        setValue('latitude', dataAlamatUsaha?.latitude);
        setValue('longitude', dataAlamatUsaha?.longitude);
        setValue('address', dataAlamatUsaha?.address);
        setValue('postal_code', dataAlamatUsaha?.postal_code);
        if (featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT')) {
          setValue('ownership_id', {
            id: dataAlamatUsaha.ownership_id || 0,
            desc: dropdown?.data?.homeOwnership?.find(item => item.id === dataAlamatUsaha.ownership_id)?.desc
          })
          setValue('ownership_other', dataAlamatUsaha.ownership_other)
        }
      }
    }
  }, [dealer]);

  useEffect(() => {
    const dataAlamatUsaha = dealer?.alamat_usaha;
    if (localStorage.getItem('token')) {
      if (dataAlamatUsaha?.id && dropdownProvince.length > 0 && !triggerMap) {
        const dropdownProvinceKtp = dropdownProvince.filter(
          (a: any) => a.id === dataAlamatUsaha?.province_id
        );
        setValue('province', dropdownProvinceKtp[0]);
      }
    }
  }, [dropdownProvince]);

  useEffect(() => {
    const dataAlamatUsaha = dealer?.alamat_usaha;
    if (localStorage.getItem('token')) {
      if (dataAlamatUsaha?.id && dropdownCity.length > 0 && !triggerMap) {
        const dropdownCityKtp = dropdownCity.filter(
          (a: any) => a.id === dataAlamatUsaha?.city_id
        );
        setValue('city', dropdownCityKtp[0]);
      }
    }
  }, [dropdownCity]);

  useEffect(() => {
    const dataAlamatUsaha = dealer?.alamat_usaha;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatUsaha?.id &&
        dropdownSubdistrict.length > 0 &&
        !triggerMap
      ) {
        const dropdownSubdistrictKtp = dropdownSubdistrict.filter(
          (a: any) => a.id === dataAlamatUsaha?.subdistrict_id
        );
        setValue('subdistrict', dropdownSubdistrictKtp[0]);
      }
    }
  }, [dropdownSubdistrict]);

  useEffect(() => {
    const dataAlamatUsaha = dealer?.alamat_usaha;
    if (localStorage.getItem('token')) {
      if (dataAlamatUsaha?.id && dropdownVillage.length > 0 && !triggerMap) {
        const dropdownVillageKtp = dropdownVillage.filter(
          (a: any) => a.id === dataAlamatUsaha?.village_id
        );
        setValue('village', dropdownVillageKtp[0]);
      }
    }
  }, [dropdownVillage]);

  const dropdownStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state?.isFocused ? '#0F2CAD' : 'default',
    }),
  };

  const mappingDropdown = [
    {
      label: 'Provinsi',
      name: 'province' as any,
      options: dropdownProvince,
      placeholder: 'Pilih provinsi',
      onChange: handleChangeProvince,
      errors: errors.province?.message,
    },
    {
      label: 'Kabupaten/Kota',
      name: 'city' as string,
      options: dropdownCity,
      placeholder: 'Pilih kabupaten/kota',
      onChange: handleChangeCity,
      errors: errors.city?.message,
    },
    {
      label: 'Kecamatan',
      name: 'subdistrict' as string,
      options: dropdownSubdistrict,
      placeholder: 'Pilih kecamatan',
      onChange: handleChangeSubdistrict,
      errors: errors.subdistrict?.message,
    },
    {
      label: 'Kelurahan',
      name: 'village' as any,
      options: dropdownVillage,
      placeholder: 'Pilih kelurahan',
      onChange: handleChangeVillage,
      errors: errors.village?.message,
    },
  ];

  const mappingHiddenForm = [
    { name: 'longitude' as any },
    { name: 'latitude' as any },
  ];

  const params = {
    activePage,
    setActivePage,
    handleSaveMaps,
    temporaryState,
    setTemporaryState,
  };

  useEffect(() => {
    dispatch(getDropdown());
    navigator.geolocation.getCurrentPosition(
      () => {
        setIsPermission(true)
      },
      () => {
        setShowModal(true)
        setIsPermission(false)
        setModalMessage({
          title: 'Penanda lokasi belum aktif',
          description:
            'Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat',
        });
      }
    )
  }, []);

  useEffect(() => {
    if ((errors.latitude && errors.longitude) || (errors.latitude_domicile && errors.longitude_domicile)) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setIsPermission(true)
        },
        () => {
          setShowModal(true)
          setIsPermission(false)
          setModalMessage({
            title: 'Penanda lokasi belum aktif',
            description:
              'Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat',
          });
        }
      )
    }
  }, [errors.latitude, errors.longitude, errors.latitude_domicile, errors.longitude_domicile])

  return (
    <>
      <div className="flex flex-1 flex-col">
        {activePage === PageType.PINPOINT_LOCATION ? (
          <PinpointLocation {...params} />
        ) : (
          <>
            {featureFlag('REACT_APP_SPRINT_9_1_DEALER_ONBOARDING_GATHERING') ? <HeaderEvent data={dealer} /> : ''}

            <h3 className="font-semibold mb-4">Alamat Usaha</h3>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                className="checked:bg-blue-500 mr-2"
                checked={isSameAddress}
                onChange={() => {
                  setIsSameAddress(!isSameAddress);
                  handleResetForm();
                }}
              />
              <span className="text-sm">
                Alamat usaha sama dengan tempat tinggal
              </span>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-1 flex-col"
            >
              {!isSameAddress && (
                <>
                  <div
                    className={`flex flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
                    onClick={() => {
                      setActivePage('pinpoint');
                      setTemporaryState({
                        lat: getValues('latitude'),
                        lng: getValues('longitude'),
                      });
                    }}
                  >
                    <div className="flex justify-center items-center">
                      <img src={Assets.iconMarkerOrange} className="mr-2" />
                      <span className="text-xs text-secondary">
                        {watch('longitude') ? 'Ubah' : 'Atur'} Peta Lokasi
                      </span>
                    </div>
                  </div>
                  <small className="text-red-600 mb-5">
                    {!isPermission ? 'Penanda lokasi belum aktif. Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat' :
                      errors.longitude?.message || errors.latitude?.message}
                  </small>
                  <Alert>
                    <span className="text-xs text-neutral-800">Mohon sesuaikan data yang diisi otomatis dengan alamat usaha sebenarnya</span>
                  </Alert>
                  {mappingDropdown.map((dt, idx) => (
                    <FormGroup className="mb-5" key={idx}>
                      <Label name={dt.label} />
                      <Controller
                        control={control}
                        name={dt.name}
                        rules={{ required: 'Data harus diisi' }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              className="text-sm"
                              onChange={dt.onChange}
                              options={dt.options}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id.toString()}
                              placeholder={dt.placeholder}
                              isSearchable={false}
                              styles={dropdownStyles}
                            />
                            <small className="text-red-600">{dt.errors}</small>
                          </>
                        )}
                      />
                    </FormGroup>
                  ))}
                  <FormGroup className="mb-5">
                    <Label name="Detail Alamat" />
                    <Controller
                      control={control}
                      name="address"
                      rules={{ required: 'Data harus diisi' }}
                      render={({ field }) => (
                        <>
                          <textarea
                            {...field}
                            rows={3}
                            className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                            placeholder="Masukkan detail alamat"
                          />
                          <small className="text-red-600">
                            {errors.address?.message}
                          </small>
                        </>
                      )}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <Label name="Kode Pos" />
                    <Controller
                      control={control}
                      name="postal_code"
                      rules={{ required: 'Data harus diisi' }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            type="number"
                            pattern="\d*"
                            placeholder="Masukkan kode pos"
                            className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                          />
                          <small className="text-red-600">
                            {errors.postal_code?.message}
                          </small>
                        </>
                      )}
                    />
                  </FormGroup>
                  {mappingHiddenForm.map((a, index) => (
                    <Controller
                      key={index}
                      control={control}
                      name={a.name}
                      rules={{ required: 'Data harus diisi' }}
                      render={({ field }) => (
                        <input {...field} className="hidden" />
                      )}
                    />
                  ))}
                </>
              )}
              {featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') && (
                <FormGroup className="mb-5">
                <Label name="Status Kepemilikan Tempat Usaha" />
                <Controller
                  rules={{ required: 'Data harus diisi' }}
                  control={control}
                  name="ownership_id"
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={dropdown.data.homeOwnership}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        getOptionLabel={(option) => option.desc!}
                        getOptionValue={(option) => option.id?.toString()}
                        placeholder="Pilih status"
                        className={Number(watch('ownership_id')?.id) === Number(ownership_id) ? 'mb-2' : ''}
                      />
                      <small className="text-red-600">
                        {errors.ownership_id?.message}
                      </small>
                    </>
                  )}
                />
                {Number(watch('ownership_id')?.id) === Number(ownership_id) && (
                  <Controller
                    rules={{ required: 'Data harus diisi' }}
                    control={control}
                    name="ownership_other"
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          type="text"
                          className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                          placeholder="Masukan alasan lainnya"
                        />
                        <small className="text-red-600">
                          {errors.ownership_other?.message}
                        </small>
                      </>
                    )}
                  />
                )}
              </FormGroup>
              )}
              <div className="flex flex-1 flex-col justify-end h-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out font-semibold text-sm"
                >
                  Simpan dan Lanjut
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <Modal
        icon={
          modalMessage.title === 'Lokasi di Luar Jangkauan'
            ? iconWarningOutOfArea
            : iconWarningDisableLocation
        }
        iconStyle="w-max"
        showModal={showModal}
        title={modalMessage.title}
        description={modalMessage.description}
        withButton={showModal}
        handleClickButton={() => {
          setShowModal(false);
          setModalMessage({
            title: '',
            description: '',
          });
        }}
      />
    </>
  );
};

export default AlamatUsaha;

import React from "react";
import Select from "react-select";
import Input from "../../components/atom/Input";
import TextArea from "../../components/atom/TextArea";
import FlagIdRounded from '../../assets/icons/flagIdRounded.svg';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { AttendanceData } from "../../types/Attendance";
import { NAME_REGEX } from "../../utils/constants";
import { saveAttendance } from "../../redux/slices/event/eventThunk";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

type ParamsAttendence = {
  eventUuid: string;
  type: 'success' | 'expired';
}

const Form = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm<AttendanceData>();
  const dispatch = useAppDispatch()
  const params = useParams<ParamsAttendence>()
  const navigate = useNavigate()
  const { data: dataEvent } = useAppSelector(s => s.event.event)
  const { data: dataDealerConsultant } = useAppSelector(s => s.dealerConsultant.dealerConsultant)
  const { data: dataWarehouse } = useAppSelector(s => s.warehouse.warehouse)
  const eventUuid = params.eventUuid || ''
  const watchDc = watch('dc')

  const onSubmit: SubmitHandler<AttendanceData> = async (data) => {
    const { dc, addressShowroom, showroomName, fullName, phone, warehouse, phoneOther } = data
    const payload = {
      warehouse: {
        id: warehouse.value,
        name: warehouse.label
      },
      dc: {
        id: dc.value,
        fullName: dc.label,
        dcm: {
          id: dc.dcm?.id || null,
          fullName: dc.dcm?.fullName || null
        }
      },
      fullName: fullName,
      phone: phone,
      phoneOther: phoneOther,
      showroomName: showroomName,
      addressShowroom: addressShowroom,
      event_id: dataEvent.id
    }

    const res = await dispatch(saveAttendance(payload))

    if (res.meta.requestStatus === 'fulfilled') {
      toast.success("Success attend event", {
        autoClose: 2000,
        onClose: () => navigate(`/attendence/${eventUuid}/success`)
      })
    } else {
      const errMessage = (res.payload as any)?.message;

      toast.error(errMessage || 'Failed submit attendence');
    }
  }

  return (
    <div className="shadow p-4 rounded bg-white relative z-10">
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-neutral-800 text-xl font-bold mb-4">Isi Data Dirimu</h1>
        <div className="mb-4">
          <label className="mb-2 text-sm text-neutral-800 font-medium block">Nama Warehouse</label>
          <Controller
            rules={{
              required: 'Nama Warehouse harus diisi'
            }}
            control={control}
            name="warehouse"
            render={({ field }) => (
              <Select
                {...field}
                options={dataWarehouse}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                placeholder="Pilih Warehouse"
                className="text-sm"
              />
            )}
          />
          <small className="text-red-600">{errors.warehouse?.message}</small>
        </div>
        <div className="mb-4">
          <label className="mb-2 text-sm text-neutral-800 font-medium block">Nama DC</label>
          <Controller
            rules={{
              required: 'Nama DC harus diisi'
            }}
            control={control}
            name="dc"
            render={({ field }) => (
              <Select
                {...field}
                options={dataDealerConsultant}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                placeholder="Pilih DC"
                className="text-sm mb-1"
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value?.toString()}
                onChange={(v) => setValue('dc', v || { label: '', value: 0 })}
              />
            )}
          />
          {watchDc?.dcm?.fullName && <small className="block">Nama DCM: <strong>{watchDc?.dcm?.fullName}</strong></small>}
          <small className="text-red-600">{errors.dc?.message}</small>
        </div>
        <div className="mb-4">
          <label className="mb-2 text-sm text-neutral-800 font-medium block">Nama Kamu  (Pemilik Dealer)</label>
          <Controller
            rules={{
              required: 'Nama harus diisi',
              pattern: {
                value: NAME_REGEX,
                message: 'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
              }
            }}
            control={control}
            name="fullName"
            render={({ field }) => (
              <Input {...field} placeholder="Masukan Nama" className="text-sm" />
            )}
          />
          <small className="text-red-600">{errors.fullName?.message}</small>
        </div>
        <div className="mb-4">
          <label className="mb-2 text-sm text-neutral-800 font-medium block">Nomor HP (WhatsApp)</label>
          <small className="block mb-1 text-xs">Masukkan nomor <strong>tanpa 0</strong> di depan & pastikan nomor sama dengan yang didaftarkan di aplikasi</small>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 border-r">
              <img src={FlagIdRounded} /> +62
            </div>
            <Controller
              rules={{
                required: 'Nomor HP (WhatsApp) harus diisi',
              }}
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  type="number"
                  className="pl-24 text-gray-900 text-sm"
                  placeholder="contoh: 8123456789"
                  value={field.value}
                  onInput={(e) => {
                    if (e.target.value.startsWith('0')) {
                      field.onChange({target: { value: getValues('phone')?.toString().length > 0 ? getValues('phone') : '' }})
                    } else {
                      field.onChange(e)
                    }
                  }}
                />
              )}
            />
          </div>
          <small className="text-red-600">{errors.phone?.message}</small>
        </div>
        <div className="mb-4">
          <label className="mb-2 text-sm text-neutral-800 font-medium block">Nama Tempat Usaha (Showroom)</label>
          <Controller
            rules={{
              required: 'Nama Tempat Usaha (Showroom) harus diisi'
            }}
            control={control}
            name="showroomName"
            render={({ field }) => (
              <Input {...field} placeholder="Contoh: Andi Motor" className="text-sm" />
            )}
          />
          <small className="text-red-600">{errors.showroomName?.message}</small>
        </div>
        <div className="mb-4">
          <label className="mb-2 text-sm text-neutral-800 font-medium block">Alamat</label>
          <div className="p-3 rounded-lg text-sm text-neutral-800 border-neutral-300 border mb-2">Alamat Tempat Usaha (Showroom)</div>
          <Controller
            rules={{
              required: 'Alamat harus diisi'
            }}
            control={control}
            name="addressShowroom"
            render={({ field }) => (
              <TextArea {...field} placeholder="Contoh: Jl. Haji Mampang no.123" className="text-sm" />
            )}
          />
          <small className="text-red-600">{errors.addressShowroom?.message}</small>
        </div>
        <button
          type="submit"
          disabled={false}
          className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
        >
          Lanjut
        </button>
      </form>
    </div>
  )
}

export default Form
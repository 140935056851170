import React from 'react';

type ModalProps = {
  showModal: boolean;
  children: React.ReactNode;
  handleClickBackground?: () => void;
  fullWidth?: boolean;
  classNameModalContent?: string;
};

const CustomModal = (props: ModalProps) => {
  const { showModal, children, handleClickBackground, fullWidth, classNameModalContent } = props;
  return (
    <>
      <div
        className={`fixed inset-0 z-10 overflow-y-auto ${
          showModal ? '' : 'invisible'
        }`}
      >
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={handleClickBackground}
        ></div>
        <div className={`flex items-center min-h-screen ${fullWidth ? '' : 'px-4 py-8'}`}>
          <div className={`relative w-full ${fullWidth ? 'min-h-screen' : 'max-w-lg'} p-4 mx-auto bg-white rounded-md shadow-lg bg-primary ${classNameModalContent}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { getStatus } from '../../redux/slices/revision/revisionThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import RefreshOrange from '../../assets/icons/refresh-orange.svg'
import DocumentSuccess from '../../assets/icons/documentSuccess.svg'
import Checklist from '../../assets/icons/checklist.svg'
import Document from '../../assets/icons/document.svg'
import DocumentNonActive from '../../assets/icons/document-non-active.svg'
import {Status} from '../../types/Revision'

export default function Sukses() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { type, uuid } = useParams();
    const [status, setStatus] = useState<Status>();

    const LatestStatus = async () => {
        try {
            const status = await dispatch(getStatus({ uuid: uuid as string, type: type as string }));
            if (status.meta.requestStatus === 'rejected') {
                toast.error('Failed to load dealer detail');
            }
            setStatus(status.payload)
        } catch (error) {
            if (error instanceof AxiosError) {
                toast.error(error.message);
            }
        }
    };

    useEffect(() => {
        LatestStatus();
      }, [navigate]);

      const handleCallCS = () => {
        window.location.href = 'mailto:hello@mofi.id';
        return;
      };

    return (
        <div className='p-5'>
            <div className='pt-5 pb-5 bg-secondary-pure-white'>
                <img src='https://moladin.com/Logo.svg' />
            </div>
            <div className='text-center mb-5'>
                <div className='flex justify-center mb-5'>
                    <img src={DocumentSuccess}/>
                </div>
                <div className='font-metropolis font-bold text-18 leading-25'>Data Berhasil Dikirim!</div>
                <div className='text-sm'>Selanjutnya tim kami akan melakukan verifikasi data</div>
            </div>
            <hr/>
            <div className='flex justify-center mt-5 mb-5'>
                <span>Nama Akun  : </span>
                <span className='font-bold ml-2'>{status?.dealer_name}</span>
            </div>
            <div className='flex p-8 flex-col items-start gap-8 self-stretch rounded-lg bg-neutral-neutral-50 shadow-md   '>
                <div className='flex w-full flex justify-between items-center'>
                    <div className='flex'>
                        <img src={Checklist} className='mr-3 bg-[#eafaf1] rounded-full w-8 p-2'/>
                       <div className='self-center'> Lengkapi Data </div>
                    </div>
                    <span className='border rounded-lg bg-[#E6EAF8] text-blue-700 pl-5 pr-5 text-sm'>Revisi {status?.total_revision}</span>
                </div>
                <div className='flex'>
                {status?.latest_status === 'revision-submitted' ||status?.latest_status === 'contract-signing' ?
                        <img src={Document} className='mr-3 bg-[#E6EAF8] rounded-full w-8 p-2'/>
                        :
                        <img src={DocumentNonActive} className='mr-3 bg-[#E6EAF8] rounded-full w-8 p-2'/>
                    }
                    <div>Menunggu Review</div>
                </div>
                <div className='flex'>
                    {status?.latest_status === 'contract-signing' ?
                        <img src={Document} className='mr-3 bg-[#E6EAF8] rounded-full w-8 p-2'/>
                        :
                        <img src={DocumentNonActive} className='mr-3 bg-[#E6EAF8] rounded-full w-8 p-2'/>
                    }
                    <div>Tanda Tangan Perjanjian</div>
                </div>
                <div className='flex'>
                    {status?.latest_status === 'registered' ?
                        <img src={Document} className='mr-3 bg-[#E6EAF8] rounded-full w-8 p-2'/>
                        :
                        <img src={DocumentNonActive} className='mr-3 bg-[#E6EAF8] rounded-full w-8 p-2'/>
                    }
                    <div>Akun Dealer Terdaftar!</div>
                </div>
            </div>
            <button
                onClick={LatestStatus}
                className="bg-white rounded-lg h-11 w-full text-secondary border border-orange-500 mt-10"
            >
                <div className='flex justify-center'>
                    <img src={RefreshOrange} className="mr-3"/>
                    <p className="!mb-0">Perbarui Status</p>
                </div>
            </button>
            <div className="text-xs text-center mt-3">
              Butuh bantuan?{' '}
              <span
                className="font-semibold text-blue-700"
                onClick={() => handleCallCS()}
              >
                Hubungi CS
              </span>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { WaitingModalMessageType } from '../../types/WaitingPage';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { sendActiveConsent } from '../../redux/slices/employee/employeeThunk';
import { EmployeeWaitingStatus } from '../../utils/constants';
import { iconChecklistGreen } from '../../utils/assets';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getEmployeeDetailV2 } from '../../redux/slices/employee/employeeThunk';
import { DukcapilValidationRejectedFirstAttempt } from './DukcapilValidationRejected';
import DukcapilValidationRejectedSecondAttempt from './DukcapilValidationRejectedSecondAttempt';
import RejectedPage from './RejectedPage';
import Modal from '../../components/atom/Modal';
import Loading from '../../components/WaitingPage/employee/Loading';
import WaitingConsent from '../../components/WaitingPage/employee/WaitingConsent';
import DukcapilValidationRejected from './DukcapilValidationRejected';
import AfterValidation from '../../components/WaitingPage/employee/AfterValidation';
import { DukcapilValidationGAGAL } from './DukcapilGAGAL';

const WaitingPage = () => {
  const dispatch = useAppDispatch();
  const { uuid } = useParams();

  const dataEmployee = useAppSelector((state) => state.employee.employee);

  const [showModal, setShowModal] = useState(false);
  const [activePage, setActivePage] = useState<string>('loading');
  const [restartCountdown, setRestartCountdown] = useState(false);
  const [pageDisplay, setPageDisplay] = useState(<Loading />);
  const initialStateModalMessage = {
    title: '',
    description: '',
  };
  const [modalMessage, setModalMessage] = useState<WaitingModalMessageType>(
    initialStateModalMessage
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage(initialStateModalMessage);
    setRestartCountdown(true);
  };

  const handleRefreshStatus = async () => {
    getDataEmployee();
  };

  const handleResendTnc = async () => {
    const res = await dispatch(sendActiveConsent({ uuid: uuid as string }));
    const requestStatus = res.meta.requestStatus;
    if (requestStatus === 'fulfilled') {
      setModalMessage({
        title: 'Terkirim',
        description: 'Syarat & Ketentuan sudah dikirim ulang ke nomor WA kamu',
      });
      setShowModal(true);
    }
    if (requestStatus === 'rejected') {
      toast.error('Syarat & Ketentuan tidak terkirim');
    }
  };

  const handleResendAgreement = () => {
    setModalMessage({
      title: 'Berhasil mengirim ulang',
      description:
        'Kontrak Kerja sudah dikirim ke nomor WA kamu untuk ditandatangani',
    });
    setShowModal(true);
  };

  const handleCallCS = () => {
    window.location.href = 'mailto:hello@mofi.id';
    return;
  };

  const getDataEmployee = () => {
    dispatch(getEmployeeDetailV2(uuid as string));
  };

  useEffect(() => {
    getDataEmployee();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (dataEmployee?.data?.latest_status) {
        setActivePage(dataEmployee?.data?.latest_status);
      }
    }
  }, [dataEmployee.data?.latest_status]);

  useEffect(() => {
    if (dataEmployee.loading) {
      setPageDisplay(<Loading />);
    } else {
      switch (activePage) {
        case EmployeeWaitingStatus.DOCUMENT_SUBMITTED:
          setPageDisplay(
            <Loading
              isDocumentSubmitted={true}
              getDataEmployee={getDataEmployee}
            />
          );
          break;
        case EmployeeWaitingStatus.WAITING_FOR_ACTIVE_CONSENT:
          setPageDisplay(
            <WaitingConsent
              handleRefreshStatus={handleRefreshStatus}
              handleResendTnc={handleResendTnc}
              restartCountdown={restartCountdown}
              setRestartCountdown={setRestartCountdown}
            />
          );
          break;
        case EmployeeWaitingStatus.INITIAL_REVIEW:
        case EmployeeWaitingStatus.REVIEW_DATA:
        case EmployeeWaitingStatus.DATA_DIRI:
        case EmployeeWaitingStatus.DATA_PELENGKAP:
        case EmployeeWaitingStatus.ALAMAT_KANDIDAT:
        case EmployeeWaitingStatus.BACKGROUND_CHECK:
        case EmployeeWaitingStatus.FINAL_REVIEW:
        case EmployeeWaitingStatus.CONTRACT_SIGNING:
        case EmployeeWaitingStatus.WAITING_FOR_JOIN_DATE:
        case EmployeeWaitingStatus.ACTIVE:
          setPageDisplay(
            <AfterValidation
              handleRefreshStatus={handleRefreshStatus}
              handleResendAgreement={handleResendAgreement}
              handleCallCS={handleCallCS}
            />
          );
          break;
        // case EmployeeWaitingStatus.DUKCAPIL_VALIDATION_REJECTED:
        //   setPageDisplay(
        //     <DukcapilValidationRejected handleTryAgain={handleRefreshStatus} />
        //   );
        //   break;
        case EmployeeWaitingStatus.DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT:
          setPageDisplay(<DukcapilValidationRejectedFirstAttempt />);
          break;
        case EmployeeWaitingStatus.DUKCAPIL_VALIDATION_REJECTED:
            setPageDisplay(<DukcapilValidationGAGAL />);
            break;
        case EmployeeWaitingStatus.DUKCAPIL_VALIDATION_REJECTED_SECOND_ATTEMPT:
          setPageDisplay(
            <DukcapilValidationRejectedSecondAttempt
              handleCallCS={handleCallCS}
            />
          );
          break;
        case EmployeeWaitingStatus.REJECTED:
        case EmployeeWaitingStatus.INTERNAL_VALIDATION_REJECTED:
          setPageDisplay(<RejectedPage dataEmployee={dataEmployee} />);
          break;
        case EmployeeWaitingStatus.CANCELLED:
          setPageDisplay(<RejectedPage dataEmployee={dataEmployee} />);
          break;
      }
    }
  }, [activePage, dataEmployee, restartCountdown]);

  return (
    <>
      {pageDisplay}
      {showModal && (
        <Modal
          icon={iconChecklistGreen}
          iconStyle="h-60 py-4"
          title={modalMessage.title}
          description={modalMessage.description}
          showModal={showModal}
          withButton={true}
          buttonTitle="Tutup"
          handleClickButton={handleCloseModal}
        />
      )}
    </>
  );
};

export default WaitingPage;

import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormGroup from '../../atom/FormGroup';
import Label from '../../atom/Label';
import TakePhoto from '../../FotoIdentitas/TakePhoto/TakePhoto';
import PopupGuide from '../../FotoIdentitas/PopupGuide/PopupGuide';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ErrorFieldObject, FotoIdentitasRequest } from '../../../types/Dealer';
import {
  CameraProperty,
  CommonTypesFotoIdentitas,
  ContentControler,
} from '../../../types/FotoIdentitasTypes';
import { uploadImageFile } from '../../../services';
import { saveDraft } from '../../../redux/slices/dealer/dealerThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_SECTION_FORM } from '../../../utils/constants';
import { toast } from 'react-toastify';
import ErrorAlert from '../../atom/ErrorAlert';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import { featureFlag } from '../../../utils/helper';
import HeaderEvent from '../../Header/HeaderEvent';

const initialContentControler: ContentControler = {
  showCamera: false,
  showPopup: false,
  contentType: '',
};

const initialFormData: CommonTypesFotoIdentitas = {
  ktp_photo_url: '',
  family_card_url: '',
  selfie_url: '',
};

const initialCameraProperty: CameraProperty = {
  facing_mode: 'environment',
};

interface ErrorsAttemptObject {
  selfie_url?: {
    title: string;
    message: string;
  } | null;
  ktp_photo_url?: {
    title: string;
    message: string;
  } | null;
}

interface ErrorAttemptValidation {
  isError: boolean;
  title: string;
  message: string;
}

const FotoIdentitas = () => {
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const dataDealer = useAppSelector((state) => state.dealer.dealer);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CommonTypesFotoIdentitas>({
    mode: 'onChange',
    defaultValues: initialFormData,
  });
  const [contentControler, setContentControler] = useState(
    initialContentControler
  );
  const [cameraPorperty, setCameraProperty] = useState(initialCameraProperty);
  const [errorSelfieValidation, setErrorSelfieValidation] =
    useState<ErrorAttemptValidation>({
      isError: false,
      title: '',
      message: '',
    });

  const handleClickShowPopupGuide = (type: string) => {
    setContentControler({
      ...contentControler,
      showPopup: true,
      contentType: type,
    });
  };

  const handleClickClosePopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: false,
      contentType: '',
    });
  };

  const handleConfirmPopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: false,
      showCamera: true,
    });
  };

  const handleBackToPopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: true,
      showCamera: false,
    });
  };

  const handleConfirmPhotoTaken = (srcUri: string, setValue: any) => {
    if (errors[contentControler.contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (
        errors[contentControler.contentType as keyof ErrorsAttemptObject]
          ?.type == 'custom'
      ) {
        clearErrors(
          contentControler.contentType as keyof CommonTypesFotoIdentitas
        );
      }
    }

    if (
      errors[contentControler.contentType as keyof CommonTypesFotoIdentitas]
    ) {
      // error basic clear
      clearErrors(
        contentControler.contentType as keyof CommonTypesFotoIdentitas
      );
    }

    setValue(contentControler.contentType, srcUri);
    setContentControler({
      ...contentControler,
      ...initialContentControler,
    });
  };

  const handleConfirmDrawerFileTaken = (
    srcUri: string,
    setValue: any,
    contentType: string
  ) => {
    if (errors[contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (errors[contentType as keyof ErrorsAttemptObject]?.type == 'custom') {
        clearErrors(contentType as keyof CommonTypesFotoIdentitas);
      }
    }
    if (errors[contentType as keyof CommonTypesFotoIdentitas]) {
      // error basic clear
      clearErrors(contentType as keyof CommonTypesFotoIdentitas);
    }
    setValue(contentType, srcUri);
    setContentControler({
      ...contentControler,
      ...initialContentControler,
    });
  };

  const handleRemovePhoto = (type: string, cb: any) => {
    cb(type, '');
  };

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<CommonTypesFotoIdentitas> = async (data) => {
    const payload: FotoIdentitasRequest = {
      foto_identitas: {
        ...data,
      },
    };
    const res = await dispatch(
      saveDraft({ data: payload, uuid: uuid as string })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/dealer/registration/${uuid}/${PAGE_SECTION_FORM.ALAMAT}`);
    }
    if (res.meta.requestStatus === 'rejected') {
      const errMessage = (res.payload as any)?.errors[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  useEffect(() => {
    const errorField = dataDealer.data.error_field;
    const fotoIdentitasData = dataDealer.data.foto_identitas;
    fotoIdentitasData &&
      Object.keys(fotoIdentitasData).map((k: string) => {
        setValue(
          k as keyof CommonTypesFotoIdentitas,
          fotoIdentitasData[k as keyof CommonTypesFotoIdentitas]
        );
      });
    const dealer = dataDealer.data;
    if (dealer.error_field) {
      // check for initial error_field state undefined or null
      //check error field is empty
      const isEmptyErrorField = Array.isArray(dealer.error_field);
      if (!isEmptyErrorField) {
        // check is error in current section
        const section = Object.keys(dealer.error_field)[0];
        if (section === 'foto_identitas') {
          // populate field error (can be hardcoded since validation vida foto_identitas only selfie_url field)
          const errorData =
            dealer.error_field[`${section}` as keyof ErrorFieldObject];
          setErrorSelfieValidation({
            isError: true,
            title: errorData['selfie_url']['title'] || '',
            message: errorData['selfie_url']['message'] || '',
          });
        }
      }
    }
  }, [dataDealer.data]);

  useEffect(() => {
    switch (contentControler.contentType) {
      case 'selfie_url':
        setCameraProperty({
          ...cameraPorperty,
          facing_mode: 'user',
        });
        break;

      default:
        setCameraProperty({
          ...cameraPorperty,
          ...initialCameraProperty,
        });
        break;
    }
  }, [contentControler]);

  useEffect(() => {
    if (errorSelfieValidation.isError) {
      setError('selfie_url', {
        type: 'custom',
        message: errorSelfieValidation.title,
      });
    }
  }, [errorSelfieValidation]);

  return (
    <>
      {featureFlag('REACT_APP_SPRINT_9_1_DEALER_ONBOARDING_GATHERING') ? <HeaderEvent data={dataDealer?.data} /> : ''}

      <form
        className="flex-1 flex flex-col justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!contentControler.showPopup && !contentControler.showCamera && (
          <>
            <div>
              {errorSelfieValidation.isError && (
                <div>
                  <div className="mb-5">
                    <ErrorAlert
                      title={errorSelfieValidation.title}
                      message={errorSelfieValidation.message}
                    />
                  </div>
                </div>
              )}
              <FormGroup className="mb-4">
                <Label name="Foto KTP" />
                <Controller
                  rules={{ required: 'Foto KTP harus diisi' }}
                  control={control}
                  name="ktp_photo_url"
                  render={({ field }) => (
                    <InputTakePhotoWithDrawer
                      imageLabel="Foto KTP"
                      placeholder="Ambil foto Ktp"
                      onSelectCamera={() =>
                        handleClickShowPopupGuide('ktp_photo_url')
                      }
                      srcUri={field.value}
                      handleRemovePhoto={() =>
                        handleRemovePhoto('ktp_photo_url', setValue)
                      }
                      onBrowseFile={() =>
                        setContentControler({
                          ...contentControler,
                          contentType: 'ktp_photo_url',
                        })
                      }
                      onSelectFile={(src) => {
                        handleConfirmDrawerFileTaken(
                          src,
                          setValue,
                          'ktp_photo_url'
                        );
                      }}
                    />
                  )}
                />
                <small className="text-red-600">
                  {errors.ktp_photo_url?.message}
                </small>
              </FormGroup>
              <FormGroup className="mb-4">
                <Label name="Kartu Keluarga" />
                <Controller
                  rules={{ required: 'Foto KK harus diisi' }}
                  control={control}
                  name="family_card_url"
                  render={({ field }) => (
                    <InputTakePhotoWithDrawer
                      imageLabel="Foto KK"
                      placeholder="Ambil foto KK"
                      onSelectCamera={() =>
                        handleClickShowPopupGuide('family_card_url')
                      }
                      srcUri={field.value}
                      handleRemovePhoto={() =>
                        handleRemovePhoto('family_card_url', setValue)
                      }
                      onBrowseFile={() =>
                        setContentControler({
                          ...contentControler,
                          contentType: 'family_card_url',
                        })
                      }
                      onSelectFile={(src) => {
                        handleConfirmDrawerFileTaken(
                          src,
                          setValue,
                          'family_card_url'
                        );
                      }}
                    />
                  )}
                />
                <small className="text-red-600">
                  {errors.family_card_url?.message}
                </small>
              </FormGroup>
              <FormGroup className="mb-4">
                <Label name="Foto Selfie Wajah" />
                <Controller
                  rules={{ required: 'Foto Selfie harus diisi' }}
                  control={control}
                  name="selfie_url"
                  render={({ field }) => (
                    <InputTakePhotoWithDrawer
                      imageLabel="Foto Selfie"
                      placeholder="Ambil foto selfie"
                      onSelectCamera={() =>
                        handleClickShowPopupGuide('selfie_url')
                      }
                      srcUri={field.value}
                      handleRemovePhoto={() =>
                        handleRemovePhoto('selfie_url', setValue)
                      }
                      onBrowseFile={() =>
                        setContentControler({
                          ...contentControler,
                          contentType: 'selfie_url',
                        })
                      }
                      onSelectFile={(src) => {
                        handleConfirmDrawerFileTaken(src, setValue, 'selfie_url');
                      }}
                    />
                  )}
                />
                <small className="text-red-600">
                  {errors.selfie_url?.message}
                </small>
              </FormGroup>
            </div>
            <button
              type="submit"
              disabled={dataDealer.loading}
              className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
            >
              <p className="!mb-0">Simpan dan Lanjut</p>
            </button>
          </>
        )}
        {contentControler.showPopup && (
          <PopupGuide
            handleClose={handleClickClosePopupGuide}
            handleConfirm={handleConfirmPopupGuide}
            contentType={contentControler.contentType}
          />
        )}
        {contentControler.showCamera && (
          <TakePhoto
            handleConfirmPhoto={(src) => handleConfirmPhotoTaken(src, setValue)}
            handleBack={handleBackToPopupGuide}
            cameraProperty={cameraPorperty}
            cameraType={contentControler.contentType}
            onUploadPhotoTaken={uploadImageFile}
          />
        )}
      </form>
    </>
  );
};

export default FotoIdentitas;

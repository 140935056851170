import React from 'react';

type CustomWrapperProps = {
  title: string;
  children: React.ReactNode;
  bodyClassName?: string;
  icon: JSX.Element | JSX.Element[];
};

const CustomWrapper = (props: CustomWrapperProps) => {
  const { title, children, bodyClassName, icon } = props;
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-white flex flex-1 flex-col">
      <div className="pt-4 bg-primary">
        <div className="flex items-center px-5 text-white pb-4">
          {icon}
          <div className="w-full bg-primary pr-4">
            <p className="text-center font-semibold">{title}</p>
          </div>
          <div />
        </div>
      </div>
      <div className={bodyClassName}>{children}</div>
    </div>
  );
};

export default CustomWrapper;

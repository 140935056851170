import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { PAGE_SECTION_FORM, WaitingStatus } from '../../utils/constants';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import HeaderEmployee from '../../components/Header/HeaderEmployee';
import HeaderEmployeeV2 from '../../components/Header/HeaderEmployeeV2';
import { getEmployeeDetailV2 } from '../../redux/slices/employee/employeeThunk';
import InformasiPribadiEmployee from '../../components/Section/employee/InformasiPribadiEmployee';
import FotoIdentitas from '../../components/Section/employee/FotoIdentitas';
import InformasiAlamat from '../../components/Section/employee/InformasiAlamat';
import KeluargaDiMoladin from '../../components/Section/employee/KeluargaDiMoladin';
import PekerjaanSebelumnya from '../../components/Section/employee/PekerjaanSebelumnya';
import CV from '../../components/Section/employee/CV';
import FotoHasilKTP from '../../components/Section/employee/OCRV2/FotoKTP';
import FotoHasilKK from '../../components/Section/employee/OCRV2/FotoKK';
import DataPelengkap from '../../components/Section/employee/OCRV2/DataPelengkap';
import TnCPage from './TnCPage';

const FormPageEmployee = () => {
  const { section_type, uuid } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.employee);

  const latestStatus = data.employee.data?.latest_status;

  const getDetaiEmployee = async () => {
    try {
      const employee = await dispatch(getEmployeeDetailV2(uuid as string));
      if (employee.meta.requestStatus === 'rejected') {
        toast.error('Failed to load employee detail');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    if (
      latestStatus &&
      latestStatus !== WaitingStatus.WAITING_REGISTRATION_FORM &&
      latestStatus !== WaitingStatus.DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT &&
      latestStatus !== WaitingStatus.DUKCAPIL_VALIDATION_REJECTED
    ) {
      navigate(`/employee/waiting-page/${uuid}`);
    }
  }, [data]);

  useEffect(() => {
    getDetaiEmployee();
  }, [navigate]);

  let page = undefined;
  let percentage = 0;
  let isOCRV2 = false;

  switch (section_type) {
    // Input your form here
    case PAGE_SECTION_FORM.INFORMASI_PRIBADI:
      page = <InformasiPribadiEmployee />;
      percentage = 0;
      break;
    case PAGE_SECTION_FORM.FOTO_IDENTITAS:
      page = <FotoIdentitas />;
      percentage = 15;
      break;
    case PAGE_SECTION_FORM.ALAMAT:
      page = <InformasiAlamat />;
      percentage = 35;
      break;
    case PAGE_SECTION_FORM.KELUARGA:
    case PAGE_SECTION_FORM.KELUARGA_V2:
      page = <KeluargaDiMoladin />;
      percentage = 65;
      isOCRV2 = data.employee.data.last_section_id > 8;
      break;
    case PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA:
    case PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA_V2:
      page = <PekerjaanSebelumnya />;
      percentage = 80;
      isOCRV2 = data.employee.data.last_section_id > 8;
      break;
    case PAGE_SECTION_FORM.CV:
    case PAGE_SECTION_FORM.CVV2:
      page = <CV />;
      percentage = 90;
      isOCRV2 = data.employee.data.last_section_id > 8;
      break;

    // OCR V2
    case PAGE_SECTION_FORM.FOTO_HASIL_KTP:
      page = <FotoHasilKTP />;
      percentage = 0;
      isOCRV2 = true;
      break;
    case PAGE_SECTION_FORM.FOTO_HASIL_KK:
      page = <FotoHasilKK />;
      percentage = 15;
      isOCRV2 = true;
      break;
    case PAGE_SECTION_FORM.DATA_PELENGKAP_ALAMAT:
      page = <DataPelengkap />;
      percentage = 35;
      isOCRV2 = true;
      break;
    case PAGE_SECTION_FORM.TNC:
      page = <TnCPage />;
      percentage = 35;
      isOCRV2 = true;
      break;
    default:
      page = <div className='text-center mt-3'>404 Not Found</div>;
  }


  return (
    <div className="min-h-screen flex flex-col">
      {
        isOCRV2 ?
          <HeaderEmployeeV2
            percentage={percentage}
            activeSession={section_type as string}
          /> :
          <HeaderEmployee
            percentage={percentage}
            activeSession={section_type as string}
          />
      }
      <div className="p-4 flex-1 flex flex-col">{page}</div>
    </div>
  );
};

export default FormPageEmployee;

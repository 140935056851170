import React from 'react';
import MoladinIcon from '../../../assets/icons/moladinIcon.svg';
import Approval from '../../../assets/svg/approval.svg';
import 'flatpickr/dist/flatpickr.css';

const InformasiRekeningEmployeeBerhasil = () => {
  return (
    <div className="px-4 flex flex-1 flex-col">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className="-mx-4" />
      </div>
      <div className="flex flex-col items-center h-[80vh] justify-center">
        <img className="w-1/4 max-w-xs" src={Approval} alt="berhasil" />
        <h3 className="text-center font-bold text-lg mt-2">
          Berhasil menyimpan rekening
        </h3>
        <h6 className="text-sm text-center mt-2">
          Silakan hubungi PIC kamu untuk tahap selanjutnya
        </h6>
      </div>
    </div>
  );
};

export default InformasiRekeningEmployeeBerhasil;

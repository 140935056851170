import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AttendancePayload } from '../../../types/Attendance'

export const saveAttendance = createAsyncThunk(
  'Attendance/submit',
  async (
    request: AttendancePayload,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ecosystem/attendance/submit`,
        request
      )

      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const getEventByIdOrCode = createAsyncThunk(
  'event/fetchData',
  async (
    request: number | string,
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/event/${request}/detail`
      )

      return response.data
    } catch (error: any) {
        throw error.response.data
    }
  }
)
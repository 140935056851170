import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DropdownMasterInformasiAlamat } from '../../../types/InformasiAlamatTypes';

export const getDropdownProvinceAddress = createAsyncThunk(
  'getDropdownProvinceAddress/fetchData',
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

type GetDropdownAddress = {
  queryParams: string;
  type: string;
};

export const getDropdownCityAddress = createAsyncThunk(
  'getDropdownCityAddress/fetchData',
  async (payload: GetDropdownAddress, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${payload?.queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownSubdistrictAddress = createAsyncThunk(
  'getDropdownSubdistrictKtp/fetchData',
  async (payload: GetDropdownAddress, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${payload?.queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownVillageAddress = createAsyncThunk(
  'getDropdownVillageKtp/fetchData',
  async (payload: GetDropdownAddress, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${payload?.queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getParentAddressByVillage = createAsyncThunk(
  'getParentAddressByVillage/fetchData',
  async (villageName: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?levels=5&limit=300&nested=parent&name=${villageName}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

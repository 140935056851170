import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import FormGroup from '../../atom/FormGroup';
import Label from '../../atom/Label';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Calendar } from 'react-feather';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../atom/Input';
import ReactFlagsSelect from 'react-flags-select';
import { PatternFormat } from 'react-number-format';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getDropdown } from '../../../redux/slices/dealer/dealerThunk';
import { DropdownMaster } from '../../../types/commonTypes';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ErrorAlert from '../../atom/ErrorAlert';
import { ErrorFieldObject } from '../../../types/Dealer';
import { saveDraft } from '../../../redux/slices/employee/employeeThunk';
import { NAME_REGEX } from '../../../utils/constants';

type FormData = {
  uuid: string;
  full_name: string;
  dob: string;
  place_birth: string;
  phone_number?: string;
  personal_npwp: string;
  ktp_number: string;
  gender: string;
  email: string;
  marriage_id: DropdownMaster;
  spouse_ktp_name: string;
  spouse_ktp_number: string;
  spouse_dob: string;
  spouse_phone_number: string;
};

interface ErrorValidationNIK {
  isError: boolean;
  title: string;
  message: string;
}

interface ErrorResponse {
  label?: string;
  message?: string;
}

const InformasiPribadiEmployee = () => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>();

  const dispatch = useAppDispatch();
  const dropdown = useAppSelector((state) => state.dealer.dropdown);
  const { data: employee, loading: isLoading } = useAppSelector(
    (state) => state.employee.employee
  );
  const navigate = useNavigate();
  const { uuid } = useParams();

  const watchMarriage = watch('marriage_id');
  const watchKtpNumber = watch('ktp_number');

  const [errorValdiationNIK, setErrorValidationNIK] =
    useState<ErrorValidationNIK>({
      isError: false,
      title: '',
      message: '',
    });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload = {
      informasi_pribadi: {
        ...data,
        marriage_id: data.marriage_id.id,
        marriage_name: data.marriage_id.desc,
        dob: dayjs(data.dob).format('YYYY-MM-DD'),
      },
    };

    if (getValues('spouse_dob')) {
      payload.informasi_pribadi.spouse_dob = dayjs(
        getValues('spouse_dob')
      ).format('YYYY-MM-DD');
    }

    delete payload.informasi_pribadi.phone_number;

    let allowSubmit = true;

    if (errorValdiationNIK.isError) {
      if (
        employee.informasi_pribadi.ktp_number ==
        payload.informasi_pribadi.ktp_number
      ) {
        setError('ktp_number', {
          type: 'custom',
          message: errorValdiationNIK.title,
        });
        allowSubmit = false;
      }
    }

    if (allowSubmit) {
      const res = await dispatch(
        saveDraft({ data: payload, uuid: uuid as string })
      );

      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/employee/registration/${uuid}/foto_identitas`);
      } else {
        let error = null;

        const r: any = res.payload;

        const errEmail = r.errors.find(
          (i: ErrorResponse) => i.label === 'email'
        );
        const errKTP = r.errors.find(
          (i: ErrorResponse) => i.label === 'ktp_number'
        );

        if (errEmail) {
          error = errEmail.message;

          setError('email', {
            type: 'custom',
            message: error,
          });
        }

        if (errKTP) {
          error = errKTP.message;

          setError('ktp_number', {
            type: 'custom',
            message: error,
          });
        }

        if (!error) {
          const errMessage = (res.payload as any)?.errors[0]?.message;
          toast.error(errMessage || 'Failed submit registration');
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getDropdown());
  }, []);

  useEffect(() => {
    const dropdownMarriage = dropdown.data.marriage?.filter(
      (item: DropdownMaster) =>
        item.id === employee.informasi_pribadi?.marriage_id
    );

    setValue('full_name', employee.informasi_pribadi?.full_name);
    setValue('phone_number', employee.informasi_pribadi?.phone_number);
    setValue('email', employee.informasi_pribadi?.email);
    setValue('gender', employee.informasi_pribadi?.gender);
    setValue('ktp_number', employee.informasi_pribadi?.ktp_number);
    setValue('personal_npwp', employee.informasi_pribadi?.personal_npwp);
    setValue('place_birth', employee.informasi_pribadi?.place_birth);
    setValue('spouse_ktp_name', employee.informasi_pribadi?.spouse_ktp_name);
    setValue(
      'spouse_ktp_number',
      employee.informasi_pribadi?.spouse_ktp_number
    );
    setValue('spouse_dob', employee.informasi_pribadi?.spouse_dob);
    setValue(
      'spouse_phone_number',
      employee.informasi_pribadi?.spouse_phone_number
    );
    setValue('marriage_id', dropdownMarriage?.[0]);
    if (employee.informasi_pribadi?.dob !== null) {
      setValue('dob', dayjs(employee.informasi_pribadi?.dob).format());
    }
    if (employee.error_field) {
      // check for initial error_field state undefined or null
      //check error field is empty
      const isEmptyErrorField = Array.isArray(employee.error_field);
      if (!isEmptyErrorField) {
        // check is error in current section
        const section = Object.keys(employee.error_field)[0];
        if (section === 'informasi_pribadi') {
          // populate field error (can be hardcoded since validation vida informasi_pribadi only ktp_number field)
          const errorData =
            employee.error_field[`${section}` as keyof ErrorFieldObject];
          setErrorValidationNIK({
            isError: true,
            title: errorData['ktp_number']['title'] || '',
            message: errorData['ktp_number']['message'] || '',
          });
        }
      }
    }
  }, [dropdown, employee?.informasi_pribadi]);

  useEffect(() => {
    if (errorValdiationNIK.isError) {
      setError('ktp_number', {
        type: 'custom',
        message: errorValdiationNIK.title,
      });
    }
  }, [errorValdiationNIK]);

  useEffect(() => {
    if (errorValdiationNIK.isError) {
      if (watchKtpNumber !== employee.informasi_pribadi?.ktp_number) {
        if (errors.ktp_number && errors.ktp_number?.type === 'custom') {
          clearErrors('ktp_number');
        }
      }
      // changes with same value
      if (watchKtpNumber === employee.informasi_pribadi?.ktp_number) {
        setError('ktp_number', {
          type: 'custom',
          message: errorValdiationNIK.title,
        });
      }
    }
  }, [watchKtpNumber]);

  return (
    <div>
      {errorValdiationNIK.isError && (
        <div className="mb-5">
          <ErrorAlert
            title={errorValdiationNIK.title}
            message={errorValdiationNIK.message}
            isMessageHtml={true}
          />
        </div>
      )}
      <h3 className="font-semibold mb-4">Informasi Pribadi</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label name="Nama Lengkap (sesuai KTP)" />
          <Controller
            rules={{
              required: 'Data harus diisi',
              pattern: {
                value: NAME_REGEX,
                message:
                  'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
              },
            }}
            control={control}
            name="full_name"
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama di sini" />
            )}
          />
          <small className="text-red-600">{errors.full_name?.message}</small>
        </FormGroup>

        <FormGroup>
          <Label name="Nomor HP" />
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <div className="relative">
                <ReactFlagsSelect
                  selected={'ID'}
                  disabled
                  className="absolute disabled:bg-[#F3F4F6]"
                  onSelect={(code) => console.log(code)}
                  countries={['ID', 'SG']}
                  customLabels={{ ID: '+62', SG: '+65' }}
                />

                <Input
                  {...field}
                  disabled
                  type="number"
                  className="pl-[120px] disabled:bg-[#F3F4F6]"
                  placeholder="No HP"
                />
              </div>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label name="Email" />
          <Controller
            control={control}
            rules={{
              required: 'Data harus diisi',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Format email salah',
              },
            }}
            name="email"
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan alamat email" />
            )}
          />
          <small className="text-red-600">{errors.email?.message}</small>
        </FormGroup>

        <FormGroup>
          <Label name="NIK" />
          <Controller
            rules={{
              required: 'Data harus diisi',
              pattern: {
                value:
                  /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}(?!0000)\d{4}$/,
                message: 'NIK salah. Mohon isi yang sesuai',
              },
            }}
            control={control}
            name="ktp_number"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                pattern="\d*"
                maxLength={16}
                placeholder="Masukkan 16 digit nomor KTP"
              />
            )}
          />
          <small className="text-red-600">{errors.ktp_number?.message}</small>
        </FormGroup>

        <FormGroup className="relative">
          <Label name="Tempat dan Tanggal Lahir" />
          <Controller
            control={control}
            rules={{ required: 'Data harus diisi' }}
            name="place_birth"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan kota tempat lahir"
                className=""
              />
            )}
          />
          <small className="text-red-600">{errors.place_birth?.message}</small>

          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="dob"
            render={({ field: { onChange, value } }) => (
              <div className="relative">
                <Flatpickr
                  onChange={onChange}
                  options={{
                    disableMobile: true,
                    minDate: dayjs().subtract(65, 'year').format('YYYY-MM-DD'),
                  }}
                  value={value}
                  placeholder="Pilih tanggal lahir"
                  className="w-full cursor-pointer border rounded-lg h-11 p-3 mt-4 focus:outline-primary"
                />
                <Calendar className="absolute top-[25px] right-5" />
                <small className="text-red-600">{errors.dob?.message}</small>
              </div>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label name="Jenis Kelamin" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="gender"
            render={({ field }) => (
              <>
                <div className="flex space-x-2">
                  <div className="flex border rounded-lg items-center justify-center w-full py-3">
                    <input
                      {...field}
                      id="laki"
                      type="radio"
                      className="h-4 accent-primary w-4 rounded-lg border p-3"
                      placeholder="Jenis Kelamin"
                      checked={field.value === 'L'}
                      value="L"
                    />
                    <label htmlFor="laki" className="font-medium text-sm ml-3">
                      Laki-laki
                    </label>
                  </div>
                  <div className="flex border rounded-lg items-center justify-center w-full py-3">
                    <input
                      {...field}
                      type="radio"
                      id="perempuan"
                      checked={field.value === 'P'}
                      className="h-4 accent-primary w-4 rounded-lg border p-3"
                      placeholder="Jenis Kelamin"
                      value="P"
                    />
                    <label
                      htmlFor="perempuan"
                      className="font-medium text-sm ml-3"
                    >
                      Perempuan
                    </label>
                  </div>
                </div>
                <small className="text-red-600">{errors.gender?.message}</small>
              </>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label name="NPWP Pribadi" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="personal_npwp"
            render={({ field }) => (
              <>
                <PatternFormat
                  {...field}
                  format="##.###.###.#-###.###"
                  mask="_"
                  placeholder="Masukkan nomor NPWP"
                  className="border w-full h-11 rounded-lg p-3 focus:outline-primary"
                />
                <small className="text-red-600">
                  {errors.personal_npwp?.message}
                </small>
              </>
            )}
          />
        </FormGroup>

        <FormGroup className="mb-5">
          <Label name="Status Pernikahan" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="marriage_id"
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  isSearchable={false}
                  options={dropdown.data.marriage}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  getOptionLabel={(option) => option.desc!}
                  getOptionValue={(option) => option.id?.toString()}
                  placeholder="Pilih status pernikahan"
                />

                <small className="text-red-600">
                  {errors.marriage_id?.message}
                </small>
              </>
            )}
          />
        </FormGroup>

        {(watchMarriage?.id === 7 ||
          employee.informasi_pribadi?.marriage_id === 7) && (
          <>
            <FormGroup>
              <Label name="Nama Pasangan" />
              <Controller
                rules={{
                  required: 'Data harus diisi',
                  pattern: {
                    value: NAME_REGEX,
                    message:
                      'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
                  },
                }}
                control={control}
                name="spouse_ktp_name"
                render={({ field }) => (
                  <>
                    <Input {...field} placeholder="Masukkan nama pasangan" />
                    <small className="text-red-600">
                      {errors.spouse_ktp_name?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>

            <FormGroup>
              <Label name="Nomor HP Pasangan" />
              <Controller
                control={control}
                rules={{
                  required: 'Data harus diisi',
                  pattern: {
                    value: /^[0-9]*$/g,
                    message: 'Format nomor HP salah',
                  },
                }}
                name="spouse_phone_number"
                render={({ field }) => (
                  <div className="relative">
                    <ReactFlagsSelect
                      selected={'ID'}
                      className="absolute disabled:bg-[#F3F4F6]"
                      onSelect={(code) => console.log(code)}
                      countries={['ID']}
                      customLabels={{ ID: '+62', SG: '+65' }}
                    />

                    <Input
                      {...field}
                      pattern="\d*"
                      type="number"
                      className="pl-[120px] disabled:bg-[#F3F4F6]"
                      placeholder="Masukkan nomor HP pasangan"
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      maxLength={14}
                    />
                    <small className="text-red-600">
                      {errors.spouse_phone_number?.message}
                    </small>
                  </div>
                )}
              />
            </FormGroup>

            <FormGroup>
              <Label name="NIK Pasangan" />
              <Controller
                rules={{
                  required: 'Data harus diisi',
                  pattern: {
                    value:
                      /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/,
                    message: 'NIK pasangan salah. Mohon isi yang sesuai',
                  },
                }}
                control={control}
                name="spouse_ktp_number"
                render={({ field }) => (
                  <Input
                    {...field}
                    pattern="\d*"
                    type="number"
                    maxLength={16}
                    placeholder="Masukkan NIK pasangan"
                  />
                )}
              />
              <small className="text-red-600">
                {errors.spouse_ktp_number?.message}
              </small>
            </FormGroup>

            <FormGroup className="relative">
              <Label name="Tanggal Lahir Pasangan" />

              <Controller
                control={control}
                rules={{
                  required: 'Data harus diisi',
                }}
                name="spouse_dob"
                render={({ field: { onChange, value } }) => (
                  <div className="relative">
                    <Flatpickr
                      onChange={onChange}
                      options={{
                        disableMobile: true,
                      }}
                      value={value}
                      placeholder="Pilih tanggal lahir pasangan"
                      className="w-full cursor-pointer border rounded-lg h-11 p-3 mt-4 focus:outline-primary"
                    />
                    <Calendar className="absolute top-[25px] right-5" />
                    <small className="text-red-600">
                      {errors.spouse_dob?.message}
                    </small>
                  </div>
                )}
              />
            </FormGroup>
          </>
        )}

        <button
          type="submit"
          disabled={isLoading}
          className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
        >
          Simpan dan Lanjut
        </button>
      </form>
    </div>
  );
};

export default InformasiPribadiEmployee;

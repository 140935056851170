import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { DropdownTypes } from '../../../types/commonTypes';
import { DataPelengkapRequest, DropdownFamilyStatusJob } from '../../../types/Employee';
import {
  Employee,
  FotoIdentitasRequest,
  InformasiAlamatRequest,
  InformasiPribadiRequest,
  InformasiUsahaRequest,
  InformasiRekeningRequest,
  AlamatUsahaRequest,
  FamilyInMoladinRequest,
  EmployeeWelcomeResponse,
  UpdateUserBankRequest,
  PreviousCompanyRequest,
  CVRequest,
  FotoHasilKTPV2Request,
  FotoHasilKKV2Request
} from '../../../types/Employee';

export const getEmployeeDetail = createAsyncThunk(
  'employeeDetail/fetchData',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/candidate/${uuid}`,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEmployeeDetailV2 = createAsyncThunk(
  'employeeDetailV2/fetchData',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/candidate/v2/${uuid}`,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveDraft = createAsyncThunk(
  'employeeDetail/saveDraft',
  async (
    request: {
      data:
      | InformasiPribadiRequest
      | InformasiAlamatRequest
      | InformasiUsahaRequest
      | InformasiRekeningRequest
      | AlamatUsahaRequest
      | FotoIdentitasRequest
      | FamilyInMoladinRequest
      | PreviousCompanyRequest;
      uuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/employee/${request.uuid}`,
        request.data,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveDraftV2 = createAsyncThunk(
  'employeeDetail/saveDraftV2',
  async (
    request: {
      data:
      | FotoHasilKTPV2Request
      | FotoHasilKKV2Request
      | DataPelengkapRequest
      | FamilyInMoladinRequest
      | PreviousCompanyRequest
      uuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/employee/v2/${request.uuid}`,
        request.data,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdown = createAsyncThunk(
  'dropdown/fetchData',
  async (request: undefined, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/master-data/dropdown`,
        config
      );
      return response.data.data as DropdownTypes;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownFamilyJob = createAsyncThunk(
  'dropdown-family-job/fetchData',
  async (request: undefined, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/master-data/dropdown/family-job`,
        config
      );
      return response.data.data as DropdownFamilyStatusJob[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownFamilyStatus = createAsyncThunk(
  'dropdown-family-status/fetchData',
  async (request: undefined, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/master-data/dropdown/family-status`,
        config
      );
      return response.data.data as DropdownFamilyStatusJob[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const checkConsent = createAsyncThunk(
  'employeeDetail/checkConsent',
  async (request: Employee, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        uuid: request.informasi_pribadi.uuid,
        nik: request.informasi_pribadi.ktp_number,
        full_name: request.informasi_pribadi.full_name,
        dob: request.informasi_pribadi.dob,
        ktp_photo_url: request.foto_identitas.ktp_photo_url,
        selfie_url: request.foto_identitas.selfie_url,
      };
      let urlConsent = `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/employee-submit-consent`
      if(process.env.REACT_APP_OPF_IZI_DATA){
        urlConsent = `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/employee-submit-consent/v2`
      }
      const response = await axios.put(
        urlConsent,
        data,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendActiveConsent = createAsyncThunk(
  'employeeDetail/sendActiveConsent',
  async (request: { uuid: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        uuid: request.uuid,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/employee/send-active-consent/${request.uuid}`,
        data,
        config
      );

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOCRKtp = createAsyncThunk(
  'employee/sendOCRKtp',
  async (request: { url: string, uuid: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        url: request.url,
        uuid: request.uuid,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_ONBOARDING}/v1/ocr/ktp`,
        data,
        config
      );

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkNIK = createAsyncThunk(
  'employee/checkNIK',
  async (request: { ktp_number: string, uuid: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        ktp_number: request.ktp_number,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/candidate/employee/check-nik/${request.uuid}`,
        data,
        config
      );

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const checkKK = createAsyncThunk(
  'employee/checkKK',
  async (request: { kk_number: string, uuid: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        kk_number: request.kk_number,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/candidate/employee/check-kk-number/${request.uuid}`,
        data,
        config
      );

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendOCRKk = createAsyncThunk(
  'employee/sendOCRKk',
  async (request: { url: string, uuid: string }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        url: request.url,
        uuid: request.uuid,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_ONBOARDING}/v1/ocr/kk`,
        data,
        config
      );

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const employeeWelcome = createAsyncThunk(
  'employeeDetail/employeeWelcome',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/employee-welcome/${uuid}`,
        config
      );

      return response.data.data as EmployeeWelcomeResponse;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserBankAccount = createAsyncThunk(
  'employeeDetail/updateUserBankAccount',
  async (
    request: { data: UpdateUserBankRequest; uuid: string },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/update-user-bank-account/${request.uuid}`,
        request.data,
        config
      );

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveCV = createAsyncThunk(
  'employeeDetail/saveCV',
  async (
    request: {
      data: CVRequest;
      uuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/employee/update-cv/${request.uuid}`,
        request.data,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveCVV2 = createAsyncThunk(
  'employeeDetail/saveCVV2',
  async (
    request: {
      data: CVRequest;
      uuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/crm/employee/update-cv/v2/${request.uuid}`,
        request.data,
        config
      );
      return response.data.data as Employee;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

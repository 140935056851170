import React from 'react';
import { PopupGuideDataContent } from '../../../types/FotoIdentitasTypes';

type Props = {
  context: string;
};

const dataContent: PopupGuideDataContent = {
  ktp_photo_url: {
    title: 'Ikuti cara mengambil foto KTP agar verifikasi berjalan lancar, ya',
    contentImageClass: 'w-full rounded',
    contents: [
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/ktp/photoktp1.png`,
        description: 'Foto tidak jelas dan informasi buram',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/ktp/photoktp2.png`,
        description: 'Foto bukan hasil scan ataupun fotokopi',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/ktp/photoktp3.png`,
        description: 'Pastikan pencahayaan cukup dan tidak gelap',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/ktp/photoktp4.png`,
        description: 'Foto jelas dan tidak buram',
      },
    ],
  },
  family_card_url: {
    title: 'Ikuti cara mengambil foto KK agar verifikasi berjalan lancar, ya',
    contentImageClass: 'w-full rounded',
    contents: [
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/kk/photokk1.png`,
        description: 'Foto tidak jelas dan informasi buram',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/kk/photokk2.png`,
        description: 'Foto bukan hasil scan ataupun fotokopi',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/kk/photokk3.png`,
        description: 'Pastikan pencahayaan cukup dan tidak gelap',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/kk/photokk4.png`,
        description: 'Foto jelas dan tidak buram',
      },
    ],
  },
  selfie_url: {
    title:
      'Ikuti cara mengambil foto selfie untuk menyelesaikan proses verifikasi',
    contentImageClass: 'w-[115px] h-[144px] rounded',
    contents: [
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/selfie/photoselfie1.png`,
        description: 'Jangan bergerak selama proses verifikasi',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/selfie/photoselfie2.png`,
        description: 'Jangan menggunakan aksesoris lain yang menutupi wajah',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/selfie/photoselfie3.png`,
        description:
          'Lakukan foto pada lokasi dengan pencahayaan cukup dan tidak gelap',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/selfie/photoselfie4.png`,
        description: 'Pastikan foto kamu jelas untuk verifikasi',
      },
    ],
  },
  npwp_url: {
    title: 'Ikuti cara mengambil foto NPWP agar verifikasi berjalan lancar, ya',
    contentImageClass: 'w-full rounded',
    contents: [
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/npwp/NPWP.jpeg`,
        description: 'Foto jelas dan tidak buram',
      },
    ],
  },
  bangunan_url: {
    title:
      'Ikuti cara mengambil foto bangunan agar verifikasi berjalan lancar, ya',
    contentImageClass: 'w-full rounded',
    contents: [
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/bangunan/bagianDepan.jpeg`,
        description: 'Foto bangunan bagian depan',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/bangunan/bagianKiri.jpeg`,
        description: 'Foto bangunan bagian kiri',
      },
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/bangunan/bagianKanan.jpeg`,
        description: 'Foto bangunan bagian kanan',
      },
    ],
  },
  selfie_dealer_url: {
    title:
      'Ikuti cara mengambil foto selfie dengan dealer agar verifikasi berjalan lancar, ya',
    contentImageClass: 'w-full rounded',
    contents: [
      {
        imgSrc: `${process.env.REACT_APP_CDN_URL}/photoGuide/bangunan/selfie.jpeg`,
        description: 'Foto jelas dan tidak buram',
      },
    ],
  },
};

export default function ContentDetail({ context }: Props) {
  return (
    <div className='pt-4 px-4'>
      <div className='text-sm text-neutral-600'>
        {dataContent[context].title}
      </div>

      {dataContent[context].contents.length == 1 ?

        <div className='grid-cols-1 gap-2 mt-8'>
          {dataContent[context].contents.map((content, idx) => (
            <div
              key={idx + 1}
              className='flex flex-col mb-2 align-center items-center gap-1.5'
            >
              <div className={dataContent[context].contentImageClass}>
                <img src={content.imgSrc} className='w-full h-full' />
              </div>
              <div className='text-center text-xs'>{content.description}</div>
            </div>
          ))}
        </div>
        :
        <div className='grid-cols-2 gap-4 grid mt-4'>
          {dataContent[context].contents.map((content, idx) => (
            <div
              key={idx + 1}
              className='flex flex-col mb-2 align-center items-center gap-1.5'
            >
              <div className={dataContent[context].contentImageClass}>
                <img src={content.imgSrc} className='w-full h-full' />
              </div>
              <div className='text-center text-xs'>{content.description}</div>
            </div>
          ))}
        </div>
      }


    </div>
  );
}

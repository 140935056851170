import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  Revision,
  Revisions
} from '../../../types/Revision';


export const getExpiredChecking = createAsyncThunk(
  'revision/fetchData',
  async ({ uuid, type }: { uuid: string; type: string }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/revision/${type}/${uuid}/expired`,
        {...config}
      );
      return response.data.data as Revision;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const getRevision = createAsyncThunk(
    'revision/fetchData',
    async ({ uuid }: { uuid: string; type: string }) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/ecosystem/revision/${uuid}/summary`,
          {...config}
        );
        return response.data.data as Revision;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        return error.response.data;
      }
    }
  );

  

  export const getStatus = createAsyncThunk(
    'status/fetchData',
    async ({ uuid }: { uuid: string; type: string }) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/ecosystem/revision/${uuid}/latest-status`,
          {...config}
        );
        return response.data.data as Revision;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        return error.response.data;
      }
    }
  );

export const getRevisionDocument = createAsyncThunk(
  'revision/fetchDocument',
  async ({ uuid }: { uuid: string; }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/revision/${uuid}/summary`,
        {...config}
      );
      return response.data.data as Revisions[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.response.data;
    }
  }
);
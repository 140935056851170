import React from 'react';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import ImgInternalValidationRejected from '../../assets/svg/imgInternalValidationRejected.svg';
import { iconWhatsappWhite } from '../../utils/assets';

type Props = {
  handleCallCS: () => void;
};

const DukcapilValidationRejectedSecondAttempt = ({
  handleCallCS,
}: Props) => {
  return (
    <div className="px-4">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className="-mx-4 mb-[75px]" />
        <div className="flex flex-col items-center justify-center ">
          <img
            src={ImgInternalValidationRejected}
            alt="moladin"
            className="my-[22px]"
          />
          <p className="font-bold mb-2">Verifikasi belum berhasil</p>
          <span className="text-[#4B5563] text-center text-xs w-[328px] leading-4 h-[34px]">
            Kamu sudah memakai 2 kesempatan untuk verifikasi, hubungi CS Moladin
            untuk lanjut
          </span>
          <button
            type="submit"
            className="font-semibold rounded-lg h-11 w-80 text-white bg-secondary mb-4 mt-8"
          >
            <div className="flex justify-center items-center">
              <span className="text-sm text-white" onClick={() => handleCallCS()}>Hubungi CS</span>
              <img src={iconWhatsappWhite} className="ml-2" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DukcapilValidationRejectedSecondAttempt;

import React from "react";
import BrowseFileHidden from "../atom/BrowseFileHidden";
import { BROWSE_FILE_TYPE } from "../../utils/constants";
import cameraIcon from '../../assets/icons/camera-minus.svg';
import galleryIcon from '../../assets/icons/gallery.svg';
import { uploadImageFile } from "../../services";

type Props = {
  onSelectCamera: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSelectFile: (src: string) => void;
  setShowDrawer: (show: boolean) => void;
  allowPDF?: boolean;
};

function sendMessage(message: string) {
  (window as any).AndroidInterface?.onMessageReceived(message);
}

const DrawerUploader = ({
  onSelectCamera,
  onSelectFile,
  setShowDrawer,
  allowPDF = false,
}: Props) => {
  return (
    <div className="fixed z-20 top-0 left-0 bottom-0 w-full flex flex-col justify-end">
      <div
        className="bg-black bg-opacity-30 flex-1"
        onClick={() => setShowDrawer(false)}
      />
      <div className="bg-white py-2">
        <button
          type="button"
          className="flex items-center gap-6 p-4 w-full justify-start"
          onClick={(e) => {
            setShowDrawer(false);
            sendMessage('openCamera');
            onSelectCamera(e);
          }}
        >
          <img src={cameraIcon} alt="" />
          <h4 className="text-sm text-gray-500 text-left">
            Ambil foto dengan kamera
          </h4>
        </button>
        <label
          htmlFor="browse-image"
          className="flex items-center gap-6 p-4 w-full justify-start cursor-pointer"
          onClick={() => {
            sendMessage('openGallery');
          }}
        >
          <img src={galleryIcon} alt="" />
          <h4 className="text-sm text-gray-500 text-left">
            Upload gambar dari galeri
          </h4>
        </label>
        <BrowseFileHidden
          id="browse-image"
          onChange={(src) => {
            onSelectFile(src);
            setShowDrawer(false);
          }}
          onUploadFile={uploadImageFile}
          type={allowPDF ? BROWSE_FILE_TYPE.FILE : BROWSE_FILE_TYPE.IMAGE}
        />
      </div>
    </div>
  )
};

export default DrawerUploader;

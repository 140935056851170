import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Whatsapp from '../../assets/icons/whatsapp.svg'
import DocumentSuccess from '../../assets/svg/cs.svg'

export default function RevisiExpired() {
    const { type } = useParams();
    const userType = type
    const [searchParams] = useSearchParams()

    const dcPhone = searchParams.get('dc-phone')
    const call = () => {
        window.location.href = `https://wa.me/${dcPhone}`;
      };
    
    return (
        <div className='p-5'>
            <div className='pt-5 pb-5 bg-secondary-pure-white'>
                <img src='https://moladin.com/Logo.svg' />
            </div>
            <div className='text-center mb-5'>
                <div className='flex justify-center mb-5'>
                    <img src={DocumentSuccess} />
                </div>
                {type === 'dealer' ?
                    <div>
                        <div className='font-metropolis font-bold text-18 leading-25'>Link revisi sudah tidak berlaku</div>
                        <div className='text-sm'>Batas revisi dokumen sudah berakhir. Jika masih ingin melanjutkan pendaftaran, hubungi DC kamu</div>
                    </div>
                    :
                    <div>
                        <div className='font-metropolis font-bold text-18 leading-25'>Link revisi expired</div>
                        <div className='text-sm'>Batas revisi dokumen sudah berakhir. </div>
                        <div className='text-sm'>Silakan lakukan pendaftaran ulang</div>
                    </div>
                }
            </div>
            {userType === 'dealer' ?
                <div>
                    <hr />
                    <button
                        onClick={call}
                        className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out mt-10"
                    >
                        <div className='flex justify-center'>
                            <p className="!mb-0">Hubungi DC</p>
                            <img src={Whatsapp} className="ml-3" />
                        </div>
                    </button>
                </div>
                :
                ''
            }

        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormGroup from '../../atom/FormGroup';
import Label from '../../atom/Label';
import TakePhoto from '../../FotoIdentitas/TakePhoto/TakePhoto';
import PopupGuide from '../../FotoIdentitas/PopupGuide/PopupGuide';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ErrorFieldObject } from '../../../types/Dealer';
import {
  CameraProperty,
  ContentControler,
} from '../../../types/FotoIdentitasTypes';
import { uploadImageFile } from '../../../services';
import { getDropdown, saveDraft } from '../../../redux/slices/dealer/dealerThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { NAME_REGEX, PAGE_SECTION_FORM } from '../../../utils/constants';
import { toast } from 'react-toastify';
import ErrorAlert from '../../atom/ErrorAlert';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import { ageValidation, featureFlag, handleInputNumber, isValidNpwp } from '../../../utils/helper';
import HeaderEvent from '../../Header/HeaderEvent';
import ReactFlagsSelect from 'react-flags-select';
import Input from '../../atom/Input';
import Select from 'react-select';
import { PatternFormat } from 'react-number-format';
import { Calendar } from 'react-feather';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { DataPelengkapFormData, DataPelengkapRequest } from '../../../types/DataPelengkap';
import DrawerUploader from '../../FotoIdentitas/DrawerUploader';
import dayjs from 'dayjs';

const initialContentControler: ContentControler = {
  showCamera: false,
  showPopup: false,
  contentType: '',
};

const initialCameraProperty: CameraProperty = {
  facing_mode: 'environment',
};

interface ErrorsAttemptObject {
  selfie_url?: {
    title: string;
    message: string;
  } | null;
}

interface ErrorAttemptValidation {
  isError: boolean;
  title: string;
  message: string;
}

const DataPelengkap = () => {
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const dataDealer = useAppSelector((state) => state.dealer.dealer);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<DataPelengkapFormData>({
    mode: 'onChange',
  });

  const [contentControler, setContentControler] = useState(
    initialContentControler
  );
  const [cameraPorperty, setCameraProperty] = useState(initialCameraProperty);
  const [errorSelfieValidation, setErrorSelfieValidation] =
    useState<ErrorAttemptValidation>({
      isError: false,
      title: '',
      message: '',
    });
  const dropdown = useAppSelector((state) => state.dealer.dropdown);
  const [showDrawer, setShowDrawer] = useState(false);

  const handleClickShowPopupGuide = (type: string) => {
    setContentControler({
      ...contentControler,
      showPopup: true,
      contentType: type,
    });
  };

  const handleClickClosePopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: false,
      contentType: '',
    });
  };

  const handleConfirmPopupGuide = () => {
    setShowDrawer(true)
  };

  const handleBackToPopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: true,
      showCamera: false,
    });
  };

  const handleConfirmPhotoTaken = (srcUri: string, setValue: any) => {
    if (errors[contentControler.contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (
        errors[contentControler.contentType as keyof ErrorsAttemptObject]
          ?.type == 'custom'
      ) {
        clearErrors(
          contentControler.contentType as keyof DataPelengkapFormData
        );
      }
    }

    if (
      errors[contentControler.contentType as keyof DataPelengkapFormData]
    ) {
      // error basic clear
      clearErrors(
        contentControler.contentType as keyof DataPelengkapFormData
      );
    }

    setValue(contentControler.contentType, srcUri);
    setContentControler({
      ...contentControler,
      ...initialContentControler,
    });
  };

  const handleConfirmDrawerFileTaken = (
    srcUri: string,
    setValue: any,
    contentType: string
  ) => {
    if (errors[contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (errors[contentType as keyof ErrorsAttemptObject]?.type == 'custom') {
        clearErrors(contentType as keyof DataPelengkapFormData);
      }
    }
    if (errors[contentType as keyof DataPelengkapFormData]) {
      // error basic clear
      clearErrors(contentType as keyof DataPelengkapFormData);
    }

    setValue(contentType, srcUri);
    setContentControler({
      ...contentControler,
      ...initialContentControler,
    });
  };

  const handleRemovePhoto = (type: string, cb: any) => {
    cb(type, '');
  };

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<DataPelengkapFormData> = async (data) => {
    const payload: DataPelengkapRequest = {
      data_pelengkap: {
        family_card_url: data.family_card_url,
        kk_number: data.kk_number,
        selfie_url: data.selfie_url,
        email: data.email,
        education_id: Number(data.education_id.id),
        personal_npwp: data.personal_npwp,
        income_id: Number(data.income_id.id),
        spouse_ktp_name: data.spouse_ktp_name,
        spouse_dob: data.spouse_dob ? dayjs(data.spouse_dob).format('YYYY-MM-DD') : null,
        spouse_phone_number: data.spouse_phone_number,
        spouse_ktp_number: data.spouse_ktp_number,
        spouse_email: data.spouse_email,
      },
    };
    const res = await dispatch(
      saveDraft({ data: payload, uuid: uuid as string })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/dealer/registration/${uuid}/${PAGE_SECTION_FORM.ALAMAT}`);
    }
    if (res.meta.requestStatus === 'rejected') {
      const errMessage = (res.payload as any)?.errors?.[0]?.message || res.payload;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const handleShowCamera = () => {
    setContentControler({
      ...contentControler,
      showPopup: false,
      showCamera: true,
    });
  }

  useEffect(() => {
    dispatch(getDropdown());
  }, []);

  useEffect(() => {
    const dataPelengkapData = dataDealer.data.data_pelengkap;
    const educations = dropdown.data.education;
    const incomes = dropdown.data.income;
    const educationValue = educations?.find(item => item.id === dataPelengkapData?.education_id);
    const incomeValue = incomes?.find(item => item.id === dataPelengkapData?.income_id);

    setValue('education_id', {
      id: educationValue?.id || '',
      code: educationValue?.code,
      desc: educationValue?.desc,
      createdAt: educationValue?.createdAt,
      updatedAt: educationValue?.updatedAt
    });
    setValue('income_id', {
      id: incomeValue?.id || '',
      code: incomeValue?.code,
      desc: incomeValue?.desc,
      createdAt: incomeValue?.createdAt,
      updatedAt: incomeValue?.updatedAt
    });
    setValue('family_card_url', dataPelengkapData?.family_card_url);
    setValue('kk_number', dataPelengkapData?.kk_number);
    setValue('selfie_url', dataPelengkapData?.selfie_url);
    setValue('email', dataPelengkapData?.email);
    setValue('personal_npwp', dataPelengkapData?.personal_npwp);
    setValue('spouse_ktp_name', dataPelengkapData?.spouse_ktp_name);
    setValue('spouse_dob', dataPelengkapData?.spouse_dob);
    setValue('spouse_phone_number', dataPelengkapData?.spouse_phone_number);
    setValue('spouse_ktp_number', dataPelengkapData?.spouse_ktp_number);
    setValue('spouse_email', dataPelengkapData?.spouse_email);

    const dealer = dataDealer.data;
    if (dealer.error_field) {
      // check for initial error_field state undefined or null
      //check error field is empty
      const isEmptyErrorField = Array.isArray(dealer.error_field);
      if (!isEmptyErrorField) {
        // check is error in current section
        const section = Object.keys(dealer.error_field)[0];
        if (section === 'data_pelengkap') {
          // populate field error (can be hardcoded since validation vida foto_identitas only selfie_url field)
          const errorData =
            dealer.error_field[`${section}` as keyof ErrorFieldObject];
          setErrorSelfieValidation({
            isError: true,
            title: errorData['selfie_url']['title'] || '',
            message: errorData['selfie_url']['message'] || '',
          });
        }
      }
    }
  }, [dataDealer.data, dropdown.data.education, dropdown.data.income]);

  useEffect(() => {
    if (contentControler.contentType === 'selfie_url') {
      setCameraProperty({
        ...cameraPorperty,
        facing_mode: 'user',
      });
    } else {
      setCameraProperty({
        ...cameraPorperty,
        ...initialCameraProperty,
      });
    }
  }, [contentControler]);

  useEffect(() => {
    if (errorSelfieValidation.isError) {
      setError('selfie_url', {
        type: 'custom',
        message: errorSelfieValidation.title,
      });
    }
  }, [errorSelfieValidation]);

  return (
    <>
      {featureFlag('REACT_APP_SPRINT_9_1_DEALER_ONBOARDING_GATHERING') ? <HeaderEvent data={dataDealer?.data} /> : ''}

      <form
        className="flex-1 flex flex-col justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="font-semibold mb-4">Data Pelengkap</h3>

        {!contentControler.showPopup && !contentControler.showCamera && (
          <>
            <div>
              {errorSelfieValidation.isError && (
                <div>
                  <div className="mb-5">
                    <ErrorAlert
                      title={errorSelfieValidation.title}
                      message={errorSelfieValidation.message}
                    />
                  </div>
                </div>
              )}

              <FormGroup className="mb-4">
                <Label name="Foto Kartu Keluarga" />
                <Controller
                  rules={{ required: 'Foto KK harus diisi' }}
                  control={control}
                  name="family_card_url"
                  render={({ field }) => (
                    <InputTakePhotoWithDrawer
                      imageLabel="Foto KK"
                      placeholder="Ambil foto KK"
                      onSelectCamera={() => {
                        handleClickShowPopupGuide('family_card_url');
                      }}
                      srcUri={field.value}
                      handleRemovePhoto={() => handleRemovePhoto('family_card_url', setValue)}
                      onBrowseFile={() => handleClickShowPopupGuide('family_card_url')}
                      onSelectFile={(src) => {
                        handleConfirmDrawerFileTaken(src, setValue, 'family_card_url');
                      }}
                      withoutDrawer
                      onShowGuide={() => handleClickShowPopupGuide('family_card_url')}
                    />
                  )}
                />
                <small className="text-red-600">
                  {errors.family_card_url?.message}
                </small>
              </FormGroup>

              <FormGroup>
                <Label name="Nomor Kartu Keluarga" />
                <Controller
                  control={control}
                  rules={{
                    required: 'Data harus diisi',
                    pattern: {
                      value:
                        /^\d{16}$/,
                      message: 'Nomor Kartu Keluarga harus berupa angka dan berjumlah 16 digit',
                    },
                  }}
                  name="kk_number"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="tel"
                      placeholder="Masukkan nomor kartu keluarga"
                      onInput={(event) => {
                        const { value } = event.target;
                        if (value.length <= 16) {
                          field.onChange(event);
                        } else if (value.length > 16) {
                          event.target.value = value.slice(0, 16);
                          field.onChange(event);
                        }
                      }}
                      onKeyDown={handleInputNumber}
                    />
                  )}
                />
                <small className="text-red-600">{errors.kk_number?.message}</small>
              </FormGroup>

              <FormGroup className="mb-4">
                <Label name="Foto Selfie Wajah" />
                <Controller
                  rules={{ required: 'Foto Selfie harus diisi' }}
                  control={control}
                  name="selfie_url"
                  render={({ field }) => (
                    <InputTakePhotoWithDrawer
                      imageLabel="Foto Selfie"
                      placeholder="Ambil foto selfie"
                      onSelectCamera={() =>
                        handleClickShowPopupGuide('selfie_url')
                      }
                      srcUri={field.value}
                      handleRemovePhoto={() =>
                        handleRemovePhoto('selfie_url', setValue)
                      }
                      onBrowseFile={() =>
                        setContentControler({
                          ...contentControler,
                          contentType: 'selfie_url',
                        })
                      }
                      onSelectFile={(src) => {
                        handleConfirmDrawerFileTaken(src, setValue, 'selfie_url');
                      }}
                    />
                  )}
                />
                <small className="text-red-600">
                  {errors.selfie_url?.message}
                </small>
              </FormGroup>

              <FormGroup>
                <Label name="Nomor HP" />
                <div className="relative">
                  <ReactFlagsSelect
                    selected={'ID'}
                    disabled
                    className="absolute disabled:bg-[#F3F4F6]"
                    onSelect={(code) => console.log(code)}
                    countries={['ID', 'SG']}
                    customLabels={{ ID: '+62', SG: '+65' }}
                  />

                  <Input
                    disabled
                    className="pl-[120px] disabled:bg-[#F3F4F6]"
                    placeholder="No HP"
                    type="tel"
                    value={dataDealer.data?.data_pelengkap?.phone_number}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <Label name="Email" />
                <Controller
                  control={control}
                  rules={{
                    required: 'Data harus diisi',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Format email salah',
                    },
                  }}
                  name="email"
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan alamat email" />
                  )}
                />
                <small className="text-red-600">{errors.email?.message}</small>
              </FormGroup>

              <FormGroup className="mb-5">
                <Label name="Pendidikan Terakhir" />
                <Controller
                  control={control}
                  name="education_id"
                  rules={{ required: 'Data harus diisi' }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={dropdown.data.education}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        getOptionLabel={(option) => option.desc!}
                        getOptionValue={(option) => option.id?.toString()}
                        placeholder="Pilih pendidikan terakhir"
                      />
                      <small className="text-red-600">
                        {errors.education_id?.message}
                      </small>
                    </>
                  )}
                />
              </FormGroup>

              <FormGroup>
                <Label name="NPWP Pribadi" />
                <Controller
                  control={control}
                  name="personal_npwp"
                  rules={{ required: 'Data harus diisi', validate: isValidNpwp }}
                  render={({ field }) => (
                    <>
                      <PatternFormat
                        {...field}
                        format="##.###.###.#-###.###"
                        mask="_"
                        placeholder="Masukkan nomor NPWP"
                        className="border w-full h-11 rounded-lg p-3 focus:outline-primary"
                      />
                      <small className="text-red-600">
                        {errors.personal_npwp?.message}
                      </small>
                    </>
                  )}
                />
              </FormGroup>

              <FormGroup className="mb-5">
                <Label name="Sumber Penghasilan" />
                <Controller
                  control={control}
                  rules={{ required: 'Data harus diisi' }}
                  name="income_id"
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={dropdown.data.income}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        getOptionLabel={(option) => option.desc!}
                        getOptionValue={(option) => option?.id + ''}
                        placeholder="Pilih sumber penghasilan utama"
                      />
                      <small className="text-red-600">
                        {errors.income_id?.message}
                      </small>
                    </>
                  )}
                />
              </FormGroup>

              {dataDealer?.data?.informasi_pribadi?.marriage_id === 7 && (
                <>
                  <h3 className="font-semibold mb-4">Data Pasangan</h3>
                  <FormGroup>
                    <Label name="Nama Suami/Istri" />
                    <Controller
                      rules={{
                        required: 'Data harus diisi',
                        pattern: {
                          value: NAME_REGEX,
                          message:
                            'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
                        },
                      }}
                      control={control}
                      name="spouse_ktp_name"
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            value={field.value || ''}
                            placeholder="Masukkan nama suami/istri"
                          />
                          <small className="text-red-600">
                            {errors.spouse_ktp_name?.message}
                          </small>
                        </>
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label name="NIK Suami/Istri" />
                    <Controller
                      rules={{
                        required: 'Data harus diisi',
                        pattern: {
                          value:
                            /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/,
                          message: 'Format KTP salah',
                        },
                      }}
                      control={control}
                      name="spouse_ktp_number"
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value || ''}
                          pattern="\d*"
                          maxLength={16}
                          placeholder="Masukkan NIK suami/istri"
                          onInput={(event) => {
                            const { value } = event.target;
                            if (value.length <= 16) {
                              field.onChange(event);
                            } else if (value.length > 16) {
                              event.target.value = value.slice(0, 16);
                              field.onChange(event);
                            }
                          }}
                          onKeyDown={handleInputNumber}
                          type="tel"
                        />
                      )}
                    />
                    <small className="text-red-600">
                      {errors.spouse_ktp_number?.message}
                    </small>
                  </FormGroup>

                  <FormGroup className="relative">
                    <Label name="Tanggal Lahir Pasangan" />

                    <Controller
                      control={control}
                      rules={{
                        required: 'Data harus diisi',
                        validate: (value) => ageValidation(value, 15)
                      }}
                      name="spouse_dob"
                      render={({ field: { onChange, value } }) => (
                        <div className="relative">
                          <Flatpickr
                            onChange={([date]: Date[]) => onChange(date)}
                            options={{
                              disableMobile: true,
                              dateFormat: 'd M Y'
                            }}
                            value={value || ''}
                            placeholder="Pilih tanggal lahir pasangan"
                            className="w-full cursor-pointer border rounded-lg h-11 p-3 mt-4 focus:outline-primary"
                          />
                          <Calendar className="absolute top-[25px] right-5" />
                          <small className="text-red-600">
                            {errors.spouse_dob?.message}
                          </small>
                        </div>
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label name="Nomor HP Suami/Istri" />
                    <Controller
                      control={control}
                      rules={{
                        required: 'Data harus diisi',
                        pattern: {
                          value: /^\d+$/g,
                          message: 'Format nomor HP salah',
                        },
                      }}
                      name="spouse_phone_number"
                      render={({ field }) => (
                        <div className="relative">
                          <ReactFlagsSelect
                            selected={'ID'}
                            className="absolute disabled:bg-[#F3F4F6]"
                            onSelect={(code) => console.log(code)}
                            countries={['ID']}
                            customLabels={{ ID: '+62', SG: '+65' }}
                          />

                          <Input
                            {...field}
                            pattern="\d*"
                            value={field.value || ''}
                            className="pl-[120px] disabled:bg-[#F3F4F6]"
                            placeholder="Masukkan nomor HP pasangan"
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );

                              const currentValue = e.target.value;
                              // Check if the first character is "0"
                              if (currentValue.length === 1 && currentValue[0] === '0') {
                                // If the first character is "0", prevent it and clear the input field
                                e.preventDefault();
                                e.target.value = '';
                              }
                            }}
                            maxLength={14}
                            onKeyDown={handleInputNumber}
                            type="tel"
                          />
                          <small className="text-red-600">
                            {errors.spouse_phone_number?.message}
                          </small>
                        </div>
                      )}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label name="Email Suami/Istri" />
                    <Controller
                      control={control}
                      rules={{
                        required: 'Data harus diisi',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Format email salah',
                        },
                      }}
                      name="spouse_email"
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value || ''}
                          placeholder="Masukkan alamat email suami/istri"
                        />
                      )}
                    />
                    <small className="text-red-600">
                      {errors.spouse_email?.message}
                    </small>
                  </FormGroup>
                </>
              )}
            </div>
            <button
              type="submit"
              disabled={dataDealer.loading}
              className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
            >
              <p className="!mb-0">Simpan dan Lanjut</p>
            </button>
          </>
        )}
        {contentControler.showPopup && (
          <PopupGuide
            handleClose={handleClickClosePopupGuide}
            handleConfirm={handleConfirmPopupGuide}
            contentType={contentControler.contentType}
          />
        )}
        {showDrawer && (
          <DrawerUploader
            allowPDF={false}
            onSelectCamera={handleShowCamera}
            onSelectFile={src => {
              handleConfirmDrawerFileTaken(
                src,
                setValue,
                'family_card_url'
              );
            }}
            setShowDrawer={setShowDrawer} />
        )}
        {contentControler.showCamera && (
          <TakePhoto
            handleConfirmPhoto={(src) => handleConfirmPhotoTaken(src, setValue)}
            handleBack={handleBackToPopupGuide}
            cameraProperty={cameraPorperty}
            cameraType={contentControler.contentType}
            onUploadPhotoTaken={uploadImageFile}
          />
        )}
      </form>
    </>
  );
};

export default DataPelengkap;

import React from 'react';

type Props = {
  className?: string;
  placeholder?: string;
  defaultValue?: string | number;
  value?: string | number;
  name?: string;
  id?: string;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
  pattern?: string;
  onChange?: () => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const Input = ({
  className,
  placeholder,
  defaultValue,
  value,
  name,
  id,
  type,
  disabled,
  maxLength,
  pattern,
  onChange,
  onInput,
  onKeyDown
}: Props) => {
  return (
    <input
      type={type || 'text'}
      name={name}
      id={id}
      maxLength={maxLength || 255}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      pattern={pattern}
      disabled={disabled}
      onChange={onChange}
      onInput={onInput}
      onKeyDown={onKeyDown}
      className={`rounded-lg w-full border h-11 p-3 ${className} focus:outline-primary`}
    />
  );
};

export default Input;

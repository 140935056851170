import { AxiosResponse } from 'axios';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { X } from 'react-feather';
import uploadIcon from '../../../assets/icons/uploadIcon.svg';
import { useAppDispatch } from '../../../redux/hooks';
import { setErrorSystemRequest } from '../../../redux/slices/errorSystemRequest/errorSystemRequestSlice';
import {
  BROWSE_FILE_ERROR_MESSAGE,
  BROWSE_FILE_VALID_TYPE,
  BROWSE_FILE_TYPE,
  LIMIT_UPLOAD_FILE_SIZE,
} from '../../../utils/constants';
import { toast } from 'react-toastify';

interface Props {
  placeholder?: string;
  value?: string | null;
  onChange: (val: string) => void;
  onUploadFile: (data: FormData) => Promise<AxiosResponse>;
  type: string;
}

function BrowseFile({
  placeholder,
  value,
  onUploadFile,
  onChange,
  type,
}: Props) {
  const dispatch = useAppDispatch();
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const uploadFileHandler = (file: File) => {
    const formData: FormData = new FormData();
    formData.append(type, file);
    onUploadFile(formData)
      .then((res) => {
        const fileUrl = res.data.data.url;
        onChange(fileUrl);
        setFileName(file.name);
        setFileError('');
      })
      .catch((res) => {
        dispatch(
          setErrorSystemRequest({
            show: true,
            message: 'Upload failed',
          })
        );
        const errorMessage = res?.response?.data?.data?.message;
        toast.error(errorMessage || 'Failed upload image');
      });
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (BROWSE_FILE_VALID_TYPE[type].includes(file.type)) {
      uploadFileHandler(file);
    } else {
      setFileError(BROWSE_FILE_ERROR_MESSAGE[type]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });
  const handleRemoveFile = () => {
    onChange('');
  };
  return (
    <>
      {value ? (
        <div className="border-2 border-dashed border-spacing-7 rounded py-7 px-[14.5px]">
          <div className="flex gap-2.5 items-center">
            <div className="h-[72px] w-[72px] shrink-0 relative">
              {BROWSE_FILE_TYPE.IMAGE === type && (
                <img src={value} className="w-full h-full object-contain" />
              )}
            </div>
            <div className="text-sm w-full font-normal">
              <div className="flex justify-between items-center">
                <div className="pr-3 break-all">{fileName}</div>
                <div className="flex items-center gap-[18px]">
                  <div
                    {...getRootProps()}
                    className="text-xs text-blue-500 font-bold"
                  >
                    Ubah
                    <input {...getInputProps()} />
                  </div>
                  <X
                    onClick={handleRemoveFile}
                    className="text-neutral-500"
                    width={24}
                    height={24}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className="border-2 border-dashed border-spacing-7 rounded py-7"
        >
          <input {...getInputProps()} />
          <div>
            <div className="w-6 h-6 mb-2 m-auto">
              <img src={uploadIcon} alt="" className="w-full h-full" />
            </div>
            <div className="text-center text-neutral-500 text-sm">
              <p>{placeholder}</p>
            </div>
          </div>
        </div>
      )}
      <small className="text-red-600">{fileError}</small>
    </>
  );
}

export default BrowseFile;

import React, { useEffect, useState } from 'react';
import * as Assets from '../../../utils/assets';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  getDropdownProvinceAddress,
  getDropdownCityAddress,
  getDropdownSubdistrictAddress,
  getDropdownVillageAddress,
} from '../../../redux/slices/informasiAlamat/informasiAlamatThunk';
import { resetNextDropdownAddress } from '../../../redux/slices/informasiAlamat/informasiAlamatSlice';
import FormGroup from '../../atom/FormGroup';
import Select from 'react-select';
import Label from '../../atom/Label';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  FormDataInformasiAlamatV2,
  ModalMessageProps,
  SelectedAddressType,
} from '../../../types/InformasiAlamatTypes';
import {
  InformasiAlamatActiveDisplay as PageType,
} from '../../../utils/constants';
import PinpointLocation from '../../InformasiAlamat/PinpointLocation';
import { TemporaryStateUsahaType } from '../../../types/AlamatUsahaTypes';
import { getLocationByLatLong, uploadImageFile } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { getDropdown, saveDraft } from '../../../redux/slices/dealer/dealerThunk';
import { toast } from 'react-toastify';
import Alert from '../../atom/Alert';
import HeaderEvent from '../../Header/HeaderEvent';
import Modal from '../../atom/Modal';
import iconWarningOutOfArea from '../../../assets/svg/iconWarningOutOfArea.svg';
import iconWarningDisableLocation from '../../../assets/svg/iconWarningDisableLocation.svg';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import TakePhotoGeneral from '../../Camera/TakePhotoGeneral';
import Thumbnail from '../../atom/Thumbnail';
import Button from '../../atom/Button';
import CloseCircle from '../../../assets/icons/close-circle.svg';
import { InformasiAlamatDomicile, InformasiAlamatKtp } from '../../../types/Dealer';
import { handleInputNumber } from '../../../utils/helper';

const InformasiAlamatV2 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const { data: dealer, loading: isLoading } = useAppSelector(
    (state) => state.dealer.dealer
  );
  const dropdownProvinceDom = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownProvinceDom
  );
  const dropdownCityDom = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownCityDom
  );
  const dropdownSubdistrictDom = useAppSelector(
    (state) =>
      state.informasiAlamat.dropdownLocationAddress.dropdownSubdistrictDom
  );
  const dropdownVillageDom = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownVillageDom
  );
  const dropdown = useAppSelector((state) => state.dealer.dropdown);

  const [isSameAddress, setIsSameAddress] = useState(false);
  const [activeForm, setActiveForm] = useState<'ktp' | 'domicile'>('ktp');
  const [activePage, setActivePage] = useState<'form' | 'pinpoint'>('form');
  const [temporaryState, setTemporaryState] = useState<TemporaryStateUsahaType>(
    { lat: '', lng: '' }
  );
  const [triggerMap, setTriggerMap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<ModalMessageProps>({
    title: '',
    description: '',
  });
  const [isPermission, setIsPermission] = useState(false);
  const [showProofCamera, setShowProofCamera] = useState(false);

  const dropdownStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state?.isFocused ? '#0F2CAD' : 'default',
    }),
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormDataInformasiAlamatV2>();

  const ownership_id = process.env.REACT_APP_OWNERSHIP_OTHER_ID || 15

  const handleChangeProvince = (event: any) => {
    const dataProvince = 'province_domicile';
    const dataCity = 'city_domicile';
    const dataSubdistrict = 'subdistrict_domicile';
    const dataVillage = 'village_domicile';
    const dataAddress = 'address_domicile';
    const dataPostalCode = 'postal_code_domicile';
    const dataLatitude = 'latitude_domicile';
    const dataLongitude = 'longitude_domicile';
    setValue(dataProvince, event);

    //Trigger Next Dropdown
    const paramsGetDropdownCity = {
      queryParams: `levels=3&parent_ids=${event?.id}`,
      type: 'domicile',
    };
    dispatch(getDropdownCityAddress(paramsGetDropdownCity));

    //Reset Next Dropdown Value
    const payloadResetDropdown = {
      location: 'province',
      type: 'domicile',
    };
    dispatch(resetNextDropdownAddress(payloadResetDropdown));

    setValue(dataCity, null as any);
    setValue(dataSubdistrict, null as any);
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const handleChangeCity = (event: any) => {
    const dataCity = 'city_domicile';
    const dataSubdistrict = 'subdistrict_domicile';
    const dataVillage = 'village_domicile';
    const dataAddress = 'address_domicile';
    const dataPostalCode = 'postal_code_domicile';
    const dataLatitude = 'latitude_domicile';
    const dataLongitude = 'longitude_domicile';
    setValue(dataCity, event);

    //Trigger Next Dropdown
    const paramsGetDropdownSubdistrict = {
      queryParams: `levels=4&parent_ids=${event?.id}`,
      type: 'domicile',
    };
    dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

    //Reset Next Dropdown Value
    const payloadResetDropdown = {
      location: 'city',
      type: 'domicile',
    };
    dispatch(resetNextDropdownAddress(payloadResetDropdown));
    setValue(dataSubdistrict, null as any);
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const handleChangeSubdistrict = (event: any) => {
    const dataSubdistrict = 'subdistrict_domicile';
    const dataVillage = 'village_domicile';
    const dataAddress = 'address_domicile';
    const dataPostalCode = 'postal_code_domicile';
    const dataLatitude = 'latitude_domicile';
    const dataLongitude = 'longitude_domicile';
    setValue(dataSubdistrict, event);

    //Trigger Next Dropdown
    const paramsGetDropdownVillage = {
      queryParams: `levels=5&parent_ids=${event?.id}`,
      type: 'domicile',
    };
    dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));

    //Reset Next Dropdown Value
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const handleChangeVillage = (event: any) => {
    const dataVillage = 'village_domicile';
    const dataAddress = 'address_domicile';
    const dataPostalCode = 'postal_code_domicile';
    const dataLatitude = 'latitude_domicile';
    const dataLongitude = 'longitude_domicile';
    setValue(dataVillage, event);

    //Reset Next Form Value
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const mappingDropdownDomicile = [
    {
      label: 'Provinsi',
      name: 'province_domicile' as any,
      options: dropdownProvinceDom,
      placeholder: 'Pilih provinsi',
      onChange: (e: any) => handleChangeProvince(e),
      errors: errors.province_domicile?.message,
    },
    {
      label: 'Kabupaten/Kota',
      name: 'city_domicile' as string,
      options: dropdownCityDom,
      placeholder: 'Pilih kabupaten/kota',
      onChange: (e: any) => handleChangeCity(e),
      errors: errors.city_domicile?.message,
    },
    {
      label: 'Kecamatan',
      name: 'subdistrict_domicile' as string,
      options: dropdownSubdistrictDom,
      placeholder: 'Pilih kecamatan',
      onChange: (e: any) => handleChangeSubdistrict(e),
      errors: errors.subdistrict_domicile?.message,
    },
    {
      label: 'Kelurahan',
      name: 'village_domicile' as any,
      options: dropdownVillageDom,
      placeholder: 'Pilih kelurahan',
      onChange: (e: any) => handleChangeVillage(e),
      errors: errors.village_domicile?.message,
    },
  ];

  const mappingHiddenForm = [
    { name: 'longitude' as any, message: 'Pin point harus diisi' },
    { name: 'latitude' as any, message: 'Pin point harus diisi' },
    { name: 'pin_point' as any, message: 'Pin point harus diisi' }
  ];

  const mappingHiddenFormDomicile = [
    { name: 'longitude_domicile' as any, message: 'Pin point harus diisi' },
    { name: 'latitude_domicile' as any, message: 'Pin point harus diisi' },
    { name: 'pin_point_domicile' as any, message: 'Pin point harus diisi' }
  ];

  const onSubmit: SubmitHandler<FormDataInformasiAlamatV2> = async (data) => {
    const alamatKtp = dealer.alamat.alamat_ktp;
    const payloadKtp: InformasiAlamatKtp = {
      latitude: data?.latitude,
      longitude: data?.longitude,
      ownership_id: Number(data.ownership_id?.id),
      ownership_other: data.ownership_other,
      pin_point: data.pin_point,
    };
    const payloadDomicile: InformasiAlamatDomicile = {
      address: data?.address_domicile,
      province_id: data?.province_domicile?.id,
      province_name: data?.province_domicile?.name,
      city_id: data?.city_domicile?.id,
      city_name: data?.city_domicile?.name,
      subdistrict_id: data?.subdistrict_domicile?.id,
      subdistrict_name: data?.subdistrict_domicile?.name,
      village_id: data?.village_domicile?.id,
      village_name: data?.village_domicile?.name,
      postal_code: data?.postal_code_domicile,
      latitude: data?.latitude_domicile,
      longitude: data?.longitude_domicile,
      ownership_id: Number(data?.ownership_id_domicile?.id),
      ownership_other: data.ownership_other_domicile,
      pin_point: data.pin_point_domicile,
      ...(!isSameAddress ? {
        domicile_certificates: data?.domicile_certificates || [] as string[],
      } : {})
    };

    const payloadSaveDraft = {
      alamat: {
        alamat_ktp: {
          ...payloadKtp,
        },
        alamat_domisili: isSameAddress ? {
          address: alamatKtp.address,
          province_id: alamatKtp.province_id,
          province_name: alamatKtp.province_name,
          city_id: alamatKtp.city_id,
          city_name: alamatKtp.city_name,
          subdistrict_id: alamatKtp.subdistrict_id,
          subdistrict_name: alamatKtp.subdistrict_name,
          village_id: alamatKtp.village_id,
          village_name: alamatKtp.village_name,
          postal_code: alamatKtp.postal_code,
          ...payloadKtp
        } : payloadDomicile,
      },
    };

    const res = await dispatch(
      saveDraft({ data: payloadSaveDraft, uuid: uuid as string })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/dealer/registration/${uuid}/informasi_rekening`);
    } else {
      const errMessage = (res.payload as any)?.errors?.[0]?.message || (res.payload as any)?.message || null;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const getLocation = (params: SelectedAddressType) => {
    setTriggerMap(true);
    const paramsGetDropdown = {
      queryParams: `excluded_ids=1&nested=child&latlng=${params.lat},${params.lng}`,
    };
    getLocationByLatLong(paramsGetDropdown)
      .then((res) => {
        const data = res.data.data;
        const dataProvince = data;
        const dataCity = data?.child;
        const dataSubdistrict = data?.child?.child;
        const dataVillage = data?.child?.child?.child;

        const fieldProvince = 'province_domicile';
        const fieldCity = 'city_domicile';
        const fieldSubdistrict = 'subdistrict_domicile';
        const fieldVillage = 'village_domicile';

        setValue(fieldProvince, dataProvince);
        setValue(fieldCity, dataCity);
        setValue(fieldSubdistrict, dataSubdistrict);
        setValue(fieldVillage, dataVillage);

        const paramsGetDropdownCity = {
          queryParams: `levels=3&parent_ids=${dataProvince?.id}`,
          type: 'domicile',
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCity));

        const paramsGetDropdownSubdistrict = {
          queryParams: `levels=4&parent_ids=${dataCity?.id}`,
          type: 'domicile',
        };
        dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

        const paramsGetDropdownVillage = {
          queryParams: `levels=5&parent_ids=${dataSubdistrict?.id}`,
          type: 'domicile',
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));
        setTimeout(() => {
          setTriggerMap(false);
        }, 2000);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSaveMaps = (params: SelectedAddressType) => {
    const longitude = activeForm === 'ktp' ? 'longitude' : 'longitude_domicile';
    const latitude = activeForm === 'ktp' ? 'latitude' : 'latitude_domicile';
    const postal_code = 'postal_code_domicile';
    const address = 'address_domicile';

    if (activeForm === 'domicile') {
      setValue(longitude, params.lng);
      setValue(latitude, params.lat);
      setValue(postal_code, params.postalCode);
      setValue(address, params.fullAddress);
      setValue('pin_point_domicile', params.fullAddress);
      getLocation(params);
    } else {
      setValue(longitude, params.lng);
      setValue(latitude, params.lat);
      setValue('pin_point', params.fullAddress);
    }
  };

  const handleResetForm = () => {
    if (isSameAddress) {
      setValue('province_domicile', null as any);
      setValue('city_domicile', null as any);
      setValue('subdistrict_domicile', null as any);
      setValue('village_domicile', null as any);
      setValue('address_domicile', '');
      setValue('postal_code_domicile', '');
      setValue('longitude_domicile', '');
      setValue('latitude_domicile', '');
      setValue('domicile_certificates', []);

      const payloadResetDropdown = {
        location: 'all',
        type: 'domicile',
      };
      dispatch(resetNextDropdownAddress(payloadResetDropdown));
    }
  };

  const checkIsSameAddress = () => {
    const dataDealerKtp = dealer?.alamat?.alamat_ktp;
    const dataDealerDomicile = dealer?.alamat?.alamat_domisili;

    const checkDataDealerKtp = Object.assign({}, dataDealerKtp);
    delete checkDataDealerKtp.id;

    const checkDataDealerDomicile = Object.assign({}, dataDealerDomicile);
    delete checkDataDealerDomicile.id;
    delete checkDataDealerDomicile.domicile_certificates;

    if (
      JSON.stringify(checkDataDealerKtp) ===
      JSON.stringify(checkDataDealerDomicile)
    ) {
      setIsSameAddress(true);
    } else {
      setIsSameAddress(false);
    }
  };

  useEffect(() => {
    const dataAlamatKtp = dealer?.alamat?.alamat_ktp;
    const dataAlamatDomisili = dealer?.alamat?.alamat_domisili;

    checkIsSameAddress();

    if (localStorage.getItem('token')) {
      dispatch(getDropdownProvinceAddress('levels=2'));

      if (dataAlamatKtp?.id) {
        setValue('latitude', dataAlamatKtp.latitude);
        setValue('longitude', dataAlamatKtp.longitude);
        setValue('ownership_id', dropdown?.data?.homeOwnership?.find(item => item.id.toString() === dataAlamatKtp.ownership_id?.toString()));
        setValue('ownership_other', dataAlamatKtp.ownership_other);
        setValue('pin_point', dataAlamatKtp.pin_point);
      }

      if (dataAlamatDomisili?.id) {
        const paramsGetDropdownCityDomicile = {
          queryParams: `levels=3&parent_ids=${dataAlamatDomisili?.province_id}`,
          type: 'domicile',
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCityDomicile));

        const paramsGetDropdownSubdistrictDomicile = {
          queryParams: `levels=4&parent_ids=${dataAlamatDomisili?.city_id}`,
          type: 'domicile',
        };
        dispatch(
          getDropdownSubdistrictAddress(paramsGetDropdownSubdistrictDomicile)
        );

        const paramsGetDropdownVillageDomicile = {
          queryParams: `levels=5&parent_ids=${dataAlamatDomisili?.subdistrict_id}`,
          type: 'domicile',
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillageDomicile));

        setValue('latitude_domicile', dataAlamatDomisili?.latitude);
        setValue('longitude_domicile', dataAlamatDomisili?.longitude);
        setValue('address_domicile', dataAlamatDomisili?.address);
        setValue('postal_code_domicile', dataAlamatDomisili?.postal_code);
        setValue('ownership_id_domicile', {
          id: dataAlamatDomisili?.ownership_id?.toString() || 0,
          desc: dropdown?.data?.homeOwnership?.find(item => item.id.toString() === dataAlamatDomisili?.ownership_id?.toString())?.desc
        });
        setValue('ownership_other_domicile', dataAlamatDomisili?.ownership_other)
        setValue('domicile_certificates', dataAlamatDomisili?.domicile_certificates);
        setValue('pin_point_domicile', dataAlamatDomisili?.pin_point);
      }
    }
  }, [dealer]);

  const deleteProofItem = (idx: number) => {
    const tempArr = [...(getValues('domicile_certificates') as string[])];

    if (idx >= 0 && idx < tempArr.length) {
      tempArr.splice(idx, 1); // Remove one element at the specified index

      setValue('domicile_certificates', [...tempArr]);
    }
  };

  useEffect(() => {
    const dataAlamatDomisili = dealer?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatDomisili?.id &&
        dropdownProvinceDom.length > 0 &&
        !triggerMap
      ) {
        const dropdownProvinceDomisili = dropdownProvinceDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.province_id
        );
        setValue('province_domicile', dropdownProvinceDomisili?.[0]);
      }
    }
  }, [dropdownProvinceDom]);

  useEffect(() => {
    const dataAlamatDomisili = dealer?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (dataAlamatDomisili?.id && dropdownCityDom.length > 0 && !triggerMap) {
        const dropdownCityDomisili = dropdownCityDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.city_id
        );
        setValue('city_domicile', dropdownCityDomisili?.[0]);
      }
    }
  }, [dropdownCityDom]);

  useEffect(() => {
    const dataAlamatDomisili = dealer?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatDomisili?.id &&
        dropdownSubdistrictDom.length > 0 &&
        !triggerMap
      ) {
        const dropdownSubdistrictDomisili = dropdownSubdistrictDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.subdistrict_id
        );
        setValue('subdistrict_domicile', dropdownSubdistrictDomisili?.[0]);
      }
    }
  }, [dropdownSubdistrictDom]);

  useEffect(() => {
    const dataAlamatDomisili = dealer?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatDomisili?.id &&
        dropdownVillageDom.length > 0 &&
        !triggerMap
      ) {
        const dropdownVillageDomisili = dropdownVillageDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.village_id
        );
        setValue('village_domicile', dropdownVillageDomisili?.[0]);
      }
    }
  }, [dropdownVillageDom]);

  useEffect(() => {
    dispatch(getDropdown());
    navigator.geolocation.getCurrentPosition(
      () => {
        setIsPermission(true)
      },
      () => {
        setShowModal(true)
        setIsPermission(false)
        setModalMessage({
          title: 'Penanda lokasi belum aktif',
          description:
            'Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat',
        });
      }
    )
  }, []);

  useEffect(() => {
    if ((errors.latitude && errors.longitude) || (errors.latitude_domicile && errors.longitude_domicile)) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setIsPermission(true)
        },
        () => {
          setShowModal(true)
          setIsPermission(false)
          setModalMessage({
            title: 'Penanda lokasi belum aktif',
            description:
              'Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat',
          });
        }
      )
    }
  }, [errors.latitude, errors.longitude, errors.latitude_domicile, errors.longitude_domicile])

  const params = {
    activePage,
    setActivePage,
    handleSaveMaps,
    temporaryState,
    setTemporaryState,
  };

  return (
    <>
      <div className="flex flex-1 flex-col">
        {activePage === PageType.PINPOINT_LOCATION ? (
          <PinpointLocation {...params} />
        ) : (
          <>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-1 flex-col"
            >
              <HeaderEvent data={dealer} />

              <h3 className="font-semibold mb-4">Alamat KTP</h3>
              {dealer.alamat?.alamat_ktp?.short_address && (
                <label className="text-xs text-disabled mb-4 block">
                  {dealer.alamat?.alamat_ktp?.short_address}, {dealer.alamat?.alamat_ktp?.village_name},{' '}
                  {dealer.alamat?.alamat_ktp?.subdistrict_name}, {dealer.alamat?.alamat_ktp?.city_name},{' '}
                  {dealer.alamat?.alamat_ktp?.province_name} {dealer.alamat?.alamat_ktp?.postal_code}, Indonesia
                </label>
              )}
              <Alert className="mb-4">
                <span className="text-xs text-neutral-800">Atur peta lokasi sesuai dengan alamat KTP</span>
              </Alert>
              <div className={watch('pin_point') ? "bg-[#FAFAFA] p-4 rounded-lg mb-3" : 'mb-1'}>
                {watch('pin_point') && <h3 className="font-semibold mb-4">Pin Point</h3>}
                <p className="text-xs text-disabled">{watch('pin_point')}</p>
                {watch('pin_point') && <p className="text-xs text-disabled">{watch('latitude')}, {watch('longitude')}</p>}
                <div
                  className={`flex flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
                  onClick={() => {
                    setActivePage('pinpoint');
                    setActiveForm('ktp');
                    setTemporaryState({
                      lat: getValues('latitude'),
                      lng: getValues('longitude'),
                    });
                  }}
                >
                  <div className="flex justify-center items-center">
                    <img src={Assets.iconMarkerOrange} className="mr-2" />
                    <span className="text-xs text-secondary">
                      {watch('longitude') ? 'Ubah' : 'Atur'} Peta Lokasi
                    </span>
                  </div>
                </div>
                <small className="text-red-600 mb-5">
                  {!isPermission ? 'Penanda lokasi belum aktif. Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat' :
                    errors.longitude?.message || errors.latitude?.message || errors.pin_point?.message}
                </small>
              </div>
              <FormGroup className="mb-5">
                <Label name="Status Kepemilikan Rumah (sesuai KTP)" />
                <Controller
                  rules={{ required: 'Data harus diisi' }}
                  control={control}
                  name="ownership_id"
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={dropdown.data.homeOwnership}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        getOptionLabel={(option) => option.desc!}
                        getOptionValue={(option) => option.id?.toString()}
                        placeholder="Pilih status"
                        className={Number(watch('ownership_id')?.id) === Number(ownership_id) ? 'mb-2' : ''}
                      />
                      <small className="text-red-600">
                        {errors.ownership_id?.message}
                      </small>
                    </>
                  )}
                />
                {Number(watch('ownership_id')?.id) === Number(ownership_id) && (
                  <Controller
                    rules={{ required: 'Data harus diisi' }}
                    control={control}
                    name="ownership_other"
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          type="text"
                          className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                          placeholder="Masukan alasan lainnya"
                        />
                        <small className="text-red-600">
                          {errors.ownership_other?.message}
                        </small>
                      </>
                    )}
                  />
                )}
              </FormGroup>
              {mappingHiddenForm.map((a) => (
                <Controller
                  key={a.name}
                  control={control}
                  name={a.name}
                  rules={{ required: a.message }}
                  render={({ field }) => <input {...field} className="hidden" />}
                />
              ))}
              <h3 className="font-semibold mb-2 mt-4">Alamat Tempat Tinggal</h3>
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  className="checked:bg-blue-500 mr-2"
                  checked={isSameAddress}
                  onChange={() => {
                    setIsSameAddress(!isSameAddress);
                    handleResetForm();
                  }}
                />
                <span className="text-xs">
                  Alamat tempat tinggal sama dengan KTP
                </span>
              </div>
              {!isSameAddress && (
                <>
                  <div className={watch('pin_point_domicile') ? "bg-[#FAFAFA] p-4 rounded-lg mb-3" : 'mb-1'}>
                    {watch('pin_point_domicile') && <h3 className="font-semibold mb-4">Pin Point</h3>}
                    <p className="text-xs text-disabled">{watch('pin_point_domicile')}</p>
                    {watch('pin_point_domicile') && <p className="text-xs text-disabled">{watch('latitude_domicile')}, {watch('longitude_domicile')}</p>}
                    <div
                      className={`flex flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
                      onClick={() => {
                        setActivePage('pinpoint');
                        setActiveForm('domicile');
                        setTemporaryState({
                          lat: getValues('latitude_domicile'),
                          lng: getValues('longitude_domicile'),
                        });
                      }}
                    >
                      <div className="flex justify-center items-center">
                        <img src={Assets.iconMarkerOrange} className="mr-2" />
                        <span className="text-xs text-secondary">
                          {watch('longitude_domicile') ? 'Ubah' : 'Atur'} Peta
                          Lokasi
                        </span>
                      </div>
                    </div>
                    <small className="text-red-600 mb-5">
                      {!isPermission ? 'Penanda lokasi belum aktif. Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat' :
                        errors.longitude_domicile?.message || errors.latitude_domicile?.message}
                    </small>
                  </div>
                  {mappingDropdownDomicile.map((dt, idx) => (
                    <FormGroup className="mb-5" key={dt.name}>
                      <Label name={dt.label} />
                      <Controller
                        control={control}
                        name={dt.name}
                        rules={{ required: 'Data harus diisi' }}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              className="text-sm"
                              onChange={dt.onChange}
                              options={dt.options}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id.toString()}
                              placeholder={dt.placeholder}
                              isSearchable={false}
                              styles={dropdownStyles}
                            />
                            <small className="text-red-600">{dt.errors}</small>
                          </>
                        )}
                      />
                    </FormGroup>
                  ))}
                  <FormGroup className="mb-5">
                    <Label name="Detail Alamat" />
                    <Controller
                      control={control}
                      name="address_domicile"
                      rules={{ required: 'Data harus diisi' }}
                      render={({ field }) => (
                        <>
                          <textarea
                            {...field}
                            rows={3}
                            className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                            placeholder="Masukkan detail alamat"
                          />
                          <small className="text-red-600">
                            {errors.address_domicile?.message}
                          </small>
                        </>
                      )}
                    />
                    <Alert className="mb-0">
                      <span className="text-xs text-neutral-800">Pastikan detail alamat data yang diisi otomatis sesuai dengan alamat tempat tinggalmu</span>
                    </Alert>
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <Label name="Kode Pos" />
                    <Controller
                      control={control}
                      name="postal_code_domicile"
                      rules={{ required: 'Data harus diisi' }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            pattern="\d*"
                            placeholder="Masukkan kode pos"
                            className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                            onKeyDown={handleInputNumber}
                            type="tel"
                          />
                          <small className="text-red-600">
                            {errors.postal_code_domicile?.message}
                          </small>
                        </>
                      )}
                    />
                  </FormGroup>
                  <FormGroup className="mb-5">
                    <Label name="Status Kepemilikan Rumah (tempat tinggal)" />
                    <Controller
                      rules={{ required: 'Data harus diisi' }}
                      control={control}
                      name="ownership_id_domicile"
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            isSearchable={false}
                            options={dropdown.data.homeOwnership}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            getOptionLabel={(option) => option.desc!}
                            getOptionValue={(option) => option.id?.toString()}
                            placeholder="Pilih status"
                            className={Number(watch('ownership_id_domicile')?.id) === Number(ownership_id) ? 'mb-2' : ''}
                          />
                          <small className="text-red-600">
                            {errors.ownership_id_domicile?.message}
                          </small>
                        </>
                      )}
                    />
                    {Number(watch('ownership_id_domicile')?.id) === Number(ownership_id) && (
                      <Controller
                        rules={{ required: 'Data harus diisi' }}
                        control={control}
                        name="ownership_other_domicile"
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              type="text"
                              className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                              placeholder="Masukan alasan lainnya"
                            />
                            <small className="text-red-600">
                              {errors.ownership_other_domicile?.message}
                            </small>
                          </>
                        )}
                      />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label name="Surat Keterangan Domisili" />
                    <label className="text-xs text-neutral-500 my-4">
                      Upload hingga 10 dokumen, file pdf atau jpg
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required:
                          'Surat Keterangan Domisili harus diisi',
                      }}
                      name="domicile_certificates"
                      render={({ field }) => (
                        <>
                          <div className="flex overflow-x-auto items-center gap-x-4">
                            <InputTakePhotoWithDrawer
                              disabled={watch('domicile_certificates')?.length === 10}
                              isMultiple
                              placeholder={''}
                              onSelectCamera={() => setShowProofCamera(true)}
                              handleRemovePhoto={() => field.onChange('')}
                              onBrowseFile={() => console.log('')}
                              onSelectFile={(src) => {
                                field.onChange([...(getValues('domicile_certificates') || []), src]);
                              }}
                              srcUri={''}
                              imageLabel=""
                              allowPDF
                            />
                            {showProofCamera && (
                              <TakePhotoGeneral
                                handleConfirmPhoto={(src) => {
                                  field.onChange([...(getValues('domicile_certificates') || []), src]);
                                  setShowProofCamera(false);
                                }}
                                handleBack={() => setShowProofCamera(false)}
                                cameraProperty={{ facing_mode: 'environment' }}
                                title={''}
                                onUploadPhotoTaken={uploadImageFile}
                                previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                              />
                            )}
                            {(watch('domicile_certificates') || [])?.map((item, key) => {
                              return (
                                <div className="relative" key={item}>
                                  <div className="absolute top-3 left-1 bg-neutral-300 rounded-full w-5 h-5 flex items-center justify-center text-[10px] z-[5]">
                                    {key + 1}
                                  </div>
                                  <Thumbnail src={item} />
                                  <Button
                                    customClassName
                                    className="absolute top-0 -right-1 bg-neutral-800 rounded-full w-5 h-5 flex items-center justify-center"
                                    withIcon
                                    icon={CloseCircle}
                                    onClick={() => deleteProofItem(key)}
                                    name=""
                                    type="button"
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <small className="text-red-600">
                            {errors.domicile_certificates?.message}
                          </small>
                        </>
                      )}
                    />
                  </FormGroup>
                  {mappingHiddenFormDomicile.map((a) => (
                    <Controller
                      key={a.name}
                      control={control}
                      name={a.name}
                      rules={{ required: a.message }}
                      render={({ field }) => (
                        <input {...field} className="hidden" />
                      )}
                    />
                  ))}
                </>
              )}
              <div className="flex flex-1 flex-col justify-end h-full">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out font-semibold text-sm"
                >
                  Simpan dan Lanjut
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      <Modal
        icon={
          modalMessage.title === 'Lokasi di Luar Jangkauan'
            ? iconWarningOutOfArea
            : iconWarningDisableLocation
        }
        iconStyle="w-max"
        showModal={showModal}
        title={modalMessage.title}
        description={modalMessage.description}
        withButton={showModal}
        handleClickButton={() => {
          setShowModal(false);
          setModalMessage({
            title: '',
            description: '',
          });
        }}
      />
    </>
  );
};

export default InformasiAlamatV2;

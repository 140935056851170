import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDealerConsultantList = createAsyncThunk(
  'event/fetchDealerConsultant',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/event/dcs`
      )

      return response.data
    } catch (error: any) {
      throw error?.response?.data
    }
  }
)
import React, { useEffect } from 'react';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import KTP from '../../assets/icons/ktp.svg';
import Rekening from '../../assets/icons/rekening.svg';
import Kk from '../../assets/icons/kk.svg';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDealerDetail, insertState } from '../../redux/slices/dealer/dealerThunk';
import { WaitingStatus } from '../../utils/constants';
import { featureFlag } from '../../utils/helper';
import HeaderEvent from '../../components/Header/HeaderEvent';

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();

  const navigate = useNavigate();
  const data = useAppSelector((state) => state.dealer);
  const dispatch = useAppDispatch();

  const handleStart = () => {
    const payload = {
      uuid: uuid as string,
      contextName: 'waiting-for-registration-form',
      stateName: 'start-register'
    };

    if (data.dealer.data?.last_section) {
      navigate(
        `/dealer/registration/${uuid}/${data.dealer.data?.last_section}`
      );
    } else {
      dispatch(insertState(payload));
      navigate(`/dealer/registration/${uuid}/informasi_pribadi/`);
    }
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') || '');
    }
    dispatch(getDealerDetail(uuid as string));
  }, []);

  useEffect(() => {
    const latestStatus = data.dealer.data?.latest_status;

    if (
      latestStatus &&
      latestStatus !== WaitingStatus.WAITING_REGISTRATION_FORM &&
      latestStatus !== WaitingStatus.DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT
    ) {
      navigate(`/dealer/waiting-page/${uuid}`);
    }
  }, [data]);

  return (
    <div className="h-screen flex flex-col">
      <div className="px-4">
        <div>
          <img
            src={MoladinIcon}
            alt="moladin"
            className="my-[22px] cursor-pointer"
          />
          <hr className="-mx-4" />
        </div>

        <h1 className="font-bold text-lg mt-6 mb-7">
          Yang perlu kamu siapkan untuk daftar jadi Dealer
        </h1>

        <div className="flex flex-col h-full pb-4">
          <div className="flex-1">
            <div className="flex items-center mb-10">
              <img src={KTP} alt="ktp" className="mr-3" />
              <p className="text-xs">
                <span className="font-bold">KTP dan Kartu Keluarga</span> untuk
                verifikasi data diri
              </p>
            </div>

            <div className="flex items-center mb-10">
              <img src={Rekening} alt="ktp" className="mr-3" />
              <p className="text-xs">
                <span className="font-bold">Info Rekening</span> untuk mencairkan
                pengajuan pembiayaan
              </p>
            </div>

            <div className="flex items-center">
              <img src={Kk} alt="ktp" className="mr-3" />
              <p className="text-xs">
                <span className="font-bold">Halaman Depan Buku Tabungan</span>{' '}
                untuk verifikasi kepemilikan rekening (opsional)
              </p>
            </div>
          </div>
        </div>
      </div>
      {localStorage.getItem('token') && uuid ? (
        <div className="items-center justify-end flex flex-col flex-1">
          <small className="text-[#1F2937] text-xs mt-10 mb-5 text-center">
            Estimasi waktu pengisian{' '}
            <span className="font-semibold"> 10 menit</span>
          </small>
          {featureFlag('REACT_APP_SPRINT_9_1_DEALER_ONBOARDING_GATHERING') ? (
            <HeaderEvent data={data?.dealer?.data} isHomePage={true} />
          ) : (
            ''
          )}
          <div className="px-4 py-4 w-full">
            <button
              onClick={handleStart}
              className="bg-secondary rounded-lg h-11 w-full text-white"
            >
              Mulai
            </button>
          </div>
        </div>
      ) : (
        <div className="items-center justify-end flex flex-col flex-1 p-4">
          <button
            disabled
            className="bg-secondary rounded-lg h-11 w-full text-white disabled:bg-[#9CA3AF] mt-10 cursor-not-allowed"
          >
            Mulai
          </button>
        </div>
      )}
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import WelcomeImage from '../../assets/svg/welcome.svg';
import UserSquare from '../../assets/icons/user-square.svg';
import Profile from '../../assets/icons/profile.svg';
import Briefcase from '../../assets/icons/briefcase.svg';
import Location from '../../assets/icons/pin-location.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { employeeWelcome } from '../../redux/slices/employee/employeeThunk';
import { EmployeeWelcomeResponse } from '../../types/Employee';
import { toast } from 'react-toastify';

const WelcomeScreenEmployee = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();
  const [detailResponse, setDetailResponse] =
    useState<EmployeeWelcomeResponse | null>(null);

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') || '');
    }
    handleGetDetail();
  }, []);

  const handleGetDetail = async () => {
    const res = await dispatch(employeeWelcome(uuid as string));

    const requestStatus = res.meta.requestStatus;
    if (requestStatus === 'fulfilled') {
      setDetailResponse(res.payload as EmployeeWelcomeResponse);
    }

    if (requestStatus === 'rejected') {
      toast.error('Failed get detail');
    }
  };

  return (
    <div className="px-4 h-screen flex flex-col">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className="-mx-4" />
      </div>

      <div className="flex flex-col items-center  mb-6">
        <img src={WelcomeImage} alt="welcome" className="w-36" />
        <h1 className="font-bold text-lg mb-2">Selamat Datang di Moladin!</h1>
        <h6 className="text-xs">
          Tinggal selangkah lagi dan akunmu sudah siap.
        </h6>
        <h6 className="text-xs">Berikut informasi akunmu:</h6>
      </div>

      {detailResponse?.user?.id && (
        <div className="flex flex-col h-full pb-4 px-4">
          <div className="flex-1">
            <div className="flex gap-3 px-4 py-3 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.12)] mb-6">
              <img src={Profile} alt="user profile" />
              <div>
                <h4 className="text-xs font-semibold mb-1">
                  {detailResponse?.user?.full_name}
                </h4>
                <h6 className="text-xs">
                  {detailResponse?.user?.phone_number}
                </h6>
              </div>
            </div>

            <h3 className="text-xs font-medium mb-1">Jabatan</h3>
            <div className="flex gap-3 px-4 py-3 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.12)] mb-6">
              <img src={Briefcase} alt="user profile" />
              <div>
                <h4 className="text-xs font-semibold mb-1">
                  {detailResponse?.role?.desc}
                </h4>
                <h6 className="text-xs">{detailResponse?.role?.job_desc}</h6>
              </div>
            </div>

            {detailResponse?.parent?.id && (
              <>
                <h3 className="text-xs font-medium mb-1">Manager</h3>
                <div className="flex gap-3 px-4 py-3 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.12)] mb-6">
                  <img src={UserSquare} alt="user profile" />
                  <div>
                    <h4 className="text-xs font-semibold mb-1">
                      {detailResponse?.parent?.full_name}
                    </h4>
                    <h6 className="text-xs">
                      {detailResponse?.parent?.Role?.desc}
                    </h6>
                  </div>
                </div>
              </>
            )}

            {detailResponse?.branch?.id && (
              <>
                <h3 className="text-xs font-medium mb-1">Cabang</h3>
                <div className="flex gap-3 px-4 py-3 rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.12)] mb-6">
                  <img src={Location} alt="user profile" />
                  <div>
                    <h4 className="text-xs font-semibold mb-1">
                      {detailResponse?.branch?.description}
                    </h4>
                    <h6 className="text-xs">
                      {detailResponse?.warehouse?.mst_warehouse?.name}
                    </h6>
                  </div>
                </div>
              </>
            )}
          </div>

          <button
            onClick={() => navigate('/employee/rekening/' + detailResponse?.user?.uuid)}
            className="bg-secondary rounded-lg h-11 w-full text-white"
          >
            Mulai
          </button>
        </div>
      )}
    </div>
  );
};

export default WelcomeScreenEmployee;

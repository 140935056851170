import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const resendWhatsappNotif = createAsyncThunk(
  'resendWhatsappNotif/fetchData',
  async (uuid: string, { rejectWithValue }) => {
    try {
      if (localStorage.getItem('token') !== null) {
        axios.defaults.headers.common.Authorization =
          'Bearer ' + localStorage.getItem('token');
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ecosystem/onboarding/md/resend-umbrella-agreement/${uuid}`
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return response.data.data as any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { getRevision, getExpiredChecking } from '../../redux/slices/revision/revisionThunk';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Calender from '../../assets/icons/calender.svg'
import UploadDocument from '../../assets/icons/uploadDocument.svg'
import {Revision, ExpiredChecking} from '../../types/Revision'

export default function LandingPageRevisi() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams, setSeachParams] = useSearchParams()

    const { uuid } = useParams();
    const [revision, setRevision] = useState<Revision>();
    const [expiryCheck, setExpiryCheck] = useState<ExpiredChecking>();
    const [loadingCheck, setLoadingCheck] = useState(true)

    const Revision = async () => {
        try {
            const revision = await dispatch(getRevision({ uuid: uuid as string, type: localStorage.getItem('type') || '' }));
            const exp = await dispatch(getExpiredChecking({ uuid: uuid as string, type: localStorage.getItem('type') || '' }));
            if (revision.meta.requestStatus === 'rejected') {
                toast.error('Failed to load dealer detail');
            }
            setRevision(revision.payload)
            setExpiryCheck(exp.payload)
        } catch (error) {
            if (error instanceof AxiosError) {
                toast.error(error.message);
            }
        }
    };
    
    useEffect(() => {
        localStorage.setItem('type', searchParams.get('type') || '')

        if (searchParams.get('token')) {
            localStorage.setItem('token', searchParams.get('token') || '');

            searchParams.delete('token')
            searchParams.delete('type')

            setSeachParams(searchParams)
        }
    }, [])

    useEffect(() => {
        Revision();
      }, [navigate]);

    const dcPhone = localStorage.getItem('type') === 'dealer' && expiryCheck?.dc_phone !== null ? `?dc-phone=${expiryCheck?.dc_phone}` : ''

    useEffect(() => {
    if (expiryCheck) {
        setLoadingCheck(false)
        const type = localStorage.getItem('type')
        
        if(expiryCheck?.expired && expiryCheck?.latest_status === 'revision-submitted'){
            return navigate(`/dealer/revision/${type}/${uuid}/success`)
        }else if(!expiryCheck?.expired && expiryCheck?.latest_status === 'revision-submitted'){
            return navigate(`/dealer/revision/${type}/${uuid}/success`)
        }else if(expiryCheck?.expired && expiryCheck?.latest_status === 'review-data'){
            return navigate(`/dealer/revision/${localStorage.getItem('type') || ''}/${uuid}/expired${dcPhone}`);
        }else if(expiryCheck?.expired && expiryCheck?.latest_status === 'revision-link-expired'){
            return navigate(`/dealer/revision/${localStorage.getItem('type') || ''}/${uuid}/expired${dcPhone}`);
        }else if(expiryCheck?.expired && expiryCheck?.latest_status !== 'revision-link-expired'){
            return navigate(`/dealer/revision/${type}/${uuid}/success`)
        }
    }
    }, [expiryCheck?.expired, expiryCheck?.latest_status]);

      const handleButtonClick = () => {
        return navigate(`/dealer/revision/${localStorage.getItem('type') || ''}/${uuid}/form`);
      };
    
    if (loadingCheck) {
        return <div />
    }

    return (
        <div className='p-5'>
            <div className='pt-5 pb-5 bg-secondary-pure-white'>
                <img src='https://moladin.com/Logo.svg' />
            </div>
            <div className='mb-8'>
                <div className='font-metropolis font-bold text-18 leading-25'>Kamu perlu merevisi dokumenmu</div>
                <div className='text-sm'> Revisi ke: {revision?.revision_count}</div>
            </div>
            <div>
                <div className='flex mb-5'>
                    <img src={Calender} className='mr-3' />
                    <span className='font-metropolis text-sm leading-140'>Batas terakhir revisi adalah <b>{revision?.expired_at}</b></span>
                </div>
                <div className='flex'>
                    <img src={UploadDocument} className='mr-3' />
                    <span className='font-metropolis text-sm leading-140'>Ada {revision?.revision_docs_count} dokumen yang perlu kamu upload ulang agar proses pendaftaran dilanjutkan</span>
                </div>
            </div>
            <button
                onClick={handleButtonClick}
                className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out mt-10"
            >
                <p className="!mb-0">Mulai Revisi</p>
            </button>
        </div>
    )
}

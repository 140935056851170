import React, { useEffect, useState } from 'react';
import iconWarningRed from '../../assets/svg/iconWarningRed.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import {
  DukcapilRejectedMessageType,
  BaseDukcapilValidationRejectedType,
} from '../../types/WaitingPage';
import { toast } from 'react-toastify';
import { featureFlag } from '../../utils/helper';

const flagOcrKkCycle = featureFlag('REACT_APP_OCR_KK_CYCLE');

export const BaseDukcapilValidationRejected = (
  props: BaseDukcapilValidationRejectedType
) => {
  const {
    titleMessage = 'Ada kesalahan pada sistem kami',
    message = 'Coba kembali dalam beberapa saat lagi',
    buttonLabel = 'Coba lagi nanti',
    handleClickButton,
  } = props;
  return (
    <div className="flex justify-center items-center flex-col w-full h-screen justify-center items-center">
      <img src={iconWarningRed} className="ml-2" />
      <div className="text-base font-semibold mt-6 mb-2">{titleMessage}</div>
      <div className="text-sm text-gray-500">{message}</div>
      <button
        type="submit"
        className="font-semibold rounded-lg h-11 w-80 text-white bg-secondary mb-4 mt-8"
        onClick={handleClickButton}
      >
        <div className="flex justify-center items-center">
          <span className="text-sm text-white">
            {buttonLabel}
          </span>
        </div>
      </button>
    </div>
  );
};

export const DukcapilValidationRejectedFirstAttempt = () => {
  const dataDealer = useAppSelector((state) => state.dealer.dealer.data);
  const navigate = useNavigate();
  const { uuid } = useParams();

  const handleClickButton = () => {
    const isEmptyError = Array.isArray(dataDealer.error_field);
    if (isEmptyError) {
      toast.error('error_field is empty');
    } else {
      const section = flagOcrKkCycle ? 'informasi_pribadi' : Object.keys(dataDealer.error_field)[0];
      navigate(`/dealer/registration/${uuid}/${section}`);
    }
  };
  return (
    <BaseDukcapilValidationRejected
      titleMessage="Verifikasi data diri gagal"
      message="Cek kembali data KTP atau foto selfie"
      buttonLabel="Perbaiki Data"
      handleClickButton={handleClickButton}
    />
  );
};

interface DukcapilValidationRejectedProps {
  handleTryAgain?: () => void;
}

const DukcapilValidationRejected = ({
  handleTryAgain,
}: DukcapilValidationRejectedProps) => {
  const navigate = useNavigate();
  const dataDealer = useAppSelector((state) => state.dealer.dealer.data);
  const { uuid } = useParams();
  const [message, setMessage] = useState<DukcapilRejectedMessageType>({
    title_message: 'Ada kesalahan pada sistem kami',
    message: 'Coba kembali dalam beberapa saat lagi',
  });

  const handleClickTryAgain = () => {
    if (handleTryAgain) {
      handleTryAgain();
    } else {
      navigate(`/dealer/waiting-page/${uuid}`);
    }
  };

  useEffect(() => {
    const checkDataDealer = dataDealer?.vida_error;
    if (checkDataDealer?.message) {
      setMessage({
        message: checkDataDealer.message,
        title_message: checkDataDealer.title_message,
      });
    }
  }, [dataDealer]);

  return (
    <BaseDukcapilValidationRejected
      titleMessage={message?.title_message}
      message={message?.message}
      handleClickButton={handleClickTryAgain}
    />
  );
};

export default DukcapilValidationRejected;

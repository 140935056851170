import React from 'react'
import infoCircle from '../../assets/icons/infoCircle.svg';

interface Props { 
  title: string;
  message: string;
  isMessageHtml?: boolean;
 }

function ErrorAlert({title, message, isMessageHtml}: Props) {
  return (
    <div className='p-[9px] flex bg-[#FEEAEA] border border-[#F56F6F] rounded gap-2'>
      <div className='w-[16px] h-[16px]'><img className='w-full h-full' src={infoCircle} /></div>
      <div>
        <p className='text-xs font-bold mb-[4px]'>{title}</p>
        {
          isMessageHtml ?
            <p className='text-xs' dangerouslySetInnerHTML={{__html: message}} />
          :
            <p className='text-xs'>{message}</p>
        }
      </div>
    </div>
  )
}

export default ErrorAlert

import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, SubmitHandler, ControllerRenderProps } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { submitFormRevision, uploadImageFile } from '../../services';
import Label from '../../components/atom/Label';
import InputTakePhotoWithDrawer from '../../components/FotoIdentitas/InputTakePhotoWithDrawer';
import TakePhotoGeneral from '../../components/Camera/TakePhotoGeneral';
import PopupGuide from '../../components/FotoIdentitas/PopupGuide/PopupGuide';
import FormGroup from '../../components/atom/FormGroup';
import { getRevisionDocument } from '../../redux/slices/revision/revisionThunk';
import { Revision as RevisionType, Revisions } from '../../types/Revision';
import { AxiosError } from 'axios';
import { ChevronRight, Info, X } from 'react-feather';
import CustomModal from '../../components/atom/CustomModal';
import AccordionItem from '../../components/atom/AccrodionItem';
import guide1 from '../../assets/images/photoGuide/transactionProof/guide1.png'
import guide2 from '../../assets/images/photoGuide/transactionProof/guide2.png'
import guide3 from '../../assets/images/photoGuide/transactionProof/guide3.png'
import guide4 from '../../assets/images/photoGuide/transactionProof/guide4.png'
import Button from '../../components/atom/Button';
import Thumbnail from '../../components/atom/Thumbnail';
import CloseCircle from '../../assets/icons/close-circle.svg'
import { featureFlag } from '../../utils/helper';
import { MULTIPLE_UPLOAD_REVISION_FORM } from '../../utils/constants';

const iconInfo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.99935 14.6667C11.6727 14.6667 14.666 11.6733 14.666 7.99999C14.666 4.32666 11.6727 1.33332 7.99935 1.33332C4.32602 1.33332 1.33268 4.32666 1.33268 7.99999C1.33268 11.6733 4.32602 14.6667 7.99935 14.6667ZM8.49935 10.6667C8.49935 10.94 8.27268 11.1667 7.99935 11.1667C7.72602 11.1667 7.49935 10.94 7.49935 10.6667L7.49935 7.33332C7.49935 7.05999 7.72602 6.83332 7.99935 6.83332C8.27268 6.83332 8.49935 7.05999 8.49935 7.33332L8.49935 10.6667ZM7.38602 5.07999C7.41935 4.99332 7.46602 4.92666 7.52602 4.85999C7.59268 4.79999 7.66602 4.75332 7.74602 4.71999C7.82602 4.68666 7.91268 4.66666 7.99935 4.66666C8.08602 4.66666 8.17268 4.68666 8.25268 4.71999C8.33268 4.75332 8.40602 4.79999 8.47268 4.85999C8.53268 4.92666 8.57935 4.99332 8.61268 5.07999C8.64602 5.15999 8.66602 5.24666 8.66602 5.33332C8.66602 5.41999 8.64602 5.50666 8.61268 5.58666C8.57935 5.66666 8.53268 5.73999 8.47268 5.80666C8.40602 5.86666 8.33268 5.91332 8.25268 5.94666C8.09268 6.01332 7.90602 6.01332 7.74602 5.94666C7.66602 5.91332 7.59268 5.86666 7.52602 5.80666C7.46602 5.73999 7.41935 5.66666 7.38602 5.58666C7.35268 5.50666 7.33268 5.41999 7.33268 5.33332C7.33268 5.24666 7.35268 5.15999 7.38602 5.07999Z" fill="#FFB600" />
  </svg>
)

const Warning = ({ notes = '' }: { notes: string }) => {
  const [isHideWarning, setHideWarning] = useState(false);

  const hideWarning = () => {
    setHideWarning(true);
  };

  return (
    <div className={'p-3 border text-sm border-1 border-yellow-300 rounded-md bg-yellow-50 ' + (!isHideWarning ? 'block' : 'hidden')}>
      <div className='flex justify-between'>
        <div className='flex'>
          <div className='items-center self-center'>
            {iconInfo}
          </div>
          <div className='ml-2'>
            Catatan : {notes}
          </div>
        </div>
        <div className="text-base cursor-pointer" onClick={hideWarning}>
          x
        </div>
      </div>
    </div>
  )
}

export default function RevisiDokumen() {
  const { uuid, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: revisionDocument } = useAppSelector((state) => state.revision.revisionDocument);
  const [showTempatUsahaCamera, setShowTempatUsahaCamera] = useState({
    showGuide: false,
    contentType: '',
    photo_front_building: false,
    photo_left_building: false,
    photo_right_building: false,
    photo_selfie: false,
  });
  const {
    handleSubmit,
    control,
  } = useForm<Revisions[]>();

  const [showCamera, setShowCamera] = useState({
    key: '',
    show: false
  })
  const [docs, setDocs] = useState<Revisions[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [showGuide, setShowGuide] = useState<boolean>(false)
  const [proofOfTransactions, setProofOfTransactions] = useState<string[]>([])
  const [domicileCertificates, setDomicileCertificates] = useState<string[]>([])
  const isAnyEmptyValue = () => {
    const arrEmptyValue: string[] = [];

    for (let i = 0; i < docs.length; i++) {
      const stateByKey = typeof docs[i].value === 'object'
        ? docs[i].key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE
          ? proofOfTransactions
          : domicileCertificates
        : docs[i].value;

      if (!stateByKey || stateByKey.length === 0) {
        arrEmptyValue.push(docs[i].key);
      }
    }

    return arrEmptyValue.length > 0;
  };

  const RevisionDocument = async () => {
    try {
      const revision = await dispatch(getRevisionDocument({ uuid: uuid as string }));
      if (revision.meta.requestStatus === 'rejected') {
        toast.error('Failed to load dealer detail');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    RevisionDocument();
  }, [])

  useEffect(() => {
    if (revisionDocument) {
      setProofOfTransactions(() => ([...revisionDocument.find(item => item.key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE)?.value || '']))
      if (featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM')) {
        setDomicileCertificates(() => ([...revisionDocument.find(item => item.key === MULTIPLE_UPLOAD_REVISION_FORM.DOMICILE_CERTIFICATES)?.value || '']))
      }
      setDocs(revisionDocument || [])
    }
  }, [revisionDocument])

  const onSubmit: SubmitHandler<Revisions[]> = () => {
    setLoadingSubmit(true)

    const currentDocs = [...docs];
    const proofDocsIndex = currentDocs.findIndex(item => item.key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE);
    const domicileDocsIndex = currentDocs.findIndex(item => item.key === MULTIPLE_UPLOAD_REVISION_FORM.DOMICILE_CERTIFICATES);

    if (proofDocsIndex !== -1) {
      const updatedProofDocs = { ...currentDocs[proofDocsIndex], value: proofOfTransactions };
      currentDocs[proofDocsIndex] = updatedProofDocs;
    }

    if (domicileDocsIndex !== -1) {
      const updatedDomicileDocs = { ...currentDocs[domicileDocsIndex], value: domicileCertificates };
      currentDocs[domicileDocsIndex] = updatedDomicileDocs;
    }

    submitFormRevision({ data: currentDocs }, uuid as string)
      .then((res) => {
        const { status } = res.data;

        setLoadingSubmit(false)

        if (status) {
          navigate(`/dealer/revision/${type}/${uuid}/waiting-approval`)
        } else {
          toast.error('Failed submit revision');
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setLoadingSubmit(false)
        err.response.status === 409 ? navigate(`/dealer/revision/${type}/${uuid}/waiting-approval?already-send=true`) : ''
        toast.error('Failed submit revision');
      });
  };

  const onDeleteFile = (key: string, file: string) => {
    if (key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE) { // delete photo from proofOfTransactions
      setProofOfTransactions(proof => {
        const idx = proof.findIndex(i => i === file)
        proof.splice(idx, 1)

        return [...proof]
      })
    } else if (key === MULTIPLE_UPLOAD_REVISION_FORM.DOMICILE_CERTIFICATES) { // delete photo from domicileCertificates
      setDomicileCertificates(certificate => {
        const idx = certificate.findIndex(i => i === file)
        certificate.splice(idx, 1)

        return [...certificate]
      })
    }
  }

  const onSelectFile = (key: string, field: ControllerRenderProps<Revisions[], any>, src: string) => {
    const prevSrc = docs.find(i => i.key === key)?.value
    const arrByKey = key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE ? proofOfTransactions : domicileCertificates
    const setStateByKey = key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE ? setProofOfTransactions : setDomicileCertificates

    if (arrByKey.length > 9) return
    else {
      if (docs.filter(doc => doc.key === key).length === 0) {
        setDocs(prev => ([
          ...prev,
          {
            key: key,
            value: (typeof prevSrc === 'string' ? src : [...prevSrc || [], src]),
            notes: '',
            label: ''
          }
        ]))
      } else {
        setDocs(prev => {
          return prev.map(prevData => key === prevData.key ? {
            ...prevData,
            value: (typeof prevSrc === 'string' ? src : [...prevSrc || [], src])
          } : prevData);
        })
      }
      field.onChange(src)

      setStateByKey(element => ([...element, src]))
    }
  }

  const onConfirmPhoto = (key: string, field: ControllerRenderProps<Revisions[], any>, src: string) => {
    const prevSrc = docs.find(i => i.key === key)?.value
    const arrByKey = key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE ? proofOfTransactions : domicileCertificates
    const setStateByKey = key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE ? setProofOfTransactions : setDomicileCertificates

    if (arrByKey.length > 9) return
    else {
      field.onChange(src);
      setShowCamera(prev => ({ key: key, show: false }));

      if (docs.filter(doc => doc.key === key).length === 0) {
        setDocs(prev => ([
          ...prev,
          {
            key: key,
            value: (typeof prevSrc === 'string' ? src : [...prevSrc || [], src]),
            notes: '',
            label: ''
          }
        ]))
      } else {
        setDocs(prev => {
          return prev.map(prevData => key === prevData.key ? {
            ...prevData,
            value: (typeof prevSrc === 'string' ? src : [...prevSrc || [], src])
          } : prevData);
        })
      }
      setStateByKey(element => ([...element, src]))
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex-1 flex flex-col justify-between"
    >
      {docs?.map((item, key) => (
        <FormGroup key={item.key}>
          <Label name={item.label} />
          <Warning notes={item.notes} />
          {[MULTIPLE_UPLOAD_REVISION_FORM.DOMICILE_CERTIFICATES, MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE].includes(item.key) ? (
            <>
              {item.key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE ? (
                <button type='button' className="mt-2 w-full flex justify-between rounded-2xl px-4 bg-[#E6EAF8] mb-2 items-center py-2" onClick={() => setShowGuide(!showGuide)}>
                  <label className="text-primary font-bold text-xs">{item.label}</label>
                  <ChevronRight className="text-primary" />
                </button>
              ) : ''}
              <label className="text-xs text-neutral-500 my-4">Upload hingga 10 dokumen</label>
              <Controller
                control={control}
                name={`${key}.key`}
                render={({ field }) => {
                  return (
                    <>
                      <div className="flex overflow-x-auto items-center gap-x-4">
                        <InputTakePhotoWithDrawer
                          disabled={proofOfTransactions.length > 9}
                          isMultiple
                          placeholder={""}
                          onSelectCamera={() => setShowCamera(prev => ({ key: item.key, show: true }))}
                          handleRemovePhoto={() => {
                            setDocs(prev => {
                              const idx = prev.findIndex(doc => doc.key === item.key);

                              if (idx !== -1) {
                                const updatedDocs = [...prev.slice(0, idx), ...prev.slice(idx + 1)];
                                return updatedDocs;
                              }

                              return prev;
                            })
                          }}
                          onBrowseFile={() => console.log('')}
                          onSelectFile={(src) => onSelectFile(item.key, field, src)}
                          srcUri=''
                          imageLabel={""}
                          allowPDF={[MULTIPLE_UPLOAD_REVISION_FORM.DOMICILE_CERTIFICATES, MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE].includes(item.key)}
                        />
                        {showCamera.key === item.key && showCamera.show && (
                          <TakePhotoGeneral
                            handleConfirmPhoto={(src) => onConfirmPhoto(item.key, field, src)}
                            handleBack={() => setShowCamera(prev => ({ key: item.key, show: false }))}
                            cameraProperty={{ facing_mode: 'environment' }}
                            title={`Ambil ${item.label}`}
                            onUploadPhotoTaken={uploadImageFile}
                            previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                          />
                        )}
                        {[
                          ...(item.key === MULTIPLE_UPLOAD_REVISION_FORM.MULTIFINANCE ? proofOfTransactions : []),
                          ...(item.key === MULTIPLE_UPLOAD_REVISION_FORM.DOMICILE_CERTIFICATES ? domicileCertificates : [])
                        ].map((photo, key) => {
                          return (
                            <div className="relative z-0" key={`${item.key}-${key}`}>
                              <div className="absolute top-3 left-1 bg-neutral-300 rounded-full w-5 h-5 flex items-center justify-center text-[10px] z-10">{key + 1}</div>
                              <Thumbnail src={photo} />
                              <Button
                                customClassName
                                className="absolute top-0 -right-1 bg-neutral-800 rounded-full w-5 h-5 flex items-center justify-center z-10"
                                withIcon
                                icon={CloseCircle}
                                onClick={() => onDeleteFile(item.key, photo)}
                                name=""
                                type="button"
                              />
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )
                }}
              />
            </>
          ) : (
            <Controller
              control={control}
              name={`${key}.key`}
              render={({ field }) => {
                return (
                  <>
                    <InputTakePhotoWithDrawer
                      placeholder={`Upload revisi ${item.label}`}
                      onSelectCamera={() => setShowCamera(prev => ({ key: item.key, show: true }))}
                      handleRemovePhoto={() => {
                        setDocs(prev => {
                          const updatedDocs = prev.map(doc => {
                            if (doc.key === item.key) {
                              return {
                                ...doc,
                                value: ''
                              }
                            }
                            return doc
                          })

                          return updatedDocs
                        })
                      }}
                      onBrowseFile={() => console.log('')}
                      onSelectFile={(src) => {
                        if (docs.filter(doc => doc.key === item.key).length === 0) {
                          setDocs(prev => ([
                            ...prev,
                            { key: item.key, value: src, notes: '', label: '' }
                          ]))
                        } else {
                          setDocs(prev => {
                            return prev.map(prevData => item.key === prevData.key ? { ...prevData, value: src } : prevData);
                          })
                        }
                        field.onChange(src)
                      }}
                      srcUri={docs.find(i => i.key === item.key)?.value}
                      imageLabel={item.label}
                      allowPDF={item.key === 'multifinance_match'}
                    />
                    {showCamera.key === item.key && showCamera.show && (
                      <TakePhotoGeneral
                        handleConfirmPhoto={(src) => {
                          field.onChange(src);
                          setShowCamera(prev => ({ key: item.key, show: false }));

                          if (docs.filter(doc => doc.key === item.key).length === 0) {
                            setDocs(prev => ([
                              ...prev,
                              { key: item.key, value: src, notes: '', label: '' }
                            ]))
                          } else {
                            setDocs(prev => {
                              return prev.map(prevData => item.key === prevData.key ? { ...prevData, value: src } : prevData);
                            })
                          }
                        }}
                        handleBack={() => setShowCamera(prev => ({ key: item.key, show: false }))}
                        cameraProperty={{ facing_mode: 'environment' }}
                        title={`Ambil ${item.label}`}
                        onUploadPhotoTaken={uploadImageFile}
                        previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                      />
                    )}
                  </>
                )
              }}
            />
          )}
        </FormGroup>
      ))}
      {showTempatUsahaCamera.showGuide && (
        <PopupGuide
          handleClose={() => {
            setShowTempatUsahaCamera({
              ...showTempatUsahaCamera,
              showGuide: false,
            });
          }}
          handleConfirm={() => {
            setShowTempatUsahaCamera({
              ...showTempatUsahaCamera,
              showGuide: false,
            });
          }}
          contentType={showTempatUsahaCamera.contentType}
        />
      )}
      <button
        type="submit"
        className={`${isAnyEmptyValue() ? 'disabled:bg-[#9CA3AF]' : ''} bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out`}
        disabled={loadingSubmit || isAnyEmptyValue()}
      >
        {loadingSubmit ? (
          <p className="!mb-0">Loading...</p>
        ) : (
          <p className="!mb-0">Simpan dan Lanjut</p>
        )}
      </button>
      <CustomModal showModal={showGuide} handleClickBackground={() => setShowGuide(!showGuide)}>
        <div className="flex items-center mb-4">
          <button type='button' className="bg-transparent" onClick={() => setShowGuide(!showGuide)}>
            <X />
          </button>
          <label className="text-neutral-800 text-center flex-1 font-bold">Panduan Bukti Transaksi Dealer</label>
        </div>
        <div className="flex mb-4 rounded-lg border p-2 bg-[#E6EAF8] border-[#6B85D4] gap-x-2">
          <div>
            <Info fill='#002DB4' color='#FFFFFF' size={16} />
          </div>
          <div className="flex flex-col">
            <span className="text-xs text-neutral-800">Dapatkan limit lebih besar dengan:</span>
            <ul className='mb-0'>
              <li className="text-xs text-neutral-800">Upload dokumen lebih banyak</li>
              <li className="text-xs text-neutral-800">Nominal transaksi lebih besar</li>
            </ul>
          </div>
        </div>
        <label className="mb-[18px] font-bold text-sm">Dokumen yang bisa digunakan sebagai bukti transaksi:</label>
        <AccordionItem title="Mutasi Bank 3 Bulan Terakhir" content={
          <>
            <img src={guide1} className="mb-4" />
            <img src={guide2} />
          </>
        } />
        <AccordionItem title="Bukti Transaksi Jual Beli Mobil" content={
          <>
            <img src={guide3} className="mb-4" />
            <label className="text-xs text-neutral-800">Catatan: Satu atau lebih transaksi dalam <b>3 bulan terakhir</b></label>
          </>
        } />
        <AccordionItem title="PO Moladin" content={
          <>
            <img src={guide4} className="mb-4" />
            <label className="text-xs text-neutral-800">Catatan: Satu atau lebih PO Moladin dalam <b>6 bulan terakhir</b></label>
          </>
        } />
        <label className="mb-[18px] font-bold text-sm">Pastikan dokumen terdapat informasi terkait jual beli kendaraan, bisa terdiri dari:</label>
        <ul className="text-xs text-neutral-700">
          <li>Bukti Pembayaran Uang Muka</li>
          <li>Bukti Pembayaran Komisi</li>
          <li>Bukti Pelunasan</li>
          <li>Bukti Pencairan</li>
        </ul>
      </CustomModal>
    </form>
  );
}

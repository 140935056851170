import React, { useState } from 'react';
import { X } from 'react-feather';
import uploadIcon from '../../assets/icons/uploadIcon.svg';
import cameraIcon from '../../assets/icons/camera-minus.svg';
import galleryIcon from '../../assets/icons/gallery.svg';
import folderIcon from '../../assets/icons/folder-open.svg';
import { uploadImageFileEmployee } from '../../services';
import { BROWSE_FILE_TYPE } from '../../utils/constants';
import BrowseFileHidden from '../atom/BrowseFileHidden';

type Props = {
  placeholder: string;
  onSelectCamera: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleRemovePhoto: () => void;
  srcUri: string | undefined;
  imageLabel: string;
  allowPDF?: boolean;
};

// communication to android webview
function sendMessage(message: string) {
  (window as any).AndroidInterface?.onMessageReceived(message);
}

export default function InputTakePhotoWithDrawerEmployeeCamera({
  placeholder,
  onSelectCamera,
  srcUri,
  handleRemovePhoto,
  imageLabel,
  allowPDF,
}: Props) {
  const [showDrawer, setShowDrawer] = useState(false);


  const srcUriIsPdf =
    srcUri?.substring(srcUri?.lastIndexOf('.') + 1, srcUri?.length) === 'pdf';

  return (
    <>
      {srcUri ? (
        <div className="border-2 mt-2 border-dashed border-spacing-7 rounded py-7">
          <div className="flex px-3 gap-2.5 items-center">
            <div className="h-[72px] w-[72px]">
              {srcUriIsPdf ? (
                <a
                  href={srcUri}
                  target="_blank"
                  rel="noreferrer"
                  className="relative inline-block w-full h-full"
                >
                  <div className="absolute h-full w-full z-10 opacity-0"></div>
                  <iframe
                    src={srcUri}
                    className="w-full h-full object-cover z-20"
                  />
                </a>
              ) : (
                <img src={srcUri} className="w-full h-full object-cover" />
              )}
            </div>
            <div className="flex w-full justify-between items-center text-sm font-normal">
              <div>{imageLabel}</div>
              <div className="flex items-center gap-[18px]">
                <div
                  onClick={() => setShowDrawer(true)}
                  className="text-xs text-blue-500 font-bold"
                >
                  Ubah
                </div>
                <X
                  onClick={handleRemovePhoto}
                  className="text-neutral-500"
                  width={24}
                  height={24}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setShowDrawer(true)}
          className="border-2 mt-2 border-dashed border-spacing-7 rounded py-7"
        >
          <div className="flex flex-col items-center gap-2.5">
            <div>
              <img src={uploadIcon} />
            </div>
            <div className="text-sm text-[#002DB4]">{placeholder}</div>
          </div>
        </div>
      )}

      {showDrawer && (
        <div className="fixed z-20 top-0 left-0 bottom-0 w-full flex flex-col justify-end">
          <div
            className="bg-black bg-opacity-30 flex-1"
            onClick={() => setShowDrawer(false)}
          />
          <div className="bg-white py-2">
            <button
              type="button"
              className="flex items-center gap-6 p-4 w-full justify-start"
              onClick={(e) => {
                setShowDrawer(false);
                sendMessage('openCamera');
                onSelectCamera(e);
              }}
            >
              <img src={cameraIcon} alt="" />
              <h4 className="text-sm text-gray-500 text-left">
                Ambil foto dengan kamera
              </h4>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

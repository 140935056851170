import React from "react";
import { Info } from 'react-feather';

type Props = {
  children?: React.ReactNode,
  className?: string
}

const Alert = ({
  children,
  className
} : Props) => {
  return (
    <div className={"flex mb-4 rounded-lg border p-2 bg-[#E6EAF8] border-[#6B85D4] gap-x-2 " + className}>
      <div>
        <Info fill='#002DB4' color='#FFFFFF' size={16} />
      </div>
      <div className="flex flex-col">
        {children}
      </div>
    </div>
  )
}

export default Alert
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as Assets from '../../../../utils/assets';
import iconWarningRed from '../../../../assets/svg/iconWarningRed.svg';
import FormGroup from '../../../atom/FormGroup';
import Label from '../../../atom/Label';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Calendar } from 'react-feather';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../../atom/Input';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getDropdown } from '../../../../redux/slices/dealer/dealerThunk';
import { DropdownMaster } from '../../../../types/commonTypes';
import { getLocationByLatLong } from '../../../../services';
import { DropdownMasterInformasiAlamat, SelectedAddressType } from '../../../../types/InformasiAlamatTypes';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import ErrorAlert from '../../../atom/ErrorAlert';
import { checkNIK, saveDraftV2 } from '../../../../redux/slices/employee/employeeThunk';
import { resetOCR } from '../../../../redux/slices/employee/employeeSlice';
import {
    NAME_REGEX,
    PAGE_SECTION_FORM,
    InformasiAlamatActiveDisplay as PageType,
} from '../../../../utils/constants';
import {
    getDropdownProvinceAddress,
    getDropdownCityAddress,
    getDropdownSubdistrictAddress,
    getDropdownVillageAddress,
    getParentAddressByVillage,
} from '../../../../redux/slices/informasiAlamat/informasiAlamatThunk';
import { resetNextDropdownAddress } from '../../../../redux/slices/informasiAlamat/informasiAlamatSlice';
import PinpointLocation from '../../../InformasiAlamat/PinpointLocation';
import { TemporaryStateUsahaType } from '../../../../types/AlamatUsahaTypes';
// import { dummyktp as dataOCR } from './dummyKTP';

export type FormData = {
    ktp_photo_url: string,
    selfie_url: string,
    ktp_number: string;
    full_name: string;
    nick_name: string,
    birth_place: string,
    dob: string;
    gender: string;
    marriage_id: DropdownMaster;
    address: string,
    province: DropdownMasterInformasiAlamat,
    city: DropdownMasterInformasiAlamat,
    subdistrict: DropdownMasterInformasiAlamat,
    village: DropdownMasterInformasiAlamat,
    postal_code: string,
};
interface ErrorValidationNIK {
    isError: boolean;
    title: string;
    message: string;
}
interface ErrorResponse {
    label?: string;
    message?: string;
}
interface Props {
    selfie_url: string;
    ktp_photo_url: string;
    errorCode?: number | null | undefined;
    showPopup: () => void
}

const InformasiKTP = ({ selfie_url, ktp_photo_url, errorCode, showPopup }: Props) => {
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormData>();

    const dispatch = useAppDispatch();
    const dropdown = useAppSelector((state) => state.dealer.dropdown);
    const { data: employee, ktpOCR: dataOCR, loading: isLoading } = useAppSelector(
        // const { data: employee, loading: isLoading } = useAppSelector(
        (state) => state.employee.employee
    );
    const [activePage, setActivePage] = useState<'form' | 'pinpoint'>('form');
    const [temporaryState, setTemporaryState] = useState<TemporaryStateUsahaType>(
        { lat: '', lng: '' }
    );
    const [triggerMap, setTriggerMap] = useState(false);

    const navigate = useNavigate();
    const { uuid } = useParams();

    const watchKtpNumber = watch('ktp_number');

    const [errorValdiationNIK, setErrorValidationNIK] =
        useState<ErrorValidationNIK>({
            isError: false,
            title: '',
            message: '',
        });
    const [isInvalidNIK, setIsInvalidNIK] = useState({ invalid: false, message: '' })
    const [isEdited, setIsEdited] = useState(false)

    const dropdownStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderColor: state?.isFocused ? '#0F2CAD' : 'default',
        }),
    };

    const dropdownProvince = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownProvince
    );
    const dropdownCity = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownCity
    );
    const dropdownSubdistrict = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownSubdistrict
    );
    const dropdownVillage = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownVillage
    );
    const parentAddress = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.parentAddressData
    );

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        if (localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownProvince.length > 0) {
                const dropdownProvinceKtp = dropdownProvince.filter(
                    (a: any) => a.id === dataAlamatKtp?.province_id
                );
                setValue('province', dropdownProvinceKtp[0]);
                const paramsGetDropdownCity = {
                    queryParams: `levels=3&parent_ids=${dropdownProvinceKtp[0].id}`,
                    type: 'ktp'
                };
                dispatch(getDropdownCityAddress(paramsGetDropdownCity));
            }
        }
    }, [dropdownProvince]);

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        if (localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownCity.length > 0) {
                const dropdownCityKtp = dropdownCity.filter(
                    (a: any) => a.id === dataAlamatKtp?.city_id
                );
                setValue('city', dropdownCityKtp[0]);
                if (dropdownCityKtp.length > 0) {
                    const paramsGetDropdownSubdistrict = {
                        queryParams: `levels=4&parent_ids=${dropdownCityKtp[0].id}`,
                        type: 'ktp'
                    };
                    dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict))
                }
            }
        }
    }, [dropdownCity]);

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        if (localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownSubdistrict.length > 0) {
                const dropdownSubdistrictKtp = dropdownSubdistrict.filter(
                    (a: any) => a.id === dataAlamatKtp?.subdistrict_id
                );
                setValue('subdistrict', dropdownSubdistrictKtp[0]);
                if (dropdownSubdistrictKtp.length > 0) {
                    const paramsGetDropdownVillage = {
                        queryParams: `levels=5&parent_ids=${dropdownSubdistrictKtp[0].id}`,
                        type: 'ktp'
                    };
                    dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));
                }
            }
        }
    }, [dropdownSubdistrict]);

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        if (localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownVillage.length > 0) {
                const dropdownVillageKtp = dropdownVillage.filter(
                    (a: any) => a.id === dataAlamatKtp?.village_id
                );
                setValue('village', dropdownVillageKtp[0]);
            }
        }
    }, [dropdownVillage]);

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const { province, city, subdistrict, village, address, postal_code, ...rest } = data
        const dataExist = employee?.foto_hasil_ktp_v2
        const payload = {
            foto_hasil_ktp_v2: {
                ...rest,
                ktp_photo_url: dataExist?.ktp_photo_url || ktp_photo_url,
                selfie_url: dataExist?.selfie_url || selfie_url,
                marriage_id: data.marriage_id.id,
                dob: dayjs(data.dob).format('YYYY-MM-DD'),
                alamat_ktp: {
                    address: data?.address,
                    province_id: data?.province?.id,
                    province_name: data?.province?.name,
                    city_id: data?.city?.id,
                    city_name: data?.city?.name,
                    subdistrict_id: data?.subdistrict?.id,
                    subdistrict_name: data?.subdistrict?.name,
                    village_id: data?.village?.id,
                    village_name: data?.village?.name,
                    postal_code: data?.postal_code
                }
            },
        };

        let allowSubmit = true;

        if (errorValdiationNIK.isError) {
            if (
                employee.foto_hasil_ktp_v2.ktp_number ==
                payload.foto_hasil_ktp_v2.ktp_number
            ) {
                setError('ktp_number', {
                    type: 'custom',
                    message: errorValdiationNIK.title,
                });
                allowSubmit = false;
            }
        }

        if (allowSubmit) {
            const res = await dispatch(
                saveDraftV2({ data: payload, uuid: uuid as string })
            );
            dispatch(resetOCR())

            if (res.meta.requestStatus === 'fulfilled') {
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.FOTO_HASIL_KK}`);
            } else {
                let error = null;

                const r: any = res.payload;

                const errKTP = r.errors.find(
                    (i: ErrorResponse) => i.label === 'ktp_number'
                );

                if (errKTP) {
                    error = errKTP.message;

                    setError('ktp_number', {
                        type: 'custom',
                        message: error,
                    });
                    toast.error('NIK tidak valid')
                }

                if (!error) {
                    const errMessage = (res.payload as any)?.errors[0]?.message;
                    toast.error(errMessage || 'Failed submit registration');
                }
            }
        }
    };

    const errorLabel = dataOCR?.label === 'failed_to_extract'
    useEffect(() => {
        dispatch(getDropdown());
        dispatch(getDropdownProvinceAddress('levels=2'));
        setValue('dob', '')
    }, []);
    useEffect(() => {
        if (Object.values(dataOCR).length > 0 && dataOCR.ktp.kel_desa_kelurahan.value) {
            const sanitizedStr = dataOCR.ktp.kel_desa_kelurahan.value.replace(/ /g, '%20').toLowerCase()
            dispatch(getParentAddressByVillage(sanitizedStr))
        }

        if (Object.values(dataOCR).length > 0 && dataOCR.ktp.nik.value) {
            const checkNikCall = async () => {
                const res = await dispatch(checkNIK({ ktp_number: dataOCR.ktp.nik.value, uuid: uuid as string }))
                if (res.payload.status === 'ERROR') {
                    setIsInvalidNIK({ invalid: true, message: res.payload?.errors[0].message })
                }
            }
            checkNikCall()
        }
    }, [dataOCR]);

    useEffect(() => {
        // get parent address by ocr subdistrict and village
        if (Object.values(dataOCR).length > 0) {
            if (parentAddress.length === 1) {
                setValue('village', parentAddress[0])
                setValue('subdistrict', parentAddress[0].parent)
                setValue('city', parentAddress[0].parent.parent)
                setValue('province', parentAddress[0].parent.parent.parent)

                dispatch(getDropdownVillageAddress({ queryParams: `levels=5&parent_ids=${parentAddress[0].parent.id}`, type: 'ktp' }));
                dispatch(getDropdownSubdistrictAddress({ queryParams: `levels=4&parent_ids=${parentAddress[0].parent.parent.id}`, type: 'ktp' }));
                dispatch(getDropdownCityAddress({ queryParams: `levels=3&parent_ids=${parentAddress[0].parent.parent.parent.id}`, type: 'ktp' }));
                dispatch(getDropdownProvinceAddress('levels=2'));
            }
            if (parentAddress.length > 1 && parentAddress.length !== 0) {
                const sanitizedStr = (str: string) => str.replace(/ |\//g, '').toLowerCase();
                const foundAddress = parentAddress.find(el => sanitizedStr(el.parent.name).includes(sanitizedStr(dataOCR.ktp.kecamatan.value)))

                if (foundAddress !== undefined) {
                    setValue('village', foundAddress)
                    setValue('subdistrict', foundAddress.parent)
                    setValue('city', foundAddress.parent.parent)
                    setValue('province', foundAddress.parent.parent.parent)

                    dispatch(getDropdownVillageAddress({ queryParams: `levels=5&parent_ids=${foundAddress.parent.id}`, type: 'ktp' }));
                    dispatch(getDropdownSubdistrictAddress({ queryParams: `levels=4&parent_ids=${foundAddress.parent.parent.id}`, type: 'ktp' }));
                    dispatch(getDropdownCityAddress({ queryParams: `levels=3&parent_ids=${foundAddress.parent.parent.parent.id}`, type: 'ktp' }));
                    dispatch(getDropdownProvinceAddress('levels=2'));
                }
            }
        }
    }, [parentAddress])

    useEffect(() => {
        const sanitizedStr = (str: string) => str.replace(/ |\//g, '').toLowerCase();
        const existData = employee?.foto_hasil_ktp_v2
        setValue('ktp_photo_url', existData?.ktp_photo_url || ktp_photo_url);
        setValue('selfie_url', existData?.selfie_url || selfie_url);
        setValue('ktp_number', existData?.ktp_number || dataOCR.ktp?.nik?.value);
        setValue('full_name', existData?.ktp_number ? existData?.full_name : dataOCR.ktp?.nama?.value);
        setValue('birth_place', existData?.place_birth || dataOCR.ktp?.tempat_lahir?.value);
        if (existData?.dob) {
            setValue('dob', existData?.dob)
        }
        if (!errorCode && dataOCR.ktp?.tgl_lahir.value && dataOCR.ktp?.tgl_lahir.status !== 4) {
            const date = dataOCR.ktp?.tgl_lahir.value.split('-');
            setValue('dob', dayjs(`${date[2]}-${date[1]}-${date[0]}`).format())
        }
        if (existData?.gender) {
            setValue('gender', existData?.gender)
        }
        if (!errorCode && !existData && dataOCR.ktp?.jenis_kelamin.status !== 4) {
            setValue('gender', sanitizedStr(dataOCR.ktp?.jenis_kelamin.value).includes('laki') ? 'L' : 'P')
        }
        if (Object.values(dataOCR).length > 0 && !dropdown.loading && !errorCode) {
            const foundMarriage = dropdown?.data?.marriage?.find(el => sanitizedStr(el?.desc || '').includes(sanitizedStr(dataOCR.ktp.status_perkawinan.value || ''))) as DropdownMaster
            setValue('marriage_id', existData?.marriage_id ? dropdown?.data?.marriage?.find(el => el.id === existData?.marriage_id) as DropdownMaster : foundMarriage);
        }
        if (existData?.marriage_id && !dropdown.loading) {
            setValue('marriage_id', existData?.marriage_id && dropdown?.data?.marriage?.find(el => el.id === existData?.marriage_id) as DropdownMaster);
        }
        setValue('address', employee?.alamat?.alamat_ktp?.address || dataOCR.ktp?.alamat?.value);
        setValue('postal_code', employee?.alamat?.alamat_ktp?.postal_code || '');
    }, [dropdown, employee]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const isEmptyOCR = Object.values(dataOCR).length === 0
            const isDataExist = employee.foto_hasil_ktp_v2.ktp_number
            const dataExist = employee.foto_hasil_ktp_v2
            const dataExistOCR = dataOCR;
            const sanitizedStr = (str: string) => str.replace(/ |\//g, '').toLowerCase();
            if (!errorCode && !isEmptyOCR && !errorLabel) {
                const gender = sanitizedStr(dataExistOCR.ktp?.jenis_kelamin.value).includes('laki') ? 'L' : 'P'
                const marriageDropdown = dropdown?.data?.marriage?.find(el => sanitizedStr(el?.desc || '').includes(sanitizedStr(dataOCR.ktp.status_perkawinan.value || ''))) as DropdownMaster
                if (
                    (watch('ktp_number') !== dataExistOCR.ktp.nik.value) ||
                    (watch('full_name') !== dataExistOCR.ktp.nama.value) ||
                    (watch('birth_place') !== dataExistOCR.ktp.tempat_lahir.value) ||
                    (watch('dob')) ||
                    (watch('gender') !== gender) ||
                    (watch('birth_place') !== dataExistOCR.ktp.tempat_lahir.value) ||
                    watch('marriage_id').id !== marriageDropdown.id ||
                    watch('address') !== dataExistOCR.ktp.alamat.value
                    // watch('province') !== dataExistOCR.ktp.nik.value ||
                    // watch('city') !== dataExistOCR.ktp.nik.value ||
                    // watch('subdistrict') !== dataExistOCR.ktp.nik.value ||
                    // watch('village') !== dataExistOCR.ktp.nik.value
                ) {
                    return setIsEdited(true)
                }
                return setIsEdited(false)
            }
            if (!errorCode && !errorLabel && isDataExist) {
                if (
                    watch('ktp_photo_url') !== dataExist.ktp_photo_url ||
                    watch('ktp_number') !== dataExist.ktp_number ||
                    watch('full_name') !== dataExist.full_name ||
                    watch('dob') !== dataExist.dob ||
                    watch('gender') !== dataExist.gender ||
                    watch('birth_place') !== dataExist.place_birth ||
                    watch('marriage_id')?.id !== dataExist.marriage_id ||
                    watch('address') !== dataExist.alamat_ktp.address ||
                    watch('province')?.id !== dataExist.alamat_ktp.province_id ||
                    watch('city')?.id !== dataExist.alamat_ktp.city_id ||
                    watch('subdistrict')?.id !== dataExist.alamat_ktp.subdistrict_id ||
                    watch('village')?.id !== dataExist.alamat_ktp.village_id ||
                    watch('postal_code') !== dataExist.alamat_ktp.postal_code
                ) {
                    return setIsEdited(true)
                }
                return setIsEdited(false)
            }
            if (isEmptyOCR) {
                if (
                    watch('ktp_number') ||
                    watch('full_name') ||
                    watch('birth_place') ||
                    watch('dob') ||
                    watch('gender') ||
                    watch('birth_place') ||
                    watch('marriage_id') ||
                    watch('address') ||
                    watch('province') !== undefined ||
                    watch('city') !== undefined ||
                    watch('subdistrict') !== undefined ||
                    watch('village') !== undefined
                ) {
                    return setIsEdited(true)
                }
                return setIsEdited(false)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const handleChangeProvince = (event: any, type: string) => {
        setValue('province', event);

        //Trigger Next Dropdown
        const paramsGetDropdownCity = {
            queryParams: `levels=3&parent_ids=${event?.id}`,
            type: 'ktp'
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCity));

        //Reset Next Dropdown Value
        const payloadResetDropdown = {
            location: 'all',
            type: 'ktp'
        };
        dispatch(resetNextDropdownAddress(payloadResetDropdown));

        setValue('city', null as any);
        setValue('subdistrict', null as any);
        setValue('village', null as any);
    };

    const handleChangeCity = (event: any, type: string) => {
        setValue('city', event);

        //Trigger Next Dropdown
        const paramsGetDropdownSubdistrict = {
            queryParams: `levels=4&parent_ids=${event?.id}`,
            type: 'ktp'
        };
        dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

        //Reset Next Dropdown Value
        const payloadResetDropdown = {
            location: 'city',
            type: 'ktp'
        };
        dispatch(resetNextDropdownAddress(payloadResetDropdown));

        setValue('subdistrict', null as any);
        setValue('village', null as any);
    };

    const handleChangeSubdistrict = (event: any, type: string) => {
        setValue('subdistrict', event);

        //Trigger Next Dropdown
        const paramsGetDropdownVillage = {
            queryParams: `levels=5&parent_ids=${event?.id}`,
            type: 'ktp'
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));

        setValue('village', null as any);
    };

    const handleChangeVillage = (event: any, type: string) => {
        setValue('village', event);

    };

    const mappingDropdown = [
        {
            label: 'Provinsi',
            name: 'province' as any,
            options: dropdownProvince,
            placeholder: 'Pilih provinsi',
            onChange: (e: any) => handleChangeProvince(e, 'ktp'),
            errors: errors.province?.message,
        },
        {
            label: 'Kabupaten/Kota',
            name: 'city' as string,
            options: dropdownCity,
            placeholder: 'Pilih kabupaten/kota',
            onChange: (e: any) => handleChangeCity(e, 'ktp'),
            errors: errors.city?.message,
        },
        {
            label: 'Kecamatan',
            name: 'subdistrict' as string,
            options: dropdownSubdistrict,
            placeholder: 'Pilih kecamatan',
            onChange: (e: any) => handleChangeSubdistrict(e, 'ktp'),
            errors: errors.subdistrict?.message,
        },
        {
            label: 'Kelurahan',
            name: 'village' as any,
            options: dropdownVillage,
            placeholder: 'Pilih kelurahan',
            onChange: (e: any) => handleChangeVillage(e, 'ktp'),
            errors: errors.village?.message,
        },
    ];


    const WarningAlert = (props: any) => (
        <div className="bg-lightyellow mt-2 p-2 text-sm border border-darkyellow rounded-lg flex flex-row">
            <div className="mr-2 w-6">
                <img src={Assets.iconWarningOrange} style={{ width: '18px' }} />
            </div>
            {
                props.isKtp ?
                    <div>Sebagian data tidak dapat terbaca dengan jelas, mohon cek kembali data informasi pribadi</div> :
                    <div>Mohon cek kesesuaian data {props.name}</div>
            }
        </div>
    )

    const DangerAlert = (props: any) => (
        <div className="bg-red-100 mt-2 p-2 text-sm border border-red-300 rounded-lg">
            <div className='flex flex-row'>
                <div className="mr-2 w-6">
                    <img src={iconWarningRed} style={{ width: '18px' }} />
                </div>
                {
                    (props.isKTP && errorCode || !errorLabel) ?
                        <div>Data di foto tidak terbaca, silakan lakukan pengisian manual</div> :
                        <div>Data di foto tidak terbaca, silakan upload foto ulang</div>
                }
            </div>
            {!errorCode && errorLabel && <button
                type='button'
                className='ml-7 mt-3 text-primary font-bold'
                onClick={showPopup}
            >Panduan foto</button>}
        </div>
    )

    const handleRetakePhoto = () => {
        showPopup()
        dispatch(resetOCR())
    }

    const renderAlert = (status: number, name: string, isKtp?: boolean) => {
        switch (status) {
            case 3:
                return <WarningAlert name={name} isKtp={isKtp} />
            // case 4:
            //     return <DangerAlert name={name} isKtp={isKtp} />
            default:
                return;
        }
    }

    const handleCheckKtpNumber = async (e: any) => {
        const res = await dispatch(checkNIK({ ktp_number: e.target.value, uuid: uuid as string }))
        const requestStatus = res.meta.requestStatus;
        if (requestStatus === 'fulfilled') setIsInvalidNIK({ invalid: false, message: '' })
        if (res.payload.status === 'ERROR') {
            setIsInvalidNIK({ invalid: true, message: res.payload?.errors[0]?.message })
            return
        }
    };

    const handleChangeKtpNumber = useRef(
        debounce((e) => {
            handleCheckKtpNumber(e);
        }, 500)
    ).current;

    const getLocation = (params: SelectedAddressType) => {
        setTriggerMap(true);
        const paramsGetDropdown = {
            queryParams: `excluded_ids=1&nested=child&latlng=${params.lat},${params.lng}`,
        };
        getLocationByLatLong(paramsGetDropdown)
            .then((res) => {
                const data = res.data.data;
                const dataProvince = data;
                const dataCity = data?.child;
                const dataSubdistrict = data?.child?.child;
                const dataVillage = data?.child?.child?.child;

                setValue('province', dataProvince);
                setValue('city', dataCity);
                setValue('subdistrict', dataSubdistrict);
                setValue('village', dataVillage);

                const paramsGetDropdownCity = {
                    queryParams: `levels=3&parent_ids=${dataProvince?.id}`,
                    type: 'ktp'
                };
                dispatch(getDropdownCityAddress(paramsGetDropdownCity));

                const paramsGetDropdownSubdistrict = {
                    queryParams: `levels=4&parent_ids=${dataCity?.id}`,
                    type: 'ktp'
                };
                dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

                const paramsGetDropdownVillage = {
                    queryParams: `levels=5&parent_ids=${dataSubdistrict?.id}`,
                    type: 'ktp'
                };
                dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));
                setTimeout(() => {
                    setTriggerMap(false);
                }, 2000);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const handleSaveMaps = (params: SelectedAddressType) => {
        setValue('postal_code', params.postalCode);
        setValue('address', params.fullAddress);
        getLocation(params);
    };

    const params = {
        activePage,
        setActivePage,
        handleSaveMaps,
        temporaryState,
        setTemporaryState,
    };

    return (
        <div>
            {activePage === PageType.PINPOINT_LOCATION ? (
                <PinpointLocation {...params} />
            ) : (
                <form className='pb-44' onSubmit={handleSubmit(onSubmit)}>
                    {
                        isEdited &&
                        <div className='bg-blue-100 items-start flex -ml-4 -mt -mr-4 mb-3 px-4 py-3 -mt-4'>
                            <img src={Assets.iconWarningBlue} className='mr-2 mt-1' style={{ width: '18px' }} />
                            <p className='text-sm mb-0'>
                                <span className='font-semibold'>Data diedit atau diisi secara manual</span><br />
                                Pengisian data secara manual membutuhkan waktu verifikasi lebih lama</p>
                        </div>
                    }
                    <img className='rounded-xl h-56 object-cover w-full mb-2' src={employee?.foto_identitas?.ktp_photo_url || ktp_photo_url} />
                    {(errorCode || errorLabel) && <DangerAlert isKtp />}
                    {!errorLabel && Object.values(dataOCR).length > 0 && Object.values(dataOCR?.ktp).some(el => el.status === 3 || el.status === 4) && <WarningAlert isKtp />}
                    {
                        (errorCode || !errorLabel) &&
                        <>
                            <h3 className="font-semibold my-4">Informasi Pribadi Sesuai KTP</h3>
                            <FormGroup>
                                <Label name="NIK" />
                                <Controller
                                    rules={{
                                        required: 'Data harus diisi',
                                        pattern: {
                                            value:
                                                /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}(?!0000)\d{4}$/,
                                            message: 'NIK salah. Mohon isi yang sesuai',
                                        },
                                    }}
                                    control={control}
                                    name="ktp_number"
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            onInput={handleChangeKtpNumber}
                                            type="number"
                                            pattern="\d*"
                                            maxLength={16}
                                            placeholder="Masukkan 16 digit nomor KTP"
                                        />
                                    )}
                                />
                                <small className="text-red-600">{errors.ktp_number?.message}</small>
                                {renderAlert(dataOCR?.ktp?.nik?.status, 'NIK')}
                            </FormGroup>

                            <FormGroup>
                                <Label name="Nama Lengkap" />
                                <Controller
                                    rules={{
                                        required: 'Data harus diisi',
                                        pattern: {
                                            value: NAME_REGEX,
                                            message:
                                                'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
                                        },
                                    }}
                                    control={control}
                                    name="full_name"
                                    render={({ field }) => (
                                        <Input {...field} placeholder="Masukkan nama di sini" />
                                    )}
                                />
                                <small className="text-red-600">{errors.full_name?.message}</small>
                                {renderAlert(dataOCR?.ktp?.nama?.status, 'nama lengkap')}
                            </FormGroup>

                            <FormGroup className="relative">
                                <Label name="Tempat dan Tanggal Lahir" />
                                <Controller
                                    control={control}
                                    rules={{ required: 'Data harus diisi' }}
                                    name="birth_place"
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            placeholder="Masukkan kota tempat lahir"
                                            className=""
                                        />
                                    )}
                                />
                                <small className="text-red-600">{errors.birth_place?.message}</small>
                                {renderAlert(dataOCR?.ktp?.tempat_lahir?.status, 'tempat lahir')}

                                <Controller
                                    rules={{ required: 'Data harus diisi' }}
                                    control={control}
                                    name="dob"
                                    render={({ field: { onChange, value } }) => (
                                        <div className="relative">
                                            <Flatpickr
                                                onChange={onChange}
                                                options={{
                                                    disableMobile: true,
                                                    maxDate: dayjs().subtract(17, 'year').toDate()
                                                }}
                                                value={value}
                                                // minDate={'today'}
                                                placeholder="Pilih tanggal lahir"
                                                className="w-full cursor-pointer border rounded-lg h-11 p-3 mt-4 focus:outline-primary"
                                            />
                                            <Calendar className="absolute top-[25px] right-5" />
                                            <small className="text-red-600">{errors.dob?.message}</small>
                                        </div>
                                    )}
                                />
                                {renderAlert(dataOCR?.ktp?.tgl_lahir?.status, 'tanggal lahir')}
                            </FormGroup>

                            <FormGroup>
                                <Label name="Jenis Kelamin" />
                                <Controller
                                    rules={{ required: 'Data harus diisi' }}
                                    control={control}
                                    name="gender"
                                    render={({ field }) => (
                                        <>
                                            <div className="flex space-x-2">
                                                <div className="flex border rounded-lg items-center justify-center w-full py-3">
                                                    <input
                                                        {...field}
                                                        id="laki"
                                                        type="radio"
                                                        className="h-4 accent-primary w-4 rounded-lg border p-3"
                                                        placeholder="Jenis Kelamin"
                                                        defaultChecked
                                                        checked={field.value === 'L'}
                                                        value="L"
                                                    />
                                                    <label htmlFor="laki" className="font-medium text-sm ml-3">
                                                        Laki-laki
                                                    </label>
                                                </div>
                                                <div className="flex border rounded-lg items-center justify-center w-full py-3">
                                                    <input
                                                        {...field}
                                                        type="radio"
                                                        id="perempuan"
                                                        checked={field.value === 'P'}
                                                        className="h-4 accent-primary w-4 rounded-lg border p-3"
                                                        placeholder="Jenis Kelamin"
                                                        value="P"
                                                    />
                                                    <label
                                                        htmlFor="perempuan"
                                                        className="font-medium text-sm ml-3"
                                                    >
                                                        Perempuan
                                                    </label>
                                                </div>
                                            </div>
                                            <small className="text-red-600">{errors.gender?.message}</small>
                                        </>
                                    )}
                                />
                                {renderAlert(dataOCR?.ktp?.jenis_kelamin?.status, 'jenis kelamin')}
                            </FormGroup>

                            <FormGroup className="mb-5">
                                <Label name="Status Perkawinan" />
                                <Controller
                                    rules={{ required: 'Data harus diisi' }}
                                    control={control}
                                    name="marriage_id"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isSearchable={false}
                                                options={dropdown.data.marriage}
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                getOptionLabel={(option) => option.desc!}
                                                getOptionValue={(option) => option.id?.toString()}
                                                placeholder="Pilih status perkawinan"
                                            />

                                            <small className="text-red-600">
                                                {errors.marriage_id?.message}
                                            </small>
                                        </>
                                    )}
                                />
                                {renderAlert(dataOCR?.ktp?.status_perkawinan?.status, 'status perkawinan')}
                            </FormGroup>

                            <h3 className="font-semibold mb-4">Alamat Sesuai KTP</h3>
                            {
                                errorCode &&
                                <div
                                    className={`flex mb-4 flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
                                    onClick={() => { setActivePage('pinpoint') }}
                                >
                                    <div className="flex justify-center items-center">
                                        <img src={Assets.iconMarkerOrange} className="mr-2" />
                                        <span className="text-xs text-secondary">Atur Peta Lokasi</span>
                                    </div>
                                </div>
                            }
                            {mappingDropdown.map((dt, idx) => (
                                <FormGroup className="mb-5" key={idx}>
                                    <Label name={dt.label} />
                                    <Controller
                                        control={control}
                                        name={dt.name}
                                        rules={{ required: 'Data harus diisi' }}
                                        render={({ field }) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    className="text-sm"
                                                    onChange={dt.onChange}
                                                    options={dt.options}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id.toString() || ''}
                                                    placeholder={dt.placeholder}
                                                    isSearchable={false}
                                                    styles={dropdownStyles}
                                                />
                                                <small className="text-red-600">{dt.errors}</small>
                                            </>
                                        )}
                                    />
                                </FormGroup>
                            ))}
                            <FormGroup className="mb-5">
                                <Label name="Detail Alamat" />
                                <Controller
                                    control={control}
                                    name="address"
                                    rules={{ required: 'Data harus diisi' }}
                                    render={({ field }) => (
                                        <>
                                            <textarea
                                                {...field}
                                                rows={3}
                                                className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                                                placeholder="Masukkan detail alamat"
                                            />
                                            <small className="text-red-600">
                                                {errors.address?.message}
                                            </small>
                                        </>
                                    )}
                                />
                            </FormGroup>
                            <FormGroup className="mb-5">
                                <Label name="Kode Pos" />
                                <Controller
                                    control={control}
                                    name="postal_code"
                                    rules={{ required: 'Data harus diisi' }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                type="number"
                                                pattern="\d*"
                                                placeholder="Masukkan kode pos"
                                                className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                                            />
                                            <small className="text-red-600">
                                                {errors.postal_code?.message}
                                            </small>
                                        </>
                                    )}
                                />
                            </FormGroup>
                        </>
                    }
                    <div className='fixed bottom-0 -ml-4 p-4 bg-white w-full'>
                        {
                            isInvalidNIK.invalid &&
                            <div className='bg-red-100 items-start flex -ml-4 -mr-4 mb-3 px-4 py-3'>
                                <div className="mr-2 w-6">
                                    <img src={iconWarningRed} style={{ width: '18px' }} />
                                </div>
                                <p className='text-sm mb-0'>{isInvalidNIK.message}</p>
                            </div>
                        }
                        <button
                            type={!errorCode && errorLabel ? 'button' : 'submit'}
                            onClick={() => !errorCode && errorLabel ? handleRetakePhoto() : undefined}
                            disabled={isLoading}
                            className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out disabled:bg-neutral-400 disabled:text-white"
                        >
                            {!errorCode && errorLabel ? 'Foto Ulang' : 'Simpan dan Lanjut'}
                        </button>
                    </div>
                </form >
            )}
        </div >
    );
};

export default InformasiKTP;

import React from 'react';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';

type WaitingDisplayProps = {
  img: string;
  title: string;
  description: string;
  customFooter?: boolean;
  marginBody?: string;
  children?: React.ReactNode;
};

const WaitingDisplay = (props: WaitingDisplayProps) => {
  const { img, title, description, customFooter, marginBody, children } = props;
  return (
    <div className="px-4">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className={`-mx-4 ${customFooter ? marginBody : 'mb-[105px]'}`} />
        <div className="flex flex-col items-center justify-center text-center">
          <img src={img} alt="moladin" className="my-[22px]" />
          <p className="font-bold mb-2">{title}</p>
          <span className="text-[#4B5563] text-center text-xs w-[328px] leading-4 h-[15px]">
            {description}
          </span>
        </div>
        {customFooter && children}
      </div>
    </div>
  );
};

export default WaitingDisplay;

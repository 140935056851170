import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
    show: false,
    message: '',
} as ErrorSystemRequestTypes;

export const errorSystemRequestSlice = createSlice({
  name: 'errorSystemRequest',
  initialState,
  reducers: {
    setErrorSystemRequest: (state, action: PayloadAction<ErrorSystemRequestTypes>) => {
      state.show = action.payload.show
      state.message = action.payload.message
    }
  },
});

export const { setErrorSystemRequest } = errorSystemRequestSlice.actions
export const selectErrorSystemRequest = (state: RootState) => state.errorSystemRequest;

export const reducer = combineReducers({
  errorSystemRequest: errorSystemRequestSlice.reducer,
});

export default errorSystemRequestSlice.reducer;

import React from 'react'

interface Props {
  src: string
}

const Thumbnail = ({src}: Props) => {
  const srcUriIsPdf =
    src?.substring(src?.lastIndexOf('.') + 1, src?.length) === 'pdf';

  return (
    <div className="mt-2 rounded border-2">
      <div className="flex items-center">
        <div className="min-w-[80px] h-[80px]">
          {srcUriIsPdf ? (
            <a
              href={src}
              target="_blank"
              rel="noreferrer"
              className="relative inline-block w-full h-full"
            >
              <div className="absolute h-full w-full z-10 opacity-0"></div>
              <iframe
                src={src}
                className="w-full h-full object-cover z-20 rounded"
              />
            </a>
          ) : (
            <img src={src} className="rounded w-full h-full object-cover" />
          )}
        </div>
      </div>
    </div>
  )
}

export default Thumbnail
import React from "react";
import WaitingHourglass from '../../assets/svg/waiting-hourglass.svg';

const Expired = () => {

  return (
    <div className="bg-white shadow p-4 rounded-lg">
      <div className="flex flex-col justify-center items-center mb-20 pt-16">
        <img src={WaitingHourglass} alt="expired" className="mb-4 ml-4" />
        <div className="text-sm font-semibold text-center text-neutral-800 mb-1">Event sudah berakhir</div>
        <div className="text-xs font-normal text-center text-neutral-500">Nantikan event selanjutnya<br />di lokasi terdekat, ya</div>
      </div>
    </div>
  );
};

export default Expired;

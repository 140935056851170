import React from 'react';

type ModalProps = {
  icon: string;
  iconStyle?: string;
  title: string;
  description: string;
  withButton?: boolean;
  buttonTitle?: string;
  showModal: boolean;
  customFooter?: React.ReactNode;
  handleClickBackground?: () => void;
  handleClickButton?: () => void;
};

const Modal = (props: ModalProps) => {
  const {
    icon,
    iconStyle = 'w-8',
    title,
    description,
    withButton,
    buttonTitle = 'Tutup',
    showModal,
    customFooter,
    handleClickButton,
    handleClickBackground,
  } = props;
  return (
    <>
      <div
        className={`fixed inset-0 z-10 overflow-y-auto ${
          showModal ? '' : 'hidden'
        }`}
      >
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={handleClickBackground}
        ></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg bg-primary">
            <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto">
              <img src={icon} className={iconStyle} />
            </div>
            <div className=" w-full flex justify-center items-center">
              <div className="mt-2 text-center">
                <h4 className="text-lg font-medium text-gray-800">{title}</h4>
                <p className="mt-2 text-[15px] leading-relaxed text-gray-500 mb-3">
                  {description}
                </p>
                {withButton && (
                  <div className="items-center gap-2 sm:flex">
                    <button
                      type="submit"
                      className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
                      onClick={handleClickButton}
                    >
                      {buttonTitle}
                    </button>
                  </div>
                )}
                {customFooter}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

import React, { useState } from 'react';
import { X, Camera } from 'react-feather';
import uploadIcon from '../../assets/icons/uploadIcon.svg';
import cameraIcon from '../../assets/icons/camera-minus.svg';
import galleryIcon from '../../assets/icons/gallery.svg';
import folderIcon from '../../assets/icons/folder-open.svg';
import { uploadImageFile } from '../../services';
import { BROWSE_FILE_TYPE } from '../../utils/constants';
import BrowseFileHidden from '../atom/BrowseFileHidden';

type Props = {
  placeholder: string;
  onSelectCamera: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  handleRemovePhoto: () => void;
  onBrowseFile: () => void;
  onSelectFile: (url: string) => void;
  srcUri: string | undefined;
  imageLabel: string;
  allowPDF?: boolean;
  isMultiple?: boolean;
  disabled?: boolean;
  withoutDrawer?: boolean;
  onShowGuide?: () => void;
};

// communication to android webview
function sendMessage(message: string) {
  (window as any).AndroidInterface?.onMessageReceived(message);
}

export default function InputTakePhotoWithDrawer({
  placeholder,
  onSelectCamera,
  srcUri,
  handleRemovePhoto,
  imageLabel,
  onBrowseFile,
  onSelectFile,
  allowPDF,
  isMultiple,
  disabled,
  withoutDrawer,
  onShowGuide,
}: Props) {
  const [showDrawer, setShowDrawer] = useState(false);
  let content;

  const handleBrowseFile = () => {
    onBrowseFile();
  };

  const onShowDrawer = () => {
    if (onShowGuide) {
      onShowGuide();
    } else {
      setShowDrawer(true);
    }
  }

  const srcUriIsPdf =
    srcUri?.substring(srcUri?.lastIndexOf('.') + 1, srcUri?.length) === 'pdf';

  if (srcUri && !withoutDrawer) {
    content = (
      <div className="border-2 mt-2 border-dashed border-spacing-7 rounded py-7">
        <div className="flex px-3 gap-2.5 items-center">
          <div className="h-[72px] w-[72px]">
            {srcUriIsPdf ? (
              <a
                href={srcUri}
                target="_blank"
                rel="noreferrer"
                className="relative inline-block w-full h-full"
              >
                <div className="absolute h-full w-full z-10 opacity-0"></div>
                <iframe
                  src={srcUri}
                  className="w-full h-full object-cover z-20"
                />
              </a>
            ) : (
              <img src={srcUri} className="w-full h-full object-cover" />
            )}
          </div>
          <div className="flex w-full justify-between items-center text-sm font-normal">
            <div>{imageLabel}</div>
            <div className="flex items-center gap-[18px]">
              <div
                onClick={onShowDrawer}
                className="text-xs text-blue-500 font-bold"
              >
                Ubah
              </div>
              <X
                onClick={handleRemovePhoto}
                className="text-neutral-500"
                width={24}
                height={24}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (srcUri && withoutDrawer) {
    content = (
      <div className="mt-2">
        <div className="flex flex-col px-3 gap-2.5 items-center">
          <img src={srcUri} className="w-full h-full object-cover rounded-3xl" />
          <button
            onClick={onShowDrawer}
            className="bg-secondary rounded-lg w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out flex items-center gap-x-2 justify-center py-2 px-4 mt-2 text-xs font-bold"
          >
            <Camera size={16} /> Ambil Foto Ulang
          </button>
        </div>
      </div>
    );
  } else {
    content = (
      <div
        onClick={() => !disabled && onShowDrawer()}
        className={`border-2 mt-2 border-dashed border-spacing-7 rounded py-7 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <div className={`flex flex-col items-center gap-2.5 ${isMultiple ? "min-w-[80px] max-h-[80px]" : ""}`}>
          <div>
            <img src={uploadIcon} />
          </div>
          <div className="text-sm text-[#002DB4]">{placeholder}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {content}
      {!withoutDrawer && showDrawer && (
        <div className="fixed z-20 top-0 left-0 bottom-0 w-full flex flex-col justify-end">
          <div
            className="bg-black bg-opacity-30 flex-1"
            onClick={() => setShowDrawer(false)}
          />
          <div className="bg-white py-2">
            <button
              type="button"
              className="flex items-center gap-6 p-4 w-full justify-start"
              onClick={(e) => {
                setShowDrawer(false);
                sendMessage('openCamera');
                onSelectCamera(e);
              }}
            >
              <img src={cameraIcon} alt="" />
              <h4 className="text-sm text-gray-500 text-left">
                Ambil foto dengan kamera
              </h4>
            </button>
            <label
              htmlFor="browse-image"
              className="flex items-center gap-6 p-4 w-full justify-start cursor-pointer"
              onClick={() => {
                sendMessage('openGallery');
                handleBrowseFile();
              }}
            >
              <img src={galleryIcon} alt="" />
              <h4 className="text-sm text-gray-500 text-left">
                Upload gambar dari galeri
              </h4>
            </label>
            <label
              htmlFor="browse-image"
              className="flex items-center gap-6 p-4 w-full justify-start cursor-pointer"
              onClick={() => {
                sendMessage('openFile');
                handleBrowseFile();
              }}
            >
              <img src={folderIcon} alt="" />
              <h4 className="text-sm text-gray-500 text-left">
                Upload dokumen dari file
              </h4>
            </label>
            <BrowseFileHidden
              id="browse-image"
              onChange={(src) => {
                onSelectFile(src);
                setShowDrawer(false);
              }}
              onUploadFile={uploadImageFile}
              type={allowPDF ? BROWSE_FILE_TYPE.FILE : BROWSE_FILE_TYPE.IMAGE}
            />
          </div>
        </div>
      )}
    </>
  );
}

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tncReducer from './slices/TnC/tncSlice';
import dealerReducer from './slices/dealer/dealerSlice';
import employeeReducer from './slices/employee/employeeSlice';
import informasiRekeningReducer from './slices/informasiRekening/informasiRekeningSlice';
import informasiAlamatReducer from './slices/informasiAlamat/informasiAlamatSlice';
import errorSystemRequestReducer from './slices/errorSystemRequest/errorSystemRequestSlice';
import waitingPageReducer from './slices/waitingPage/waitingPageSlice';
import alamatUsahaReducer from './slices/alamatUsaha/alamatUsahaSlice';
import revisionReducer from './slices/revision/revisionSlice'
import eventReducer from './slices/event/eventSlice'
import dealerConsultantReducer from './slices/dealerConsultan/dealerConsultanSlice';
import warehouseReducer from './slices/warehouse/warehouseSlice';

export const store = configureStore({
  reducer: {
    dealer: dealerReducer,
    employee: employeeReducer,
    tnc: tncReducer,
    informasiRekening: informasiRekeningReducer,
    informasiAlamat: informasiAlamatReducer,
    errorSystemRequest: errorSystemRequestReducer,
    waitingPage: waitingPageReducer,
    alamatUsaha: alamatUsahaReducer,
    revision: revisionReducer,
    event: eventReducer,
    dealerConsultant: dealerConsultantReducer,
    warehouse: warehouseReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

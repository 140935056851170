import {
    CameraProperty,
    CommonTypesFotoIdentitas,
    ContentControler,
} from '../../../../types/FotoIdentitasTypes';

export const initialFormData: CommonTypesFotoIdentitas = {
    ktp_photo_url: '',
    family_card_url: '',
    selfie_url: '',
};

export const initialContentController: ContentControler = {
    showCamera: false,
    showPopup: true,
    contentType: '',
    errorCode: null
};

export const initialErrorSelfieValidation = {
    isError: false,
    title: '',
    message: '',
};

export const initialCameraProperty: CameraProperty = {
    facing_mode: 'environment',
};

export interface ErrorSelfieValidation {
    isError: boolean;
    title: string;
    message: string;
}

export interface ErrorsAttemptObject {
    selfie_url?: {
        title: string;
        message: string;
    } | null;
    ktp_photo_url?: {
        title: string;
        message: string;
    } | null;
}
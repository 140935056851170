/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormGroup from '../../atom/FormGroup';
import Label from '../../atom/Label';
import { NumericFormat } from 'react-number-format';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  LimitPembiayaanRequest,
  RequestLimitResponse,
} from '../../../types/Dealer';
import { getTierRequest } from '../../../redux/slices/dealer/dealerThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { CHECKED_NO, CHECKED_YES } from '../../../utils/constants';
import { toast } from 'react-toastify';
import Select from 'react-select';
import HeaderEvent from '../../Header/HeaderEvent';
import Alert from '../../atom/Alert';
import { submitFormRegistration } from '../../../services';

const initialFormData: LimitPembiayaanRequest = {
  isRequestLimit: CHECKED_NO,
  tierUuid: '',
  limitLoanOther: '',
  reason: null,
};

export default function LimitPembiayaan() {
  const data = useAppSelector((state) => state.dealer);
  const tierRequest = useAppSelector((state) => state?.dealer?.tierRequest);
  const dataDealer = useAppSelector((state) => state.dealer.dealer);

  const dataTier = tierRequest.data || [];
  const { uuid } = useParams();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch
  } = useForm<LimitPembiayaanRequest>({ defaultValues: initialFormData });

  const [isExpectedTier, setIsExpectedTier] = useState(CHECKED_NO);
  const [isLoading, setIsloading] = useState(false);

  const limitMsg =
    'Limit tidak sesuai. masukan limit minimal 50 juta dan maksimal 5 M';

  const navigate = useNavigate();

  const handleResetForm = () => {
    setValue('tierUuid', '');
    setValue('limitLoanOther', '');
    setValue('reason', '');
  };

  const validateInputLimit = (value: number | string | undefined) => {
    if (value) {
      return (+value < 50000000 || +value > 5000000000) ? limitMsg : true;
    } else {
      return limitMsg
    }
  };

  const onSubmit: SubmitHandler<LimitPembiayaanRequest> = async (data) => {
    setIsloading(true);

    const payload: LimitPembiayaanRequest = {
      isRequestLimit:
        data.isRequestLimit === CHECKED_YES ? true : (false as any),
      tierUuid: !data.tierUuid ? '-' : data.tierUuid?.toString(),
      limitLoanOther: data.limitLoanOther || 0,
      reason: data.reason,
    };

    setValue('isRequestLimit', data.isRequestLimit);
    setValue('tierUuid', data.tierUuid);
    setValue('limitLoanOther', data.limitLoanOther);
    setValue('reason', data.reason);

    if (data.isRequestLimit === CHECKED_YES) {
      if (data.tierUuid === '-')
        validateInputLimit(data.limitLoanOther as number);
    }

    submitFormRegistration(payload, uuid as string)
      .then((res) => {
        const { submitRegistrationStatus } = res.data.data;
        if (submitRegistrationStatus) {
          setIsloading(false);
          navigate(`/dealer/waiting-page/${uuid}`);
        } else {
          toast.error('Failed submit registration');
          setIsloading(false);
        }
      })
      .catch((err) => {
        toast.error('Failed submit registration');
        setIsloading(false);
      });
  };

  useEffect(() => {
    dispatch(getTierRequest());
  }, []);

  useEffect(() => {
    const dataLimitPembiayaan = dataDealer?.data?.limit_pembiayaan;
    if (
      dataLimitPembiayaan === undefined ||
      dataLimitPembiayaan?.is_request_limit === null
    )
      return;

    const isRequestLimit =
      dataLimitPembiayaan && dataLimitPembiayaan?.is_request_limit === true
        ? CHECKED_YES
        : CHECKED_NO;

    if (isRequestLimit === CHECKED_YES) {
      setIsExpectedTier(CHECKED_YES);
    } else if (isRequestLimit === CHECKED_NO) {
      setIsExpectedTier(CHECKED_NO);
      handleResetForm();
    }

    setValue('isRequestLimit', isRequestLimit);
    setValue('tierUuid', dataLimitPembiayaan?.tier_uuid ?? '');
    setValue('limitLoanOther', dataLimitPembiayaan?.limit_loan_other || 0);
    setValue('reason', dataLimitPembiayaan?.reason);
  }, [dataDealer]);

  return (
    <>
      <HeaderEvent data={data?.dealer?.data} />

      <h3 className="font-semibold mb-4">Limit Pembiayaan</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <FormGroup>
          <Label name="Apakah mau mengajukan Limit Pembiayaan?" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="isRequestLimit"
            render={({ field }) => (
              <>
                <div className="flex space-x-2">
                  <div className="flex border rounded-lg items-center justify-center w-full py-3">
                    <input
                      {...field}
                      id="request-limit-yes"
                      type="radio"
                      className="h-4 accent-primary w-4 rounded-lg border p-3"
                      checked={field.value?.toLowerCase() === CHECKED_YES}
                      value={CHECKED_YES}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setIsExpectedTier(e.target.value);
                      }}
                    />
                    <label
                      htmlFor="request-limit-yes"
                      className="font-medium text-sm ml-3"
                    >
                      Ya
                    </label>
                  </div>
                  <div className="flex border rounded-lg items-center justify-center w-full py-3">
                    <input
                      {...field}
                      type="radio"
                      id="request-limit-no"
                      checked={field.value?.toLowerCase() === CHECKED_NO}
                      className="h-4 accent-primary w-4 rounded-lg border p-3"
                      value={CHECKED_NO}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setIsExpectedTier(e.target.value);
                        handleResetForm();
                      }}
                    />
                    <label
                      htmlFor="request-limit-no"
                      className="font-medium text-sm ml-3"
                    >
                      Tidak
                    </label>
                  </div>
                </div>
                <small className="text-red-600">
                  {errors.isRequestLimit?.message}
                </small>
              </>
            )}
          />
        </FormGroup>

        {isExpectedTier === CHECKED_YES ? (
          <>
            <FormGroup>
              <Label name="Limit Pembiayaan yang Dibutuhkan" />
              <Controller
                control={control}
                name="tierUuid"
                rules={{ required: 'Data harus diisi' }}
                render={({ field }) => {
                  return (
                    <>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={dataTier || []}
                        getOptionLabel={(option) => option.limit}
                        getOptionValue={(option) => option.uuid}
                        name="tierUuid"
                        placeholder="Pilih Limit Pembiayaan"
                        className={
                          watch('tierUuid')?.toString().toLowerCase() === '-'
                            ? 'mb-2'
                            : ''
                        }
                        onChange={(item) => {
                          if (item?.uuid === '-') {
                            setValue('tierUuid', '-');
                            validateInputLimit(0);
                          }
                          setValue('limitLoanOther', '');
                          field.onChange(item?.uuid);
                        }}
                        value={
                          dataTier &&
                          Object.values(dataTier).filter(
                            (item) => item.uuid === watch('tierUuid')
                          )
                        }
                      />
                      <small className="text-red-600">
                        {errors.tierUuid?.message}
                      </small>
                      {watch('tierUuid') === '-' && (
                        <Controller
                          rules={{
                            required: 'Data harus diisi',
                            validate: validateInputLimit,
                          }}
                          control={control}
                          name="limitLoanOther"
                          render={({ field }) => {
                            return (
                              <>
                                <div className="relative">
                                  <div className="absolute border-r text-center rounded-l-lg w-[50px] h-[50px] py-3">
                                    Rp
                                  </div>
                                  <NumericFormat
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    onValueChange={(values) => {
                                      const value = values.floatValue!;
                                      field.onChange(value);
                                    }}
                                    value={field.value && +field.value}
                                    className="p-2 disabled:bg-[#F3F4F6] rounded-md border-2 w-full focus:outline-primary pl-14"
                                    placeholder="Masukan limit sendiri"
                                  />
                                </div>
                              </>
                            );
                          }}
                        />
                      )}
                      <small className="text-red-600">{errors.limitLoanOther?.message}</small>
                    </>
                  );
                }}
              />

              <Alert className="mt-2">
                <span className="text-xs text-neutral-800">
                  Limit yang didapatkan <strong>bisa berbeda</strong>, setelah
                  direview Credit Analyst
                </span>
              </Alert>
            </FormGroup>

            <FormGroup className="mb-5">
              <Label name="Alasan" />
              <Controller
                rules={{ required: 'Data harus diisi' }}
                control={control}
                name="reason"
                render={({ field }) => (
                  <>
                    <textarea
                      {...field}
                      rows={3}
                      className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                      placeholder="Contoh: Butuh modal usaha besar"
                      value={field.value || ''}
                    />
                    <small className="text-red-600">
                      {errors.reason?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>
          </>
        ) : (
          ''
        )}

        <button
          type="submit"
          disabled={isLoading}
          className="bg-secondary mt-[28px] rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
        >
          Simpan dan Lanjut
        </button>
      </form>
    </>
  );
}

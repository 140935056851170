import React, { useEffect, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useForm,
  useFieldArray,
} from 'react-hook-form';
import FormGroup from '../../atom/FormGroup';
import Input from '../../atom/Input';
import Label from '../../atom/Label';
import { NumericFormat } from 'react-number-format';
import { Plus, X } from 'react-feather';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  AdditionalQuestion,
  InformasiUsaha,
  InformasiUsahaRequest,
} from '../../../types/Dealer';
import { getDropdown, saveDraft } from '../../../redux/slices/dealer/dealerThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_SECTION_FORM } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { featureFlag } from '../../../utils/helper';
import Select from 'react-select';
import { DropdownMaster } from '../../../types/commonTypes';
import HeaderEvent from '../../Header/HeaderEvent';

const initialFormData: InformasiUsaha = {
  dealer_name: undefined,
  monthly_revenue: undefined,
  average_monthly_unit_sold: undefined,
  additional_questions: undefined,
  ...(featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? {
    dealer_type_id: undefined,
    ownership_id: undefined,
    dealer_type_other: undefined,
    ownership_other: undefined,
  } : {
    buyer_reference: Array(2).fill({
      buyer_name: '',
      buyer_phone: '',
      car_type: '',
    }),
  })
};

export default function InformasiDealer() {
  const data = useAppSelector((state) => state.dealer);
  const dropdown = useAppSelector((state) => state.dealer.dropdown);
  const { uuid } = useParams();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<InformasiUsaha>({defaultValues: initialFormData});
  const [businessDuration, setBusinessDuration] = useState<DropdownMaster | null>({
    id: 9,
    code: undefined,
    desc: "Kurang dari 1 tahun",
    createdAt: "2023-11-22 03:42:46",
    updatedAt: "2023-11-22 03:42:46"
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'buyer_reference',
    ...(featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? {} : {
      rules: {
        minLength: 2,
      }
    })
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<InformasiUsaha> = async (data) => {
    const add: AdditionalQuestion[] = [];
    let idx = 3;

    if (featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT')) {
      delete data.buyer_reference
    }

    data.additional_questions?.map(
      (data, i) => {
        idx += 1
        return data.answer &&
        add.push({
          additional_question_id: featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? idx : i + 1,
          answer: featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? data.answerOther ? `${businessDuration?.desc}, ${data.answerOther}` : data?.answer : data?.answer,
        })
      }
    );
    data.additional_questions = add.length ? add : undefined;

    const payload: InformasiUsahaRequest = {
      informasi_usaha: {
        dealer_name: data.dealer_name,
        monthly_revenue: data.monthly_revenue?.toString(),
        additional_questions: data.additional_questions,
        average_monthly_unit_sold: data.average_monthly_unit_sold?.toString(),
        ...(featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? {
          dealer_type_id: data.dealer_type_id?.id,
          ownership_id: data.ownership_id?.id,
          dealer_type_other: data.dealer_type_other,
          ownership_other: data.ownership_other
        } : {
          buyer_reference: data.buyer_reference
        })
      },
    };

    const res = await dispatch(
      saveDraft({ data: payload, uuid: uuid as string })
    );

    if (res.meta.requestStatus === 'fulfilled') {
      setValue('additional_questions.1.answerOther', undefined)
      navigate(
        `/dealer/registration/${uuid}/${PAGE_SECTION_FORM.ALAMAT_USAHA}`
      );
    } else {
      const errMessage = (res.payload as any)?.errors?.[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  useEffect(() => {
    const dataInformasiDealer = data.dealer.data.informasi_usaha;
    if (dataInformasiDealer) {
      Object.keys(dataInformasiDealer).map((k) => {
        setValue(
          k as keyof InformasiUsaha,
          dataInformasiDealer[k as keyof InformasiUsaha]
        );

        if (featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') && k === 'dealer_type_id') {
          setValue(k, {
            id: dataInformasiDealer?.dealer_type_id?.toString() || 0,
            desc: dropdown?.data?.dealerType?.find(item => item.id.toString() === dataInformasiDealer?.dealer_type_id?.toString())?.desc
          })
        }

        if (featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') && k === 'ownership_id') {
          setValue(k, {
            id: dataInformasiDealer?.ownership_id?.toString() || 0,
            desc: dropdown?.data?.businessOwnership?.find(item => item.id.toString() === dataInformasiDealer?.ownership_id?.toString())?.desc
          })
        }
      });
      if (dataInformasiDealer?.buyer_reference && dataInformasiDealer?.buyer_reference.length <= 0) {
        append(
          Array(2).fill({
            buyer_name: '',
            buyer_phone: '',
            car_type: '',
          })
        );
      }
      if (featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT')) {
        setBusinessDuration(dropdown?.data?.businessDuration?.find(item => {
          const businessDurationQuestion = dataInformasiDealer.additional_questions?.find(question => question.additional_question_id === 5 )
          const answerBusinessDurationQuestion = businessDurationQuestion?.answer

          if (answerBusinessDurationQuestion?.toLowerCase().includes(item.desc?.toLowerCase() || '')) {
            setValue('additional_questions.1.answerOther', answerBusinessDurationQuestion.split(',')[1])
            return item
          }
        }) || null)
      }
    }
  }, [data.dealer.data.informasi_usaha, dropdown.data.businessDuration]);

  const validateNotZero = (value: string | undefined) => {
    // Convert the value to a number if it's a string
    const numericValue = Number(value);

    if (numericValue === 0) {
      return 'rata-rata omset bulanan tidak boleh 0';
    }
    return true;
  };

  useEffect(() => {
    dispatch(getDropdown());
  }, []);

  return (
    <>
      {featureFlag('REACT_APP_SPRINT_9_1_DEALER_ONBOARDING_GATHERING') ? <HeaderEvent data={data?.dealer?.data} /> : ''}

      <h3 className="font-semibold mb-4">
        Informasi Usaha
      </h3>
      {featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? null : (
        <div className="text-xs text-neutral-700 leading-[18px]">
          <p>
            Limit pembiayaan bisa <span className="font-bold">lebih tinggi</span>{' '}
            dan pendaftaran Dealer{' '}
            <span className="font-bold">lebih mudah disetujui</span> kalau kamu
            punya data ini
          </p>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <FormGroup>
          <Label name="Nama Tempat Usaha" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="dealer_name"
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama tempat usaha" />
            )}
          />
          {errors.dealer_name?.message ? (
            <small className="text-red-600">
              {errors.dealer_name?.message}
            </small>
          ) : (
            <small className="text-neutral-400 text-xs">Cth: Andi Motor</small>
          )}
        </FormGroup>
        {featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? (
          <>
            <FormGroup className="mb-5">
              <Label name="Jenis Dealer" />
              <Controller
                rules={{ required: 'Data harus diisi' }}
                control={control}
                name="dealer_type_id"
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={dropdown.data.dealerType || []}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      getOptionLabel={(option) => option.desc!}
                      getOptionValue={(option) => option.id?.toString()}
                      placeholder="Pilih jenis"
                      className={Number(watch('dealer_type_id')?.id) === 3 ? 'mb-2' : ''}
                      onChange={(val) => {
                        field.onChange(val)
                        setValue('dealer_type_other', undefined)
                      }}
                    />
                    <small className="text-red-600">
                      {errors.dealer_type_id?.message}
                    </small>
                  </>
                )}
              />
              {Number(watch('dealer_type_id')?.id) === 3 && (
                <Controller
                  rules={{ required: 'Data harus diisi' }}
                  control={control}
                  name="dealer_type_other"
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                        placeholder="Masukan alasan lainnya"
                      />
                      <small className="text-red-600">
                        {errors.dealer_type_other?.message}
                      </small>
                    </>
                  )}
                />
              )}
            </FormGroup>
            <FormGroup className="mb-5">
              <Label name="Status Kepemilikan Usaha" />
              <Controller
                rules={{ required: 'Data harus diisi' }}
                control={control}
                name="ownership_id"
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={dropdown.data.businessOwnership || []}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      getOptionLabel={(option) => option.desc!}
                      getOptionValue={(option) => option.id?.toString()}
                      placeholder="Pilih status"
                      className={Number(watch('ownership_id')?.id) === 8 ? 'mb-2' : ''}
                      onChange={(val) => {
                        field.onChange(val)
                        setValue('ownership_other', undefined)
                      }}
                    />
                    <small className="text-red-600">
                      {errors.ownership_id?.message}
                    </small>
                  </>
                )}
              />
              {Number(watch('ownership_id')?.id) === 8 && (
                <Controller
                  rules={{ required: 'Data harus diisi' }}
                  control={control}
                  name="ownership_other"
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                        placeholder="Masukan alasan lainnya"
                      />
                      <small className="text-red-600">
                        {errors.ownership_other?.message}
                      </small>
                    </>
                  )}
                />
              )}
            </FormGroup>
          </>
        ) : null}
        <FormGroup>
          <Label name="Rata-rata Omset Bulanan" />
          <Controller
            control={control}
            rules={{
              required: 'Data harus diisi',
              validate: validateNotZero, // Updated custom validation function
            }}
            name="monthly_revenue"
            render={({ field }) => (
              <div className="relative">
                <div className="absolute border-r text-center rounded-l-lg w-[50px] h-[50px] py-3">
                  Rp
                </div>
                <NumericFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  onValueChange={(values) => {
                    field.onChange(values.floatValue);
                  }}
                  value={field.value}
                  className="p-3 disabled:bg-[#F3F4F6] rounded-md border-2 w-full focus:outline-primary pl-14"
                  placeholder="Masukkan rata-rata omset bulanan"
                />
              </div>
            )}
          />
          {errors.monthly_revenue && (
            <small className="text-red-500">{errors.monthly_revenue.message}</small>
          )}
        </FormGroup>
        <FormGroup>
          <Label name="Rata-rata Penjualan Mobil Bulanan (dalam Unit)" />
          <Controller
            control={control}
            rules={{ required: 'Data harus diisi' }}
            name="average_monthly_unit_sold"
            render={({ field }) => (
              <NumericFormat
                onValueChange={(values) => {
                  field.onChange(values.value);
                }}
                value={field.value}
                className="p-3 disabled:bg-[#F3F4F6] rounded-md border-2 w-full focus:outline-primary"
                placeholder="Masukkan jumlah unit"
              />
            )}
          />
          {errors.average_monthly_unit_sold && (
            <small className="text-red-500">{errors.average_monthly_unit_sold.message}</small>
          )}
        </FormGroup>
        <FormGroup>
          <Label name={featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? "Berapa mobil yang bisa muat di garasi?" : "Apakah kapasitas garasi bisa melebihi 1 mobil?"} />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name={`additional_questions.${0}.answer`}
            render={({ field }) => (
              <>
                {featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? (
                  <>
                    <div className="flex border rounded-lg">
                      <NumericFormat
                        onValueChange={(values) => {
                          field.onChange(values.value);
                        }}
                        value={field.value}
                        className="disabled:bg-[#F3F4F6] p-3 rounded-md w-full focus:outline-primary"
                        placeholder="isi jumlah mobil"
                      />
                      <div className="border-l-[1px] p-3">Mobil</div>
                    </div>
                    <small className="text-red-600">
                      {errors.additional_questions?.[0]?.answer?.message}
                    </small>
                  </>
                ) : (
                  <>
                    <div className="flex space-x-2">
                      <div className="flex border rounded-lg items-center justify-center w-full py-3">
                        <input
                          {...field}
                          id="mobil-ya"
                          type="radio"
                          className="h-4 accent-primary w-4 rounded-lg border p-3"
                          placeholder="Apakah kapasitas garasi bisa melebihi 1 mobil?"
                          checked={field.value === 'ya'}
                          value="ya"
                        />
                        <label
                          htmlFor="mobil-ya"
                          className="font-medium text-sm ml-3"
                        >
                          Ya
                        </label>
                      </div>
                      <div className="flex border rounded-lg items-center justify-center w-full py-3">
                        <input
                          {...field}
                          type="radio"
                          id="mobil-tidak"
                          checked={field.value === 'tidak'}
                          className="h-4 accent-primary w-4 rounded-lg border p-3"
                          placeholder="Apakah kapasitas garasi bisa melebihi 1 mobil?"
                          value="tidak"
                        />
                        <label
                          htmlFor="mobil-tidak"
                          className="font-medium text-sm ml-3"
                        >
                          Tidak
                        </label>
                      </div>
                    </div>
                    <small className="text-red-600">
                      {errors.additional_questions?.[0]?.answer?.message}
                    </small>
                  </>
                )}
              </>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label name={featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? "Berapa lama usaha sudah berjalan?" : "Apakah usaha telah berlangsung selama 2 tahun atau lebih?"} />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name={`additional_questions.${1}.answer`}
            render={({ field }) => {
              return (
                <>
                  {featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? (
                    <>
                      <Select
                        {...field}
                        isSearchable={false}
                        options={dropdown.data.businessDuration}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        getOptionLabel={(option) => option.desc!}
                        getOptionValue={(option) => option.desc!}
                        placeholder="Pilih durasi"
                        className={businessDuration?.desc?.toString().toLowerCase() === 'lainnya' ? 'mb-2' : ''}
                        onChange={item => {
                          if (!item?.desc?.toLowerCase().includes('lainnya')) {
                            setValue('additional_questions.1.answerOther', undefined)
                          }
                          field.onChange(item?.desc)
                          setBusinessDuration(item || null)
                        }}
                        value={dropdown.data.businessDuration?.filter(item => 
                          item.desc?.toLowerCase().includes(
                            businessDuration?.desc?.toLowerCase() || ''
                          ) && item?.id === businessDuration?.id
                        )}
                      />
                      <small className="text-red-600">
                        {errors.additional_questions?.[1]?.answer?.message}
                      </small>
                      {businessDuration?.desc?.toLowerCase().includes('lainnya') && (
                        <Controller
                          rules={{ required: 'Data harus diisi' }}
                          control={control}
                          name={`additional_questions.1.answerOther`}
                          render={({ field: field2 }) => {
                            return (
                              <>
                                <div className="flex border rounded-lg">
                                  <NumericFormat
                                    onValueChange={(values) => {
                                      field2.onChange(values.value);
                                    }}
                                    value={field2.value}
                                    className="disabled:bg-[#F3F4F6] p-3 rounded-md w-full focus:outline-primary"
                                    placeholder="Masukan durasi lainnya"
                                  />
                                  <div className="border-l-[1px] p-3">Tahun</div>
                                </div>
                                <small className="text-red-600">
                                  {errors.additional_questions?.[1]?.answerOther?.message}
                                </small>
                              </>
                            )
                          } }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <div className="flex space-x-2">
                        <div className="flex border rounded-lg items-center justify-center w-full py-3">
                          <input
                            {...field}
                            id="tahun-ya"
                            type="radio"
                            className="h-4 accent-primary w-4 rounded-lg border p-3"
                            placeholder="Apakah usaha telah berlangsung selama 2 tahun atau lebih?"
                            checked={field.value === 'ya'}
                            value="ya"
                          />
                          <label
                            htmlFor="tahun-ya"
                            className="font-medium text-sm ml-3"
                          >
                            Ya
                          </label>
                        </div>
                        <div className="flex border rounded-lg items-center justify-center w-full py-3">
                          <input
                            {...field}
                            type="radio"
                            id="tahun-tidak"
                            checked={field.value === 'tidak'}
                            className="h-4 accent-primary w-4 rounded-lg border p-3"
                            placeholder="Apakah usaha telah berlangsung selama 2 tahun atau lebih?"
                            value="tidak"
                          />
                          <label
                            htmlFor="tahun-tidak"
                            className="font-medium text-sm ml-3"
                          >
                            Tidak
                          </label>
                        </div>
                      </div>
                      <small className="text-red-600">
                        {errors.additional_questions?.[1]?.answer?.message}
                      </small>
                    </>
                  )}
                </>
              )
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label name="Apakah terdapat plang/banner/sign board di tempat usaha?" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name={`additional_questions.${2}.answer`}
            render={({ field }) => (
              <>
                <div className="flex space-x-2">
                  <div className="flex border rounded-lg items-center justify-center w-full py-3">
                    <input
                      {...field}
                      id="banner-ya"
                      type="radio"
                      className="h-4 accent-primary w-4 rounded-lg border p-3"
                      placeholder="Apakah terdapat plang/banner/sign board di tempat usaha?"
                      checked={field.value?.toLowerCase() === 'ya'}
                      value="ya"
                    />
                    <label
                      htmlFor="banner-ya"
                      className="font-medium text-sm ml-3"
                    >
                      Ya
                    </label>
                  </div>
                  <div className="flex border rounded-lg items-center justify-center w-full py-3">
                    <input
                      {...field}
                      type="radio"
                      id="banner-tidak"
                      checked={field.value?.toLowerCase() === 'tidak'}
                      className="h-4 accent-primary w-4 rounded-lg border p-3"
                      placeholder="Apakah terdapat plang/banner/sign board di tempat usaha?"
                      value="tidak"
                    />
                    <label
                      htmlFor="banner-tidak"
                      className="font-medium text-sm ml-3"
                    >
                      Tidak
                    </label>
                  </div>
                </div>
                <small className="text-red-600">
                  {errors.additional_questions?.[2]?.answer?.message}
                </small>
              </>
            )}
          />
        </FormGroup>
        {featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT') ? null : (
          <>
            <div className="border mb-[20px] mt-[4px] border-neutral-200 w-full"></div>
            <h3 className="font-semibold mb-4">Referensi (minimal 2)</h3>
            <div className="text-xs text-neutral-700 leading-[18px] mb-3">
              <p>
                Masukkan referensi <span className="font-bold">pembeli</span> atau{' '}
                <span className="font-bold">partner Dealer</span> kamu, semakin
                banyak, pendaftaranmu{' '}
                <span className="font-bold">lebih mudah disetujui</span>
              </p>
            </div>
            {fields.map((field, idx) => (
              <div key={field.id}>
                <div className="flex justify-between items-center ">
                  <Label name={`Referensi ${idx + 1}`} />
                  {idx >= 2 &&
                    !field.buyer_name &&
                    !field.buyer_phone &&
                    !field.car_type && (
                      <X
                        onClick={() => remove(idx)}
                        className="mb-2"
                        height={16}
                        width={16}
                      />
                    )}
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <FormGroup>
                    <Controller
                      rules={{ required: 'Data harus diisi' }}
                      control={control}
                      name={`buyer_reference.${idx}.buyer_name`}
                      render={({ field }) => (
                        <Input {...field} placeholder="Nama Pembeli" />
                      )}
                    />
                    <small className="text-red-600">
                      {errors?.buyer_reference?.[`${idx}`]?.buyer_name?.message}
                    </small>
                  </FormGroup>
                  <FormGroup>
                    <Controller
                      rules={{ required: 'Data harus diisi' }}
                      control={control}
                      name={`buyer_reference.${idx}.buyer_phone`}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="Nomor telepon"
                        />
                      )}
                    />
                    <small className="text-red-600">
                      {errors?.buyer_reference?.[`${idx}`]?.buyer_phone?.message}
                    </small>
                  </FormGroup>
                </div>
                <FormGroup>
                  <Label name="Mobil yang dibeli" />
                  <Controller
                    rules={{ required: 'Data harus diisi' }}
                    control={control}
                    name={`buyer_reference.${idx}.car_type`}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Masukkan jenis mobil yang dibeli"
                      />
                    )}
                  />
                  <small className="text-red-600">
                    {errors?.buyer_reference?.[`${idx}`]?.car_type?.message}
                  </small>
                </FormGroup>
              </div>
            ))}
            {fields?.length < 4 && (
              <div
                onClick={() =>
                  append({
                    buyer_name: '',
                    buyer_phone: '',
                    car_type: '',
                  })
                }
                className="flex gap-2 justify-center mt-2 text-primary items-center font-bold"
              >
                <Plus width={16} height={16} />
                <div className="text-sm">
                  <p>Tambah Referensi</p>
                </div>
              </div>
            )}
          </>
        )}
        <button
          type="submit"
          disabled={data.dealer.loading}
          className="bg-secondary mt-[28px] rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
        >
          Simpan dan Lanjut
        </button>
      </form>
    </>
  );
}

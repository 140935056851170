import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDealerDetail } from '../../redux/slices/dealer/dealerThunk';
import { agreeTnC, getTnC } from '../../redux/slices/TnC/tncThunk';
import '../../assets/css/tnc.css';
import { WaitingStatus } from '../../utils/constants';

const TnCPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [content, setContent] = useState('');
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const tnc = useAppSelector((state) => state.tnc);
  const dealer = useAppSelector((state) => state.dealer.dealer.data);
  const navigate = useNavigate();

  const handleAgree = async () => {
    const res = await dispatch(
      agreeTnC({ userId: dealer?.id, tncId: tnc.data?.id })
    );

    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/dealer/validation/${uuid}`);
    }
  };

  useEffect(() => {
    const res = tnc?.data?.content
      ?.replace('{name}', dealer?.informasi_pribadi?.full_name || '')
      ?.replace('{nik}', dealer?.informasi_pribadi?.ktp_number || '')
      ?.replace('{phone}', dealer?.informasi_pribadi?.phone_number || '');

    setContent(res);
  }, [tnc, dealer]);

  useEffect(() => {
    dispatch(getTnC('dealer_onboarding_consent'));
    dispatch(getDealerDetail(uuid as string));

    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') || '');
      dispatch(getDealerDetail(uuid as string));
    }
  }, []);

  useEffect(() => {
    if (dealer.latest_status) {
      dealer?.latest_status !== WaitingStatus.WAITING_FOR_ACTIVE_CONSENT &&
        navigate(`/dealer/waiting-page/${uuid}`);
    }
  }, [dealer]);

  return (
    <div className="px-4">
      <div className="flex flex-col items-center justify-center text-center space-y-2 mt-4">
        <img
          src={MoladinIcon}
          alt="moladin"
          className="w-[120px] h-[40px] cursor-pointer"
        />
        <h1 className="font-semibold">Surat Pernyataan Dealer</h1>
        <span className="text-xs">
          Versi {tnc.data?.version} diperbarui tanggal{' '}
          {dayjs(tnc.data?.created_at).format('DD MMMM YYYY')}
        </span>
      </div>

      <div
        className="bg-[#F7F7F7] rounded-2xl px-4 py-[9px] mt-[30px] mb-4"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>

      <div className="flex items-center justify-center text-sm mb-3">
        <input
          type="checkbox"
          id="check"
          onChange={(e) => setIsChecked(e.target.checked)}
          className="rounded-lg"
        />
        <label htmlFor="check" className="text-xs ml-[10px]">
          Saya telah membaca dan setuju dengan <b>Syarat dan Ketentuan</b> yang
          berlaku di Moladin
        </label>
      </div>

      <div>
        <button
          onClick={handleAgree}
          disabled={isChecked === false}
          className="bg-secondary disabled:bg-disabled disabled:cursor-not-allowed rounded-lg h-11 w-full text-white my-[18px]"
        >
          Setujui
        </button>
      </div>
    </div>
  );
};

export default TnCPage;

import { AxiosResponse } from 'axios';
import React from 'react';
import { X } from 'react-feather';
import { CanvasContentDescription } from '../../../types/FotoIdentitasTypes';

interface Props {
  title: string | undefined;
  imageSrc: string;
  handleRetakePicture: () => void;
  onConfirmPhoto: (srcUri: string) => void;
  type: string;
  disabledButtonUpload: boolean;
}

const canvasContentDescription: CanvasContentDescription = {
  ktp_photo_url: (
    <>Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca</>
  ),
  family_card_url: (
    <>Pastikan informasi pada foto terlihat dengan jelas dan dapat dibaca</>
  ),
  selfie_url: (
    <>
      Pastikan wajah terlihat jelas dan foto tidak gelap.{' '}
      <strong>Jangan memegang KTP atau memakai aksesoris</strong>
    </>
  ),
  npwp_url: (
    <>Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca</>
  ),
  bangunan_url: (
    <>Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca</>
  ),
};

export default function Canvas({
  title,
  imageSrc,
  handleRetakePicture,
  onConfirmPhoto,
  type,
  disabledButtonUpload,
}: Props) {
  return (
    <div className="z-0 absolute top-0 left-0 w-full h-full">
      <div className="w-full h-full">
        <div className="py-4 bg-black">
          <div className="flex items-center px-5 text-white">
            <X
              onClick={handleRetakePicture}
              width={24}
              height={24}
              className="cursor-pointer"
            />
            <div className="w-full">
              <p className="text-center mb-0">{title}</p>
            </div>
          </div>
        </div>
        <div className="absolute top-[56px] left-0 bottom-0 right-0 bg-white flex flex-col">
          <div className="flex-1 flex flex-col justify-center">
            <div className="h-[302px] rounded mt-[21px] px-[65px] mb-[31px]">
              <img
                src={imageSrc}
                className="w-full h-full object-cover object-center rounded"
              />
            </div>
            <div className="mx-4 text-center mb-[31px] text-xs text-neutral-600 font-normal">
              {canvasContentDescription[type as keyof CanvasContentDescription]}
            </div>
          </div>
          <div className="flex flex-col">
            <button
              onClick={handleRetakePicture}
              disabled={disabledButtonUpload}
              className="mx-4 mb-5 bg-orange-100 font-bold rounded py-3 text-center text-orange-300 disabled:bg-neutral-400 disabled:text-white"
            >
              Foto Ulang
            </button>
            <button
              disabled={disabledButtonUpload}
              onClick={() => onConfirmPhoto(imageSrc)}
              className="mx-4 block mb-3 bg-orange-500 font-bold rounded py-3 text-center text-white disabled:bg-neutral-400"
            >
              <p className="!mb-0">Gunakan Foto Ini</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { useParams } from 'react-router-dom';
import WaitingDisplay from '../../atom/WaitingDisplay';
import { imageWaitingConsent } from '../../../utils/assets';

type Props = {
  handleRefreshStatus: () => void;
  handleResendTnc: () => void;
  restartCountdown?: boolean;
  setRestartCountdown?: (isRestart: boolean) => void;
};

const WaitingConsent = ({
  handleRefreshStatus,
  handleResendTnc,
  restartCountdown,
  setRestartCountdown,
}: Props) => {
  const { uuid } = useParams();
  const [countdown, setCountdown] = useState(0);

  const setLocalStorageCountdown = (countdown = 60) => {
    const setDataCountdown = {
      uuid: uuid,
      countdown: countdown,
    };
    localStorage.setItem(
      'waitingPageCountdown',
      JSON.stringify(setDataCountdown)
    );
  };

  const checkCountdown = () => {
    const dataCountdown = localStorage.getItem('waitingPageCountdown');
    if (dataCountdown) {
      const parsedDataCountdown = JSON.parse(dataCountdown);
      if (parsedDataCountdown?.uuid === uuid) {
        setCountdown(parsedDataCountdown?.countdown);
      } else {
        setLocalStorageCountdown();
        setCountdown(60);
      }
    } else {
      setLocalStorageCountdown();
      setCountdown(60);
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown((prevState) => prevState - 1);
        setLocalStorageCountdown(countdown - 1);
      }, 1000);
    }
  }, [countdown]);

  useEffect(() => {
    checkCountdown();
  }, []);

  return (
    <WaitingDisplay
      img={imageWaitingConsent}
      title="Setujui Syarat & Ketentuan untuk melanjutkan"
      description="Untuk melanjutkan proses pendaftaran, mohon menyetujui Syarat & Ketentuan yang telah dikirim ke nomor WA Dealer"
      customFooter={true}
      marginBody="mb-[85px]"
    >
      <div className="mt-[100px]">
        <button
          type="submit"
          onClick={() => handleRefreshStatus()}
          className="font-semibold rounded-lg h-11 w-full text-white bg-secondary mb-4"
        >
          <div className="flex justify-center items-center">
            <RefreshCw size={12} className="mr-2" />
            <span className="text-sm text-white">Perbarui Status</span>
          </div>
        </button>
        <div className="text-center text-sm text-gray-500">
          {countdown && countdown > 0 ? (
            <div>
              <span className="font-semibold">{countdown} Detik </span>
              sebelum bisa mengirim ulang
            </div>
          ) : (
            <div
              className="font-semibold text-blue-700"
              onClick={() => {
                handleResendTnc();
                setCountdown(60);
              }}
            >
              Kirim Ulang Syarat & Ketentuan
            </div>
          )}
        </div>
      </div>
    </WaitingDisplay>
  );
};

export default WaitingConsent;

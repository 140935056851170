import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { Event } from "../../../types/Event";
import { getEventByIdOrCode } from "./eventThunk";

export const eventSlice = createSlice({
  name: 'eventDetail',
  initialState: {
    data: {
      id: 0,
      city: {
        id: 1,
        name: ''
      },
      code: '',
      endDate: '',
      isExpired: false,
      name: '',
      startDate: ''
    } as Event,
    loading: false,
    message: '' as string
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEventByIdOrCode.pending, (state) => {
        state.loading = true;
        state.message = ''
      })
      .addCase(getEventByIdOrCode.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.message = ''
      })
      .addCase(getEventByIdOrCode.rejected, (state, action) => {
        state.message = action.error.message || ''
      })
  },
});

export const reducer = combineReducers({
  event: eventSlice.reducer
});

export default reducer
import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpiredChecking, Revision, Status, Revisions } from '../../../types/Revision';

import { RootState } from '../../store';
import { getExpiredChecking, getRevision, getStatus, getRevisionDocument } from './revisionThunk';

export const expiredSlice = createSlice({
  name: 'revision',
  initialState: {
    data: {} as ExpiredChecking,
    loading: false,
    error: false,
  },
  reducers: {
    setExpiredRevision: (state, action: PayloadAction<ExpiredChecking>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpiredChecking.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpiredChecking.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getExpiredChecking.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  },
});

export const revisionSlice = createSlice({
    name: 'revision',
    initialState: {
      data: {} as Revision,
      loading: false,
      error: false,
    },
    reducers: {
      setExpiredRevision: (state, action: PayloadAction<Revision>) => {
        state.data = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getRevision.pending, (state) => {
          state.loading = true;
        })
        .addCase(getRevision.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getRevision.rejected, (state) => {
          state.loading = false;
          state.error = true;
        })
    },
  });

  export const statusSlice = createSlice({
    name: 'status',
    initialState: {
      data: {} as Status,
      loading: false,
      error: false,
    },
    reducers: {
      setExpiredRevision: (state, action: PayloadAction<Status>) => {
        state.data = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getStatus.pending, (state) => {
          state.loading = true;
        })
        .addCase(getStatus.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getStatus.rejected, (state) => {
          state.loading = false;
          state.error = true;
        })
    },
  });

export const revisionDocumentSlice = createSlice({
  name: 'revisionDocument',
  initialState: {
    data: [] as Revisions[],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRevisionDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRevisionDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload?.statusCode === 404 ? [] : action.payload.revisions;
      })
      .addCase(getRevisionDocument.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.data = [];
      })
  },
});


export const { setExpiredRevision } = expiredSlice.actions;

export const selectData = (state: RootState) => state.revision;

export const reducer = combineReducers({
  dealer: expiredSlice.reducer,
  revisionDocument: revisionDocumentSlice.reducer
});

export default reducer;

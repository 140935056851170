import React from "react";

type CardEventProps = {
  name: string;
  city: string;
  startDate: string;
}

const CardEvent = ({
  name,
  city,
  startDate
} : CardEventProps) => {
  return (
    <div className="p-4 rounded-xl shadow-lg mb-3 bg-white relative z-10">
      <h1 className="text-xl mb-4 font-semibold text-[#0069FF]">Selamat Datang!</h1>
      <h3 className="text-neutral-800 mb-1 text-base font-semibold">{name}</h3>
      <small className="text-neutral-500 text-xs">{city} • {startDate}</small>
    </div>
  )
}

export default CardEvent
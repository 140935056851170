import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../../types/commonTypes';

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const { user }: User = jwtDecode(localStorage.getItem('token') || '');
      navigate(`/dealer/homepage/${user.uuid}`);
    } else {
      navigate(`/dealer/homepage/uuid`);
    }
  }, []);
  return <div>NotFoundPage</div>;
};

export default NotFoundPage;

import React, { useEffect, useState } from 'react';
import * as Assets from '../../../../utils/assets';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import FormGroup from '../../../atom/FormGroup';
import Label from '../../../atom/Label';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Calendar } from 'react-feather';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from '../../../atom/Input';
import ReactFlagsSelect from 'react-flags-select';
import { PatternFormat } from 'react-number-format';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getDropdown } from '../../../../redux/slices/dealer/dealerThunk';
import { DropdownMaster } from '../../../../types/commonTypes';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { saveDraftV2 } from '../../../../redux/slices/employee/employeeThunk';
import { resetNextDropdownAddress } from '../../../../redux/slices/informasiAlamat/informasiAlamatSlice';
import { getLocationByLatLong } from '../../../../services';
import PopupGuide from '../../../FotoIdentitas/PopupGuide/PopupGuide';
import TakePhoto from '../../../FotoIdentitas/TakePhoto/TakePhoto';
import {
    InformasiAlamatActiveDisplay as PageType,
    NAME_REGEX,
    PAGE_SECTION_FORM
} from '../../../../utils/constants';
import InputTakePhotoWithDrawerEmployee from '../../../FotoIdentitas/InputTakePhotoWithDrawerEmployee';
import InputTakePhotoWithDrawerEmployeeCamera from '../../../FotoIdentitas/InputTakePhotoWithDrawerEmployeeCamera';
import { TemporaryStateUsahaType } from '../../../../types/AlamatUsahaTypes';
import PinpointLocation from '../../../InformasiAlamat/PinpointLocation';
import { CameraProperty, ContentControler } from '../../../../types/FotoIdentitasTypes';
import {
    DropdownMasterInformasiAlamat,
    SelectedAddressType
} from '../../../../types/InformasiAlamatTypes';
import {
    getDropdownProvinceAddress,
    getDropdownCityAddress,
    getDropdownSubdistrictAddress,
    getDropdownVillageAddress,
} from '../../../../redux/slices/informasiAlamat/informasiAlamatThunk';
import { uploadTakenImageFileEmployee } from '../../../../services';

type FormData = {
    uuid: string;
    dob: string;
    phone_number?: string;
    personal_npwp: string;
    email: string;
    selfie_url: string;
    npwp_url: string;
    nick_name: string;
    marriage_id: DropdownMaster;
    spouse_ktp_name: string;
    spouse_ktp_number: string;
    spouse_dob: string;
    spouse_phone_number: string;
    address: string;
    province: DropdownMasterInformasiAlamat;
    city: DropdownMasterInformasiAlamat;
    subdistrict: DropdownMasterInformasiAlamat;
    village: DropdownMasterInformasiAlamat;
    postal_code: string;
};

const initialContentController: ContentControler = {
    showCamera: false,
    showPopup: false,
    contentType: '',
};

const initialCameraProperty: CameraProperty = {
    facing_mode: 'environment',
};

const DataPelengkap = () => {
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormData>();

    const dispatch = useAppDispatch();
    const dropdown = useAppSelector((state) => state.dealer.dropdown);
    const { data: employee, loading: isLoading } = useAppSelector(
        (state) => state.employee.employee
    );
    const [contentController, setContentController] = useState(
        initialContentController
    );
    const [cameraProperty, setCameraProperty] = useState(initialCameraProperty);
    const navigate = useNavigate();
    const { uuid } = useParams();

    const watchMarriage = watch('marriage_id');

    const dropdownProvince = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownProvince
    );
    const dropdownCity = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownCity
    );
    const dropdownSubdistrict = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownSubdistrict
    );
    const dropdownVillage = useAppSelector(
        (state) => state.informasiAlamat.dropdownLocationAddress.dropdownVillage
    );
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [activeForm, setActiveForm] = useState<'ktp' | 'domicile'>('ktp');
    const [activePage, setActivePage] = useState<'form' | 'pinpoint'>('form');
    const [temporaryState, setTemporaryState] = useState<TemporaryStateUsahaType>(
        { lat: '', lng: '' }
    );
    const [triggerMap, setTriggerMap] = useState(false);

    const dropdownStyles = {
        control: (base: any, state: any) => ({
            ...base,
            borderColor: state?.isFocused ? '#0F2CAD' : 'default',
        }),
    };

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const { province, city, subdistrict, village, address, postal_code, dob, ...rest } = data
        const payloadAlamat = {
            address: data?.address,
            province_id: data?.province?.id,
            province_name: data?.province?.name,
            city_id: data?.city?.id,
            city_name: data?.city?.name,
            subdistrict_id: data?.subdistrict?.id,
            subdistrict_name: data?.subdistrict?.name,
            village_id: data?.village?.id,
            village_name: data?.village?.name,
            postal_code: data?.postal_code,
        };

        const payloadSaveDraft = {
            data_pelengkap_alamat_v2: {
                ...rest,
                alamat_domisili: payloadAlamat,
                marriage_id: data.marriage_id.id as number,
                spouse_dob: employee?.foto_hasil_ktp_v2?.marriage_id === 7 ? dayjs(data.spouse_dob).format('YYYY-MM-DD') : ''
            },
        };

        const res = await dispatch(saveDraftV2({ data: payloadSaveDraft, uuid: uuid as string }));
        if (res.meta.requestStatus === 'fulfilled') {
            navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.KELUARGA_V2}`);
        } else {
            const errMessage = (res.payload as any)?.errors[0]?.message;
            toast.error(errMessage || 'Failed submit registration');
        }
    };

    useEffect(() => {
        dispatch(getDropdown());
    }, []);

    useEffect(() => {
        const dropdownMarriage = dropdown.data.marriage?.filter(
            (item: DropdownMaster) =>
                item.id === employee.data_pelengkap_alamat_v2?.marriage_id
        );
        const isNotMarried = employee?.foto_hasil_ktp_v2?.marriage_id === 8
        const isDataSpouseExist = employee?.data_pelengkap_alamat_v2?.spouse_ktp_number !== null
        setValue('phone_number', employee.informasi_pribadi?.phone_number);
        setValue('nick_name', employee.data_pelengkap_alamat_v2?.nick_name);
        setValue('selfie_url', employee.data_pelengkap_alamat_v2?.selfie_url);
        setValue('npwp_url', employee.foto_identitas?.npwp_url);
        setValue('email', employee.data_pelengkap_alamat_v2?.email);
        setValue('personal_npwp', employee.data_pelengkap_alamat_v2?.personal_npwp);
        setValue('spouse_ktp_name', isNotMarried ? '' : employee.data_pelengkap_alamat_v2?.spouse_ktp_name);
        setValue('spouse_ktp_number', isNotMarried ? '' : employee.data_pelengkap_alamat_v2?.spouse_ktp_number);
        setValue('spouse_dob', isNotMarried ? '' : employee.data_pelengkap_alamat_v2?.spouse_dob);
        setValue('spouse_phone_number', isNotMarried ? '' : employee.data_pelengkap_alamat_v2?.spouse_phone_number);
        setValue('marriage_id', dropdownMarriage?.[0]);

        if (!isNotMarried && !isDataSpouseExist) {
            if (employee.foto_hasil_ktp_v2.gender === 'L') {
                const foundUser = employee.foto_hasil_kk_v2.family_employee.find(el => el.family_status_id === 3) //istri
                setValue('spouse_ktp_number', foundUser?.family_nik || '')
                setValue('spouse_ktp_name', foundUser?.family_name || '')
                setValue('spouse_dob', foundUser?.family_dob || '')
            }
            if (employee.foto_hasil_ktp_v2.gender === 'P') {
                const foundUser = employee.foto_hasil_kk_v2.family_employee.find(el => el.family_status_id === 1 || el.family_status_id === 2) //kepala keluarga
                console.log('foundUserfoundUserfoundUserfoundUser', foundUser);

                setValue('spouse_ktp_number', foundUser?.family_nik || '')
                setValue('spouse_ktp_name', foundUser?.family_name || '')
                setValue('spouse_dob', foundUser?.family_dob || '')
            }
        }
    }, [dropdown, employee?.data_pelengkap_alamat_v2]);

    const handleClickShowPopupGuide = (type: string) => {
        setContentController({
            ...contentController,
            showPopup: true,
            contentType: type,
        });
    };

    const handleRemovePhoto = (type: string, callback: any) => {
        callback(type, '');
    };

    const handleConfirmDrawerFileTaken = (
        srcUri: string,
        setValue: any,
        contentType: string
    ) => {
        setValue(contentType, srcUri);
        setContentController({
            ...contentController,
            ...initialContentController,
        });
    };

    const handleClickClosePopupGuide = () => {
        setContentController({
            ...contentController,
            showPopup: false,
            contentType: '',
        });
    };

    const handleConfirmPopupGuide = () => {
        setContentController({
            ...contentController,
            showPopup: false,
            showCamera: true,
        });
    };

    const handleBackToPopupGuide = () => {
        setContentController({
            ...contentController,
            showPopup: true,
            showCamera: false,
        });
    };

    const handleConfirmPhotoTaken = (srcUri: string, callback: any) => {
        callback(contentController.contentType, srcUri);
        setContentController({
            ...contentController,
            ...initialContentController,
        });
    };

    const handleChangeProvince = (event: any, type: string) => {
        setValue('province', event);

        //Trigger Next Dropdown
        const paramsGetDropdownCity = {
            queryParams: `levels=3&parent_ids=${event?.id}`,
            type: type === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCity));

        //Reset Next Dropdown Value
        const payloadResetDropdown = {
            location: 'province',
            type: type === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(resetNextDropdownAddress(payloadResetDropdown));

        setValue('city', null as any);
        setValue('subdistrict', null as any);
        setValue('village', null as any);
        setValue('address', '');
        setValue('postal_code', '');
    };

    const handleChangeCity = (event: any, type: string) => {
        setValue('city', event);

        //Trigger Next Dropdown
        const paramsGetDropdownSubdistrict = {
            queryParams: `levels=4&parent_ids=${event?.id}`,
            type: type === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

        //Reset Next Dropdown Value
        const payloadResetDropdown = {
            location: 'city',
            type: type === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(resetNextDropdownAddress(payloadResetDropdown));

        setValue('subdistrict', null as any);
        setValue('village', null as any);
        setValue('address', '');
        setValue('postal_code', '');
    };

    const handleChangeSubdistrict = (event: any, type: string) => {
        setValue('subdistrict', event);

        //Trigger Next Dropdown
        const paramsGetDropdownVillage = {
            queryParams: `levels=5&parent_ids=${event?.id}`,
            type: type === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));

        //Reset Next Dropdown Value
        setValue('village', null as any);
        setValue('address', '');
        setValue('postal_code', '');
    };

    const handleChangeVillage = (event: any, type: string) => {
        setValue('village', event);
        //Reset Next Form Value
        setValue('address', '');
        setValue('postal_code', '');
    };

    const mappingDropdown = [
        {
            label: 'Provinsi',
            name: 'province' as any,
            options: dropdownProvince,
            placeholder: 'Pilih provinsi',
            onChange: (e: any) => handleChangeProvince(e, 'ktp'),
            errors: errors.province?.message,
        },
        {
            label: 'Kabupaten/Kota',
            name: 'city' as string,
            options: dropdownCity,
            placeholder: 'Pilih kabupaten/kota',
            onChange: (e: any) => handleChangeCity(e, 'ktp'),
            errors: errors.city?.message,
        },
        {
            label: 'Kecamatan',
            name: 'subdistrict' as string,
            options: dropdownSubdistrict,
            placeholder: 'Pilih kecamatan',
            onChange: (e: any) => handleChangeSubdistrict(e, 'ktp'),
            errors: errors.subdistrict?.message,
        },
        {
            label: 'Kelurahan',
            name: 'village' as any,
            options: dropdownVillage,
            placeholder: 'Pilih kelurahan',
            onChange: (e: any) => handleChangeVillage(e, 'ktp'),
            errors: errors.village?.message,
        },
    ];

    const getLocation = (params: SelectedAddressType) => {
        setTriggerMap(true);
        const paramsGetDropdown = {
            queryParams: `excluded_ids=1&nested=child&latlng=${params.lat},${params.lng}`,
        };
        getLocationByLatLong(paramsGetDropdown)
            .then((res) => {
                const data = res.data.data;
                const dataProvince = data;
                const dataCity = data?.child;
                const dataSubdistrict = data?.child?.child;
                const dataVillage = data?.child?.child?.child;

                setValue('province', dataProvince);
                setValue('city', dataCity);
                setValue('subdistrict', dataSubdistrict);
                setValue('village', dataVillage);

                const paramsGetDropdownCity = {
                    queryParams: `levels=3&parent_ids=${dataProvince?.id}`,
                    type: activeForm === 'ktp' ? 'ktp' : 'domicile',
                };
                dispatch(getDropdownCityAddress(paramsGetDropdownCity));

                const paramsGetDropdownSubdistrict = {
                    queryParams: `levels=4&parent_ids=${dataCity?.id}`,
                    type: activeForm === 'ktp' ? 'ktp' : 'domicile',
                };
                dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

                const paramsGetDropdownVillage = {
                    queryParams: `levels=5&parent_ids=${dataSubdistrict?.id}`,
                    type: activeForm === 'ktp' ? 'ktp' : 'domicile',
                };
                dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));
                setTimeout(() => {
                    setTriggerMap(false);
                }, 2000);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const handleSaveMaps = (params: SelectedAddressType) => {
        setValue('postal_code', params.postalCode);
        setValue('address', params.fullAddress);
        getLocation(params);
    };

    const handleResetForm = () => {
        if (!isSameAddress) {
            setValue('province', null as any);
            setValue('city', null as any);
            setValue('subdistrict', null as any);
            setValue('village', null as any);
            setValue('address', '');
            setValue('postal_code', '');

            const payloadResetDropdown = {
                location: 'all',
                type: activeForm === 'ktp' ? 'ktp' : 'domicile',
            };
            dispatch(resetNextDropdownAddress(payloadResetDropdown));
        }
    };

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        const dataExisting = employee?.data_pelengkap_alamat_v2?.alamat_domisili;
        if (isSameAddress && localStorage.getItem('token')) {
            dispatch(getDropdownProvinceAddress('levels=2'));
            if (dataAlamatKtp?.id) {
                const paramsGetDropdownCityKtp = {
                    queryParams: `levels=3&parent_ids=${dataAlamatKtp?.province_id}`,
                    type: 'ktp',
                };
                dispatch(getDropdownCityAddress(paramsGetDropdownCityKtp));

                const paramsGetDropdownSubdistrictKtp = {
                    queryParams: `levels=4&parent_ids=${dataAlamatKtp?.city_id}`,
                    type: 'ktp',
                };
                dispatch(
                    getDropdownSubdistrictAddress(paramsGetDropdownSubdistrictKtp)
                );

                const paramsGetDropdownVillageKtp = {
                    queryParams: `levels=5&parent_ids=${dataAlamatKtp?.subdistrict_id}`,
                    type: 'ktp',
                };
                dispatch(getDropdownVillageAddress(paramsGetDropdownVillageKtp));
                setValue('address', dataAlamatKtp?.address);
                setValue('postal_code', dataAlamatKtp?.postal_code);
            }
        }
        if (dataExisting) {
            dispatch(getDropdownProvinceAddress('levels=2'));
            const paramsGetDropdownCityKtp = {
                queryParams: `levels=3&parent_ids=${dataExisting?.province_id}`,
                type: 'ktp',
            };
            dispatch(getDropdownCityAddress(paramsGetDropdownCityKtp));

            const paramsGetDropdownSubdistrictKtp = {
                queryParams: `levels=4&parent_ids=${dataExisting?.city_id}`,
                type: 'ktp',
            };
            dispatch(
                getDropdownSubdistrictAddress(paramsGetDropdownSubdistrictKtp)
            );

            const paramsGetDropdownVillageKtp = {
                queryParams: `levels=5&parent_ids=${dataExisting?.subdistrict_id}`,
                type: 'ktp',
            };
            dispatch(getDropdownVillageAddress(paramsGetDropdownVillageKtp));
            setValue('address', dataExisting?.address);
            setValue('postal_code', dataExisting?.postal_code);

            if (
                employee?.foto_hasil_ktp_v2?.alamat_ktp?.address === dataExisting.address &&
                employee?.foto_hasil_ktp_v2?.alamat_ktp?.postal_code === dataExisting.postal_code
            ) setIsSameAddress(true)
        } else {
            dispatch(getDropdownProvinceAddress('levels=2'));
        }
    }, [employee, isSameAddress]);

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        const dataExisting = employee?.data_pelengkap_alamat_v2?.alamat_domisili;
        if (isSameAddress && localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownProvince.length > 0 && !triggerMap) {
                const dropdownProvinceKtp = dropdownProvince.filter(
                    (a: any) => a.id === dataAlamatKtp?.province_id
                );
                setValue('province', dropdownProvinceKtp[0]);
            }
        }
        if (dataExisting) {
            if (dropdownProvince.length > 0 && !triggerMap) {
                const dropdownProvinceKtp = dropdownProvince.filter(
                    (a: any) => a.id === dataExisting?.province_id
                );
                setValue('province', dropdownProvinceKtp[0]);
            }
        }
    }, [dropdownProvince]);


    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        const dataExisting = employee?.data_pelengkap_alamat_v2?.alamat_domisili;
        if (isSameAddress && localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownCity.length > 0 && !triggerMap) {
                const dropdownCityKtp = dropdownCity.filter(
                    (a: any) => a.id === dataAlamatKtp?.city_id
                );
                setValue('city', dropdownCityKtp[0]);
            }
        }
        if (dataExisting) {
            if (dropdownCity.length > 0 && !triggerMap) {
                const dropdownCityKtp = dropdownCity.filter(
                    (a: any) => a.id === dataExisting?.city_id
                );
                setValue('city', dropdownCityKtp[0]);
            }
        }
    }, [dropdownCity]);

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        const dataExisting = employee?.data_pelengkap_alamat_v2?.alamat_domisili;
        if (isSameAddress && localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownSubdistrict.length > 0 && !triggerMap) {
                const dropdownSubdistrictKtp = dropdownSubdistrict.filter(
                    (a: any) => a.id === dataAlamatKtp?.subdistrict_id
                );
                setValue('subdistrict', dropdownSubdistrictKtp[0]);
            }
        }
        if (dataExisting) {
            if (dropdownSubdistrict.length > 0 && !triggerMap) {
                const dropdownSubdistrictKtp = dropdownSubdistrict.filter(
                    (a: any) => a.id === dataExisting?.subdistrict_id
                );
                setValue('subdistrict', dropdownSubdistrictKtp[0]);
            }
        }
    }, [dropdownSubdistrict]);

    useEffect(() => {
        const dataAlamatKtp = employee?.alamat?.alamat_ktp;
        const dataExisting = employee?.data_pelengkap_alamat_v2?.alamat_domisili;
        if (isSameAddress && localStorage.getItem('token')) {
            if (dataAlamatKtp?.id && dropdownVillage.length > 0 && !triggerMap) {
                const dropdownVillageKtp = dropdownVillage.filter(
                    (a: any) => a.id === dataAlamatKtp?.village_id
                );
                setValue('village', dropdownVillageKtp[0]);
            }
        }
        if (dataExisting) {
            if (dropdownVillage.length > 0 && !triggerMap) {
                const dropdownVillageKtp = dropdownVillage.filter(
                    (a: any) => a.id === dataExisting?.village_id
                );
                setValue('village', dropdownVillageKtp[0]);
            }
        }
    }, [dropdownVillage]);

    const params = {
        activePage,
        setActivePage,
        handleSaveMaps,
        temporaryState,
        setTemporaryState,
    };

    return (
        <div>
            {activePage === PageType.PINPOINT_LOCATION ? (
                <PinpointLocation {...params} />
            ) : !contentController.showCamera && !contentController.showPopup && (
                <>
                    <h3 className="font-semibold mb-4">Data Pelengkap</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label name="Nomor WA" />
                            <Controller
                                control={control}
                                name="phone_number"
                                render={({ field }) => (
                                    <div className="relative">
                                        <ReactFlagsSelect
                                            selected={'ID'}
                                            disabled
                                            className="absolute disabled:bg-[#F3F4F6]"
                                            onSelect={(code) => console.log(code)}
                                            countries={['ID', 'SG']}
                                            customLabels={{ ID: '+62', SG: '+65' }}
                                        />

                                        <Input
                                            {...field}
                                            disabled
                                            type="number"
                                            className="pl-[120px] disabled:bg-[#F3F4F6]"
                                            placeholder="No HP"
                                        />
                                    </div>
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label name="Nama Panggilan" />
                            <Controller
                                control={control}
                                rules={{
                                    required: 'Data harus diisi',
                                }}
                                name="nick_name"
                                render={({ field }) => (
                                    <Input {...field} placeholder="Masukkan nama panggilan" />
                                )}
                            />
                            <small className="text-red-600">{errors.nick_name?.message}</small>
                        </FormGroup>


                        <FormGroup>
                            <Label name="Email" />
                            <Controller
                                control={control}
                                rules={{
                                    required: 'Data harus diisi',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Format email salah',
                                    },
                                }}
                                name="email"
                                render={({ field }) => (
                                    <Input {...field} placeholder="Masukkan alamat email" />
                                )}
                            />
                            <small className="text-red-600">{errors.email?.message}</small>
                        </FormGroup>

                        <FormGroup className="mb-4">
                            <Label name="Foto Selfie Wajah" />
                            <Controller
                                rules={{ required: 'Foto Selfie harus diisi' }}
                                control={control}
                                name="selfie_url"
                                render={({ field }) => (
                                    <InputTakePhotoWithDrawerEmployee
                                        imageLabel="Foto Selfie"
                                        placeholder="Ambil foto selfie"
                                        onSelectCamera={() =>
                                            handleClickShowPopupGuide('selfie_url')
                                        }
                                        srcUri={field.value}
                                        handleRemovePhoto={() =>
                                            handleRemovePhoto('selfie_url', setValue)
                                        }
                                        onBrowseFile={() =>
                                            setContentController({
                                                ...contentController,
                                                contentType: 'selfie_url',
                                            })
                                        }
                                        onSelectFile={(src) => {
                                            handleConfirmDrawerFileTaken(src, setValue, 'selfie_url');
                                        }}
                                    />
                                )}
                            />
                            <small className="text-red-600">
                                {errors.selfie_url?.message}
                            </small>
                        </FormGroup>

                        <FormGroup className="mb-4">
                            <Label name="Foto NPWP" />
                            <Controller
                              rules={{ required: 'Foto NWPWP harus diisi' }}
                              control={control}
                              name="npwp_url"
                              render={({ field }) => (
                                <InputTakePhotoWithDrawerEmployeeCamera
                                  imageLabel="Foto NPWP"
                                  placeholder="Ambil foto NPWP"
                                  onSelectCamera={() =>
                                    handleClickShowPopupGuide('npwp_url')
                                  }
                                  srcUri={field.value}
                                  handleRemovePhoto={() =>
                                    handleRemovePhoto('npwp_url', setValue)
                                  }
                                />
                              )}
                            />
                            <small className="text-red-600">
                                {errors.npwp_url?.message}
                            </small>
                        </FormGroup>

                        <FormGroup>
                            <Label name="NPWP Pribadi" />
                            <Controller
                                rules={{ required: 'Data harus diisi' }}
                                control={control}
                                name="personal_npwp"
                                render={({ field }) => (
                                    <>
                                        <PatternFormat
                                            {...field}
                                            format="##.###.###.#-###.###"
                                            mask="_"
                                            placeholder="Masukkan nomor NPWP"
                                            className="border w-full h-11 rounded-lg p-3 focus:outline-primary"
                                        />
                                        <small className="text-red-600">
                                            {errors.personal_npwp?.message}
                                        </small>
                                    </>
                                )}
                            />
                        </FormGroup>

                        <FormGroup className="mb-5">
                            <Label name="Status Pernikahan" />
                            <Controller
                                rules={{ required: 'Data harus diisi' }}
                                control={control}
                                name="marriage_id"
                                render={({ field }) => (
                                    <>
                                        <Select
                                            isDisabled
                                            {...field}
                                            isSearchable={false}
                                            options={dropdown.data.marriage}
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            getOptionLabel={(option) => option.desc!}
                                            getOptionValue={(option) => option.id?.toString()}
                                            placeholder="Pilih status pernikahan"
                                        />

                                        <small className="text-red-600">
                                            {errors.marriage_id?.message}
                                        </small>
                                    </>
                                )}
                            />
                        </FormGroup>

                        {(watchMarriage?.id === 7 ||
                            employee.informasi_pribadi?.marriage_id === 7) && (
                                <>
                                    <FormGroup>
                                        <Label name="NIK Suami/Istri" />
                                        <Controller
                                            rules={{
                                                required: 'Data harus diisi',
                                                pattern: {
                                                    value:
                                                        /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/,
                                                    message: 'NIK suami/istri salah. Mohon isi yang sesuai',
                                                },
                                            }}
                                            control={control}
                                            name="spouse_ktp_number"
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    pattern="\d*"
                                                    type="number"
                                                    maxLength={16}
                                                    placeholder="Masukkan NIK suami/istri"
                                                />
                                            )}
                                        />
                                        <small className="text-red-600">
                                            {errors.spouse_ktp_number?.message}
                                        </small>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label name="Nama Suami/Istri (sesuai KTP Suami/Istri)" />
                                        <Controller
                                            rules={{
                                                required: 'Data harus diisi',
                                                pattern: {
                                                    value: NAME_REGEX,
                                                    message:
                                                        'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
                                                },
                                            }}
                                            control={control}
                                            name="spouse_ktp_name"
                                            render={({ field }) => (
                                                <>
                                                    <Input {...field} placeholder="Masukkan nama suami/istri" />
                                                    <small className="text-red-600">
                                                        {errors.spouse_ktp_name?.message}
                                                    </small>
                                                </>
                                            )}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label name="Nomor HP Suami/Istri" />
                                        <Controller
                                            control={control}
                                            rules={{
                                                required: 'Data harus diisi',
                                                pattern: {
                                                    value: /^[0-9]*$/g,
                                                    message: 'Format nomor HP salah',
                                                },
                                            }}
                                            name="spouse_phone_number"
                                            render={({ field }) => (
                                                <div className="relative">
                                                    <ReactFlagsSelect
                                                        selected={'ID'}
                                                        className="absolute disabled:bg-[#F3F4F6]"
                                                        onSelect={(code) => console.log(code)}
                                                        countries={['ID']}
                                                        customLabels={{ ID: '+62', SG: '+65' }}
                                                    />

                                                    <Input
                                                        {...field}
                                                        pattern="\d*"
                                                        type="number"
                                                        className="pl-[120px] disabled:bg-[#F3F4F6]"
                                                        placeholder="Masukkan nomor HP suami/istri"
                                                        onInput={(e) => {
                                                            if (e.target.value.length > e.target.maxLength)
                                                                e.target.value = e.target.value.slice(
                                                                    0,
                                                                    e.target.maxLength
                                                                );
                                                        }}
                                                        maxLength={14}
                                                    />
                                                    <small className="text-red-600">
                                                        {errors.spouse_phone_number?.message}
                                                    </small>
                                                </div>
                                            )}
                                        />
                                    </FormGroup>

                                    <FormGroup className="relative">
                                        <Label name="Tanggal Lahir Suami/Istri" />

                                        <Controller
                                            control={control}
                                            rules={{
                                                required: 'Data harus diisi',
                                            }}
                                            name="spouse_dob"
                                            render={({ field: { onChange, value } }) => (
                                                <div className="relative">
                                                    <Flatpickr
                                                        onChange={onChange}
                                                        options={{
                                                            disableMobile: true,
                                                        }}
                                                        value={value}
                                                        placeholder="Pilih tanggal lahir suami/istri"
                                                        className="w-full cursor-pointer border rounded-lg h-11 p-3 focus:outline-primary"
                                                    />
                                                    <Calendar className="absolute top-[8px] right-5" />
                                                    <small className="text-red-600">
                                                        {errors.spouse_dob?.message}
                                                    </small>
                                                </div>
                                            )}
                                        />
                                    </FormGroup>
                                </>
                            )
                        }
                        <h3 className="font-semibold mb-2 mt-4">Alamat Tempat Tinggal</h3>
                        <div className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                className="checked:bg-blue-500 mr-2"
                                checked={isSameAddress}
                                onChange={() => {
                                    setIsSameAddress(!isSameAddress);
                                    handleResetForm();
                                }}
                            />
                            <span className="text-xs">
                                Alamat tempat tinggal sama dengan KTP
                            </span>
                        </div>
                        <div
                            className={`flex mb-4 flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
                            onClick={() => {
                                setActivePage('pinpoint');
                                setActiveForm('domicile');
                            }}
                        >
                            <div className="flex justify-center items-center">
                                <img src={Assets.iconMarkerOrange} className="mr-2" />
                                <span className="text-xs text-secondary">Ubah Peta Lokasi</span>
                            </div>
                        </div>
                        {mappingDropdown.map((dt, idx) => (
                            <FormGroup className="mb-5" key={idx}>
                                <Label name={dt.label} />
                                <Controller
                                    control={control}
                                    name={dt.name}
                                    rules={{ required: 'Data harus diisi' }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                className="text-sm"
                                                onChange={dt.onChange}
                                                options={dt.options}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id.toString()}
                                                placeholder={dt.placeholder}
                                                isSearchable={false}
                                                styles={dropdownStyles}
                                            />
                                            <small className="text-red-600">{dt.errors}</small>
                                        </>
                                    )}
                                />
                            </FormGroup>
                        ))}
                        <FormGroup className="mb-5">
                            <Label name="Detail Alamat" />
                            <Controller
                                control={control}
                                name="address"
                                rules={{ required: 'Data harus diisi' }}
                                render={({ field }) => (
                                    <>
                                        <textarea
                                            {...field}
                                            rows={3}
                                            className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                                            placeholder="Masukkan detail alamat"
                                        />
                                        <small className="text-red-600">
                                            {errors.address?.message}
                                        </small>
                                    </>
                                )}
                            />
                        </FormGroup>
                        <FormGroup className="mb-5">
                            <Label name="Kode Pos" />
                            <Controller
                                control={control}
                                name="postal_code"
                                rules={{ required: 'Data harus diisi' }}
                                render={({ field }) => (
                                    <>
                                        <input
                                            {...field}
                                            type="number"
                                            pattern="\d*"
                                            placeholder="Masukkan kode pos"
                                            className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                                        />
                                        <small className="text-red-600">
                                            {errors.postal_code?.message}
                                        </small>
                                    </>
                                )}
                            />
                        </FormGroup>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out disabled:bg-neutral-400 disabled:text-white"
                        >
                            Simpan dan Lanjut
                        </button>
                    </form>
                </>
            )}
            {contentController.showPopup && (
                <PopupGuide
                    handleClose={handleClickClosePopupGuide}
                    handleConfirm={handleConfirmPopupGuide}
                    contentType={contentController.contentType}
                />
            )}

            {contentController.showCamera && (
                <TakePhoto
                    handleConfirmPhoto={(src) => handleConfirmPhotoTaken(src, setValue)}
                    handleBack={handleBackToPopupGuide}
                    cameraProperty={cameraProperty}
                    cameraType={contentController.contentType}
                    onUploadPhotoTaken={uploadTakenImageFileEmployee}
                />
            )}
        </div>
    );
};

export default DataPelengkap;

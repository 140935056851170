import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DropdownMasterInformasiAlamat } from '../../../types/InformasiAlamatTypes';

export const getDropdownProvince = createAsyncThunk(
  'getDropdownProvince/fetchData',
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownCity = createAsyncThunk(
  'getDropdownCity/fetchData',
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownSubdistrict = createAsyncThunk(
  'getDropdownSubdistrict/fetchData',
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdownVillage = createAsyncThunk(
  'getDropdownVillage/fetchData',
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${queryParams}`
      );
      return response.data.data as DropdownMasterInformasiAlamat[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

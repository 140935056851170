import React from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { featureFlag } from '../../utils/helper';

type Props = {
  percentage: number;
  activeSession: string;
};

const flagOcrKkCycle = featureFlag('REACT_APP_OCR_KK_CYCLE');

const Header = ({ percentage, activeSession }: Props) => {
  const { uuid } = useParams();

  const navigate = useNavigate();

  let scroll = 0;

  const sections = [
    { name: (flagOcrKkCycle ? 'Foto & Hasil KTP' : 'Informasi Pribadi'), slug: 'informasi_pribadi' },
    { name: (flagOcrKkCycle ? 'Data Pelengkap' : 'Foto Identitas'), slug: 'foto_identitas' },
    { name: 'Alamat', slug: 'alamat' },
    { name: 'Informasi Rekening', slug: 'informasi_rekening' },
    { name: 'Informasi Usaha', slug: 'informasi_usaha' },
    { name: 'Alamat Usaha', slug: 'alamat_usaha' },
    { name: 'Dokumen Pendukung', slug: 'dokumen_pendukung' },
    { name: 'Limit Pembiayaan', slug: 'limit_pembiayaan' },
  ];

  switch (activeSession) {
    case 'informasi_pribadi':
      scroll = 120;
      break;
    case 'foto_identitas':
      scroll = 35;
      break;
    case 'alamat':
      scroll = 160;
      break;
    case 'informasi_rekening':
      scroll = 290;
      break;
    case 'informasi_usaha':
      scroll = 450;
      break;
    case 'alamat_usaha':
      scroll = 600;
      break;
    case 'dokumen_pendukung':
      scroll = 760;
      break;
    case 'limit_pembiayaan':
      scroll = 860;
      break;
    default:
      break;
  }

  const handleBack = () => {
    switch (activeSession) {
      case 'informasi_pribadi':
        navigate(`/dealer/homepage/${uuid}`);
        break;
      case 'foto_identitas':
        navigate(`/dealer/registration/${uuid}/informasi_pribadi`);
        break;
      case 'alamat':
        navigate(`/dealer/registration/${uuid}/foto_identitas`);
        break;
      case 'informasi_rekening':
        navigate(`/dealer/registration/${uuid}/alamat`);
        break;
      case 'informasi_usaha':
        if (featureFlag('REACT_APP_SPRINT_8_1_ONBOARDING_IMPROVEMENT')) {
          navigate(`/dealer/registration/${uuid}/informasi_rekening`);
        } else {
          navigate(`/dealer/homepage-usaha/${uuid}`);
        }
        break;
      case 'alamat_usaha':
        navigate(`/dealer/registration/${uuid}/informasi_usaha`);
        break;
      case 'dokumen_pendukung':
        navigate(`/dealer/registration/${uuid}/alamat_usaha`);
        break;
      case 'limit_pembiayaan':
        navigate(`/dealer/registration/${uuid}/dokumen_pendukung`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="h-[106px] bg-primary">
      <div className="flex items-center justify-around text-white pt-6 mb-6">
        <ChevronLeft
          onClick={handleBack}
          width={24}
          height={24}
          className="cursor-pointer"
        />
        <div className="shadow w-[140px] bg-white/50 rounded-[99px] ">
          <div
            style={{ width: `${percentage}%` }}
            className={`bg-[#E6EAF8] text-xs leading-none py-1 text-center rounded-[99px] text-white `}
          ></div>
        </div>
        <span className="text-xs">{percentage}% Selesai</span>
      </div>

      <div
        style={
          activeSession !== 'informasi_pribadi'
            ? { marginLeft: -scroll }
            : { marginLeft: scroll }
        }
        className="flex items-center justify-start mx-[10px] overflow-hidden whitespace-nowrap"
      >
        {sections.map((section, index) => (
          <>
            <div
              key={section.slug}
              className="flex justify-center items-center"
            >
              <div
                className={`rounded-full flex items-center justify-center ${
                  section.slug === activeSession ? 'bg-white' : 'bg-white/50'
                } text-primary w-[18px] h-[18px] text-xs`}
              >
                {index + 1}
              </div>
              <span
                className={`font-bold ${
                  section.slug === activeSession
                    ? 'text-white'
                    : 'text-white/50'
                } ml-2 text-xs `}
              >
                {section.name}
              </span>
            </div>
            <span className="text-white mx-2 last:hidden">---</span>
          </>
        ))}
      </div>
    </div>
  );
};

export default Header;

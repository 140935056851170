import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DropdownBank } from '../../../types/InformasiRekening';

export const getDropdownBank = createAsyncThunk(
  'getDropdownBank/fetchData',
  async (request: undefined, { rejectWithValue }) => {
    try {
      let serviceName;
      let signatureKey;

      if (process.env.REACT_APP_ENV === 'production') {
        signatureKey = '$2a$10$UpDdpRPk1hJRljQshuaJy.tbTDE015uzl/H6HogDm2VwqysXT0USq';
        serviceName = 'defi-user-service';
      } else {
        signatureKey = '$2a$10$A7K4bBe/zlbB2Td.EFgb6uvSUkS0ZOSdWcNFAN6QWW3AkMMp0YNl6';
        serviceName = 'internal-payment';
      }

      axios.defaults.headers.common['X-Service-Name'] = serviceName;
      axios.defaults.headers.common['X-Request-Signature'] = signatureKey;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_PAYMENT_INVOICE_GATEWAY}/v3/account/banks`,
      );
      return response.data.data as DropdownBank;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

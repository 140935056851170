import { RootState } from '../../store';
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import {
  getDropdownProvince,
  getDropdownCity,
  getDropdownSubdistrict,
  getDropdownVillage,
} from './alamatUsahaThunk';
import { DropdownMasterInformasiAlamat } from '../../../types/InformasiAlamatTypes';

export const dropdownSlice = createSlice({
  name: 'getDropdownProvinceSlice',
  initialState: {
    dropdownProvince: [] as DropdownMasterInformasiAlamat[],
    dropdownProvinceLoading: false,
    dropdownCity: [] as DropdownMasterInformasiAlamat[],
    dropdownCityLoading: false,
    dropdownSubdistrict: [] as DropdownMasterInformasiAlamat[],
    dropdownSubdistrictLoading: false,
    dropdownVillage: [] as DropdownMasterInformasiAlamat[],
    dropdownVillageLoading: false,
  },
  reducers: {
    resetNextDropdown: (state, action) => {
      const data = action.payload;
      switch (data) {
        case 'all':
          state.dropdownCity = [];
          state.dropdownSubdistrict = [];
          state.dropdownVillage = [];
          break;
        case 'province':
          state.dropdownSubdistrict = [];
          state.dropdownVillage = [];
          break;
        case 'city':
          state.dropdownVillage = [];
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDropdownProvince.pending, (state) => {
        state.dropdownProvinceLoading = true;
      })
      .addCase(getDropdownProvince.fulfilled, (state, action) => {
        state.dropdownProvinceLoading = false;
        state.dropdownProvince = action.payload;
      })
      .addCase(getDropdownCity.pending, (state) => {
        state.dropdownCityLoading = true;
      })
      .addCase(getDropdownCity.fulfilled, (state, action) => {
        state.dropdownCityLoading = false;
        state.dropdownCity = action.payload;
      })
      .addCase(getDropdownSubdistrict.pending, (state) => {
        state.dropdownSubdistrictLoading = true;
      })
      .addCase(getDropdownSubdistrict.fulfilled, (state, action) => {
        state.dropdownSubdistrictLoading = false;
        state.dropdownSubdistrict = action.payload;
      })
      .addCase(getDropdownVillage.pending, (state) => {
        state.dropdownVillageLoading = true;
      })
      .addCase(getDropdownVillage.fulfilled, (state, action) => {
        state.dropdownVillageLoading = false;
        state.dropdownVillage = action.payload;
      });
  },
});

export const { resetNextDropdown } = dropdownSlice.actions;

export const reducer = combineReducers({
  dropdownLocation: dropdownSlice.reducer,
});

export default reducer;

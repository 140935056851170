import React, { useEffect, useState } from 'react';
import * as Assets from '../../../utils/assets';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  getDropdownProvinceAddress,
  getDropdownCityAddress,
  getDropdownSubdistrictAddress,
  getDropdownVillageAddress,
} from '../../../redux/slices/informasiAlamat/informasiAlamatThunk';
import { resetNextDropdownAddress } from '../../../redux/slices/informasiAlamat/informasiAlamatSlice';
import FormGroup from '../../atom/FormGroup';
import Select from 'react-select';
import Label from '../../atom/Label';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  FormDataInformasiAlamat,
  SelectedAddressType,
} from '../../../types/InformasiAlamatTypes';
import {
  InformasiAlamatActiveDisplay as PageType,
  ADDRESS_LOCATION_DROPDOWN as Location,
} from '../../../utils/constants';
import PinpointLocation from '../../InformasiAlamat/PinpointLocation';
import { TemporaryStateUsahaType } from '../../../types/AlamatUsahaTypes';
import { getLocationByLatLong } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { saveDraft } from '../../../redux/slices/employee/employeeThunk';
import { toast } from 'react-toastify';

const InformasiAlamat = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const { data: employee, loading: isLoading } = useAppSelector(
    (state) => state.employee.employee
  );

  const dropdownProvince = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownProvince
  );
  const dropdownCity = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownCity
  );
  const dropdownSubdistrict = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownSubdistrict
  );
  const dropdownVillage = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownVillage
  );
  const dropdownProvinceDom = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownProvinceDom
  );
  const dropdownCityDom = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownCityDom
  );
  const dropdownSubdistrictDom = useAppSelector(
    (state) =>
      state.informasiAlamat.dropdownLocationAddress.dropdownSubdistrictDom
  );
  const dropdownVillageDom = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownVillageDom
  );

  const [isSameAddress, setIsSameAddress] = useState(true);
  const [activeForm, setActiveForm] = useState<'ktp' | 'domicile'>('ktp');
  const [activePage, setActivePage] = useState<'form' | 'pinpoint'>('form');
  const [temporaryState, setTemporaryState] = useState<TemporaryStateUsahaType>(
    { lat: '', lng: '' }
  );
  const [triggerMap, setTriggerMap] = useState(false);

  const dropdownStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state?.isFocused ? '#0F2CAD' : 'default',
    }),
  };

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormDataInformasiAlamat>();

  const handleChangeProvince = (event: any, type: string) => {
    const dataProvince = type === 'ktp' ? 'province' : 'province_domicile';
    const dataCity = type === 'ktp' ? 'city' : 'city_domicile';
    const dataSubdistrict =
      type === 'ktp' ? 'subdistrict' : 'subdistrict_domicile';
    const dataVillage = type === 'ktp' ? 'village' : 'village_domicile';
    const dataAddress = type === 'ktp' ? 'address' : 'address_domicile';
    const dataPostalCode =
      type === 'ktp' ? 'postal_code' : 'postal_code_domicile';
    const dataLatitude = type === 'ktp' ? 'latitude' : 'latitude_domicile';
    const dataLongitude = type === 'ktp' ? 'longitude' : 'longitude_domicile';
    setValue(dataProvince, event);

    //Trigger Next Dropdown
    const paramsGetDropdownCity = {
      queryParams: `levels=3&parent_ids=${event?.id}`,
      type: type === 'ktp' ? 'ktp' : 'domicile',
    };
    dispatch(getDropdownCityAddress(paramsGetDropdownCity));

    //Reset Next Dropdown Value
    const payloadResetDropdown = {
      location: 'province',
      type: type === 'ktp' ? 'ktp' : 'domicile',
    };
    dispatch(resetNextDropdownAddress(payloadResetDropdown));

    setValue(dataCity, null as any);
    setValue(dataSubdistrict, null as any);
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const handleChangeCity = (event: any, type: string) => {
    const dataCity = type === 'ktp' ? 'city' : 'city_domicile';
    const dataSubdistrict =
      type === 'ktp' ? 'subdistrict' : 'subdistrict_domicile';
    const dataVillage = type === 'ktp' ? 'village' : 'village_domicile';
    const dataAddress = type === 'ktp' ? 'address' : 'address_domicile';
    const dataPostalCode =
      type === 'ktp' ? 'postal_code' : 'postal_code_domicile';
    const dataLatitude = type === 'ktp' ? 'latitude' : 'latitude_domicile';
    const dataLongitude = type === 'ktp' ? 'longitude' : 'longitude_domicile';
    setValue(dataCity, event);

    //Trigger Next Dropdown
    const paramsGetDropdownSubdistrict = {
      queryParams: `levels=4&parent_ids=${event?.id}`,
      type: type === 'ktp' ? 'ktp' : 'domicile',
    };
    dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

    //Reset Next Dropdown Value
    const payloadResetDropdown = {
      location: 'city',
      type: type === 'ktp' ? 'ktp' : 'domicile',
    };
    dispatch(resetNextDropdownAddress(payloadResetDropdown));
    setValue(dataSubdistrict, null as any);
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const handleChangeSubdistrict = (event: any, type: string) => {
    const dataSubdistrict =
      type === 'ktp' ? 'subdistrict' : 'subdistrict_domicile';
    const dataVillage = type === 'ktp' ? 'village' : 'village_domicile';
    const dataAddress = type === 'ktp' ? 'address' : 'address_domicile';
    const dataPostalCode =
      type === 'ktp' ? 'postal_code' : 'postal_code_domicile';
    const dataLatitude = type === 'ktp' ? 'latitude' : 'latitude_domicile';
    const dataLongitude = type === 'ktp' ? 'longitude' : 'longitude_domicile';
    setValue(dataSubdistrict, event);

    //Trigger Next Dropdown
    const paramsGetDropdownVillage = {
      queryParams: `levels=5&parent_ids=${event?.id}`,
      type: type === 'ktp' ? 'ktp' : 'domicile',
    };
    dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));

    //Reset Next Dropdown Value
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const handleChangeVillage = (event: any, type: string) => {
    const dataVillage = type === 'ktp' ? 'village' : 'village_domicile';
    const dataAddress = type === 'ktp' ? 'address' : 'address_domicile';
    const dataPostalCode =
      type === 'ktp' ? 'postal_code' : 'postal_code_domicile';
    const dataLatitude = type === 'ktp' ? 'latitude' : 'latitude_domicile';
    const dataLongitude = type === 'ktp' ? 'longitude' : 'longitude_domicile';
    setValue(dataVillage, event);

    //Reset Next Form Value
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
    setValue(dataLatitude, '');
    setValue(dataLongitude, '');
  };

  const mappingDropdown = [
    {
      label: 'Provinsi',
      name: 'province' as any,
      options: dropdownProvince,
      placeholder: 'Pilih provinsi',
      onChange: (e: any) => handleChangeProvince(e, 'ktp'),
      errors: errors.province?.message,
    },
    {
      label: 'Kabupaten/Kota',
      name: 'city' as string,
      options: dropdownCity,
      placeholder: 'Pilih kabupaten/kota',
      onChange: (e: any) => handleChangeCity(e, 'ktp'),
      errors: errors.city?.message,
    },
    {
      label: 'Kecamatan',
      name: 'subdistrict' as string,
      options: dropdownSubdistrict,
      placeholder: 'Pilih kecamatan',
      onChange: (e: any) => handleChangeSubdistrict(e, 'ktp'),
      errors: errors.subdistrict?.message,
    },
    {
      label: 'Kelurahan',
      name: 'village' as any,
      options: dropdownVillage,
      placeholder: 'Pilih kelurahan',
      onChange: (e: any) => handleChangeVillage(e, 'ktp'),
      errors: errors.village?.message,
    },
  ];

  const mappingDropdownDomicile = [
    {
      label: 'Provinsi',
      name: 'province_domicile' as any,
      options: dropdownProvinceDom,
      placeholder: 'Pilih provinsi',
      onChange: (e: any) => handleChangeProvince(e, 'dom'),
      errors: errors.province_domicile?.message,
    },
    {
      label: 'Kabupaten/Kota',
      name: 'city_domicile' as string,
      options: dropdownCityDom,
      placeholder: 'Pilih kabupaten/kota',
      onChange: (e: any) => handleChangeCity(e, 'dom'),
      errors: errors.city_domicile?.message,
    },
    {
      label: 'Kecamatan',
      name: 'subdistrict_domicile' as string,
      options: dropdownSubdistrictDom,
      placeholder: 'Pilih kecamatan',
      onChange: (e: any) => handleChangeSubdistrict(e, 'dom'),
      errors: errors.subdistrict_domicile?.message,
    },
    {
      label: 'Kelurahan',
      name: 'village_domicile' as any,
      options: dropdownVillageDom,
      placeholder: 'Pilih kelurahan',
      onChange: (e: any) => handleChangeVillage(e, 'dom'),
      errors: errors.village_domicile?.message,
    },
  ];

  const mappingHiddenForm = [
    { name: 'longitude' as any },
    { name: 'latitude' as any },
  ];

  const mappingHiddenFormDomicile = [
    { name: 'longitude_domicile' as any },
    { name: 'latitude_domicile' as any },
  ];

  const onSubmit: SubmitHandler<FormDataInformasiAlamat> = async (data) => {
    const payloadKtp = {
      address: data?.address,
      province_id: data?.province?.id,
      province_name: data?.province?.name,
      city_id: data?.city?.id,
      city_name: data?.city?.name,
      subdistrict_id: data?.subdistrict?.id,
      subdistrict_name: data?.subdistrict?.name,
      village_id: data?.village?.id,
      village_name: data?.village?.name,
      postal_code: data?.postal_code,
      latitude: data?.latitude,
      longitude: data?.longitude,
    };
    const payloadDomicile = {
      address: data?.address_domicile,
      province_id: data?.province_domicile?.id,
      province_name: data?.province_domicile?.name,
      city_id: data?.city_domicile?.id,
      city_name: data?.city_domicile?.name,
      subdistrict_id: data?.subdistrict_domicile?.id,
      subdistrict_name: data?.subdistrict_domicile?.name,
      village_id: data?.village_domicile?.id,
      village_name: data?.village_domicile?.name,
      postal_code: data?.postal_code_domicile,
      latitude: data?.latitude_domicile,
      longitude: data?.longitude_domicile,
    };

    const payloadSaveDraft = {
      alamat: {
        alamat_ktp: payloadKtp,
        alamat_domisili: isSameAddress ? payloadKtp : payloadDomicile,
      },
    };

    const res = await dispatch(
      saveDraft({ data: payloadSaveDraft, uuid: uuid as string })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/employee/registration/${uuid}/keluarga`);
    } else {
      const errMessage = (res.payload as any)?.errors[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const getLocation = (params: SelectedAddressType) => {
    setTriggerMap(true);
    const paramsGetDropdown = {
      queryParams: `excluded_ids=1&nested=child&latlng=${params.lat},${params.lng}`,
    };
    getLocationByLatLong(paramsGetDropdown)
      .then((res) => {
        const data = res.data.data;
        const dataProvince = data;
        const dataCity = data?.child;
        const dataSubdistrict = data?.child?.child;
        const dataVillage = data?.child?.child?.child;

        const fieldProvince =
          activeForm === 'ktp' ? 'province' : 'province_domicile';
        const fieldCity = activeForm === 'ktp' ? 'city' : 'city_domicile';
        const fieldSubdistrict =
          activeForm === 'ktp' ? 'subdistrict' : 'subdistrict_domicile';
        const fieldVillage =
          activeForm === 'ktp' ? 'village' : 'village_domicile';

        setValue(fieldProvince, dataProvince);
        setValue(fieldCity, dataCity);
        setValue(fieldSubdistrict, dataSubdistrict);
        setValue(fieldVillage, dataVillage);

        const paramsGetDropdownCity = {
          queryParams: `levels=3&parent_ids=${dataProvince?.id}`,
          type: activeForm === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCity));

        const paramsGetDropdownSubdistrict = {
          queryParams: `levels=4&parent_ids=${dataCity?.id}`,
          type: activeForm === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

        const paramsGetDropdownVillage = {
          queryParams: `levels=5&parent_ids=${dataSubdistrict?.id}`,
          type: activeForm === 'ktp' ? 'ktp' : 'domicile',
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));
        setTimeout(() => {
          setTriggerMap(false);
        }, 2000);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleSaveMaps = (params: SelectedAddressType) => {
    const longitude = activeForm === 'ktp' ? 'longitude' : 'longitude_domicile';
    const latitude = activeForm === 'ktp' ? 'latitude' : 'latitude_domicile';
    const postal_code =
      activeForm === 'ktp' ? 'postal_code' : 'postal_code_domicile';
    const address = activeForm === 'ktp' ? 'address' : 'address_domicile';
    setValue(longitude, params.lng);
    setValue(latitude, params.lat);
    setValue(postal_code, params.postalCode);
    setValue(address, params.fullAddress);
    getLocation(params);
  };

  const handleResetForm = () => {
    if (isSameAddress) {
      setValue('province_domicile', null as any);
      setValue('city_domicile', null as any);
      setValue('subdistrict_domicile', null as any);
      setValue('village_domicile', null as any);
      setValue('address_domicile', '');
      setValue('postal_code_domicile', '');
      setValue('longitude_domicile', '');
      setValue('latitude_domicile', '');

      const payloadResetDropdown = {
        location: 'all',
        type: activeForm === 'ktp' ? 'ktp' : 'domicile',
      };
      dispatch(resetNextDropdownAddress(payloadResetDropdown));
    }
  };

  const checkIsSameAddress = () => {
    const dataEmployeeKtp = employee?.alamat?.alamat_ktp;
    const dataEmployeeDomicile = employee?.alamat?.alamat_domisili;

    const checkDataEmployeeKtp = Object.assign({}, dataEmployeeKtp) as any;
    delete checkDataEmployeeKtp.id;
    delete checkDataEmployeeKtp.type;

    const checkDataEmployeeDomicile = Object.assign(
      {},
      dataEmployeeDomicile
    ) as any;
    delete checkDataEmployeeDomicile.id;
    delete checkDataEmployeeDomicile.type;

    if (
      JSON.stringify(checkDataEmployeeKtp) ===
      JSON.stringify(checkDataEmployeeDomicile)
    ) {
      setIsSameAddress(true);
    } else {
      setIsSameAddress(false);
    }
  };

  useEffect(() => {
    const dataAlamatKtp = employee?.alamat?.alamat_ktp;
    const dataAlamatDomisili = employee?.alamat?.alamat_domisili;
    checkIsSameAddress();
    if (localStorage.getItem('token')) {
      dispatch(getDropdownProvinceAddress('levels=2'));
      if (dataAlamatKtp?.id) {
        const paramsGetDropdownCityKtp = {
          queryParams: `levels=3&parent_ids=${dataAlamatKtp?.province_id}`,
          type: 'ktp',
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCityKtp));

        const paramsGetDropdownSubdistrictKtp = {
          queryParams: `levels=4&parent_ids=${dataAlamatKtp?.city_id}`,
          type: 'ktp',
        };
        dispatch(
          getDropdownSubdistrictAddress(paramsGetDropdownSubdistrictKtp)
        );

        const paramsGetDropdownVillageKtp = {
          queryParams: `levels=5&parent_ids=${dataAlamatKtp?.subdistrict_id}`,
          type: 'ktp',
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillageKtp));

        setValue('latitude', dataAlamatKtp?.latitude);
        setValue('longitude', dataAlamatKtp?.longitude);
        setValue('address', dataAlamatKtp?.address);
        setValue('postal_code', dataAlamatKtp?.postal_code);
      }

      if (dataAlamatDomisili?.id) {
        const paramsGetDropdownCityDomicile = {
          queryParams: `levels=3&parent_ids=${dataAlamatDomisili?.province_id}`,
          type: 'domicile',
        };
        dispatch(getDropdownCityAddress(paramsGetDropdownCityDomicile));

        const paramsGetDropdownSubdistrictDomicile = {
          queryParams: `levels=4&parent_ids=${dataAlamatDomisili?.city_id}`,
          type: 'domicile',
        };
        dispatch(
          getDropdownSubdistrictAddress(paramsGetDropdownSubdistrictDomicile)
        );

        const paramsGetDropdownVillageDomicile = {
          queryParams: `levels=5&parent_ids=${dataAlamatDomisili?.subdistrict_id}`,
          type: 'domicile',
        };
        dispatch(getDropdownVillageAddress(paramsGetDropdownVillageDomicile));

        setValue('latitude_domicile', dataAlamatDomisili?.latitude);
        setValue('longitude_domicile', dataAlamatDomisili?.longitude);
        setValue('address_domicile', dataAlamatDomisili?.address);
        setValue('postal_code_domicile', dataAlamatDomisili?.postal_code);
      }
    }
  }, [employee]);

  useEffect(() => {
    const dataAlamatKtp = employee?.alamat?.alamat_ktp;
    if (localStorage.getItem('token')) {
      if (dataAlamatKtp?.id && dropdownProvince.length > 0 && !triggerMap) {
        const dropdownProvinceKtp = dropdownProvince.filter(
          (a: any) => a.id === dataAlamatKtp?.province_id
        );
        setValue('province', dropdownProvinceKtp[0]);
      }
    }
  }, [dropdownProvince]);

  useEffect(() => {
    const dataAlamatDomisili = employee?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatDomisili?.id &&
        dropdownProvinceDom.length > 0 &&
        !triggerMap
      ) {
        const dropdownProvinceDomisili = dropdownProvinceDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.province_id
        );
        setValue('province_domicile', dropdownProvinceDomisili[0]);
      }
    }
  }, [dropdownProvinceDom]);

  useEffect(() => {
    const dataAlamatKtp = employee?.alamat?.alamat_ktp;
    if (localStorage.getItem('token')) {
      if (dataAlamatKtp?.id && dropdownCity.length > 0 && !triggerMap) {
        const dropdownCityKtp = dropdownCity.filter(
          (a: any) => a.id === dataAlamatKtp?.city_id
        );
        setValue('city', dropdownCityKtp[0]);
      }
    }
  }, [dropdownCity]);

  useEffect(() => {
    const dataAlamatDomisili = employee?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (dataAlamatDomisili?.id && dropdownCityDom.length > 0 && !triggerMap) {
        const dropdownCityDomisili = dropdownCityDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.city_id
        );
        setValue('city_domicile', dropdownCityDomisili[0]);
      }
    }
  }, [dropdownCityDom]);

  useEffect(() => {
    const dataAlamatKtp = employee?.alamat?.alamat_ktp;
    if (localStorage.getItem('token')) {
      if (dataAlamatKtp?.id && dropdownSubdistrict.length > 0 && !triggerMap) {
        const dropdownSubdistrictKtp = dropdownSubdistrict.filter(
          (a: any) => a.id === dataAlamatKtp?.subdistrict_id
        );
        setValue('subdistrict', dropdownSubdistrictKtp[0]);
      }
    }
  }, [dropdownSubdistrict]);

  useEffect(() => {
    const dataAlamatDomisili = employee?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatDomisili?.id &&
        dropdownSubdistrictDom.length > 0 &&
        !triggerMap
      ) {
        const dropdownSubdistrictDomisili = dropdownSubdistrictDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.subdistrict_id
        );
        setValue('subdistrict_domicile', dropdownSubdistrictDomisili[0]);
      }
    }
  }, [dropdownSubdistrictDom]);

  useEffect(() => {
    const dataAlamatKtp = employee?.alamat?.alamat_ktp;
    if (localStorage.getItem('token')) {
      if (dataAlamatKtp?.id && dropdownVillage.length > 0 && !triggerMap) {
        const dropdownVillageKtp = dropdownVillage.filter(
          (a: any) => a.id === dataAlamatKtp?.village_id
        );
        setValue('village', dropdownVillageKtp[0]);
      }
    }
  }, [dropdownVillage]);

  useEffect(() => {
    const dataAlamatDomisili = employee?.alamat?.alamat_domisili;
    if (localStorage.getItem('token')) {
      if (
        dataAlamatDomisili?.id &&
        dropdownVillageDom.length > 0 &&
        !triggerMap
      ) {
        const dropdownVillageDomisili = dropdownVillageDom.filter(
          (a: any) => a.id === dataAlamatDomisili?.village_id
        );
        setValue('village_domicile', dropdownVillageDomisili[0]);
      }
    }
  }, [dropdownVillageDom]);

  const params = {
    activePage,
    setActivePage,
    handleSaveMaps,
    temporaryState,
    setTemporaryState,
  };

  return (
    <div className="flex flex-1 flex-col">
      {activePage === PageType.PINPOINT_LOCATION ? (
        <PinpointLocation {...params} />
      ) : (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-1 flex-col"
          >
            <h3 className="font-semibold mb-4">Alamat Sesuai KTP</h3>
            <div
              className={`flex flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
              onClick={() => {
                setActivePage('pinpoint');
                setActiveForm('ktp');
                setTemporaryState({
                  lat: getValues('latitude'),
                  lng: getValues('longitude'),
                });
              }}
            >
              <div className="flex justify-center items-center">
                <img src={Assets.iconMarkerOrange} className="mr-2" />
                <span className="text-xs text-secondary">
                  {watch('longitude') ? 'Ubah' : 'Atur'} Peta Lokasi
                </span>
              </div>
            </div>
            <small className="text-red-600 mb-5">
              {errors.longitude?.message || errors.latitude?.message}
            </small>
            {mappingDropdown.map((dt, idx) => (
              <FormGroup className="mb-5" key={idx}>
                <Label name={dt.label} />
                <Controller
                  control={control}
                  name={dt.name}
                  rules={{ required: 'Data harus diisi' }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        className="text-sm"
                        onChange={dt.onChange}
                        options={dt.options}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id.toString()}
                        placeholder={dt.placeholder}
                        isSearchable={false}
                        styles={dropdownStyles}
                      />
                      <small className="text-red-600">{dt.errors}</small>
                    </>
                  )}
                />
              </FormGroup>
            ))}
            <FormGroup className="mb-5">
              <Label name="Detail Alamat" />
              <Controller
                control={control}
                name="address"
                rules={{ required: 'Data harus diisi' }}
                render={({ field }) => (
                  <>
                    <textarea
                      {...field}
                      rows={3}
                      className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                      placeholder="Masukkan detail alamat"
                    />
                    <small className="text-red-600">
                      {errors.address?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>
            <FormGroup className="mb-5">
              <Label name="Kode Pos" />
              <Controller
                control={control}
                name="postal_code"
                rules={{ required: 'Data harus diisi' }}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      type="number"
                      pattern="\d*"
                      placeholder="Masukkan kode pos"
                      className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                    />
                    <small className="text-red-600">
                      {errors.postal_code?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>
            {mappingHiddenForm.map((a, index) => (
              <Controller
                key={index}
                control={control}
                name={a.name}
                rules={{ required: 'Data harus diisi' }}
                render={({ field }) => <input {...field} className="hidden" />}
              />
            ))}
            <h3 className="font-semibold mb-2 mt-4">Alamat Tempat Tinggal</h3>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                className="checked:bg-blue-500 mr-2"
                checked={isSameAddress}
                onChange={() => {
                  setIsSameAddress(!isSameAddress);
                  handleResetForm();
                }}
              />
              <span className="text-xs">
                Alamat tempat tinggal sama dengan KTP
              </span>
            </div>
            {!isSameAddress && (
              <>
                <div
                  className={`flex flex-row items-center justify-center cursor-pointer bg-white font-semibold rounded-lg h-11 w-full text-white border-2 border-orange-600 mb-1`}
                  onClick={() => {
                    setActivePage('pinpoint');
                    setActiveForm('domicile');
                    setTemporaryState({
                      lat: getValues('latitude_domicile'),
                      lng: getValues('longitude_domicile'),
                    });
                  }}
                >
                  <div className="flex justify-center items-center">
                    <img src={Assets.iconMarkerOrange} className="mr-2" />
                    <span className="text-xs text-secondary">
                      {watch('longitude_domicile') ? 'Ubah' : 'Atur'} Peta
                      Lokasi
                    </span>
                  </div>
                </div>
                <small className="text-red-600 mb-5">
                  {errors.longitude_domicile?.message ||
                    errors.latitude_domicile?.message}
                </small>
                {mappingDropdownDomicile.map((dt, idx) => (
                  <FormGroup className="mb-5" key={idx}>
                    <Label name={dt.label} />
                    <Controller
                      control={control}
                      name={dt.name}
                      rules={{ required: 'Data harus diisi' }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            className="text-sm"
                            onChange={dt.onChange}
                            options={dt.options}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id.toString()}
                            placeholder={dt.placeholder}
                            isSearchable={false}
                            styles={dropdownStyles}
                          />
                          <small className="text-red-600">{dt.errors}</small>
                        </>
                      )}
                    />
                  </FormGroup>
                ))}
                <FormGroup className="mb-5">
                  <Label name="Detail Alamat" />
                  <Controller
                    control={control}
                    name="address_domicile"
                    rules={{ required: 'Data harus diisi' }}
                    render={({ field }) => (
                      <>
                        <textarea
                          {...field}
                          rows={3}
                          className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                          placeholder="Masukkan detail alamat"
                        />
                        <small className="text-red-600">
                          {errors.address_domicile?.message}
                        </small>
                      </>
                    )}
                  />
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label name="Kode Pos" />
                  <Controller
                    control={control}
                    name="postal_code_domicile"
                    rules={{ required: 'Data harus diisi' }}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          type="number"
                          pattern="\d*"
                          placeholder="Masukkan kode pos"
                          className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                        />
                        <small className="text-red-600">
                          {errors.postal_code_domicile?.message}
                        </small>
                      </>
                    )}
                  />
                </FormGroup>
                {mappingHiddenFormDomicile.map((a, index) => (
                  <Controller
                    key={index}
                    control={control}
                    name={a.name}
                    rules={{ required: 'Data harus diisi' }}
                    render={({ field }) => (
                      <input {...field} className="hidden" />
                    )}
                  />
                ))}
              </>
            )}
            <div className="flex flex-1 flex-col justify-end h-full">
              <button
                type="submit"
                disabled={isLoading}
                className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out font-semibold text-sm"
              >
                Simpan dan Lanjut
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default InformasiAlamat;

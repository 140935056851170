import { BrowseFileAcceptType } from '../types/commonTypes';

//SectionInformasiAlamat
export const InformasiAlamatActiveDisplay = {
  FORM: 'form',
  PINPOINT_LOCATION: 'pinpoint',
};

//BrowseFile props
export const BROWSE_FILE_TYPE = {
  IMAGE: 'image',
  PDF: 'pdf',
  FILE: 'file',
};
export const BROWSE_FILE_ACCEPT_TYPE: BrowseFileAcceptType = {
  image: {
    'image/jpg': ['.jpg', '.png', '.jpeg'],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
  file: {
    'image/jpg': ['.jpg', '.png', '.jpeg'],
    'application/pdf': ['.pdf'],
    'application/application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  },
};

export const BROWSE_FILE_VALID_TYPE = {
  [BROWSE_FILE_TYPE.IMAGE]: ['image/jpeg', 'image/png', 'image/jpg'],
  [BROWSE_FILE_TYPE.PDF]: ['application/pdf'],
  [BROWSE_FILE_TYPE.FILE]: [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
};

export const BROWSE_FILE_ERROR_MESSAGE = {
  [BROWSE_FILE_TYPE.IMAGE]: 'File harus berupa image (.jpeg, .jpg, .png)',
  [BROWSE_FILE_TYPE.PDF]: 'File harus berupa pdf (.pdf)',
  [BROWSE_FILE_TYPE.FILE]: 'File harus berupa file (.jpeg, .jpg, .png, .pdf, .docx)',
};

export const InformasiAlamatStateForm = {
  KTP: 'ktp',
  DOMICILE: 'domicile',
};

//Waiting Page
export const WaitingStatus = {
  WAITING_REGISTRATION_FORM: 'waiting-for-registration-form',
  WAITING_FOR_ACTIVE_CONSENT: 'waiting-for-active-consent',
  DOCUMENT_SUBMITTED: 'document-submitted',
  INTERNAL_VALIDATION_REJECTED: 'internal-validation-rejected',
  DUKCAPIL_VALIDATION_REJECTED: 'dukcapil-validation-rejected',
  DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT:
    'dukcapil-validation-rejected-first-attempt',
  DUKCAPIL_VALIDATION_REJECTED_SECOND_ATTEMPT:
    'dukcapil-validation-rejected-second-attempt',
  PRINCIPAL_APPROVED: 'principal-approved',
  REVIEW_DATA: 'review-data',
  BUSINESS_SURVEY: 'business-survey',
  VERIFIKASI_TELEPON: 'phone-verification',
  HOME_SURVEY: 'home-survey',
  PROSES_HOME_SURVEY: 'proses-survei-rumah',
  FINAL_REVIEW: 'final-review',
  MANAGER_APPROVAL: 'manager-approval',
  CONTRACT_SIGNING: 'contract-signing',
  WAITING_FOR_JOIN_DATE: 'waiting-for-join-date',
  ACTIVE: 'active',
  REJECTED: 'rejected',
};

//Waiting Page
export const EmployeeWaitingStatus = {
  WAITING_REGISTRATION_FORM: 'waiting-for-registration-form',
  WAITING_FOR_ACTIVE_CONSENT: 'waiting-for-active-consent',
  DOCUMENT_SUBMITTED: 'document-submitted',
  INTERNAL_VALIDATION_REJECTED: 'internal-validation-rejected',
  DUKCAPIL_VALIDATION_REJECTED: 'dukcapil-validation-rejected',
  DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT:
    'dukcapil-validation-rejected-first-attempt',
  DUKCAPIL_VALIDATION_REJECTED_SECOND_ATTEMPT:
    'dukcapil-validation-rejected-second-attempt',
  INITIAL_REVIEW: 'initial-review',
  REVIEW_DATA: 'review-data',
  BACKGROUND_CHECK: 'background-check',
  DATA_DIRI: 'data-diri',
  DATA_PELENGKAP: 'data-pelengkap',
  ALAMAT_KANDIDAT: 'alamat-kandidat',
  FINAL_REVIEW: 'final-review',
  CONTRACT_SIGNING: 'contract-signing',
  WAITING_FOR_JOIN_DATE: 'waiting-for-join-date',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
};

export const PAGE_SECTION_FORM = {
  INFORMASI_PRIBADI: 'informasi_pribadi',
  FOTO_IDENTITAS: 'foto_identitas',
  ALAMAT: 'alamat',
  INFORMASI_REKENING: 'informasi_rekening',
  INFORMASI_USAHA: 'informasi_usaha',
  ALAMAT_USAHA: 'alamat_usaha',
  DOKUMEN_PENDUKUNG: 'dokumen_pendukung',
  KELUARGA: 'keluarga',
  PEKERJAAN_SEBELUMNYA: 'pekerjaan_sebelumnya',
  CV: 'cv',
  FOTO_HASIL_KTP: 'foto_hasil_ktp_v2',
  FOTO_HASIL_KK: 'foto_hasil_kk_v2',
  DATA_PELENGKAP_ALAMAT: 'data_pelengkap_alamat_v2',
  PEKERJAAN_SEBELUMNYA_V2: 'pekerjaan_sebelumnya_v2',
  KELUARGA_V2: 'keluarga_v2',
  CVV2: 'cv_v2',
  TNC: 'tnc',
  MENUNGGU_PENGISIAN_DATA: 'menunggu_pengisian_data',
  LIMIT_PEMBIAYAAN: 'limit_pembiayaan',
};

export const ADDRESS_LOCATION_DROPDOWN = {
  PROVINCE: 'province',
  CITY: 'city',
  SUBDISTRICT: 'subdistrict',
  VILLAGE: 'village',
};

//AfterValidation Page
export const LatestStatus = {
  PENGISIAN_DATA: 'pengisian-data',
  VERIFIKASI_DATA: 'verifikasi-data',
  TANDA_TANGAN_PERJANJIAN: 'tanda-tangan-perjanjian',
  ACTIVE: 'aktif',
};

export const LIMIT_UPLOAD_FILE_SIZE = 2621440;

export const NAME_REGEX = /^[a-zA-Z ',.]+$/;

export const CHECKED_YES = 'yes';
export const CHECKED_NO = 'no';

export const MULTIPLE_UPLOAD_REVISION_FORM = {
  DOMICILE_CERTIFICATES: 'domicile_certificates_match',
  MULTIFINANCE: 'multifinance_match',
};
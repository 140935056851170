import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getDropdownBank} from './informasiRekeningThunk';

export const getDropdownBankSlice = createSlice({
  name: 'getDropdownBank',
  initialState: {
    data: [] as any,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDropdownBank.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDropdownBank.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.beneficiary_banks
      })
  }
})

export const selectData = (state: RootState) => state.dealer;

export const reducer = combineReducers({
  dropdownBank: getDropdownBankSlice.reducer
});

export default reducer;

import React, { useEffect, useState } from 'react';
import {
  iconChecklistGreen as iconActive,
  iconDocumentGray as iconInactive,
  iconDocumentBlue as iconOnprogress,
  iconBtnRefreshOrange as iconRefreshOrange,
  iconWhatsappWhite,
  imageAfterValidation,
} from '../../../utils/assets';
import Button from '../../atom/Button';
import WaitingDisplay from '../../atom/WaitingDisplay';
import { EmployeeWaitingStatus as STATUS } from '../../../utils/constants';
import { useAppSelector } from '../../../redux/hooks';

type Props = {
  handleRefreshStatus: () => void;
  handleResendAgreement: () => void;
  handleCallCS: () => void;
};

const AfterValidation = ({
  handleRefreshStatus,
  handleResendAgreement,
  handleCallCS,
}: Props) => {
  const dataEmployee = useAppSelector((state) => state.employee.employee.data);

  const [latestStatus, setLatestStatus] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  const statePengisianData = [
    { icon: iconOnprogress, title: 'Lengkapi Data' },
    { icon: iconInactive, title: 'Menunggu Review' },
    { icon: iconInactive, title: 'Tanda Tangan Perjanjian' },
    { icon: iconInactive, title: 'Pendaftaran Berhasil' },
  ];
  const stateVerifikasiData = [
    { icon: iconActive, title: 'Lengkapi Data' },
    { icon: iconOnprogress, title: 'Menunggu Review' },
    { icon: iconInactive, title: 'Tanda Tangan Perjanjian' },
    { icon: iconInactive, title: 'Pendaftaran Berhasil' },
  ];
  const stateTandaTanganPerjanjian = [
    { icon: iconActive, title: 'Lengkapi Data' },
    { icon: iconActive, title: 'Menunggu Review' },
    { icon: iconOnprogress, title: 'Tanda Tangan Perjanjian' },
    { icon: iconInactive, title: 'Pendaftaran Berhasil' },
  ];
  const stateActive = [
    { icon: iconActive, title: 'Lengkapi Data' },
    { icon: iconActive, title: 'Menunggu Review' },
    { icon: iconActive, title: 'Tanda Tangan Perjanjian' },
    { icon: iconActive, title: 'Pendaftaran Berhasil' },
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setLatestStatus(dataEmployee?.latest_status);
      setUserName(dataEmployee?.informasi_pribadi?.full_name);
    }
  }, [dataEmployee]);

  let mappingStatus = statePengisianData;
  switch (latestStatus) {
    case STATUS.WAITING_REGISTRATION_FORM:
      mappingStatus = statePengisianData;
      break;
    case STATUS.INITIAL_REVIEW:
    case STATUS.BACKGROUND_CHECK:
    case STATUS.FINAL_REVIEW:
      mappingStatus = stateVerifikasiData;
      break;
    case STATUS.CONTRACT_SIGNING:
    case STATUS.WAITING_FOR_JOIN_DATE:
      mappingStatus = stateTandaTanganPerjanjian;
      break;
    case STATUS.ACTIVE:
      mappingStatus = stateActive;
      break;
  }
  return (
    <WaitingDisplay
      img={imageAfterValidation}
      title="Data berhasil dikirim!"
      description="Selanjutnya tim kami akan melakukan verifikasi data"
      customFooter={true}
      marginBody="mb-[0px]"
    >
      <div className="p-4">
        <hr className="-mx-4" />
        <div className="text-center text-xs mt-2">
          Nama akun: <span className="font-semibold">{userName || '-'}</span>
        </div>
        <div className="border border-gray-100 rounded-lg drop-shadow-sm p-3 mt-3 mb-4">
          {mappingStatus.map((data, index, row) => (
            <div key={index}>
              <div className="flex flex-row items-center">
                <img
                  src={data.icon}
                  alt="image_status"
                  width="32px"
                  className="mr-2"
                />
                <span className="text-sm font-semibold">{data.title}</span>
              </div>
              {index + 1 !== row.length && <hr className="-mx-1 my-3" />}
            </div>
          ))}
        </div>

        {latestStatus === STATUS.CONTRACT_SIGNING && (
          <button
            className="font-semibold rounded-lg h-11 w-full text-white bg-secondary mb-3"
            onClick={() => handleResendAgreement()}
          >
            <div className="flex justify-center items-center">
              <span className="text-sm text-white">Kirim Ulang Perjanjian</span>
              <img src={iconWhatsappWhite} className="ml-2" />
            </div>
          </button>
        )}

        {latestStatus !== STATUS.ACTIVE && (
          <>
            <Button
              name="Perbarui Status"
              withIcon={true}
              icon={iconRefreshOrange}
              onClick={() => handleRefreshStatus()}
              customClassName={true}
              className="font-semibold rounded-lg h-11 w-full text-white border border-orange-500 mb-3"
            />
            <div className="text-xs text-center">
              Butuh bantuan?{' '}
              <span
                className="font-semibold text-blue-700"
                onClick={() => handleCallCS()}
              >
                Hubungi CS
              </span>
            </div>
          </>
        )}
      </div>
    </WaitingDisplay>
  );
};

export default AfterValidation;

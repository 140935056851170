import { combineReducers, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { resendWhatsappNotif } from './waitingPageThunk';

export const resendWhatsappNotifSlice = createSlice({
  name: 'dropdownMaster',
  initialState: {
    data: {} as any,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resendWhatsappNotif.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendWhatsappNotif.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
  },
});

export const selectData = (state: RootState) => state.dealer;

export const reducer = combineReducers({
  resendWANotif: resendWhatsappNotifSlice.reducer,
});

export default reducer;

import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  CameraProperty,
  VideoConstraints,
} from '../../types/FotoIdentitasTypes';
import CameraReact from './Camera';
import { X } from 'react-feather';

interface Props {
  handleConfirmPhoto: (srcUri: string) => void;
  handleBack: () => void;
  cameraProperty: CameraProperty;
  title: string;
  onUploadPhotoTaken: (data: FormData) => Promise<AxiosResponse>;
  previewDescription: string;
}

export default function TakePhotoGeneral({
  handleConfirmPhoto,
  handleBack,
  cameraProperty,
  title,
  onUploadPhotoTaken,
  previewDescription,
}: Props) {
  const [videoConstraints, setVideoConstraints] = useState<VideoConstraints>({
    facingMode: cameraProperty.facing_mode,
  });
  const [loadingUpload, setLoadingUpload] = useState(Boolean);
  const [url, setUrl] = useState<string | null>(null);
  const onHandleTakePhoto = (srcUri: string) => {
    setUrl(srcUri);
  };

  const handleRetakePicture = () => {
    setUrl('');
  };

  const onConfirmPhotoTaken = (url: string) => {
    const formData = new FormData();
    const base64String = url.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    // if (blob.size <= LIMIT_UPLOAD_FILE_SIZE) {
    formData.append('image', blob, `${title}.jpg`);
    setLoadingUpload(true);
    onUploadPhotoTaken(formData)
      .then((res) => {
        handleConfirmPhoto(res.data.data.url);
      })
      .catch((err) => {
        console.error(err);
        toast.error(`error upload photo: ${err?.data?.message}`);
      })
      .finally(() => {
        setLoadingUpload(false);
      });
    // } else {
    //   toast.error('Gambar terlalu besar (Max 2.5Mb)');
    // }
  };

  return (
    <div className="fixed h-full w-full top-0 left-0 z-10">
      {url ? (
        <Canvas
          title={`${title}`}
          imageSrc={url}
          handleRetakePicture={handleRetakePicture}
          onConfirmPhoto={onConfirmPhotoTaken}
          disabledButtonUpload={loadingUpload}
          description={previewDescription}
        />
      ) : (
        <CameraReact
          title={`${title}`}
          cameraOptions={videoConstraints}
          cameraType="general"
          handleTakePhoto={onHandleTakePhoto}
          handleBack={handleBack}
        />
      )}
    </div>
  );
}

const Canvas = (props: {
  title: string;
  imageSrc: string;
  handleRetakePicture: () => void;
  onConfirmPhoto: (srcUri: string) => void;
  disabledButtonUpload: boolean;
  description?: string;
}) => {
  return (
    <div className="z-0 absolute top-0 left-0 w-full h-full">
      <div className="w-full h-full">
        <div className="py-4 bg-black">
          <div className="flex items-center px-5 text-white">
            <X
              onClick={props.handleRetakePicture}
              width={24}
              height={24}
              className="cursor-pointer"
            />
            <div className="w-full">
              <p className="text-center">{props.title}</p>
            </div>
          </div>
        </div>
        <div className="absolute top-[56px] left-0 bottom-0 right-0 bg-white flex flex-col">
          <div className="flex-1 flex flex-col justify-center">
            <div className="h-[302px] rounded mt-[21px] px-[65px] mb-[31px]">
              <img
                src={props.imageSrc}
                className="w-full h-full object-cover object-center rounded"
              />
            </div>
            <div className="mx-4 text-center mb-[31px] text-xs text-neutral-600 font-normal">
              {props.description}
            </div>
          </div>
          <div className="flex flex-col">
            <button
              onClick={props.handleRetakePicture}
              disabled={props.disabledButtonUpload}
              className="mx-4 mb-5 bg-orange-100 font-bold rounded py-3 text-center text-orange-300 disabled:bg-neutral-400 disabled:text-white"
            >
              Foto Ulang
            </button>
            <button
              disabled={props.disabledButtonUpload}
              onClick={() => props.onConfirmPhoto(props.imageSrc)}
              className="mx-4 block mb-3 bg-orange-500 font-bold rounded py-3 text-center text-white disabled:bg-neutral-400"
            >
              <p className="!mb-0">Gunakan Foto Ini</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

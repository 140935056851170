import React, { useEffect, useState } from 'react';
import iconWarningRed from '../../assets/svg/iconWarningRed.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import {
  DukcapilRejectedMessageType,
  BaseDukcapilValidationRejectedType,
} from '../../types/WaitingPage';
import { toast } from 'react-toastify';

export const BaseDukcapilValidationRejected = (
  props: BaseDukcapilValidationRejectedType
) => {
  const dataEmployee = useAppSelector((state) => state.employee.employee.data);
  const isV2 = dataEmployee.last_section_id >= 8

  const {
    titleMessage = isV2 ? dataEmployee?.vida_error?.title_message : 'Ada kesalahan pada sistem kami',
    message = isV2 ? dataEmployee?.vida_error?.message : 'Coba kembali dalam beberapa saat lagi',
    buttonLabel = 'Coba lagi nanti',
    handleClickButton,
  } = props;
  return (
    <div className="flex justify-center items-center flex-col w-full h-screen justify-center items-center">
      <img src={iconWarningRed} className="ml-2" />
      <div className="text-base font-semibold mt-6 mb-2">{titleMessage}</div>
      <div className="text-sm text-gray-500">{message}</div>
      <button
        type="submit"
        disabled={isV2}
        className="font-semibold rounded-lg h-11 w-80 text-white bg-secondary mb-4 mt-8 disabled:bg-neutral-400 disabled:text-white"
      >
        <div className="flex justify-center items-center">
          <span className="text-sm text-white" onClick={handleClickButton}>
            {buttonLabel}
          </span>
        </div>
      </button>
    </div>
  );
};

export const DukcapilValidationRejectedFirstAttempt = () => {
  const dataEmployee = useAppSelector((state) => state.employee.employee.data);
  const isV2 = dataEmployee.last_section_id >= 8

  const navigate = useNavigate();
  const { uuid } = useParams();
  const handleClickButton = () => {
    if (isV2) return undefined
    const isEmptyError = Array.isArray(dataEmployee.error_field);
    if (isEmptyError) {
      toast.error('error_field is empty');
    } else {
      const section = Object.keys(dataEmployee.error_field)[0];
      navigate(`/employee/registration/${uuid}/${section}`);
    }
  };
  return (
    <BaseDukcapilValidationRejected
      titleMessage="Verifikasi data diri gagal"
      message="Cek kembali data KTP atau foto selfie"
      buttonLabel={isV2 ? "Hubungi CS" : "Perbaiki Data"}
      handleClickButton={handleClickButton}
      isV2
    />
  );
};

interface DukcapilValidationRejectedProps {
  handleTryAgain?: () => void;
}

const DukcapilValidationRejected = ({
  handleTryAgain,
}: DukcapilValidationRejectedProps) => {
  const navigate = useNavigate();
  const dataDealer = useAppSelector((state) => state.dealer.dealer.data);
  const { uuid } = useParams();
  const dataEmployee = useAppSelector((state) => state.employee.employee.data);
  const isV2 = dataEmployee.last_section_id >= 8

  const [message, setMessage] = useState<DukcapilRejectedMessageType>({
    title_message: isV2 ? dataEmployee?.vida_error?.title_message : 'Ada kesalahan pada sistem kami',
    message: isV2 ? dataEmployee?.vida_error?.message : 'Coba kembali dalam beberapa saat lagi',
  });

  const handleClickTryAgain = () => {
    if (handleTryAgain) {
      handleTryAgain();
    } else {
      navigate(`/employee/waiting-page/${uuid}`);
    }
  };

  useEffect(() => {
    const checkDataDealer = dataDealer?.vida_error;
    if (checkDataDealer?.message) {
      setMessage({
        message: checkDataDealer.message,
        title_message: checkDataDealer.title_message,
      });
    }
  }, [dataDealer]);

  return (
    <BaseDukcapilValidationRejected
      titleMessage={message?.title_message}
      message={message?.message}
      handleClickButton={handleClickTryAgain}
    />
  );
};

export default DukcapilValidationRejected;

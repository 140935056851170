import React, { useState, useRef, useEffect } from 'react';
import MoladinIcon from '../../../assets/icons/moladinIcon.svg';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { uploadFile } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { BROWSE_FILE_TYPE } from '../../../utils/constants';
import { Check, XCircle } from 'react-feather';
import {
  FormData,
  BankProps,
  ValidateBankType,
} from '../../../types/InformasiRekening';
import { getDropdownBank } from '../../../redux/slices/informasiRekening/informasiRekeningThunk';
import { checkBankAccountNumber } from '../../../services';
import * as Assets from '../../../utils/assets';
import Select from 'react-select';
import Label from '../../atom/Label';
import debounce from 'lodash/debounce';
import FormGroup from '../../atom/FormGroup';
import BrowseFile from './BrowseFileRekeningEmployee';
import 'flatpickr/dist/flatpickr.css';
import { updateUserBankAccount } from '../../../redux/slices/employee/employeeThunk';

const InformasiRekeningEmployee = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dataDropdownBank = useAppSelector(
    (state) => state.informasiRekening.dropdownBank
  );

  const {
    handleSubmit,
    setValue,
    control,
    register,
    getValues,
    formState: { errors },
  } = useForm<FormData>();

  const initialValidationMessage = {
    success: 'Nomor rekening ditemukan!',
    failed: 'Nomor rekening tidak ditemukan',
    error:
      'Rekening gagal dicek otomatis karena kendala sistem. Tim Moladin akan melakukan cek rekening manual setelah pendaftaran selesai',
  };
  // VirtualAccountNumber Validation Type: 'success', 'failed', 'error'
  const [bankValidation, setBankValidation] = useState<BankProps>({
    type: '',
    message: '',
  });
  const [isLoadingCheckBank, setIsLoadingCheckBank] = useState(false);
  const [isActiveInputBank, setIsActiveInputBank] = useState(false);

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (bankValidation.type !== 'failed' && !isLoadingCheckBank) {
      const payload = {
        bankName: data.bank.code,
        bankAccountNumber: data.bank_account_number,
        bankBookUrl: data.bank_book_url ? data.bank_book_url : '',
        bankAccountName: data.bank_account_name,
      };

      const res = await dispatch(
        updateUserBankAccount({ data: payload, uuid: uuid as string })
      );
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/employee/rekening-berhasil`);
      } else {
        toast.error('Failed submit registration');
      }
    }
  };

  const validateBankAccountNumber = async (checkParams: ValidateBankType) => {
    setIsLoadingCheckBank(true);
    checkBankAccountNumber(checkParams)
      .then((res) => {
        setIsLoadingCheckBank(false);
        const responseMessage = res?.data?.message;
        const data = res?.data?.data;
        if (responseMessage === 'account doest not exist') {
          setBankValidation({
            type: 'failed',
            message: initialValidationMessage.failed,
          });
          setValue('bank_account_name', '');
        }
        if (responseMessage === 'Success') {
          setBankValidation({
            type: 'success',
            message: initialValidationMessage.success,
          });
          setValue('bank_account_name', data?.accountName);
        }
      })
      .catch((err) => {
        setIsLoadingCheckBank(false);
        setBankValidation({
          type: 'error',
          message: initialValidationMessage.error,
        });
        setValue('bank_account_name', '');
      });
  };

  const handleChangeBankAccountNumber = useRef(
    debounce((e, bank) => {
      const checkParams = {
        bank: bank,
        account: e.target.value,
      };
      validateBankAccountNumber(checkParams);
    }, 500)
  ).current;

  const handleResetAccNum = () => {
    setValue('bank_account_number', '');
    setBankValidation({
      type: '',
      message: '',
    });
  };

  const handleChangeBank = (e: any) => {
    setValue('bank', e);
    const checkFieldBank = getValues('bank');
    const checkFieldAccount = getValues('bank_account_number');
    if (checkFieldBank && checkFieldAccount) {
      const checkParams = {
        bank: checkFieldBank?.code,
        account: checkFieldAccount,
      };
      validateBankAccountNumber(checkParams);
    }
  };

  useEffect(() => {
    dispatch(getDropdownBank());
  }, []);

  const dropdownStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state?.isFocused ? '#0F2CAD' : 'default',
    }),
  };

  return (
    <div className="px-4 flex flex-1 flex-col">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className="-mx-4" />
      </div>
      <h3 className="font-semibold mb-4 mt-7">
        Lengkapi Informasi Rekeningmu dulu, yuk!
      </h3>
      <p className="text-xs">
        Ini akan menjadi rekening utama untuk pencairan gaji dan lainnya
      </p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-1 flex-col mt-4"
      >
        <FormGroup className="mb-5">
          <Label name="Bank" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="bank"
            render={({ field }) => (
              <>
                <Select
                  {...register}
                  {...field}
                  options={dataDropdownBank.data}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.code}
                  placeholder="Pilih Bank"
                  className="text-sm"
                  onChange={(e) => handleChangeBank(e)}
                  isSearchable={true}
                  styles={dropdownStyles}
                />
                <small className="text-red-600">
                  {errors.bank?.message?.toString()}
                </small>
              </>
            )}
          />
        </FormGroup>

        <FormGroup className="mb-5">
          <Label name="Nomor Rekening" />
          <Controller
            rules={{ required: 'Data harus diisi' }}
            control={control}
            name="bank_account_number"
            render={({ field }) => (
              <div
                className={`rounded-lg w-full border h-10 p-2 flex flex-row ${
                  bankValidation.type === 'success'
                    ? 'border border-green-500'
                    : bankValidation.type === 'failed'
                    ? 'border border-red-500'
                    : null
                } ${
                  isActiveInputBank && !bankValidation.type
                    ? 'border-2 border-primary'
                    : ''
                }`}
              >
                <input
                  {...register}
                  {...field}
                  name="bank_account_number"
                  type="number"
                  pattern="\d*"
                  maxLength={255}
                  placeholder="Masukkan nomor rekening"
                  defaultValue={''}
                  onChange={(e) => {
                    setValue('bank_account_number', e.target.value);
                    handleChangeBankAccountNumber(
                      e,
                      getValues('bank')?.code || '0'
                    );
                  }}
                  onFocus={() => setIsActiveInputBank(true)}
                  onBlur={() => setIsActiveInputBank(false)}
                  className={`border-transparent outline-none focus:border-transparent focus:ring-0 w-full text-sm mr-2`}
                />
                {isLoadingCheckBank && (
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-primary/50 fill-primary"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}
                {bankValidation.type === 'success' && !isLoadingCheckBank && (
                  <Check size={18} color="green" />
                )}
                {bankValidation.type === 'failed' && !isLoadingCheckBank && (
                  <XCircle size={18} onClick={handleResetAccNum} />
                )}
              </div>
            )}
          />
          {bankValidation.type === 'success' ? (
            <small className="text-green-600">{bankValidation.message}</small>
          ) : bankValidation.type === 'failed' ? (
            <small className="text-red-600">{bankValidation.message}</small>
          ) : bankValidation.type === 'error' ? (
            <div className="bg-lightyellow mt-2 p-2 text-sm border border-darkyellow rounded-lg flex flex-row">
              <div className="mr-2 w-12">
                <img src={Assets.iconWarningOrange} style={{ width: '18px' }} />
              </div>
              <div>
                <span>{bankValidation.message}</span>
              </div>
            </div>
          ) : null}
          <div>
            <small className="text-red-600">
              {errors?.bank_account_number?.message}
            </small>
          </div>
        </FormGroup>

        {getValues('bank_account_name') && (
          <FormGroup>
            <Controller
              control={control}
              name="bank_account_name"
              render={({ field }) => (
                <div className="rounded-lg w-full border h-10 p-2 flex flex-row bg-lightdisabled">
                  <input
                    {...register}
                    {...field}
                    disabled={true}
                    type="text"
                    maxLength={255}
                    defaultValue={''}
                    className={`border-transparent outline-none focus:border-transparent focus:ring-0 w-full text-sm mr-2 bg-lightdisabled`}
                  />
                </div>
              )}
            />
          </FormGroup>
        )}

        <FormGroup className="mb-5">
          <div className="pt-2 mr-2">
            <Label name="Foto Halaman Depan Buku Tabungan" />
          </div>
          <Controller
            control={control}
            name="bank_book_url"
            rules={{ required: 'Data harus diisi' }}
            render={({ field }) => (
              <>
                <BrowseFile
                  {...register}
                  {...field}
                  placeholder="Upload file"
                  onUploadFile={uploadFile}
                  type={BROWSE_FILE_TYPE.IMAGE}
                  // onChange={(url) => console.log(url)}
                />
              </>
            )}
          />
          <div>
            <small className="text-red-600">
              {errors?.bank_book_url?.message}
            </small>
          </div>
        </FormGroup>

        <div className="flex flex-col flex-1 justify-end h-full">
          <button
            disabled={!getValues('bank_account_name')}
            type="submit"
            className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out font-semibold mb-4 disabled:bg-[#9CA3AF]"
          >
            Simpan
          </button>
        </div>
      </form>
    </div>
  );
};

export default InformasiRekeningEmployee;

import React, { useEffect, useState } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { uploadImageFile } from '../../../services';
import { DokumenPendukungTypes } from '../../../types/Dealer';
import Label from '../../atom/Label';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import TakePhotoGeneral from '../../Camera/TakePhotoGeneral';
import PopupGuide from '../../FotoIdentitas/PopupGuide/PopupGuide';
import FormGroup from '../../atom/FormGroup';
import Thumbnail from '../../atom/Thumbnail';
import Button from '../../atom/Button';
import CloseCircle from '../../../assets/icons/close-circle.svg';
import { featureFlag, isValidNpwp } from '../../../utils/helper';
import { ChevronRight, Info, X } from 'react-feather';
import CustomModal from '../../atom/CustomModal';
import AccordionItem from '../../atom/AccrodionItem';
import guide1 from '../../../assets/images/photoGuide/transactionProof/guide1.png';
import guide2 from '../../../assets/images/photoGuide/transactionProof/guide2.png';
import guide3 from '../../../assets/images/photoGuide/transactionProof/guide3.png';
import guide4 from '../../../assets/images/photoGuide/transactionProof/guide4.png';
import HeaderEvent from '../../Header/HeaderEvent';
import { saveDraft } from '../../../redux/slices/dealer/dealerThunk';
import Mutation from '../../../assets/svg/Mutation.svg';
import BankTransaction from '../../../assets/svg/BankTransaction.svg';
import BankTransactionDetail from '../../../assets/svg/BankTransactionDetail.svg';

export default function DokumenPendukung() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const dataDealer = useAppSelector((state) => state.dealer.dealer);
  const [isLoading, setIsloading] = useState(false);
  const [showProofCamera, setShowProofCamera] = useState(false);
  const [showNIBCamera, setShowNIBCamera] = useState(false);
  const [showTempatUsahaCamera, setShowTempatUsahaCamera] = useState({
    showGuide: false,
    contentType: '',
    photo_front_building: false,
    photo_left_building: false,
    photo_right_building: false,
    photo_selfie: false,
  });
  const [cameraType, setCameraType] = useState('');
  const [proofOfTransactions, setProofOfTransactions] = useState<string[]>([]);
  const [showGuide, setShowGuide] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const flagImprovementRegis = featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM')

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    watch
  } = useForm<DokumenPendukungTypes>();

  const onSubmit: SubmitHandler<DokumenPendukungTypes> = async (data) => {
    const {
      dealer_npwp = null,
      nib_photo_url = null,
      photo_front_building = null,
      photo_left_building = null,
      photo_right_building = null,
      photo_selfie = null,
    } = data;
    const photosOfProofTransaction = [];

    if (featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM')) {
      photosOfProofTransaction.push(...(data.photos_proof_of_transaction || []))
    }

    delete data.photos_proof_of_transaction;

    const payload: DokumenPendukungTypes = {
      ...data,
      dealer_npwp,
      nib_photo_url,
      photo_front_building,
      photo_left_building,
      photo_right_building,
      photo_selfie,
      photo_proof_of_transaction: featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ? photosOfProofTransaction : proofOfTransactions
    };

    if (featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ? photosOfProofTransaction.length > 10 : proofOfTransactions.length > 10) {
      return;
    }
    setIsloading(true);
    const res = await dispatch(
      saveDraft({
        data: { dokumen_pendukung: payload } as {
          dokumen_pendukung: DokumenPendukungTypes;
        },
        uuid: uuid as string,
      })
    );
    if (res.meta.requestStatus === 'fulfilled') {
      setIsloading(false);
      navigate(`/dealer/registration/${uuid}/limit_pembiayaan`);
    } else {
      setIsloading(false);
      const errMessage = (res.payload as any)?.errors?.[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const selectCamera = (type: string) => {
    setShowTempatUsahaCamera({
      ...showTempatUsahaCamera,
      showGuide: true,
      contentType:
        type === 'photo_selfie' ? 'selfie_dealer_url' : 'bangunan_url',
    });
    setCameraType(type);
  };

  useEffect(() => {
    if (dataDealer?.data?.dokumen_pendukung) {
      const dataDokumenPendukung = dataDealer?.data?.dokumen_pendukung;
      dataDokumenPendukung &&
        Object.keys(dataDokumenPendukung).map((key) => {
          setValue(
            key as keyof DokumenPendukungTypes,
            dataDokumenPendukung[key as keyof DokumenPendukungTypes]
          );
        });
      const photosOfProofTransaction = []
      if (featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM')) {
        if (typeof dataDokumenPendukung?.photos_proof_of_transaction === 'object') {
          photosOfProofTransaction.push(...(dataDokumenPendukung?.photos_proof_of_transaction || []))
        } else {
          photosOfProofTransaction.push(dataDokumenPendukung?.photos_proof_of_transaction || '')
        }
      }

      setValue('photos_proof_of_transaction', photosOfProofTransaction);
      setProofOfTransactions(
        dataDokumenPendukung?.photos_proof_of_transaction || []
      );
    }
  }, [dataDealer?.data?.dokumen_pendukung]);

  const deleteProofItem = (idx: number) => {
    const tempArr = [
      ...(featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ?
        (getValues('photos_proof_of_transaction') as string[]) :
        proofOfTransactions)
    ];

    if (idx >= 0 && idx < tempArr.length) {
      tempArr.splice(idx, 1); // Remove one element at the specified index

      setProofOfTransactions([...tempArr]);
      setValue('photos_proof_of_transaction', [...tempArr])
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex-1 flex flex-col justify-between"
      method="post"
    >
      {showTempatUsahaCamera.showGuide && (
        <PopupGuide
          handleClose={() => {
            setShowTempatUsahaCamera({
              ...showTempatUsahaCamera,
              showGuide: false,
            });
          }}
          handleConfirm={() => {
            setShowTempatUsahaCamera({
              ...showTempatUsahaCamera,
              showGuide: false,
              [cameraType]: true,
            });
          }}
          contentType={showTempatUsahaCamera.contentType}
        />
      )}

      {!showTempatUsahaCamera.showGuide && (
        <div>
          <HeaderEvent data={dataDealer?.data} />

          <h3 className="font-semibold mb-4">Dokumen Pendukung</h3>
          <FormGroup>
            <Label name="Foto Tempat Usaha Tampak Depan" />
            <div className="text-xs text-[#374151] mb-2">{`Upload foto tampak depan bangunan tempat usaha (showroom/garasi/rumah/dsb)`}</div>
            <Controller
              control={control}
              rules={{
                required: 'Foto Tempat Usaha Tampak Depan harus diisi',
              }}
              name="photo_front_building"
              render={({ field }) => (
                <>
                  <InputTakePhotoWithDrawer
                    placeholder="Upload foto lokasi usaha"
                    onSelectCamera={() =>
                      selectCamera('photo_front_building')
                    }
                    handleRemovePhoto={() => field.onChange('')}
                    onBrowseFile={() => console.log('')}
                    onSelectFile={(src) => field.onChange(src)}
                    srcUri={field.value || ''}
                    imageLabel=""
                  />
                  {showTempatUsahaCamera.photo_front_building && (
                    <TakePhotoGeneral
                      handleConfirmPhoto={(src) => {
                        field.onChange(src);
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_front_building: false,
                          showGuide: false,
                        });
                      }}
                      handleBack={() =>
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_front_building: false,
                        })
                      }
                      cameraProperty={{ facing_mode: 'environment' }}
                      title="Ambil Foto Tempat Usaha Tampak Depan"
                      onUploadPhotoTaken={uploadImageFile}
                      previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                    />
                  )}
                  <small className="text-red-600">
                    {errors.photo_front_building?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label name="Foto Tempat Usaha Bagian Kiri" />
            <div className="text-xs text-[#374151] mb-2">{`Upload foto bagian kiri bangunan tempat usaha (showroom/garasi/rumah/dsb)`}</div>
            <Controller
              control={control}
              rules={{
                required: 'Foto Tempat Usaha Bagian Kiri harus diisi',
              }}
              name="photo_left_building"
              render={({ field }) => (
                <>
                  <InputTakePhotoWithDrawer
                    placeholder="Upload foto lokasi usaha"
                    onSelectCamera={() =>
                      selectCamera('photo_left_building')
                    }
                    handleRemovePhoto={() => field.onChange('')}
                    onBrowseFile={() => console.log('')}
                    onSelectFile={(src) => field.onChange(src)}
                    srcUri={field.value || ''}
                    imageLabel=""
                  />
                  {showTempatUsahaCamera.photo_left_building && (
                    <TakePhotoGeneral
                      handleConfirmPhoto={(src) => {
                        field.onChange(src);
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_left_building: false,
                          showGuide: false,
                        });
                      }}
                      handleBack={() =>
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_left_building: false,
                        })
                      }
                      cameraProperty={{ facing_mode: 'environment' }}
                      title="Ambil Foto Tempat Usaha Tampak Kiri"
                      onUploadPhotoTaken={uploadImageFile}
                      previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                    />
                  )}
                  <small className="text-red-600">
                    {errors.photo_left_building?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label name="Foto Tempat Usaha Bagian Kanan" />
            <div className="text-xs text-[#374151] mb-2">{`Upload foto bagian kanan bangunan tempat usaha (showroom/garasi/rumah/dsb)`}</div>
            <Controller
              control={control}
              rules={{
                required: 'Foto Tempat Usaha Bagian Kanan harus diisi',
              }}
              name="photo_right_building"
              render={({ field }) => (
                <>
                  <InputTakePhotoWithDrawer
                    placeholder="Upload foto lokasi usaha"
                    onSelectCamera={() =>
                      selectCamera('photo_right_building')
                    }
                    handleRemovePhoto={() => field.onChange('')}
                    onBrowseFile={() => console.log('')}
                    onSelectFile={(src) => field.onChange(src)}
                    srcUri={field.value || ''}
                    imageLabel=""
                  />
                  {showTempatUsahaCamera.photo_right_building && (
                    <TakePhotoGeneral
                      handleConfirmPhoto={(src) => {
                        field.onChange(src);
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_right_building: false,
                          showGuide: false,
                        });
                      }}
                      handleBack={() =>
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_right_building: false,
                        })
                      }
                      cameraProperty={{ facing_mode: 'environment' }}
                      title="Ambil Foto Tempat Usaha Tampak Kanan"
                      onUploadPhotoTaken={uploadImageFile}
                      previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                    />
                  )}
                  <small className="text-red-600">
                    {errors.photo_right_building?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label name="Bukti transaksi Dealer berupa Mutasi Bank 3 bulan terakhir secara lengkap" />
            <button
              className="w-full flex justify-between rounded-2xl px-4 bg-[#E6EAF8] mb-2 items-center py-2"
              onClick={() => setShowGuide(!showGuide)}
              type="button"
            >
              <label className="text-primary font-bold text-xs">
                Lihat panduan bukti transaksi
              </label>
              <ChevronRight className="text-primary" />
            </button>
            <label className="text-xs text-neutral-500 my-4">
              Upload hingga 10 dokumen
            </label>
            <Controller
              control={control}
              rules={{
                required:
                  'Bukti transaksi Dealer berupa Mutasi Bank 3 bulan terakhir secara lengkap harus diisi',
              }}
              name={featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ? "photos_proof_of_transaction" : "photo_proof_of_transaction"}
              render={({ field }) => (
                <>
                  <div className="flex overflow-x-auto items-center gap-x-4">
                    <InputTakePhotoWithDrawer
                      disabled={featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ? watch('photos_proof_of_transaction')?.length === 10 : proofOfTransactions.length === 10}
                      isMultiple
                      placeholder={''}
                      onSelectCamera={() => setShowProofCamera(true)}
                      handleRemovePhoto={() => field.onChange('')}
                      onBrowseFile={() => console.log('')}
                      onSelectFile={(src) => {
                        if (featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM')) {
                          field.onChange([...(getValues('photos_proof_of_transaction') || []), src]);
                        } else {
                          const arrFiles: string[] = [];
  
                          if (proofOfTransactions.length > 9) return;
                          else {
                            arrFiles.push(src);
                            setProofOfTransactions((prev) => [
                              ...prev,
                              ...arrFiles,
                            ]);
                          }
                          field.onChange(src);
                        }
                      }}
                      srcUri={''}
                      imageLabel=""
                      allowPDF
                    />
                    {showProofCamera && (
                      <TakePhotoGeneral
                        handleConfirmPhoto={(src) => {
                          const arrFiles: string[] = [];
                          if (featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM')) {
                            field.onChange([...(getValues('photos_proof_of_transaction') || []), src]);
                            setShowProofCamera(false);
                          } else {
                            if (proofOfTransactions.length > 9) return;
                            else {
                              arrFiles.push(src);
  
                              setProofOfTransactions((prev) => [
                                ...prev,
                                ...arrFiles,
                              ]);
                            }
                            field.onChange(src);
                            setShowProofCamera(false);
                          }
                        }}
                        handleBack={() => setShowProofCamera(false)}
                        cameraProperty={{ facing_mode: 'environment' }}
                        title={''}
                        onUploadPhotoTaken={uploadImageFile}
                        previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                      />
                    )}
                    {[
                      ...(featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ?
                        watch('photos_proof_of_transaction') :
                        proofOfTransactions) ?? []
                    ].map((item, key) => {
                      return (
                        <div className="relative" key={key}>
                          <div className="absolute top-3 left-1 bg-neutral-300 rounded-full w-5 h-5 flex items-center justify-center text-[10px]">
                            {key + 1}
                          </div>
                          <Thumbnail src={item} />
                          <Button
                            customClassName
                            className="absolute top-0 -right-1 bg-neutral-800 rounded-full w-5 h-5 flex items-center justify-center"
                            withIcon
                            icon={CloseCircle}
                            onClick={() => deleteProofItem(key)}
                            name=""
                            type="button"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <small className="text-red-600">
                    {featureFlag('REACT_APP_X_MEN_10_1_IMPROVEMENT_REGIS_FORM') ? errors.photos_proof_of_transaction?.message : errors.photo_proof_of_transaction?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label name="NPWP Usaha (Opsional)" />
            <Controller
              control={control}
              name="dealer_npwp"
              rules={{ validate: isValidNpwp }}
              render={({ field }) => (
                <>
                  <PatternFormat
                    {...field}
                    format="##.###.###.#-###.###"
                    mask="_"
                    placeholder="Masukkan 15 digit NPWP usaha"
                    className="border w-full h-11 rounded-lg p-3 focus:outline-primary"
                  />
                  <small className="text-red-600">
                    {errors.dealer_npwp?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label name={`NIB/SKU/SIUP ${flagImprovementRegis ? '' : '(Opsional)'}`} />
            <Controller
              control={control}
              name="nib_photo_url"
              {...(flagImprovementRegis ? {
                rules: { required: 'NIB/SKU/SIUP harus diisi' }
              } : {})}
              render={({ field }) => (
                <>
                  <InputTakePhotoWithDrawer
                    placeholder="Upload foto NIB/SKU/SIUP"
                    onSelectCamera={() => setShowNIBCamera(true)}
                    handleRemovePhoto={() => field.onChange('')}
                    onBrowseFile={() => console.log('')}
                    onSelectFile={(src) => field.onChange(src)}
                    srcUri={field.value || ''}
                    imageLabel=""
                  />
                  {showNIBCamera && (
                    <TakePhotoGeneral
                      handleConfirmPhoto={(src) => {
                        field.onChange(src);
                        setShowNIBCamera(false);
                      }}
                      handleBack={() => setShowNIBCamera(false)}
                      cameraProperty={{ facing_mode: 'environment' }}
                      title="Ambil Foto NIB/SKU/SIUP"
                      onUploadPhotoTaken={uploadImageFile}
                      previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                    />
                  )}
                  <small className="text-red-600">
                    {errors.nib_photo_url?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label name="Selfie DC dan Dealer" />
            <div className="text-xs text-[#374151] mb-2">{`Upload foto selfie DC dan Dealer di depan bangunan tempat usaha (showroom/garasi/rumah/dsb)`}</div>
            <Controller
              control={control}
              rules={{ required: 'Foto Selfie harus diisi' }}
              name="photo_selfie"
              render={({ field }) => (
                <>
                  <InputTakePhotoWithDrawer
                    placeholder="Upload foto selfie"
                    onSelectCamera={() => selectCamera('photo_selfie')}
                    handleRemovePhoto={() => field.onChange('')}
                    onBrowseFile={() => console.log('')}
                    onSelectFile={(src) => field.onChange(src)}
                    srcUri={field.value || ''}
                    imageLabel=""
                  />
                  {showTempatUsahaCamera.photo_selfie && (
                    <TakePhotoGeneral
                      handleConfirmPhoto={(src) => {
                        field.onChange(src);
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_selfie: false,
                        });
                      }}
                      handleBack={() =>
                        setShowTempatUsahaCamera({
                          ...showTempatUsahaCamera,
                          photo_selfie: false,
                        })
                      }
                      cameraProperty={{ facing_mode: 'environment' }}
                      title="Ambil Foto Selfie DC dan Dealer"
                      onUploadPhotoTaken={uploadImageFile}
                      previewDescription="Pastikan informasi pada foto terlihat dengan jelas dan mudah dibaca"
                    />
                  )}
                  <small className="text-red-600">
                    {errors.photo_selfie?.message}
                  </small>
                </>
              )}
            />
          </FormGroup>
        </div>
      )}

      <button
        type="submit"
        disabled={isLoading}
        className={
          'bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out'
        }
      >
        <p className="!mb-0">Simpan dan Lanjut</p>
      </button>
      <CustomModal
        showModal={showGuide}
        handleClickBackground={() => setShowGuide(!showGuide)}
        fullWidth
      >
        <div className="flex items-center mb-4">
          <button
            type="button"
            className="bg-transparent"
            onClick={() => setShowGuide(!showGuide)}
          >
            <X />
          </button>
          <label className="text-neutral-800 text-center flex-1 font-bold">
            Panduan Bukti Transaksi Dealer
          </label>
        </div>
        {!flagImprovementRegis && ( // show when flag is off
          <>
            <div className="flex mb-4 rounded-lg border p-2 bg-[#E6EAF8] border-[#6B85D4] gap-x-2">
              <div>
                <Info fill="#002DB4" color="#FFFFFF" size={16} />
              </div>
              <div className="flex flex-col">
                <span className="text-xs text-neutral-800">
                  Dapatkan limit lebih besar dengan:
                </span>
                <ul className="mb-0">
                  <li className="text-xs text-neutral-800">
                    Upload dokumen lebih banyak
                  </li>
                  <li className="text-xs text-neutral-800">
                    Nominal transaksi lebih besar
                  </li>
                </ul>
              </div>
            </div>
            <label className="mb-[18px] font-bold text-sm">
              Dokumen yang bisa digunakan sebagai bukti transaksi:
            </label>
          </>
        )}
        {flagImprovementRegis && ( // show when flag is on
          <>
            <h3 className="font-medium text-sm">Mutasi Bank 3 Bulan Terakhir</h3>
            <div className="flex flex-col justify-center items-center">
              <img src={Mutation} alt="Mutasi" />
              <p className="text-neutral-500 text-xs mb-4">Gambar 1. Dokumen mutasi bank 3 bulan terakhir</p>
              <div className="relative">
                <img src={BankTransaction} alt="Transaksi pembiayaan" />
                <img src={BankTransactionDetail} alt="Detail transaksi pembiayaan" className="absolute top-[50%] left-0 bottom-0 right-0 scale-150 md:scale-[2]" />
              </div>
              <p className="text-neutral-500 text-xs mb-4 text-center">Gambar 2. Dalam mutasi bank, terdapat bukti transaksi jual beli atau pembiayaan mobil</p>
            </div>
          </>
        )}
        {!flagImprovementRegis && ( // show when flag is off
          <>
            <AccordionItem
              title="Mutasi Bank 3 Bulan Terakhir"
              content={
                <>
                  <img src={guide1} className="mb-4" />
                  <img src={guide2} />
                </>
              }
            />
            <AccordionItem
              title="Bukti Transaksi Jual Beli Mobil"
              content={
                <>
                  <img src={guide3} className="mb-4" />
                  <label className="text-xs text-neutral-800">
                    Catatan: Satu atau lebih transaksi dalam <b>3 bulan terakhir</b>
                  </label>
                </>
              }
            />
            <AccordionItem
              title="PO Moladin"
              content={
                <>
                  <img src={guide4} className="mb-4" />
                  <label className="text-xs text-neutral-800">
                    Catatan: Satu atau lebih PO Moladin dalam{' '}
                    <b>6 bulan terakhir</b>
                  </label>
                </>
              }
            />
            <label className="mb-[18px] font-bold text-sm">
              Pastikan dokumen terdapat informasi terkait jual beli kendaraan, bisa
              terdiri dari:
            </label>
            <ul className="text-xs text-neutral-700">
              <li>Bukti Pembayaran Uang Muka</li>
              <li>Bukti Pembayaran Komisi</li>
              <li>Bukti Pelunasan</li>
              <li>Bukti Pencairan</li>
            </ul>
          </>
        )}
      </CustomModal>
    </form>
  );
}

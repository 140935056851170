import React from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_SECTION_FORM } from '../../utils/constants';

type Props = {
    percentage: number;
    activeSession: string;
};

const HeaderEmployeeV2 = ({ percentage, activeSession }: Props) => {
    const { uuid } = useParams();

    const navigate = useNavigate();

    let scroll = 0;

    const sections = [
        { name: 'Foto & Hasil KTP', slug: PAGE_SECTION_FORM.FOTO_HASIL_KTP },
        { name: 'Foto & Hasil KK', slug: PAGE_SECTION_FORM.FOTO_HASIL_KK },
        { name: 'Data Pelengkap & Alamat', slug: PAGE_SECTION_FORM.DATA_PELENGKAP_ALAMAT },
        { name: 'Keluarga di Moladin', slug: PAGE_SECTION_FORM.KELUARGA_V2 },
        { name: 'Referensi', slug: PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA_V2 },
        { name: 'CV', slug: PAGE_SECTION_FORM.CVV2 },
        { name: 'Surat Pernyataan', slug: PAGE_SECTION_FORM.TNC },
    ];

    switch (activeSession) {
        case PAGE_SECTION_FORM.FOTO_HASIL_KTP:
            scroll = -15;
            break;
        case PAGE_SECTION_FORM.FOTO_HASIL_KK:
            scroll = 35;
            break;
        case PAGE_SECTION_FORM.DATA_PELENGKAP_ALAMAT:
            scroll = 160;
            break;
        case PAGE_SECTION_FORM.KELUARGA_V2:
        case PAGE_SECTION_FORM.KELUARGA:
            scroll = 290;
            break;
        case PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA:
        case PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA_V2:
            scroll = 500;
            break;
        case PAGE_SECTION_FORM.CV:
        case PAGE_SECTION_FORM.CVV2:
            scroll = 600;
            break;
        case PAGE_SECTION_FORM.TNC:
            scroll = 650;
            break;
        default:
            break;
    }

    const handleBack = () => {
        switch (activeSession) {
            case PAGE_SECTION_FORM.FOTO_HASIL_KTP:
                navigate(`/employee/homepage/${uuid}`);
                break;
            case PAGE_SECTION_FORM.FOTO_HASIL_KK:
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.FOTO_HASIL_KTP}`);
                break;
            case PAGE_SECTION_FORM.DATA_PELENGKAP_ALAMAT:
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.FOTO_HASIL_KK}`);
                break;
            case PAGE_SECTION_FORM.KELUARGA_V2:
            case PAGE_SECTION_FORM.KELUARGA:
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.DATA_PELENGKAP_ALAMAT}`);
                break;
            case PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA:
            case PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA_V2:
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.KELUARGA_V2}`);
                break;
            case PAGE_SECTION_FORM.CV:
            case PAGE_SECTION_FORM.CVV2:
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.PEKERJAAN_SEBELUMNYA_V2}`);
                break;
            case PAGE_SECTION_FORM.TNC:
                navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.CVV2}`);
                break;
            default:
                break;
        }
    };

    return (
        <div className="h-[106px] bg-primary">
            <div className="flex items-center justify-around text-white pt-6 mb-6">
                <ChevronLeft
                    onClick={handleBack}
                    width={24}
                    height={24}
                    className="cursor-pointer"
                />
                <div className="shadow w-[140px] bg-white/50 rounded-[99px] ">
                    <div
                        style={{ width: `${percentage}%` }}
                        className={`bg-[#E6EAF8] text-xs leading-none py-1 text-center rounded-[99px] text-white `}
                    ></div>
                </div>
                <span className="text-xs">{percentage}% Selesai</span>
            </div>

            <div
                style={
                    activeSession !== 'informasi_pribadi'
                        ? { marginLeft: -scroll }
                        : { marginLeft: scroll }
                }
                className="flex items-center justify-start mx-[10px] overflow-hidden whitespace-nowrap"
            >
                {sections.map((section, index) => (
                    <React.Fragment
                        key={section.slug}>
                        <div
                            className="flex justify-center items-center"
                        >
                            <div
                                className={`rounded-full flex items-center justify-center ${section.slug === activeSession ? 'bg-white' : 'bg-white/50'
                                    } text-primary w-[18px] h-[18px] text-xs`}
                            >
                                {index + 1}
                            </div>
                            <span
                                className={`font-bold ${section.slug === activeSession
                                    ? 'text-white'
                                    : 'text-white/50'
                                    } ml-2 text-xs `}
                            >
                                {section.name}
                            </span>
                        </div>
                        <span className="text-white mx-2 last:hidden">---</span>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default HeaderEmployeeV2;

import React,{useEffect, useState} from 'react';
import templateGuideKK from '../../../assets/images/photoGuide/kk/templateGuideKK.svg';
import templateGuideSelfie from '../../../assets/images/photoGuide/selfie/templateguideSelfie.svg';
import { FrameTemplateType } from '../../../types/FotoIdentitasTypes';

type Props = {
  type: string;
  handleCapture: (types:any,width:any) => void;
};

const frameTemplate: FrameTemplateType = {
  ktp_photo_url: {
    imageFrame: templateGuideKK,
    content: (
      <div className="px-3 pb-8 text-neutral-50">
        <div className="font-bold mb-2">Foto Bagian Depan KTP</div>
        <div className="text-xs">
          Letakkan pada permukaan yang landai, pastikan seluruh bagian terlihat
          dalam kotak bingkai.
        </div>
      </div>
    ),
  },
  family_card_url2: {
    imageFrame: templateGuideKK,
    content: (
      <div className="px-3 pb-8 text-neutral-50">
        <div className="font-bold mb-2">Foto Kartu Keluarga (Harap Landscape)</div>
        <div className="text-xs">
          Letakkan pada permukaan yang landai, pastikan seluruh bagian terlihat
          dalam kamera dan landscape (ikuti atas bawah).
        </div>
      </div>
    ),
  },
  family_card_url: {
    imageFrame: templateGuideKK,
    content: (
      <div className="px-3 pb-8 text-neutral-50">
        <div className="font-bold mb-2">Foto Kartu Keluarga</div>
        <div className="text-xs">
          Letakkan pada permukaan yang landai, pastikan seluruh bagian terlihat
          dalam kotak bingkai.
        </div>
      </div>
    ),
  },
  selfie_url: {
    imageFrame: templateGuideSelfie,
    content: (
      <div className="text-center px-3 pb-8 text-xs text-neutral-50">
        Pastikan wajahmu terambil dengan jelas di dalam bingkai yang tersedia,{' '}
        <strong>tanpa memegang KTP </strong>
      </div>
    ),
  },
  npwp_url: {
    imageFrame: templateGuideKK,
    content: (
      <div className="px-3 pb-8 text-neutral-50">
        <div className="font-bold mb-2">Foto NPWP</div>
        <div className="text-xs">
          Letakkan pada permukaan yang landai, pastikan seluruh bagian terlihat
          dalam kotak bingkai.
        </div>
      </div>
    ),
  },
};
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default function FrameCamera({ handleCapture, type }: Props) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className="absolute top-[56px] left-0 bottom-0 right-0">
      {windowSize.innerWidth>900 || type as keyof FrameTemplateType!="family_card_url"?<img
          src={frameTemplate[type as keyof FrameTemplateType]?.imageFrame}
          className="w-full h-full object-cover"
        />:""}
        {windowSize.innerWidth<=900 &&  type as keyof FrameTemplateType=="family_card_url"?<div className='absolute right-0 top-20 my-auto text-neutral-50' style={{transform:'rotate(90deg)'}}>
        ATAS
      </div>:""}
      {windowSize.innerWidth<=900 &&  type as keyof FrameTemplateType=="family_card_url"?<div className='absolute left-0 top-20 my-auto text-neutral-50' style={{transform:'rotate(90deg)'}}>
        BAWAH
      </div>:""}
      </div>
      <div className="absolute bottom-[32px] left-0 right-0">
      {type as keyof FrameTemplateType=="family_card_url" && windowSize.innerWidth<=900?frameTemplate.family_card_url2?.content:frameTemplate[type as keyof FrameTemplateType]?.content}
        <div
          onClick={()=>handleCapture(type as keyof FrameTemplateType, windowSize.innerWidth)}
          className="w-[64px] h-[64px] rounded-full bg-white m-auto"
        ></div>
      </div>
    </>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getTnC = createAsyncThunk(
  'tnc/fetchTnC',
  async (scope: string, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PRIVACY_POLICY_TOKEN}`,
        },
        params: {
          scope: scope,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_PRIVACY_POLICY_URL}/api/latest-tnc`,
        config
      );
      return response.data.data as TnCType;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const agreeTnC = createAsyncThunk(
  'tnc/agreeTnC',
  async (request: { userId: number; tncId: number }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        user_id: request.userId,
        tnc_id: request.tncId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_PRIVACY_POLICY_URL}/api/tnc/agree`,
        data,
        config
      );
      return response.data.data as TnCType;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

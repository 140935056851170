import React from 'react';

type Props = {
  name: string;
  className?: string;
};

const Label = ({ name, className = '' }: Props) => {
  return <p className={`font-medium text-sm mb-2 ${className}`}>{name}</p>;
};

export default Label;

import React from 'react';
import { X } from 'react-feather';
import { CommonTypesFotoIdentitas } from '../../../types/FotoIdentitasTypes';
import PopupContent from './PopupContent';

type Props = {
  contentType?: string;
  handleClose: () => void;
  handleConfirm: () => void;
};

const titleHeader: CommonTypesFotoIdentitas = {
  ktp_photo_url: 'Foto KTP',
  family_card_url: 'Panduan Foto KK',
  selfie_url: 'Panduan Foto Selfie',
  npwp_url: 'Panduan Foto NPWP',
  bangunan_url: 'Panduan Foto Bangunan',
  selfie_dealer_url: 'Panduan Foto Selfie Dealer',
};

export default function PopupGuide({
  contentType = '',
  handleClose,
  handleConfirm,
}: Props) {
  return (
    <div className="absolute top-0 bottom-0 left-0 w-full bg-white flex flex-col">
      <div className="py-4 bg-primary">
        <div className="flex items-center px-5 text-white">
          <X
            onClick={() => handleClose()}
            width={24}
            height={24}
            className="cursor-pointer"
          />
          <div className="w-full">
            <p className="text-center mb-0">
              {titleHeader[contentType as keyof CommonTypesFotoIdentitas]}
            </p>
          </div>
        </div>
      </div>
      {/* popup guide content */}
      <div className="flex-1 flex flex-col justify-between">
        <PopupContent context={contentType} />
        <div className="px-4 pb-4">
          <div
            className="bg-[#EA5A00] py-3 mt-7 rounded text-center text-white text-sm font-bold"
            onClick={handleConfirm}
          >
            {contentType === 'ktp_photo_url' ? 'Ambil Foto KTP' : contentType === 'family_card_url' ? 'Ambil Foto KK' : 'Ambil Foto'}
          </div>
        </div>
      </div>
    </div>
  );
}

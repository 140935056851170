import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Plus } from 'react-feather';
import { saveDraftV2 } from '../../../redux/slices/employee/employeeThunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { FamilyInMoladinRequest } from '../../../types/Employee';
import { toast } from 'react-toastify';

type FormValues = {
  family_in_moladin: Family[];
};

interface Family {
  id?: number;
  user_id?: number;
  family_name: string;
  phone_number: string;
  nik: string;
  relation: string;
  position: string;
}

const KeluargaDiMoladin = () => {
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { data: employee, loading: isLoading } = useAppSelector(
    (state) => state.employee.employee
  );
  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      family_in_moladin: [{}],
    },
  });
  const { fields, append, prepend, remove } = useFieldArray({
    name: 'family_in_moladin',
    control,
  });
  const isV2 = employee.last_section_id > 8

  const [isFamilyInMoladin, setIsFamilyInMoladin] = useState<boolean | null>(
    null
  );

  const handleChangeFamily = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsFamilyInMoladin(e.target.value === 'Y');
  };

  const handleSubmitForm = async (e: FormValues) => {
    const payload: FamilyInMoladinRequest = !isFamilyInMoladin
      ? {
        has_family: isFamilyInMoladin ? 1 : 0,
        family_in_moladin: [],
      }
      : { has_family: isFamilyInMoladin ? 1 : 0, ...e };

    const res = await dispatch(
      saveDraftV2({ data: payload, uuid: uuid as string })
    );

    const requestStatus = res.meta.requestStatus;
    if (requestStatus === 'fulfilled') {
      handleNavigateNextStep();
    }

    if (requestStatus === 'rejected') {
      const errMessage = (res.payload as any)?.errors[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const handleNavigateNextStep = () => {
    navigate(`/employee/registration/${uuid}/${isV2 ? 'pekerjaan_sebelumnya_v2' : 'pekerjaan_sebelumnya'}`);
  };

  useEffect(() => {
    if (employee?.keluarga_di_moladin?.length !== 0) {
      setIsFamilyInMoladin(true);
      const families: Family[] = employee?.keluarga_di_moladin?.map((fam) => ({
        id: fam.id,
        user_id: fam.user_id,
        family_name: fam.family_name,
        phone_number: fam.phone_number.toString(),
        nik: fam.nik.toString(),
        relation: fam.relationship,
        position: fam.positiion_in_moladin,
      }));
      setValue('family_in_moladin', families);
    } else {
      setIsFamilyInMoladin(null);

      if (employee?.has_family === 0) {
        setIsFamilyInMoladin(false);
      }
    }
  }, [employee]);

  return (
    <>
      <div className="flex-1 pb-5">
        <h3 className="font-semibold mb-4">Keluarga di Moladin</h3>
        <p className="text-xs">
          Isi jika ada keluargamu, baik keluarga inti atau keluarga jauh, yang
          <span className="font-semibold"> bekerja di Moladin</span>
        </p>
        <h4 className="font-medium text-sm mb-4">
          Apakah ada keluarga yang bekerja di Moladin?
        </h4>

        <div className="flex space-x-2">
          <div className="flex border rounded-lg items-center justify-center w-full py-3">
            <input
              id="yes"
              type="radio"
              className="h-4 accent-primary w-4 rounded-lg border p-3"
              value="Y"
              name="family"
              checked={isFamilyInMoladin === true}
              onChange={handleChangeFamily}
            />
            <label htmlFor="yes" className="font-medium text-sm ml-3">
              Ya
            </label>
          </div>
          <div className="flex border rounded-lg items-center justify-center w-full py-3">
            <input
              type="radio"
              id="no"
              className="h-4 accent-primary w-4 rounded-lg border p-3"
              value="P"
              name="family"
              checked={isFamilyInMoladin === false}
              onChange={handleChangeFamily}
            />
            <label htmlFor="no" className="font-medium text-sm ml-3">
              Tidak
            </label>
          </div>
        </div>

        {isFamilyInMoladin && (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            {fields.map((field, index) => {
              return (
                <section key={field.id} className="mt-6">
                  <h3 className="mb-4 text-sm font-semibold">
                    Keluarga #{index + 1}
                  </h3>

                  <InputWrapper
                    label="Nama Keluarga"
                    error={
                      errors.family_in_moladin?.length &&
                      errors.family_in_moladin[index]?.family_name?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`family_in_moladin.${index}.family_name`, {
                        required: 'Data harus diisi',
                      })}
                      placeholder="Masukkan nama"
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Nomor Telepon Keluarga"
                    error={
                      errors.family_in_moladin?.length &&
                      errors.family_in_moladin[index]?.phone_number?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`family_in_moladin.${index}.phone_number`, {
                        required: 'Data harus diisi',
                      })}
                      pattern="\d*"
                      maxLength={15}
                      placeholder="Masukan nomor"
                      type="number"
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Nomor NIK Keluarga"
                    error={
                      errors.family_in_moladin?.length &&
                      errors.family_in_moladin[index]?.nik?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`family_in_moladin.${index}.nik`, {
                        required: 'Data harus diisi',
                        pattern: {
                          value:
                            /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}(?!0000)\d{4}$/,
                          message: 'Format NIK salah',
                        },
                      })}
                      pattern="\d*"
                      maxLength={16}
                      placeholder="Masukkan 16 digit NIK"
                      type="number"
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Hubungan dengan Keluarga"
                    error={
                      errors.family_in_moladin?.length &&
                      errors.family_in_moladin[index]?.relation?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`family_in_moladin.${index}.relation`, {
                        required: 'Data harus diisi',
                      })}
                      placeholder="contoh: orangtua/tante/saudara"
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Posisi Keluarga di Moladin"
                    error={
                      errors.family_in_moladin?.length &&
                      errors.family_in_moladin[index]?.position?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`family_in_moladin.${index}.position`, {
                        required: 'Data harus diisi',
                      })}
                      placeholder="contoh: Dealer Consultant/Product Manager"
                    />
                  </InputWrapper>
                </section>
              );
            })}

            <button
              className="flex items-center gap-2 text-primary w-full justify-center h-11 mt-6"
              onClick={handleSubmit((data) => {
                append({
                  family_name: '',
                  position: '',
                  relation: '',
                  nik: '',
                  phone_number: '',
                });
              })}
            >
              <Plus />
              <span>Tambah Keluarga</span>
            </button>
          </form>
        )}
      </div>
      <button
        type="submit"
        disabled={isFamilyInMoladin == null && isLoading}
        className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out disabled:bg-[#9CA3AF]"
        onClick={handleSubmit(handleSubmitForm)}
      >
        Simpan dan Lanjut
      </button>
    </>
  );
};

const InputWrapper: FC<{
  children: ReactNode;
  error?: string | 0;
  label: string;
}> = ({ children, error, label }) => {
  return (
    <div className="mb-4">
      <label>
        <p className="mb-2 text-sm font-medium">{label}</p>
        {children}
        <small className="text-red-600">{error}</small>
      </label>
    </div>
  );
};

export default KeluargaDiMoladin;

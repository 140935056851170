import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  BROWSE_FILE_ERROR_MESSAGE,
  BROWSE_FILE_VALID_TYPE,
  LIMIT_UPLOAD_FILE_SIZE,
} from '../../utils/constants';
import { toast } from 'react-toastify';

interface Props {
  onChange: (val: string) => void;
  onUploadFile: (data: FormData) => Promise<AxiosResponse>;
  type: string;
  id?: string;
  setClickUploadFromFile?: (val: boolean) => void;
  clickUploadFromFile?: boolean;
}

function BrowseFileHidden({ onUploadFile, onChange, type, id, setClickUploadFromFile, clickUploadFromFile }: Props) {
  const uploadFileHandler = (file: File) => {
    if (file.size <= LIMIT_UPLOAD_FILE_SIZE) {
      const formData: FormData = new FormData();
      formData.append('image', file);
      onUploadFile(formData)
        .then((res) => {
          const fileUrl = res.data.data.url;
          onChange(fileUrl);
        })
        .catch((err) => {
          toast.error(err?.message || 'Upload file gagal');
        })
    } else {
      toast.error('Ukuran File tidak boleh lebih besar dari 2.5 Mb');
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (BROWSE_FILE_VALID_TYPE[type].includes(file.type)) {
      uploadFileHandler(file);
    } else {
      toast.error(BROWSE_FILE_ERROR_MESSAGE[type]);
    }
    if (setClickUploadFromFile) setClickUploadFromFile(false)
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  useEffect(() => {
    if (clickUploadFromFile && getRootProps().ref) {
      getRootProps().ref.current.click()
    }
  }, [clickUploadFromFile])

  return (
    <div className='asdasd' {...getRootProps()}>
      <input {...getInputProps()} id={id} />
    </div>
  );
}

export default BrowseFileHidden;

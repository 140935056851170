import React, { useEffect, useState, useRef } from 'react';
import { ChevronLeft, X } from 'react-feather';
import { PinpointAlamatUsahaType } from '../../types/AlamatUsahaTypes';
import {
  SelectedProps,
  AddressProps,
  ModalMessageProps,
} from '../../types/InformasiAlamatTypes';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import * as Assets from '../../utils/assets';
import Modal from '../atom/Modal';
import Button from '../atom/Button';
import Geocode from 'react-geocode';
import LoadingMaps from '../InformasiAlamat/LoadingMaps';
import CustomWrapper from '../atom/CustomWrapper';
import iconWarningOutOfArea from '../../../src/assets/svg/iconWarningOutOfArea.svg';
import iconWarningDisableLocation from '../../../src/assets/svg/iconWarningDisableLocation.svg';

const libraries: any = ['places'];

const PinpointLocation = (props: PinpointAlamatUsahaType) => {
  const { setActivePage, handleSaveMaps, temporaryState } = props;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_API_KEY_GOOGLE_MAP}`,
    libraries,
  });

  const inputRef = useRef<any>(null);
  const [showMarker, setShowMarker] = useState(false);
  const [isPermission, setIsPermission] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<ModalMessageProps>({
    title: '',
    description: '',
  });
  const [address, setAddress] = useState<AddressProps>({
    village: '',
    fullAddress: '',
  });
  const [selected, setSelected] = useState<SelectedProps>({
    lat: -6.223332852125561,
    lng: 106.82212022169297,
  });

  const onMarkerDragEnd = (e: any) => {
    setSelected({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const getGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setIsPermission(true);
        setShowModal(false);
        setSelected({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      function (error) {
        setIsPermission(false);
        setModalMessage({
          title: 'Penanda lokasi belum aktif',
          description:
            'Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat',
        });
        setShowModal(true);
      }
    );
  };

  const getDetailAddress = (latitude: number, longitude: number) => {
    Geocode.setApiKey(`${process.env.REACT_APP_API_KEY_GOOGLE_MAP}`);
    Geocode.fromLatLng(`${latitude}`, `${longitude}`).then(
      (response: any) => {
        const formattedAddress = response.results[0].formatted_address;
        const villageAddress =
          response.results[0]?.address_components[2]?.long_name;
        setAddress({
          village: villageAddress,
          fullAddress: formattedAddress,
        });
      },
      (error: any) => {
        throw Error(error);
      }
    );
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place?.address_components) {
      setAddress({
        village: place.address_components[0].long_name,
        fullAddress: place.formatted_address,
      });
      setSelected({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const handleClickSave = () => {
    Geocode.fromLatLng(`${selected.lat}`, `${selected.lng}`).then(
      (response: any) => {
        const data = response.results;
        const checkLocation = data.filter(
          (a: any) => a.formatted_address === 'Indonesia'
        );
        const checkPostalCode = data.filter((a: any) =>
          a.types.includes('postal_code')
        );
        let postalCode = '';

        if (checkPostalCode.length > 0) {
          postalCode = checkPostalCode[0].address_components[0].long_name;
        }

        if (checkLocation.length > 0) {
          const params = {
            lat: `${selected.lat}`,
            lng: `${selected.lng}`,
            fullAddress: address.fullAddress,
            village: address.village,
            postalCode: postalCode,
          };
          handleSaveMaps(params);
          setActivePage('form');
        } else {
          setModalMessage({
            title: 'Lokasi di Luar Jangkauan',
            description:
              'Pastikan kamu berada di lokasi dalam jangkauan Moladin',
          });
          setShowModal(true);
        }
      },
      (error: any) => {
        setShowModal(true);
        setModalMessage({
          title: 'Maaf, terjadi kesalahan',
          description: 'Silahkan ulangi pilih titik lokasi',
        });
        throw Error(error);
      }
    );
  };

  useEffect(() => {
    getGeolocation();
  }, []);

  useEffect(() => {
    getDetailAddress(selected.lat, selected.lng);
  }, [selected, isPermission]);

  useEffect(() => {
    setTimeout(() => {
      setShowMarker(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (temporaryState?.lat) {
      setTimeout(() => {
        setSelected({
          lat: Number(temporaryState?.lat),
          lng: Number(temporaryState?.lng),
        });
      }, 1000);
    }
  }, [temporaryState]);

  if (!isLoaded) return <LoadingMaps />;

  return (
    <>
      <CustomWrapper
        title="Location"
        icon={
          <ChevronLeft
            onClick={() => {
              setActivePage('form');
            }}
            width={24}
            height={24}
            className="cursor-pointer"
          />
        }
      >
        <div>
          <div className="absolute top-20 z-10 w-full pl-5 pr-5">
            <StandaloneSearchBox
              onLoad={(ref) => (inputRef.current = ref)}
              onPlacesChanged={handlePlaceChanged}
            >
              {/* <form method="post" onSubmit={(e) => {
                e.preventDefault()
                handlePlaceChanged()
                handleClickSave()
              }}> */}
              <div className="flex items-center rounded-lg bg-white w-full border h-11 p-3">
                <img src={Assets.iconSearchGrey} />
                <input
                  type="text"
                  className="w-full ml-2 focus:outline-none"
                  placeholder="Cari alamat"
                  value={searchInput}
                  onChange={handleChangeSearch}
                />
                {searchInput.length > 0 && (
                  <X color="grey" onClick={() => setSearchInput('')} />
                )}
              </div>
              {/* </form> */}
            </StandaloneSearchBox>
          </div>
          <div className="absolute bg-secondary w-full z-10 top-2/3">
            <button
              className="w-200 flex justify-center items-center p-2 rounded-md border-2 border-blue absolute right-3 bg-white bottom-5"
              onClick={getGeolocation}
            >
              <img src={Assets.iconLocationBlue} />
              <span className="text-xs ml-2 text-primary">
                Gunakan lokasi saat ini
              </span>
            </button>
          </div>
          <GoogleMap
            zoom={20}
            center={selected}
            mapContainerClassName="w-full h-60vh"
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              disableDefaultUI: true,
            }}
          >
            {showMarker && (
              <Marker
                icon={{
                  url: Assets.iconMarkerBlue,
                  scale: 7,
                }}
                position={selected}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
              />
            )}
          </GoogleMap>
          <div className="px-4 pt-4">
            <div className="flex flex-row mt-0">
              <img src={Assets.iconWarningBlue} />
              <span className="text-xs text-primary ml-2">
                Geser peta untuk menemukan titik lokasi
              </span>
            </div>
            <div className="mt-3 mb-4">
              <div className="text-sm font-semibold">{address.village}</div>
              <div className="text-sm">{address.fullAddress}</div>
            </div>
            <Button name="Simpan" disabled={!isPermission} withIcon={false} onClick={handleClickSave} />
            {!isPermission ? <small className="text-red-600">Penanda lokasi belum aktif. Hidupkan penanda lokasi di perangkatmu untuk mengatur titik alamat</small> : ''}
          </div>
        </div>
      </CustomWrapper>
      <Modal
        icon={
          modalMessage.title === 'Lokasi di Luar Jangkauan'
            ? iconWarningOutOfArea
            : iconWarningDisableLocation
        }
        iconStyle="w-max"
        showModal={showModal}
        title={modalMessage.title}
        description={modalMessage.description}
        withButton={showModal}
        handleClickButton={() => {
          setShowModal(false);
          setModalMessage({
            title: '',
            description: '',
          });
        }}
      />
    </>
  );
};

export default PinpointLocation;

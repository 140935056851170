import React from "react";
import moladinIcon from '../../assets/icons/moladinIcon.svg'

const HeaderAttendance = () => {
  return (
    <header className="p-[24px] shadow-sm">
      <img src={moladinIcon} width={114} />
    </header>
  )
}

export default HeaderAttendance
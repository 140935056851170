/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import FormGroup from '../../atom/FormGroup';
import Label from '../../atom/Label';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Calendar, Info } from 'react-feather';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  getDropdown,
  saveDraft,
} from '../../../redux/slices/dealer/dealerThunk';
import { DropdownMaster } from '../../../types/commonTypes';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ErrorAlert from '../../atom/ErrorAlert';
import { ErrorFieldObject, InformasiPribadiRequestV2 } from '../../../types/Dealer';
import Input from '../../atom/Input';
import { NAME_REGEX } from '../../../utils/constants';
import { ageValidation, handleInputNumber } from '../../../utils/helper';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import { CameraProperty, ContentControler } from '../../../types/FotoIdentitasTypes';
import PopupGuide from '../../FotoIdentitas/PopupGuide/PopupGuide';
import DrawerUploader from '../../FotoIdentitas/DrawerUploader';
import TakePhoto from '../../FotoIdentitas/TakePhoto/TakePhoto';
import { uploadImageFile } from '../../../services';
import { getDropdownCityAddress, getDropdownProvinceAddress, getDropdownSubdistrictAddress, getDropdownVillageAddress } from '../../../redux/slices/informasiAlamat/informasiAlamatThunk';
import { resetNextDropdownAddress } from '../../../redux/slices/informasiAlamat/informasiAlamatSlice';
import { DropdownMasterInformasiAlamat } from '../../../types/InformasiAlamatTypes';
import Alert from '../../atom/Alert';
import HeaderEvent from '../../Header/HeaderEvent';
import { imageKtpAlamat } from '../../../utils/assets';

type FormData = {
  full_name: string;
  dob: string;
  birth_place: string;
  phone_number?: string;
  ktp_number: string;
  gender: string;
  marriage_id: DropdownMaster;
  ktp_photo_url?: string;
  alamat_ktp: {
    address: string,
    short_address: string,
    province: DropdownMasterInformasiAlamat | undefined,
    city: DropdownMasterInformasiAlamat | undefined,
    subdistrict: DropdownMasterInformasiAlamat | undefined,
    village: DropdownMasterInformasiAlamat | undefined,
    postal_code: string,
    rt: string,
    rw: string,
  };
};

interface ErrorValidationNIK {
  isError: boolean;
  title: string;
  message: string;
}

interface ErrorResponse {
  label?: string;
  message?: string;
}

interface ErrorsAttemptObject {
  ktp_photo_url?: {
    title: string;
    message: string;
  } | null;
}

const initialContentControler: ContentControler = {
  showCamera: false,
  showPopup: false,
  contentType: '',
};

const initialCameraProperty: CameraProperty = {
  facing_mode: 'environment',
};

const InformasiPribadiV2 = () => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>();

  const dispatch = useAppDispatch();
  const dropdown = useAppSelector((state) => state.dealer.dropdown);
  const { data: dealer, loading: isLoading } = useAppSelector(
    (state) => state.dealer.dealer
  );

  const dropdownProvince = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownProvince
  );
  const dropdownCity = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownCity
  );
  const dropdownSubdistrict = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownSubdistrict
  );
  const dropdownVillage = useAppSelector(
    (state) => state.informasiAlamat.dropdownLocationAddress.dropdownVillage
  );

  const navigate = useNavigate();
  const { uuid } = useParams();

  const watchKtpNumber = watch('ktp_number');
  const watchFullName = watch('full_name');

  const [errorValdiationNIK, setErrorValidationNIK] =
    useState<ErrorValidationNIK>({
      isError: false,
      title: '',
      message: '',
    });

  const [contentControler, setContentControler] = useState(
    initialContentControler
  );

  const [showDrawer, setShowDrawer] = useState(false);

  const [cameraPorperty] = useState(initialCameraProperty);

  const dropdownStyles = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: state?.isFocused ? '#0F2CAD' : 'default',
    }),
  };

  const mappingDropdown = [
    {
      label: 'Provinsi',
      name: 'alamat_ktp.province' as any,
      options: dropdownProvince,
      placeholder: 'Pilih provinsi',
      onChange: (e: any) => handleChangeProvince(e),
      errors: errors.alamat_ktp?.province?.message,
    },
    {
      label: 'Kabupaten/Kota',
      name: 'alamat_ktp.city' as string,
      options: dropdownCity,
      placeholder: 'Pilih kabupaten/kota',
      onChange: (e: any) => handleChangeCity(e),
      errors: errors.alamat_ktp?.city?.message,
    },
    {
      label: 'Kecamatan',
      name: 'alamat_ktp.subdistrict' as string,
      options: dropdownSubdistrict,
      placeholder: 'Pilih kecamatan',
      onChange: (e: any) => handleChangeSubdistrict(e),
      errors: errors.alamat_ktp?.subdistrict?.message,
    },
    {
      label: 'Kelurahan',
      name: 'alamat_ktp.village' as any,
      options: dropdownVillage,
      placeholder: 'Pilih kelurahan',
      onChange: (e: any) => handleChangeVillage(e),
      errors: errors.alamat_ktp?.village?.message,
    },
  ];

  const detailAddress = `${watch('alamat_ktp.short_address')}, RT ${watch('alamat_ktp.rt')}/RW ${watch('alamat_ktp.rw')}, ${watch('alamat_ktp.village.name')}, ${watch('alamat_ktp.subdistrict.name')}, ${watch('alamat_ktp.city.name')}, ${watch('alamat_ktp.province.name')}, ${watch('alamat_ktp.postal_code')}`;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const payload: InformasiPribadiRequestV2 = {
      informasi_pribadi: {
        ...data,
        marriage_id: data.marriage_id.id,
        marriage_name: data.marriage_id.desc,
        dob: dayjs(data.dob).format('YYYY-MM-DD'),
        alamat_ktp: {
          address: detailAddress,
          province_id: data.alamat_ktp?.province?.id,
          city_id: data.alamat_ktp?.city?.id,
          subdistrict_id: data.alamat_ktp?.subdistrict?.id,
          village_id: data.alamat_ktp?.village?.id,
          postal_code: data.alamat_ktp?.postal_code,
          city_name: data.alamat_ktp?.city?.name,
          province_name: data.alamat_ktp?.province?.name,
          subdistrict_name: data.alamat_ktp?.subdistrict?.name,
          village_name: data.alamat_ktp?.village?.name,
          rt: data.alamat_ktp.rt,
          rw: data.alamat_ktp.rw,
          short_address: data.alamat_ktp?.short_address,
        }
      },
    };
    let allowSubmit = true;
    delete payload.informasi_pribadi.phone_number;

    if (errorValdiationNIK.isError) {
      if (
        dealer.informasi_pribadi.ktp_number ==
        payload.informasi_pribadi.ktp_number &&
        errorValdiationNIK.title !== 'NIK tidak ditemukan'
      ) {
        setError('ktp_number', {
          type: 'custom',
          message: errorValdiationNIK.title,
        });
        allowSubmit = false;
      }
    }

    if (allowSubmit) {
      const res = await dispatch(
        saveDraft({ data: payload, uuid: uuid as string })
      );

      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/dealer/registration/${uuid}/foto_identitas`);
      } else {
        let error = null;

        const r: any = res.payload;

        const errKTP = r.errors.find(
          (i: ErrorResponse) => i.label === 'ktp_number'
        );

        if (errKTP) {
          error = errKTP.message;

          setError('ktp_number', {
            type: 'custom',
            message: error,
          });
        }

        if (!error) {
          const errMessage = (res.payload as any)?.errors[0]?.message;
          toast.error(errMessage || 'Failed submit registration');
        }
      }
    }
  };

  const handleClickShowPopupGuide = (type: string) => {
    setContentControler({
      ...contentControler,
      showPopup: true,
      contentType: type,
    });
  };

  const handleRemovePhoto = (type: string, cb: any) => {
    cb(type, '');
  };

  const handleConfirmDrawerFileTaken = (
    srcUri: string,
    setValue: any,
    contentType: string
  ) => {
    if (errors[contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (errors[contentType as keyof ErrorsAttemptObject]?.type == 'custom') {
        clearErrors(contentType as keyof FormData);
      }
    }
    if (errors[contentType as keyof FormData]) {
      // error basic clear
      clearErrors(contentType as keyof FormData);
    }
    setValue(contentType, srcUri);
    setContentControler({
      ...contentControler,
      ...initialContentControler,
    });
  };

  const handleClickClosePopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: false,
      contentType: '',
    });
  };

  const handleConfirmPopupGuide = () => {
    setShowDrawer(true);
  };

  const handleShowCamera = () => {
    setContentControler({
      ...contentControler,
      showPopup: false,
      showCamera: true,
    });
  }

  const handleBackToPopupGuide = () => {
    setContentControler({
      ...contentControler,
      showPopup: true,
      showCamera: false,
    });
  };

  const handleConfirmPhotoTaken = (srcUri: string, setValue: any) => {
    if (errors[contentControler.contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (
        errors[contentControler.contentType as keyof ErrorsAttemptObject]
          ?.type == 'custom'
      ) {
        clearErrors(
          contentControler.contentType as keyof FormData
        );
      }
    }

    if (
      errors[contentControler.contentType as keyof FormData]
    ) {
      // error basic clear
      clearErrors(
        contentControler.contentType as keyof FormData
      );
    }

    setValue(contentControler.contentType, srcUri);
    setContentControler({
      ...contentControler,
      ...initialContentControler,
    });
  };

  const handleChangeProvince = (event: any) => {
    const dataProvince = 'alamat_ktp.province';
    const dataCity = 'alamat_ktp.city';
    const dataSubdistrict = 'alamat_ktp.subdistrict';
    const dataVillage = 'alamat_ktp.village';
    const dataAddress = 'alamat_ktp.short_address';
    const dataPostalCode = 'alamat_ktp.postal_code';

    setValue(dataProvince, event);

    //Trigger Next Dropdown
    const paramsGetDropdownCity = {
      queryParams: `levels=3&parent_ids=${event?.id}`,
      type: 'ktp',
    };
    dispatch(getDropdownCityAddress(paramsGetDropdownCity));

    //Reset Next Dropdown Value
    const payloadResetDropdown = {
      location: 'province',
      type: 'ktp',
    };
    dispatch(resetNextDropdownAddress(payloadResetDropdown));

    setValue(dataCity, null as any);
    setValue(dataSubdistrict, null as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeCity = (event: any) => {
    const dataCity = 'alamat_ktp.city';
    const dataSubdistrict = 'alamat_ktp.subdistrict';
    const dataVillage = 'alamat_ktp.village';
    const dataAddress = 'alamat_ktp.short_address';
    const dataPostalCode = 'alamat_ktp.postal_code';

    setValue(dataCity, event);

    //Trigger Next Dropdown
    const paramsGetDropdownSubdistrict = {
      queryParams: `levels=4&parent_ids=${event?.id}`,
      type: 'ktp',
    };
    dispatch(getDropdownSubdistrictAddress(paramsGetDropdownSubdistrict));

    //Reset Next Dropdown Value
    const payloadResetDropdown = {
      location: 'city',
      type: 'ktp',
    };
    dispatch(resetNextDropdownAddress(payloadResetDropdown));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(dataSubdistrict, null as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeSubdistrict = (event: any) => {
    const dataSubdistrict = 'alamat_ktp.subdistrict';
    const dataVillage = 'alamat_ktp.village';
    const dataAddress = 'alamat_ktp.short_address';
    const dataPostalCode = 'alamat_ktp.postal_code';

    setValue(dataSubdistrict, event);

    //Trigger Next Dropdown
    const paramsGetDropdownVillage = {
      queryParams: `levels=5&parent_ids=${event?.id}`,
      type: 'ktp',
    };
    dispatch(getDropdownVillageAddress(paramsGetDropdownVillage));

    //Reset Next Dropdown Value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(dataVillage, null as any);
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeVillage = (event: any) => {
    const dataVillage = 'alamat_ktp.village';
    const dataAddress = 'alamat_ktp.short_address';
    const dataPostalCode = 'alamat_ktp.postal_code';

    setValue(dataVillage, event);

    //Reset Next Form Value
    setValue(dataAddress, '');
    setValue(dataPostalCode, '');
  };

  useEffect(() => {
    dispatch(getDropdown());
    dispatch(getDropdownProvinceAddress('levels=2'));
  }, []);

  useEffect(() => {
    if (dealer.alamat?.alamat_ktp.province_id) {
      dispatch(getDropdownCityAddress({
        queryParams: `levels=3&parent_ids=${dealer.alamat?.alamat_ktp.province_id}`,
        type: 'ktp',
      }));
    }

    if (dealer.alamat?.alamat_ktp.city_id) {
      dispatch(getDropdownSubdistrictAddress({
        queryParams: `levels=4&parent_ids=${dealer.alamat?.alamat_ktp.city_id}`,
        type: 'ktp',
      }));
    }

    if (dealer.alamat?.alamat_ktp.subdistrict_id) {
      dispatch(getDropdownVillageAddress({
        queryParams: `levels=5&parent_ids=${dealer.alamat?.alamat_ktp.subdistrict_id}`,
        type: 'ktp',
      }));
    }
  }, [dealer.alamat]);

  useEffect(() => {
    if (dealer.alamat?.alamat_ktp.province_id) {
      const provinceValue = dropdownProvince.find(
        (item: DropdownMaster) =>
          item.id === dealer.alamat?.alamat_ktp.province_id
      );
      setValue('alamat_ktp.province', provinceValue);
    }
  }, [dealer?.alamat?.alamat_ktp?.province_id, dropdownProvince]);

  useEffect(() => {
    if (dealer.alamat?.alamat_ktp.city_id) {
      const cityValue = dropdownCity.find(
        (item: DropdownMaster) =>
          item.id === dealer.alamat?.alamat_ktp.city_id
      );
      setValue('alamat_ktp.city', cityValue);
    }
  }, [dealer?.alamat?.alamat_ktp?.city_id, dropdownCity]);

  useEffect(() => {
    if (dealer.alamat?.alamat_ktp.subdistrict_id) {
      const subdistrictValue = dropdownSubdistrict.find(
        (item: DropdownMaster) =>
          item.id === dealer.alamat?.alamat_ktp.subdistrict_id
      );
      setValue('alamat_ktp.subdistrict', subdistrictValue);
    }
  }, [dealer?.alamat?.alamat_ktp?.subdistrict_id, dropdownSubdistrict]);

  useEffect(() => {
    if (dealer.alamat?.alamat_ktp.village_id) {
      const villageValue = dropdownVillage.find(
        (item: DropdownMaster) =>
          item.id === dealer.alamat?.alamat_ktp.village_id
      );
      setValue('alamat_ktp.village', villageValue);
    }
  }, [dealer?.alamat?.alamat_ktp?.village_id, dropdownVillage]);

  useEffect(() => {
    if (dealer) {
      const dropdownMarriage = dropdown.data.marriage?.filter(
        (item: DropdownMaster) =>
          item.id === dealer.informasi_pribadi?.marriage_id
      );

      setValue('full_name', dealer.informasi_pribadi?.full_name);
      setValue('phone_number', dealer.informasi_pribadi?.phone_number);
      setValue('gender', dealer.informasi_pribadi?.gender);
      setValue('ktp_number', dealer.informasi_pribadi?.ktp_number);
      setValue('birth_place', dealer.informasi_pribadi?.birth_place);
      setValue('marriage_id', dropdownMarriage?.[0]);
      setValue('ktp_photo_url', dealer.foto_identitas?.ktp_photo_url);
      setValue('alamat_ktp.address', dealer.alamat?.alamat_ktp?.address || '');
      setValue('alamat_ktp.short_address', dealer.alamat?.alamat_ktp?.short_address || '');
      setValue('alamat_ktp.postal_code', dealer.alamat?.alamat_ktp.postal_code);
      setValue('alamat_ktp.rt', dealer.alamat?.alamat_ktp.rt || '');
      setValue('alamat_ktp.rw', dealer.alamat?.alamat_ktp.rw || '');

      if (dealer.informasi_pribadi?.dob !== null) {
        setValue('dob', dayjs(dealer.informasi_pribadi?.dob).format());
      }
      if (dealer.error_field) {
        // check for initial error_field state undefined or null
        //check error field is empty
        const isEmptyErrorField = Array.isArray(dealer.error_field);
        if (!isEmptyErrorField) {
          // check is error in current section
          const section = Object.keys(dealer.error_field)[0];
          if (section === 'informasi_pribadi') {
            // populate field error (can be hardcoded since validation vida informasi_pribadi only ktp_number field)
            const errorData =
              dealer.error_field[`${section}` as keyof ErrorFieldObject];
            setErrorValidationNIK({
              isError: true,
              title: errorData['ktp_number']['title'] || '',
              message: errorData['ktp_number']['message'] || '',
            });
          }
        }
      }
    }
  }, [dropdown, dealer.informasi_pribadi, dealer.alamat]);

  useEffect(() => {
    if (errorValdiationNIK.isError) {
      setError('ktp_number', {
        type: 'custom',
        message: errorValdiationNIK.title,
      });
    }
  }, [errorValdiationNIK]);

  useEffect(() => {
    if (errorValdiationNIK.isError) {
      if (watchKtpNumber !== dealer.informasi_pribadi?.ktp_number) {
        if (errors.ktp_number && errors.ktp_number?.type === 'custom') {
          clearErrors('ktp_number');
        }
      }
      // changes with same value
      if (watchKtpNumber === dealer.informasi_pribadi?.ktp_number) {
        setError('ktp_number', {
          type: 'custom',
          message: errorValdiationNIK.title,
        });
      }
    }
  }, [watchKtpNumber]);

  useEffect(() => {
    if (watchFullName !== dealer.informasi_pribadi?.full_name) {
      if (errors.ktp_number && errors.ktp_number?.type === 'custom') {
        clearErrors('ktp_number');
      }
    }
  }, [watchFullName]);

  return (
    <div>
      {errorValdiationNIK.isError && (
        <div className="mb-5">
          <ErrorAlert
            title={errorValdiationNIK.title}
            message={errorValdiationNIK.message}
            isMessageHtml={true}
          />
        </div>
      )}
      <HeaderEvent data={dealer} />

      <h3 className="font-semibold mb-4">Foto & Hasil KTP</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        {!contentControler.showPopup && !contentControler.showCamera && (
          <>

            <FormGroup className="mb-4">
              <Label name="Foto KTP" />
              <Controller
                rules={{ required: 'Foto KTP harus diisi' }}
                control={control}
                name="ktp_photo_url"
                render={({ field }) => (
                  <>
                    <InputTakePhotoWithDrawer
                      imageLabel="Foto KTP"
                      placeholder="Ambil foto Ktp"
                      onSelectCamera={() => handleClickShowPopupGuide('ktp_photo_url')}
                      srcUri={field.value}
                      handleRemovePhoto={() => handleRemovePhoto('ktp_photo_url', setValue)}
                      onBrowseFile={() => handleClickShowPopupGuide('ktp_photo_url')}
                      onSelectFile={(src) => {
                        handleConfirmDrawerFileTaken(
                          src,
                          setValue,
                          'ktp_photo_url'
                        );
                      }}
                      withoutDrawer
                      onShowGuide={() => handleClickShowPopupGuide('ktp_photo_url')}
                    />
                  </>
                )}
              />
              <small className="text-red-600">
                {errors.ktp_photo_url?.message}
              </small>
            </FormGroup>

            <h3 className="font-semibold mb-4">Informasi Pribadi Sesuai KTP</h3>
            <FormGroup>
              <Label name="NIK" />
              <Controller
                rules={{
                  required: 'Data harus diisi',
                  pattern: {
                    value:
                      /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12]|81|82|83|84|85|86)\d{2}\d{2}([04][1-9]|[1256]\d|[37][01])(0[1-9]|1[0-2])\d{2}(?!0000)\d{4}$/,
                    message: 'Format KTP salah',
                  },
                }}
                control={control}
                name="ktp_number"
                render={({ field }) => (
                  <Input
                    {...field}
                    pattern="\d*"
                    maxLength={16}
                    placeholder="Masukkan 16 digit nomor KTP"
                    onInput={(event) => {
                      const { value } = event.target;
                      if (value.length <= 16) {
                        field.onChange(event);
                      } else if (value.length > 16) {
                        event.target.value = value.slice(0, 16);
                        field.onChange(event);
                      }
                    }}
                    onKeyDown={handleInputNumber}
                    type="tel"
                  />
                )}
              />
              <small className="text-red-600">{errors.ktp_number?.message}</small>
            </FormGroup>

            <FormGroup>
              <Label name="Nama Lengkap" />
              <Controller
                rules={{
                  required: 'Data harus diisi',
                  pattern: {
                    value: NAME_REGEX,
                    message:
                      'Nama tidak boleh mengandung special character (contoh: ! ? & $ # “)',
                  },
                }}
                control={control}
                name="full_name"
                render={({ field }) => (
                  <Input {...field} placeholder="Masukkan nama di sini" />
                )}
              />
              <small className="text-red-600">{errors.full_name?.message}</small>
            </FormGroup>
            <FormGroup className="relative">
              <Label name="Tempat dan Tanggal Lahir" />
              <Controller
                control={control}
                rules={{ required: 'Data harus diisi' }}
                name="birth_place"
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Masukkan kota tempat lahir"
                    className=""
                  />
                )}
              />
              <small className="text-red-600">{errors.birth_place?.message}</small>

              <Controller
                rules={{ required: 'Data harus diisi', validate: (value) => ageValidation(value, 18) }}
                control={control}
                name="dob"
                render={({ field: { onChange, value } }) => (
                  <div className="relative">
                    <Flatpickr
                      onChange={onChange}
                      options={{
                        disableMobile: true,
                        minDate: dayjs().subtract(65, 'year').format('YYYY-MM-DD'),
                      }}
                      value={value}
                      placeholder="Pilih tanggal lahir"
                      className="w-full cursor-pointer border rounded-lg h-11 p-3 mt-4 focus:outline-primary"
                    />
                    <Calendar className="absolute top-[25px] right-5" />
                    <small className="text-red-600">{errors.dob?.message}</small>
                  </div>
                )}
              />
            </FormGroup>

            <FormGroup>
              <Label name="Jenis Kelamin" />
              <Controller
                rules={{ required: 'Data harus diisi' }}
                control={control}
                name="gender"
                render={({ field }) => (
                  <>
                    <div className="flex space-x-2">
                      <div className="flex border rounded-lg items-center justify-center w-full py-3">
                        <input
                          {...field}
                          id="laki"
                          type="radio"
                          className="h-4 accent-primary w-4 rounded-lg border p-3"
                          placeholder="Jenis Kelamin"
                          checked={field.value === 'L'}
                          value="L"
                        />
                        <label htmlFor="laki" className="font-medium text-sm ml-3">
                          Laki-laki
                        </label>
                      </div>
                      <div className="flex border rounded-lg items-center justify-center w-full py-3">
                        <input
                          {...field}
                          type="radio"
                          id="perempuan"
                          checked={field.value === 'P'}
                          className="h-4 accent-primary w-4 rounded-lg border p-3"
                          placeholder="Jenis Kelamin"
                          value="P"
                        />
                        <label
                          htmlFor="perempuan"
                          className="font-medium text-sm ml-3"
                        >
                          Perempuan
                        </label>
                      </div>
                    </div>
                    <small className="text-red-600">{errors.gender?.message}</small>
                  </>
                )}
              />
            </FormGroup>

            <FormGroup className="mb-5">
              <Label name="Status Perkawinan" />
              <Controller
                rules={{ required: 'Data harus diisi' }}
                control={control}
                name="marriage_id"
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      isSearchable={false}
                      options={dropdown.data.marriage}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      getOptionLabel={(option) => option.desc!}
                      getOptionValue={(option) => option.id?.toString()}
                      placeholder="Pilih status perkawinan"
                    />

                    <small className="text-red-600">
                      {errors.marriage_id?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>

            <h3 className="font-semibold mb-4">Alamat Sesuai KTP</h3>
            <Alert className="mb-3">
              <span className="text-xs text-neutral-800">Pastikan alamat yang diisi sesuai dengan yang tertera di KTP</span>
            </Alert>
            {mappingDropdown.map((dt) => (
              <FormGroup className="mb-5" key={dt.name}>
                <Label name={dt.label} />
                <Controller
                  control={control}
                  name={dt.name}
                  rules={{ required: 'Data harus diisi' }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        className="text-sm"
                        onChange={dt.onChange}
                        options={dt.options}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id.toString()}
                        placeholder={dt.placeholder}
                        styles={dropdownStyles}
                        isSearchable={false}
                      />
                      <small className="text-red-600">{dt.errors}</small>
                    </>
                  )}
                />
              </FormGroup>
            ))}

            <FormGroup className="mb-5">
              <Label name="RT/RW" />
              <div className="flex gap-x-2 items-center">
                <Controller
                  control={control}
                  name="alamat_ktp.rt"
                  rules={{ required: 'Data harus diisi' }}
                  render={({ field }) => (
                    <div className="flex-1">
                      <input
                        {...field}
                        type="tel"
                        placeholder="Masukkan RT"
                        className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                        onKeyDown={handleInputNumber}
                      />
                    </div>
                  )}
                />
                <div>
                  /
                </div>
                <Controller
                  control={control}
                  name="alamat_ktp.rw"
                  rules={{ required: 'Data harus diisi' }}
                  render={({ field }) => (
                    <div className="flex-1">
                      <input
                        {...field}
                        placeholder="Masukkan RW"
                        className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                        onKeyDown={handleInputNumber}
                        type="tel"
                      />
                    </div>
                  )}
                />
              </div>
              {(errors.alamat_ktp?.rt?.message || errors.alamat_ktp?.rw?.message) && (
                <div className="flex gap-x-2">
                  <div className="flex-1">
                    <small className="text-red-600">
                      {errors.alamat_ktp?.rt?.message}
                    </small>
                  </div>
                  <div className="opacity-0">/</div>
                  <div className="flex-1">
                    <small className="text-red-600">
                      {errors.alamat_ktp?.rw?.message}
                    </small>
                  </div>
                </div>
              )}
            </FormGroup>

            <FormGroup className="mb-5">
              <div className="flex items-center mb-2 gap-x-1">
                <Label name="Kode Pos" className="mb-0" />
              </div>
              <Controller
                control={control}
                name="alamat_ktp.postal_code"
                rules={{ required: 'Data harus diisi' }}
                render={({ field }) => (
                  <>
                    <input
                      {...field}
                      pattern="\d*"
                      placeholder="Masukkan kode pos"
                      className="rounded-lg w-full border h-11 p-3 mb-1 text-sm focus:outline-primary"
                      onKeyDown={handleInputNumber}
                      type="tel"
                    />
                    <small className="text-red-600">
                      {errors.alamat_ktp?.postal_code?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>

            <FormGroup className="mb-5">
              <Label name="Alamat" />
              <div className="flex gap-x-1 items-center">
                <Info size={10} color="#FFF" fill="#002DB4" />
                <span className="text-xs">Contoh di KTP</span>
              </div>
              <img src={imageKtpAlamat} className="mb-3" />
              <Controller
                control={control}
                name="alamat_ktp.short_address"
                rules={{ required: 'Data harus diisi' }}
                render={({ field }) => (
                  <>
                    <textarea
                      {...field}
                      rows={3}
                      className="rounded-lg w-full border p-3 text-sm focus:outline-primary"
                      placeholder="Masukkan detail alamat"
                    />
                    <small className="text-red-600">
                      {errors.alamat_ktp?.short_address?.message}
                    </small>
                  </>
                )}
              />
            </FormGroup>
            {watch('alamat_ktp.short_address') &&
              watch('alamat_ktp.rt') &&
              watch('alamat_ktp.rw') &&
              watch('alamat_ktp.village') &&
              watch('alamat_ktp.subdistrict') &&
              watch('alamat_ktp.city') &&
              watch('alamat_ktp.province') &&
              watch('alamat_ktp.postal_code') && (
                <FormGroup className="mb-5 bg-[#FAFAFA] rounded-lg p-4">
                  <h3 className="font-semibold mb-1">Detail Alamat</h3>
                  <p>
                    {detailAddress}
                  </p>
                </FormGroup>
              )}

            <button
              type="submit"
              disabled={isLoading}
              className={`rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out ${isLoading ? 'bg-disabled' : 'bg-secondary'}`}
            >
              {isLoading ? 'Sedang menyimpan...' : 'Simpan dan Lanjut'}
            </button>
          </>
        )}
      </form>
      {contentControler.showPopup && (
        <PopupGuide
          handleClose={handleClickClosePopupGuide}
          handleConfirm={handleConfirmPopupGuide}
          contentType={contentControler.contentType}
        />
      )}
      {showDrawer && (
        <DrawerUploader
          allowPDF={false}
          onSelectCamera={handleShowCamera}
          onSelectFile={src => {
            handleConfirmDrawerFileTaken(
              src,
              setValue,
              'ktp_photo_url'
            );
          }}
          setShowDrawer={setShowDrawer} />
      )}
      {contentControler.showCamera && (
        <TakePhoto
          handleConfirmPhoto={(src) => handleConfirmPhotoTaken(src, setValue)}
          handleBack={handleBackToPopupGuide}
          cameraProperty={cameraPorperty}
          cameraType={contentControler.contentType}
          onUploadPhotoTaken={uploadImageFile}
        />
      )}
    </div>
  );
};

export default InformasiPribadiV2;

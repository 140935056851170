import { useLocation } from "react-router-dom";
import CryptoJS from 'crypto-js';

export const getQueryParam = (key: string) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return queryParams.get(key);
};

export const featureFlag = (ff: any) => {
  const flag = process.env[ff]

  if (!flag || flag.toString().toLowerCase() === 'false') {
    return false
  } else {
    return true
  }
}

// handling backward compatibility OCR flow v1 or v2
export const employeeV2BackwardCompability = (lastSectionId: number) => {
  switch (lastSectionId) {
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
      return true
    default:
      return false
  }
}

// age validation based on dob
export const ageValidation = (dob: string | null | undefined, validateAge: number) => {
  if (dob) {
    const currentYear = new Date().getFullYear() as number
    const dobYear = new Date(dob).getFullYear() as number
    const currentAge = currentYear - dobYear

    return currentAge < validateAge ? `Umur tidak boleh di bawah ${validateAge} tahun` : true
  }
}

// cek kelengkpan nomor npwp
export const isValidNpwp = (value: string | null | undefined) => {
  if (!value) {
    return true
  }

  const pattern = /_/;
  return !pattern.test(value) ? true : 'NPWP Harus lengkap';
};

export const decryptor = (data: any) => {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENCRYPTION_SECRET_KEY || '');
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
}

export const handleInputNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/\d/.test(event.key) && event.key !== "Backspace") {
    event.preventDefault();
  }
}
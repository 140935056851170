import React from 'react';

type Props = {
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  name?: string;
  id?: string;
  onChange?: () => void;
};

const TextArea = ({
  className,
  placeholder,
  defaultValue,
  value,
  name,
  id,
  onChange,
}: Props) => {
  return (
    <textarea
      rows={3}
      name={name}
      id={id}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      className={`rounded-lg w-full border p-3 ${className}`}
    />
  );
};

export default TextArea;

import React, { useState } from 'react'

type Props = {
  title?: string;
  content?: any;
  contentClass?: string
  label?: any;
  open?: boolean;
}

const AccordionItem = ({ title, content, contentClass, label, open }: Props) => {
  const [isOpen, setOpen] = useState<boolean>(open || false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <div className="border-b mb-2">
      <div
        className="flex items-center justify-between py-4 cursor-pointer"
        onClick={handleClick}
      >
        <div>
          <h3 className="font-medium text-sm">{title}</h3>
          {label}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`w-4 h-4 transition-transform ${isOpen ? "transform rotate-180" : ""
            }`}
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </div>
      {isOpen && <div className={`p-4 ${contentClass || ''}`}>{content}</div>}
    </div>
  );
};

export default AccordionItem
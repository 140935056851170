import axios, { AxiosResponse } from 'axios';
import { DokumenPendukungTypes, LimitPembiayaanRequest } from './types/Dealer';
import { CheckBankAccountNumberProps } from './types/InformasiRekening';

const urlUpload = `${process.env.REACT_APP_API_URL}/ecosystem/upload-file`;
const urlUploadEmployee = `${process.env.REACT_APP_API_URL_EMPLOYEE}/ecosystem/upload-file`;

export const uploadImageFile = (data: FormData): Promise<AxiosResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(urlUpload, data, config);
};

export const uploadImageFileEmployee = (data: FormData): Promise<AxiosResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(urlUploadEmployee, data, config);
};

export const uploadTakenImageFile = (
  data: FormData
): Promise<AxiosResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(urlUpload, data, config);
};

export const uploadTakenImageFileEmployee = (
  data: FormData
): Promise<AxiosResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(urlUploadEmployee, data, config);
};


export const uploadPdfFile = (data: FormData): Promise<AxiosResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(urlUpload, data, config);
};

export const uploadFile = (data: FormData): Promise<AxiosResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(urlUpload, data, config);
};

export const submitFormRegistration = (
  data: DokumenPendukungTypes | LimitPembiayaanRequest,
  uuid: string
): Promise<AxiosResponse> => {
  if (localStorage.getItem('token') !== null) {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + localStorage.getItem('token');
  }
  return axios.post(
    `${process.env.REACT_APP_API_URL}/ecosystem/onboarding/md/registration/submit/${uuid}`,
    data
  );
};

export const checkBankAccountNumber = (
  data: CheckBankAccountNumberProps
): Promise<AxiosResponse> => {
  let serviceName;
  let signatureKey;

  if (process.env.REACT_APP_ENV === 'production') {
    signatureKey = '$2a$10$UpDdpRPk1hJRljQshuaJy.tbTDE015uzl/H6HogDm2VwqysXT0USq';
    serviceName = 'defi-user-service';
  } else {
    signatureKey = '$2a$10$A7K4bBe/zlbB2Td.EFgb6uvSUkS0ZOSdWcNFAN6QWW3AkMMp0YNl6';
    serviceName = 'internal-payment';
  }

  axios.defaults.headers.common['X-Service-Name'] = serviceName;
  axios.defaults.headers.common['X-Request-Signature'] = signatureKey;

  return axios.post(
    `${process.env.REACT_APP_API_URL_PAYMENT_INVOICE_GATEWAY}/v3/account/validate`,
    data
  );
};

export const resendWhatsappNotif = (uuid: string): Promise<AxiosResponse> => {
  if (localStorage.getItem('token') !== null) {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + localStorage.getItem('token');
  }
  return axios.post(
    `${process.env.REACT_APP_API_URL}/ecosystem/onboarding/md/resend-umbrella-agreement/${uuid}`
  );
};

type GetLocationByLatLongType = {
  queryParams: string;
};

export const getLocationByLatLong = (
  data: GetLocationByLatLongType
): Promise<AxiosResponse> => {
  if (localStorage.getItem('token') !== null) {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + localStorage.getItem('token');
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL_GEOSPATIAL}/v1/q?${data?.queryParams}`
  );
};

export const submitFormRevision = (
  data: any,
  uuid: string
): Promise<AxiosResponse> => {
  if (localStorage.getItem('token') !== null) {
    axios.defaults.headers.common.Authorization =
      'Bearer ' + localStorage.getItem('token');
  }
  return axios.put(
    `${process.env.REACT_APP_API_URL}/ecosystem/revision/${uuid}/documents`,
    data
  );
};
import React from 'react';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import Rejected from '../../assets/svg/rejected.svg';

const RejectedPage = ({ dataEmployee }: any) => {
  return (
    <div className="px-4">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className="-mx-4 mb-[118px]" />
        <div className="flex flex-col items-center justify-center ">
          <img src={Rejected} alt="moladin" className="my-[22px]" />
          <p className="font-bold mb-2">Pendaftaran telah ditolak</p>
          <span className="text-[#4B5563] text-center text-xs w-[328px] leading-4 h-[34px]">
            {
              !dataEmployee?.data.rejected_blacklist ? dataEmployee?.data.rejected_reason :
                'Kamu tidak memenuhi kriteria atau syarat yang berlaku di Moladin'
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default RejectedPage;

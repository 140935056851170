import React, { useRef, useCallback, useState, useEffect } from 'react';
import { X } from 'react-feather';
import Webcam from 'react-webcam';
import FrameCamera from '../FotoIdentitas/TakePhoto/FrameCamera';
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
interface Props {
  title: string | undefined;
  cameraOptions: {
    width?: number;
    height?: number;
    facingMode: string;
  };
  handleTakePhoto: (imageUrl: string) => void;
  handleBack: () => void;
  cameraType: string;
}
const rotateImage = (imageBase64: any, cb: any) => {
  const img = new Image();
  img.src = imageBase64;
  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = img.height;
    canvas.height = img.width;
    const ctx = canvas.getContext("2d");
    ctx?.setTransform(1, 0, 0, 1, img.height / 2, img.width / 2);
    ctx?.rotate(-90 * (Math.PI / 180));
    ctx?.drawImage(img, -img.width / 2, -img.height / 2);
    cb(canvas.toDataURL("image/jpeg"))
  };
}
export default function Camera({
  title,
  cameraOptions,
  handleTakePhoto,
  handleBack,
  cameraType,
}: Props) {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [cameraReady, setCameraReady] = useState<boolean>(false);
  const [errorCamera, setErrorCamera] = useState<string>('');
  const webcamRef = useRef<Webcam>(null);
  const capture = useCallback((types: any, width: any) => {
    const imageSrc = webcamRef.current?.getScreenshot();
    rotateImage(imageSrc, (url: any) => {
      if (width <= 900) {
        if (url && types == "family_card_url") {
          handleTakePhoto(url);
        }
      }
    })
    if (width > 900 || types != "family_card_url") {
      if (imageSrc) {
        handleTakePhoto(imageSrc);
      }
    }
    // handleTakePhoto(imageSrc as string);

    // const img = new Image();
    // img.src = imageSrc as string;
    // img.onload = () => {
    //   console.log('imageSrcimageSrcimageSrcimageSrc', img.naturalWidth, img.naturalHeight);
    // }

  }, [webcamRef]);

  const handleUserMedia = () => {
    setCameraReady(true);
  };

  const handleUserMediaError = (err: any) => {
    console.error(err?.name);
    setErrorCamera(err?.message);
  };

  useEffect(() => {
    const orientationScreen = window.screen.orientation;
    if (orientationScreen && orientationScreen.lock) {
      orientationScreen
        .lock('portrait')
        .then(() => console.log('orientation locked'))
        .catch((err) => console.log(err));
    }

    return () => {
      if (orientationScreen) {
        try {
          orientationScreen.unlock();
        } catch (error) {
          console.error(error);
        }
      }
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 w-full h-full">
      <div className="w-full h-full relative">
        <div className="z-20 absolute top-0 left-0  right-0 py-4 bg-black">
          <div className="flex items-center px-5 text-white">
            <X
              onClick={handleBack}
              width={24}
              height={24}
              className="cursor-pointer"
            />
            <div className="w-full">
              <p className="text-center mb-0">{title}</p>
            </div>
          </div>
        </div>
        <div className="w-full h-full bg-white">
          <Webcam
            onUserMedia={handleUserMedia}
            className="w-full h-full object-cover"
            // width={windowSize.innerWidth > 900 ? 1280 : windowSize.innerWidth}
            // height={windowSize.innerWidth > 900 ? 720 : windowSize.innerWidth}
            minScreenshotHeight={1000}
            minScreenshotWidth={1400}
            audio={false}
            ref={webcamRef}
            // forceScreenshotSourceSize={true}
            screenshotQuality={1}
            screenshotFormat="image/jpeg"
            videoConstraints={cameraOptions}
            imageSmoothing={true}
            onUserMediaError={handleUserMediaError}
          />
        </div>
        {cameraReady ? (
          <FrameCamera type={cameraType} handleCapture={capture} />
        ) : (
          <>
            {errorCamera ? (
              <div className="absolute top-[56px] pt-7 left-0 right-0 bottom-0 m-auto text-center">
                {`camera error: ${errorCamera}`}
              </div>
            ) : (
              <div
                className={`
                inline-block
                h-8
                w-8
                animate-spin
                rounded-full
                border-4
                border-solid
                border-current
                border-r-transparent
                align-[-0.125em]
                text-primary
                motion-reduce:animate-[spin_1.5s_linear_infinite]
                absolute
                top-[56px]
                left-0
                right-0
                bottom-0
                m-auto
            `}
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

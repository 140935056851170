import React, { useEffect, FC, ReactNode, useState, useRef } from 'react';
import { Plus, Trash2 } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as Assets from '../../../../utils/assets';
import iconWarningRed from '../../../../assets/svg/iconWarningRed.svg';
import FormGroup from '../../../atom/FormGroup';
import Label from '../../../atom/Label';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { Calendar } from 'react-feather';
import { Controller, SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import Input from '../../../atom/Input';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getDropdownFamilyJob, getDropdownFamilyStatus } from '../../../../redux/slices/employee/employeeThunk';
import { DropdownFamilyStatusJob, FamilyMember } from '../../../../types/Employee';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ErrorAlert from '../../../atom/ErrorAlert';
import debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { isEqualWith, isObject } from 'lodash';
import { resetOCR } from '../../../../redux/slices/employee/employeeSlice';
import { saveDraftV2, checkKK } from '../../../../redux/slices/employee/employeeThunk';
import AccordionItem from '../../../atom/AccrodionItem';
import { PAGE_SECTION_FORM } from '../../../../utils/constants';
// import { dummykk as dataOCR } from './dummyKK';

export type FormData = {
    family_card_url: string,
    kk_number: string,
    place_kk: string
    family_employee?: FamilyMember[]
};
interface ErrorValidationNIK {
    isError: boolean;
    title: string;
    message: string;
}

interface ErrorResponse {
    label?: string;
    message?: string;
}

interface Props {
    family_card_url: string;
    errorCode?: number | null | undefined;
    showPopup: () => void
}

const dropdownStyles = {
    control: (base: any, state: any) => ({
        ...base,
        borderColor: state?.isFocused ? '#0F2CAD' : 'default',
    }),
};

const InformasiKK = ({ family_card_url, errorCode, showPopup }: Props) => {
    const dispatch = useAppDispatch();
    const dropdown = useAppSelector((state) => state.dealer.dropdown);
    const [isNoCandidate, setIsNoCandidate] = useState(false)
    const [isEdited, setIsEdited] = useState(false)
    const [isUpdatedFields, setIsUpdatedFields] = useState(false)
    const [isInvalidKK, setIsInvalidKK] = useState({ invalid: false, message: '' })
    const navigate = useNavigate();
    const { uuid } = useParams();
    const { data: employee, kkOCR: dataOCR, loading: isLoading } = useAppSelector(
        (state) => state.employee.employee
    );

    const dropdownFamilyJob = useAppSelector(
        (state) => state.employee.dropdown.dataFamilyJob
    );
    const dropdownFamilyStatus = useAppSelector(
        (state) => state.employee.dropdown.dataFamilyStatus
    );

    const defaultFamilyEmployee: FamilyMember[] = (dataOCR?.kk_sub || []).map((sub) => ({
        family_name: sub.nama.value || '',
        family_nik: sub.nik.value || '',
        family_status_id: sub.status_hubungan.value || 0,
        family_status_dropdown: { id: '', name: '' } as DropdownFamilyStatusJob,
        family_job: sub.pekerjaan.value || 0,
        family_job_dropdown: { id: '', name: '', desc: '' } as DropdownFamilyStatusJob,
        family_dob: sub.tgl_lahir.value || '',
    }
    ));

    const {
        register,
        handleSubmit,
        control,
        setValue,
        getValues,
        watch,
        setError,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            family_employee: defaultFamilyEmployee
        },
    });

    const { fields, append, prepend, update, remove } = useFieldArray({
        name: 'family_employee',
        control,
    });

    const errorLabel = dataOCR?.label === 'failed_to_extract'
    useEffect(() => {
        dispatch(getDropdownFamilyJob());
        dispatch(getDropdownFamilyStatus());
        if (errorCode && !errorLabel) {
            append({ family_name: '', family_nik: '', family_status_id: '', family_status_dropdown: { id: '', name: '' }, family_job: '', family_job_dropdown: { id: '', name: '', desc: '' }, family_dob: '' })
            append({ family_name: '', family_nik: '', family_status_id: '', family_status_dropdown: { id: '', name: '' }, family_job: '', family_job_dropdown: { id: '', name: '', desc: '' }, family_dob: '' })
        }
    }, []);

    useEffect(() => {
        if (dropdownFamilyStatus.length > 0 && dropdownFamilyJob.length > 0) {
            const mapKKSub = dataOCR?.kk_sub || []
            const sanitizedStr = (str: string) => str.replace(/ |\//g, '').toLowerCase();
            mapKKSub.map((sub, idx) => {
                update(idx,
                    {
                        ...fields[idx],
                        family_status_dropdown: {
                            id: dropdownFamilyStatus.find(el => sanitizedStr(sub.status_hubungan.value).includes(sanitizedStr(el.name)))?.id as string,
                            name: dropdownFamilyStatus.find(el => sanitizedStr(sub.status_hubungan.value).includes(sanitizedStr(el.name)))?.name as string
                            ,
                        },
                        family_job_dropdown: {
                            id: dropdownFamilyJob.find(el => sanitizedStr(sub.pekerjaan.value).includes(sanitizedStr(el.desc || '')))?.id as string,
                            name: dropdownFamilyJob.find(el => sanitizedStr(sub.pekerjaan.value).includes(sanitizedStr(el.desc || '')))?.desc as string
                        }
                    }
                )
            })
            if (employee?.foto_hasil_kk_v2?.family_employee.length > 0) {
                const existMember = employee?.foto_hasil_kk_v2?.family_employee
                existMember.map((exi, idx) => {
                    update(idx,
                        {
                            ...fields[idx],
                            family_name: exi.family_name,
                            family_dob: exi.family_dob,
                            family_nik: exi.family_nik,
                            family_status_id: exi.family_status_id,
                            family_job: exi.family_job,
                            family_status_dropdown: {
                                id: dropdownFamilyStatus.find(el => exi.family_status_id === el.id)?.id as string,
                                name: dropdownFamilyStatus.find(el => exi.family_status_id === el.id)?.name as string,
                            },
                            family_job_dropdown: {
                                id: dropdownFamilyJob.find(el => exi.family_job === el.id)?.id as string,
                                name: dropdownFamilyJob.find(el => exi.family_job === el.id)?.desc as string,
                            }
                        }
                    )
                })
            }
            setIsUpdatedFields(true)
        }
    }, [dropdownFamilyStatus, dropdownFamilyJob])

    useEffect(() => {
        if (!errorLabel && Object.values(dataOCR).length > 0 && dataOCR.kk.nomor_kk.value) {
            const checkKKCall = async () => {
                const res = await dispatch(checkKK({ kk_number: dataOCR.kk.nomor_kk.value, uuid: uuid as string }))
                if (res.payload.status === 'ERROR') {
                    setIsInvalidKK({ invalid: true, message: res.payload?.errors[0].message })
                }
            }
            checkKKCall()
        }
    }, [dataOCR]);

    const handleCheckKKNumber = async (e: any) => {
        const res = await dispatch(checkKK({ kk_number: e.target.value, uuid: uuid as string }))
        const requestStatus = res.meta.requestStatus;
        if (requestStatus === 'fulfilled') setIsInvalidKK({ invalid: false, message: '' })
        if (res.payload.status === 'ERROR') {
            setIsInvalidKK({ invalid: true, message: res.payload?.errors[0]?.message })
            return
        }
    };

    const handleChangeKtpNumber = useRef(
        debounce((e) => {
            handleCheckKKNumber(e);
        }, 500)
    ).current;

    const [errorValdiationNIK, setErrorValidationNIK] =
        useState<ErrorValidationNIK>({
            isError: false,
            title: '',
            message: '',
        });

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const isFoundCandidate = data.family_employee?.some(el => el.family_nik === employee.informasi_pribadi.ktp_number)
        const isDataExist = employee.foto_hasil_kk_v2.kk_number
        if (!isFoundCandidate) {
            return setIsNoCandidate(true)
        } else {
            setIsNoCandidate(false)
        }

        const modifiedEmployee = [...data.family_employee || []]
        modifiedEmployee.map((el, idx) => {
            const date = typeof el.family_dob === 'string' ? el.family_dob.split('-') : '';
            modifiedEmployee[idx].id = employee.foto_hasil_kk_v2.family_employee[idx]?.id || ''
            // modifiedEmployee[idx].family_dob = typeof modifiedEmployee[idx].family_dob === 'string' ? `${date[2]}-${date[1]}-${date[0]}` : dayjs(modifiedEmployee[idx].family_dob).format('YYYY-MM-DD')
            modifiedEmployee[idx].family_dob = typeof el.family_dob === 'string' ? el.family_dob : dayjs(modifiedEmployee[idx].family_dob).format('YYYY-MM-DD')
            modifiedEmployee[idx].family_job = modifiedEmployee[idx].family_job_dropdown?.id as number
            modifiedEmployee[idx].family_status_id = modifiedEmployee[idx].family_status_dropdown?.id as number
        })

        const payload = {
            foto_hasil_kk_v2: {
                ...data,
                family_card_url: employee.foto_hasil_kk_v2.family_card_url || family_card_url,
                family_employee: modifiedEmployee
            },
        };

        payload.foto_hasil_kk_v2.family_employee.map(el => {
            !isDataExist && delete el.id
            delete el.family_job_dropdown
            delete el.family_status_dropdown
        })

        const res = await dispatch(
            saveDraftV2({ data: payload, uuid: uuid as string })
        );
        dispatch(resetOCR())

        if (res.meta.requestStatus === 'fulfilled') {
            navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.DATA_PELENGKAP_ALAMAT}`);
        } else {
            let error = null;

            const r: any = res.payload;

            const errKK = r.errors.find(
                (i: ErrorResponse) => i.label === 'ktp_number'
            );

            if (errKK) {
                error = errKK.message;
                setError('family_card_url', {
                    type: 'custom',
                    message: error,
                });
            }

            if (!error) {
                const errMessage = (res.payload as any)?.errors[0]?.message;
                toast.error(errMessage || 'Failed submit registration');
            }
        }
    };

    useEffect(() => {
        if (isUpdatedFields) {
            getValues('family_employee')?.map((el, idx) => {
                if (!el.family_status_dropdown?.id || el.family_status_dropdown?.id === undefined) {
                    setError(`family_employee.${idx}.family_status_dropdown`, {
                        type: 'custom',
                        message: 'Data harus diisi',
                    });
                }
                if (!el.family_job_dropdown?.id || el.family_job_dropdown?.id === undefined) {
                    setError(`family_employee.${idx}.family_job_dropdown`, {
                        type: 'custom',
                        message: 'Data harus diisi',
                    });
                }
            })
        }
    }, [isUpdatedFields])

    useEffect(() => {
        setValue('family_card_url', employee?.foto_hasil_kk_v2?.family_card_url || '');
        setValue('kk_number', employee?.foto_hasil_kk_v2?.kk_number || dataOCR.kk?.nomor_kk?.value);
        setValue('place_kk', employee?.foto_hasil_kk_v2?.place_kk || dataOCR.kk?.kabupaten_kota?.value);
    }, [dropdown, employee?.foto_hasil_ktp_v2]);

    const WarningAlert = (props: any) => (
        <div className="bg-lightyellow mt-2 p-2 text-sm border border-darkyellow rounded-lg flex flex-row">
            <div className="mr-2 w-6">
                <img src={Assets.iconWarningOrange} style={{ width: '18px' }} />
            </div>
            {
                props.isKtp ?
                    <div>Sebagian data tidak dapat terbaca dengan jelas, mohon cek kembali data informasi pribadi</div> :
                    <div>Mohon cek kesesuaian data {props.name}</div>
            }
        </div>
    )

    const DangerAlert = (props: any) => (
        <div className="bg-red-100 mt-2 p-2 text-sm border border-red-300 rounded-lg">
            <div className='flex flex-row'>
                <div className="mr-2 w-6">
                    <img src={iconWarningRed} style={{ width: '18px' }} />
                </div>
                {
                    (props.isKTP && errorCode || !errorLabel) ?
                        <div>Data di foto tidak terbaca, silakan lakukan pengisian manual</div> :
                        <div>Data di foto tidak terbaca, silakan upload foto ulang</div>
                }
            </div>
            {!errorCode && errorLabel && <button
                type='button'
                className='ml-7 mt-3 text-primary font-bold'
                onClick={showPopup}
            >Panduan foto</button>}
        </div>
    )

    const renderAlert = (status: number, name: string, isKtp?: boolean) => {
        switch (status) {
            case 3:
                return <WarningAlert name={name} isKtp={isKtp} />
            // case 4:
            //     return <DangerAlert name={name} isKtp={isKtp} />
            default:
                return;
        }
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const isEmptyOCR = Object.values(dataOCR).length === 0
            const isDataExist = employee.foto_hasil_kk_v2.kk_number
            const sanitizedStr = (str: string) => str.replace(/ |\//g, '').toLowerCase();
            if (!errorCode && !isEmptyOCR) {
                const existEmployee = dataOCR;
                const formEmployee = getValues();
                if (existEmployee.kk_sub) {
                    for (let i = 0; i < existEmployee?.kk_sub?.length; i++) {
                        const originalFamily = existEmployee.kk_sub?.[i];
                        const newFamily = formEmployee.family_employee?.[i];
                        if (
                            watch('family_card_url') !== existEmployee.url_photo ||
                            watch('place_kk') !== existEmployee.kk.kabupaten_kota.value ||
                            watch('kk_number') !== existEmployee.kk.nomor_kk.value ||
                            watch('family_employee')?.length !== existEmployee.kk_sub?.length ||
                            originalFamily.nama.value !== newFamily?.family_name ||
                            originalFamily.nik.value !== newFamily?.family_nik ||
                            sanitizedStr(originalFamily.pekerjaan.value) !== sanitizedStr(newFamily?.family_job_dropdown?.name as string) ||
                            sanitizedStr(originalFamily.status_hubungan.value) !== sanitizedStr(newFamily?.family_status_dropdown?.name as string)
                        ) {
                            return setIsEdited(true)
                        }
                    }
                }
                return setIsEdited(false)
            }
            if (isDataExist && fields.length > 0) {
                const existEmployee = employee.foto_hasil_kk_v2;
                const formEmployee = getValues();
                for (let i = 0; i < existEmployee.family_employee.length; i++) {
                    const originalFamily = existEmployee.family_employee?.[i];
                    const newFamily = formEmployee.family_employee?.[i];

                    if (
                        watch('family_card_url') !== existEmployee.family_card_url ||
                        watch('place_kk') !== existEmployee.place_kk ||
                        watch('kk_number') !== existEmployee.kk_number ||
                        watch('family_employee')?.length !== existEmployee.family_employee?.length ||
                        originalFamily.family_name !== newFamily?.family_name ||
                        originalFamily.family_nik !== newFamily?.family_nik ||
                        originalFamily.family_job !== newFamily?.family_job_dropdown?.id ||
                        originalFamily.family_status_id !== newFamily?.family_status_dropdown?.id
                    ) {
                        return setIsEdited(true)
                    }
                }
                return setIsEdited(false)
            }
            if (isEmptyOCR) {
                const formEmployee = getValues();
                for (let i = 0; i < fields.length; i++) {
                    const originalFamily = fields?.[i];
                    const newFamily = formEmployee.family_employee?.[i];
                    if (
                        watch('family_card_url') ||
                        watch('place_kk') ||
                        watch('kk_number') ||
                        watch('family_employee') ||
                        originalFamily.family_name !== newFamily?.family_name ||
                        originalFamily.family_nik !== newFamily?.family_nik ||
                        originalFamily.family_job !== newFamily?.family_job_dropdown?.id ||
                        originalFamily.family_status_id !== newFamily?.family_status_dropdown?.id
                    ) {
                        return setIsEdited(true)
                    }
                }
                return setIsEdited(false)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, fields])

    return (
        <div>
            {errorValdiationNIK.isError && (
                <div className="mb-5">
                    <ErrorAlert
                        title={errorValdiationNIK.title}
                        message={errorValdiationNIK.message}
                        isMessageHtml={true}
                    />
                </div>
            )}
            {
                !errorLabel && isEdited &&
                <div className='bg-blue-100 items-start flex -ml-4 -mt -mr-4 mb-3 px-4 py-3 -mt-4'>
                    <img src={Assets.iconWarningBlue} className='mr-2 mt-1' style={{ width: '18px' }} />
                    <p className='text-sm mb-0'>
                        <span className='font-semibold'>Data diedit atau diisi secara manual</span><br />
                        Pengisian data secara manual membutuhkan waktu verifikasi lebih lama</p>
                </div>
            }
            <form className='pb-52' onSubmit={handleSubmit(onSubmit)}>
                <img className='rounded-xl h-56 object-cover w-full mb-2' src={employee?.foto_identitas?.family_card_url || family_card_url} />
                {(errorCode || errorLabel) && <DangerAlert isKTP />}
                {!errorLabel && Object.values(dataOCR).length > 0 && Object.values(dataOCR?.kk).some(el => el.status === 3) && <WarningAlert isKtp />}

                {/* wrong */}
                {/* {!errorCode && dataOCR && Object.values(dataOCR?.kk ?? {}).some(el => (el.status as number) === 3) && <WarningAlert isKtp={true} />} */}

                {
                    (errorCode || !errorLabel) &&
                    <>
                        <h3 className="font-semibold my-4">Informasi KK</h3>
                        <FormGroup>
                            <Label name="Nomor KK" />
                            <Controller
                                rules={{ required: 'Data harus diisi' }}
                                control={control}
                                name="kk_number"
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        onInput={handleChangeKtpNumber}
                                        type="number"
                                        pattern="\d*"
                                        placeholder="Masukkan nomor KK"
                                    />
                                )}
                            />
                            <small className="text-red-600">{errors.kk_number?.message}</small>
                            {renderAlert(dataOCR?.kk?.nomor_kk?.status, 'Nomor KK')}
                        </FormGroup>

                        <FormGroup>
                            <Label name="Kabupaten/Kota" />
                            <Controller
                                rules={{ required: 'Data harus diisi' }}
                                control={control}
                                name="place_kk"
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        placeholder="Masukkan nama kabupaten/kota"
                                    />
                                )}
                            />
                            <small className="text-red-600">{errors.kk_number?.message}</small>
                            {renderAlert(dataOCR?.kk?.kabupaten_kota?.status, 'Kabupaten/Kota')}
                        </FormGroup>

                        {
                            fields.map((el, idx) => (
                                <AccordionItem key={idx} contentClass='!p-0' title={`Keluarga #${idx + 1}`}
                                    label={<small className="text-red-600">
                                        {errors.family_employee?.[idx]?.family_status_dropdown?.message}
                                    </small>}
                                    content={
                                        <>
                                            <div className='-mt-2 mb-4'>
                                                {
                                                    watch(`family_employee.${idx}.family_status_dropdown`)?.id === 1 &&
                                                    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-3">Kepala Keluarga</span>
                                                }
                                                {
                                                    watch(`family_employee.${idx}.family_nik`) as string === employee.informasi_pribadi.ktp_number as string &&
                                                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10">Kandidat Karyawan</span>
                                                }
                                            </div>
                                            <InputWrapper
                                                label="Nama Keluarga"
                                                error={
                                                    errors.family_employee?.length &&
                                                    errors.family_employee[idx]?.family_name?.message
                                                }
                                            >
                                                <input
                                                    className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                                                    {...register(`family_employee.${idx}.family_name`, {
                                                        required: 'Data harus diisi',
                                                    })}
                                                    placeholder="Masukkan nama"
                                                />
                                                {renderAlert(dataOCR?.kk_sub?.[idx]?.nama?.status || 1, 'Nama')}
                                            </InputWrapper>
                                            <InputWrapper
                                                label="NIK Keluarga"
                                                error={
                                                    errors.family_employee?.length &&
                                                    errors.family_employee[idx]?.family_nik?.message
                                                }
                                            >
                                                <input
                                                    {...register(`family_employee.${idx}.family_nik`, {
                                                        required: 'Data harus diisi',
                                                        pattern: {
                                                            value:
                                                                /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}(?!0000)\d{4}$/,
                                                            message: 'Format NIK salah',
                                                        },
                                                    })}
                                                    pattern="\d*"
                                                    maxLength={16}
                                                    className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                                                    placeholder="Masukkan NIK"
                                                />
                                                {renderAlert(dataOCR?.kk_sub?.[idx]?.nik?.status || 1, 'NIK')}
                                            </InputWrapper>

                                            <FormGroup className="mb-5">
                                                <Label name="Status Keluarga" />
                                                <Controller
                                                    rules={{ required: 'Data harus diisi' }}
                                                    control={control}
                                                    name={`family_employee.${idx}.family_status_dropdown`}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...register(`family_employee.${idx}.family_status_dropdown.id`, {
                                                                    required: 'Data harus diisi',
                                                                })}
                                                                {...field}
                                                                options={dropdownFamilyStatus}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.id?.toString()}
                                                                placeholder="Pilih status keluarga"
                                                                className="text-sm"
                                                                onChange={(e) => setValue(`family_employee.${idx}.family_status_dropdown`, { id: e?.id as string, name: e?.name as string })}
                                                                isSearchable={true}
                                                                styles={dropdownStyles}
                                                            />
                                                            <small className="text-red-600">
                                                                {
                                                                    errors.family_employee?.length &&
                                                                    errors.family_employee[idx]?.family_status_dropdown?.id?.message
                                                                }
                                                            </small>
                                                            {renderAlert(dataOCR?.kk_sub?.[idx]?.status_hubungan?.status || 1, 'Status Keluarga')}
                                                        </>
                                                    )}
                                                />
                                            </FormGroup>

                                            <FormGroup className="relative">
                                                <Label name="Tanggal Lahir" />
                                                <Controller
                                                    rules={{ required: 'Data harus diisi' }}
                                                    control={control}
                                                    name={`family_employee.${idx}.family_dob`}
                                                    render={({ field: { onChange, value } }) => (
                                                        <div className="relative">
                                                            <Flatpickr
                                                                {...register(`family_employee.${idx}.family_dob`, {
                                                                    required: 'Data harus diisi',
                                                                })}
                                                                onChange={onChange}
                                                                options={{
                                                                    disableMobile: true,
                                                                    minDate: dayjs(`family_employee.${idx}.family_dob`).format('YYYY-MM-DD'),
                                                                }}
                                                                value={value}
                                                                placeholder="Pilih tanggal lahir"
                                                                className="w-full cursor-pointer border rounded-lg h-11 p-3 focus:outline-primary"
                                                            />
                                                            <Calendar className="absolute top-[8px] right-5" />
                                                            <small className="text-red-600">
                                                                {
                                                                    errors.family_employee?.length &&
                                                                    errors.family_employee[idx]?.family_dob?.message
                                                                }
                                                            </small>
                                                            {renderAlert(dataOCR?.kk_sub?.[idx]?.tgl_lahir?.status || 1, 'Tanggal Lahir')}
                                                        </div>
                                                    )}
                                                />
                                            </FormGroup>

                                            <FormGroup className="mb-5">
                                                <Label name="Jenis Pekerjaan" />
                                                <Controller
                                                    rules={{ required: 'Data harus diisi' }}
                                                    control={control}
                                                    name={`family_employee.${idx}.family_job_dropdown`}
                                                    render={({ field }) => (
                                                        <>
                                                            <Select
                                                                {...register(`family_employee.${idx}.family_job_dropdown.id`, {
                                                                    required: 'Data harus diisi',
                                                                })}
                                                                {...field}
                                                                options={dropdownFamilyJob}
                                                                getOptionLabel={(option) => (option.name || option.desc || '')}
                                                                getOptionValue={(option) => option.id?.toString()}
                                                                placeholder="Pilih pekerjaan"
                                                                className="text-sm"
                                                                onChange={(e) => setValue(`family_employee.${idx}.family_job_dropdown`, { id: e?.id as string, name: e?.name as string, desc: e?.desc as string })}
                                                                isSearchable={true}
                                                                styles={dropdownStyles}
                                                            />
                                                            <small className="text-red-600">
                                                                {
                                                                    errors.family_employee?.length &&
                                                                    errors.family_employee[idx]?.family_job_dropdown?.id?.message
                                                                }
                                                            </small>
                                                            {renderAlert(dataOCR?.kk_sub?.[idx]?.pekerjaan?.status || 1, 'Pekerjaan')}
                                                        </>
                                                    )}
                                                />
                                            </FormGroup>

                                            {
                                                fields.length > 1 &&
                                                <button
                                                    className="flex items-center gap-2 text-red-400 w-full font-semibold justify-center h-11 mb-3 text-sm"
                                                    onClick={() => remove(idx)}
                                                >
                                                    <Trash2 size={16} />
                                                    <span>Hapus Keluarga #{idx + 1}</span>
                                                </button>
                                            }
                                        </>
                                    } />
                            )) ?? []
                        }
                        {
                            fields.length !== 10 &&
                            <button
                                className="flex items-center gap-2 text-primary w-full font-semibold justify-center h-11 mt-3 text-sm "
                                onClick={handleSubmit((data) => {
                                    append({
                                        family_name: '',
                                        family_nik: '',
                                        family_status_id: '',
                                        family_status_dropdown: { id: '', name: '' } as DropdownFamilyStatusJob || 0,
                                        family_job: '',
                                        family_job_dropdown: { id: '', desc: '' } as DropdownFamilyStatusJob || 0,
                                        family_dob: ''
                                    });
                                })}
                            >
                                <Plus />
                                <span>Tambah Keluarga</span>
                            </button>
                        }
                    </>
                }

                <div className='fixed bottom-0 -ml-4 p-4 bg-white w-full'>
                    {
                        isNoCandidate &&
                        <div className='bg-blue-100 items-start flex -ml-4 -mr-4 mb-3 px-4 py-3'>
                            <img src={Assets.iconWarningBlue} className='mr-2 mt-1' style={{ width: '18px' }} />
                            <p className='text-sm mb-0'>NIK calon karyawan tidak tercantum di Kartu Keluarga ini. Pastikan Kartu Keluarga yang diupload sesuai</p>
                        </div>
                    }
                    {
                        isInvalidKK.invalid &&
                        <div className='bg-red-100 items-start flex -ml-4 -mr-4 mb-3 px-4 py-3'>
                            <div className="mr-2 w-6">
                                <img src={iconWarningRed} style={{ width: '18px' }} />
                            </div>
                            <p className='text-sm mb-0'>{isInvalidKK.message}</p>
                        </div>
                    }
                    <button
                        type={!errorCode && errorLabel ? 'button' : 'submit'}
                        onClick={() => !errorCode && errorLabel ? showPopup() : undefined}
                        disabled={isLoading}
                        className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out disabled:bg-neutral-400 disabled:text-white"
                    >
                        {!errorCode && errorLabel ? 'Foto Ulang' : 'Simpan dan Lanjut'}
                    </button>
                </div>
            </form >
        </div >
    );
};


const InputWrapper: FC<{
    children: ReactNode;
    error?: string | 0;
    label: string;
}> = ({ children, error, label }) => {
    return (
        <div className="mb-4">
            <label>
                <p className="mb-2 text-sm font-medium">{label}</p>
                {children}
                <small className="text-red-600">{error}</small>
            </label>
        </div>
    );
};

export default InformasiKK;

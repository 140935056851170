import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { getDealerConsultantList } from "./dealerConsultanThunk";
import { DealerConsultant, DealerConsultantDropdown } from "../../../types/DealerConsultanTypes";

export const dealerConsultantSlice = createSlice({
  name: 'dealerConsultan',
  initialState: {
    data: [] as DealerConsultantDropdown[],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDealerConsultantList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDealerConsultantList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.map((item: DealerConsultant) => ({ label: item.fullName, value: item.id, dcm: item.dcm }));
      });
  },
});

export const reducer = combineReducers({
  dealerConsultant: dealerConsultantSlice.reducer
});

export default reducer
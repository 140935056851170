import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import ReactFlagsSelect from 'react-flags-select';
import { useFieldArray, useForm } from 'react-hook-form';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import { uploadTakenImageFile, uploadTakenImageFileEmployee } from '../../../services';
import Select from 'react-select';
import { COMPANY_INDUSTRY } from '../../../utils/companyIndustryData';
import { saveDraftV2 } from '../../../redux/slices/employee/employeeThunk';
import { PreviousCompanyRequest } from '../../../types/Employee';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import TakePhotoGeneral from '../../Camera/TakePhotoGeneral';
import InputTakePhotoWithDrawerEmployee from '../../FotoIdentitas/InputTakePhotoWithDrawerEmployee';
const MAX_REFERENCE = 5;

type FormValues = {
  previous_company: Company[];
};

interface Company {
  company_name: string;
  industry: string;
  previous_position: string;
  reference_position: string;
  reference_name: string;
  reference_phone: string;
  salary_slip: string;
}

const PekerjaanSebelumnya = () => {
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [hasReference, setHasReference] = useState<boolean | null>(null);
  const { data: employee, loading: isLoading } = useAppSelector(
    (state) => state.employee.employee
  );
  const [showCamera, setShowCamera] = useState<{ id?: number; show?: boolean }>(
    {}
  );

  const handleChangeReference = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasReference(e.target.value === 'Y');
    reset();
  };

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    reset,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      previous_company: [{}, {}],
    },
  });

  const { fields, append } = useFieldArray({
    name: 'previous_company',
    control,
  });

  const handleSubmitForm = async (e: FormValues) => {
    const payload: PreviousCompanyRequest = {
      previous_company: {
        has_experience: hasReference ? 1 : 0,
        reference_data: [...e.previous_company],
      },
    };

    const res = await dispatch(
      saveDraftV2({ data: payload, uuid: uuid as string })
    );

    const requestStatus = res.meta.requestStatus;
    if (requestStatus === 'fulfilled') {
      handleNavigateNextStep();
    }

    if (requestStatus === 'rejected') {
      const errMessage = (res.payload as any)?.errors[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const handleNavigateNextStep = () => {
    navigate(`/employee/registration/${uuid}/cv`);
  };

  const handleChangeImage = (key: any, value: string) => {
    setValue(key, value, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (employee?.referensi?.reference?.length !== 0) {
      let isHaveReference = null;
      switch (employee?.referensi?.has_experience) {
        case null:
          break;
        case 1:
          isHaveReference = true;
          break;
        case 0:
          isHaveReference = false;
          break;
        default:
          break;
      }
      setHasReference(isHaveReference);
      const reference = employee?.referensi?.reference?.map((ref) => ({
        company_name: ref.company_name,
        industry: ref.industry,
        previous_position: ref.previous_position,
        reference_position: ref.referece_position,
        reference_name: ref.reference_name,
        reference_phone: ref.reference_phone,
        salary_slip: ref.salary_slip,
      }));
      setValue('previous_company', reference);
    }
  }, [employee]);

  return (
    <>
      <div className="flex-1 pb-5">
        <h3 className="font-semibold mb-4">Referensi</h3>
        <p className="text-xs">
          Isi referensi yang pernah bekerjasama dengan kamu
        </p>
        <h4 className="font-medium text-sm mb-4">
          Apakah kamu punya pengalaman kerja?
        </h4>

        <div className="flex space-x-2">
          <div className="flex border rounded-lg items-center justify-center w-full py-3">
            <input
              id="yes"
              type="radio"
              className="h-4 accent-primary w-4 rounded-lg border p-3"
              value="Y"
              name="hasReference"
              checked={hasReference === true}
              onChange={handleChangeReference}
            />
            <label htmlFor="yes" className="font-medium text-sm ml-3">
              Ya
            </label>
          </div>
          <div className="flex border rounded-lg items-center justify-center w-full py-3">
            <input
              type="radio"
              id="no"
              className="h-4 accent-primary w-4 rounded-lg border p-3"
              value="N"
              name="hasReference"
              checked={hasReference === false}
              onChange={handleChangeReference}
            />
            <label htmlFor="no" className="font-medium text-sm ml-3">
              Tidak
            </label>
          </div>
        </div>
        {hasReference && (
          <p className="text-xs text-[rgba(55,65,81,1)] py-4">
            Masukkan minimal 2 referensi (bisa dari perusahaan yang sama)
          </p>
        )}

        {hasReference === false && (
          <p className="text-xs text-[rgba(55,65,81,1)] py-4">
            Masukkan minimal 2 referensi
          </p>
        )}

        {hasReference && (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            {fields.map((field, index) => {
              return (
                <section key={field.id} className="mb-8">
                  <h3 className="mb-4 text-sm font-semibold">
                    Referensi #{index + 1}
                  </h3>

                  <InputWrapper
                    label="Nama Perusahaan Sebelumnya"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.company_name?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`previous_company.${index}.company_name`, {
                        required: 'Data harus diisi',
                      })}
                      placeholder="Masukkan nama perusahaan sebelumnya "
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Industri"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.industry?.message
                    }
                  >
                    <Select
                      options={COMPANY_INDUSTRY.map((d) => ({
                        label: d,
                        value: d,
                      }))}
                      value={
                        getValues(`previous_company.${index}.industry`)
                          ? {
                              label: getValues(
                                `previous_company.${index}.industry`
                              ),
                              value: getValues(
                                `previous_company.${index}.industry`
                              ),
                            }
                          : undefined
                      }
                      onChange={(opt) =>
                        setValue(
                          `previous_company.${index}.industry`,
                          opt?.value || '',
                          {
                            shouldValidate: true,
                          }
                        )
                      }
                      isSearchable
                      placeholder="Pilih industri"
                      isClearable
                    />
                    <input
                      hidden
                      {...register(`previous_company.${index}.industry`, {
                        required: 'Data harus diisi',
                      })}
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Posisi Kamu Sebelumnya"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.previous_position?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(
                        `previous_company.${index}.previous_position`,
                        {
                          required: 'Data harus diisi',
                        }
                      )}
                      placeholder="Masukkan posisi"
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Posisi Referensi dari Perusahaan"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.reference_position
                        ?.message
                    }
                  >
                    <Select
                      options={['Atasan', 'HR', 'Keluarga'].map((d) => ({
                        label: d,
                        value: d,
                      }))}
                      value={
                        getValues(
                          `previous_company.${index}.reference_position`
                        )
                          ? {
                              label: getValues(
                                `previous_company.${index}.reference_position`
                              ),
                              value: getValues(
                                `previous_company.${index}.reference_position`
                              ),
                            }
                          : undefined
                      }
                      onChange={(opt) =>
                        setValue(
                          `previous_company.${index}.reference_position`,
                          opt?.value || '',
                          { shouldValidate: true }
                        )
                      }
                      isSearchable
                      isClearable
                      placeholder="Pilih posisi referensi kamu"
                    />
                    <input
                      hidden
                      {...register(
                        `previous_company.${index}.reference_position`,
                        {
                          required: 'Data harus diisi',
                        }
                      )}
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Nama Referensi"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.reference_name?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`previous_company.${index}.reference_name`, {
                        required: 'Data harus diisi',
                      })}
                      placeholder="Masukkan nama "
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Nomor WA Referensi"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.reference_phone?.message
                    }
                  >
                    <div className="relative">
                      <ReactFlagsSelect
                        selected={'ID'}
                        className="absolute disabled:bg-[#F3F4F6]"
                        onSelect={(code) => console.log(code)}
                        countries={['ID']}
                        customLabels={{ ID: '+62' }}
                      />

                      <input
                        className={`pl-[120px] rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                        {...register(
                          `previous_company.${index}.reference_phone`,
                          {
                            required: 'Data harus diisi',
                          }
                        )}
                        placeholder="81234567890 "
                        pattern="\d*"
                        type="number"
                      />
                    </div>
                  </InputWrapper>

                  <p className="mb-2 text-sm font-medium">
                    Slip Gaji Kamu (opsional)
                  </p>
                  <InputTakePhotoWithDrawerEmployee
                    imageLabel="Slip Gaji"
                    placeholder="Upload Slip Gaji"
                    onSelectCamera={() =>
                      setShowCamera({ id: index, show: true })
                    }
                    srcUri={
                      getValues(`previous_company.${index}.salary_slip`) || ''
                    }
                    handleRemovePhoto={() =>
                      handleChangeImage(
                        `previous_company.${index}.salary_slip`,
                        ''
                      )
                    }
                    onBrowseFile={() => null}
                    onSelectFile={(src) =>
                      handleChangeImage(
                        `previous_company.${index}.salary_slip`,
                        src
                      )
                    }
                    allowPDF
                  />
                  {showCamera.id === index && showCamera.show && (
                    // <div className="fixed h-full w-full top-0 left-0">
                    <TakePhotoGeneral
                      handleConfirmPhoto={(src) => {
                        handleChangeImage(
                          `previous_company.${index}.salary_slip`,
                          src
                        );
                        setShowCamera({ id: index, show: false });
                      }}
                      handleBack={() =>
                        setShowCamera({ id: index, show: false })
                      }
                      cameraProperty={{ facing_mode: 'environment' }}
                      onUploadPhotoTaken={uploadTakenImageFileEmployee}
                      title="Slip Gaji"
                      previewDescription="Foto Slip Gaji Kamu"
                    />
                    // </div>
                  )}
                </section>
              );
            })}

            {getValues('previous_company').length < MAX_REFERENCE && (
              <button
                className="flex items-center gap-2 text-primary w-full justify-center h-11 mt-6"
                onClick={handleSubmit((data) => {
                  append({
                    company_name: '',
                    previous_position: '',
                    industry: '',
                    reference_name: '',
                    reference_phone: '',
                    reference_position: '',
                    salary_slip: '',
                  });
                })}
              >
                <Plus />
                <span>Tambah Referensi</span>
              </button>
            )}
          </form>
        )}

        {hasReference === false && (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            {fields.map((field, index) => {
              return (
                <section key={field.id} className="mt-6">
                  <h3 className="mb-4 text-sm font-semibold">
                    Referensi #{index + 1}
                  </h3>

                  <InputWrapper
                    label="Hubungan dengan Referensi"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.reference_position
                        ?.message
                    }
                  >
                    <Select
                      options={['Kolega', 'Guru/Dosen', 'Lainnya'].map((d) => ({
                        label: d,
                        value: d,
                      }))}
                      value={
                        getValues(
                          `previous_company.${index}.reference_position`
                        )
                          ? {
                              label: getValues(
                                `previous_company.${index}.reference_position`
                              ),
                              value: getValues(
                                `previous_company.${index}.reference_position`
                              ),
                            }
                          : undefined
                      }
                      onChange={(opt) =>
                        setValue(
                          `previous_company.${index}.reference_position`,
                          opt?.value || '',
                          { shouldValidate: true }
                        )
                      }
                      isSearchable
                      isClearable
                      placeholder="Pilih posisi referensi kamu"
                    />
                    <input
                      hidden
                      {...register(
                        `previous_company.${index}.reference_position`,
                        {
                          required: 'Data harus diisi',
                        }
                      )}
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Nama Referensi"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.reference_name?.message
                    }
                  >
                    <input
                      className={`rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                      {...register(`previous_company.${index}.reference_name`, {
                        required: 'Data harus diisi',
                      })}
                      placeholder="Masukkan nama "
                    />
                  </InputWrapper>

                  <InputWrapper
                    label="Nomor WA Referensi"
                    error={
                      errors.previous_company?.length &&
                      errors.previous_company[index]?.reference_phone?.message
                    }
                  >
                    <div className="relative">
                      <ReactFlagsSelect
                        selected={'ID'}
                        className="absolute disabled:bg-[#F3F4F6]"
                        onSelect={(code) => console.log(code)}
                        countries={['ID']}
                        customLabels={{ ID: '+62' }}
                      />

                      <input
                        className={`pl-[120px] rounded-lg w-full border h-11 p-3 focus:outline-primary`}
                        {...register(
                          `previous_company.${index}.reference_phone`,
                          {
                            required: 'Data harus diisi',
                          }
                        )}
                        placeholder="81234567890 "
                        pattern="\d*"
                        type="number"
                      />
                    </div>
                  </InputWrapper>
                </section>
              );
            })}

            {getValues('previous_company').length < MAX_REFERENCE && (
              <button
                className="flex items-center gap-2 text-primary w-full justify-center h-11 mt-6"
                onClick={handleSubmit((data) => {
                  append({
                    company_name: '',
                    previous_position: '',
                    industry: '',
                    reference_name: '',
                    reference_phone: '',
                    reference_position: '',
                    salary_slip: '',
                  });
                })}
              >
                <Plus />
                <span>Tambah Referensi</span>
              </button>
            )}
          </form>
        )}
      </div>

      <button
        type="submit"
        disabled={hasReference == null && isLoading}
        className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out disabled:bg-[#9CA3AF]"
        onClick={handleSubmit(handleSubmitForm)}
      >
        Simpan dan Lanjut
      </button>
    </>
  );
};

const InputWrapper: FC<{
  children: ReactNode;
  error?: string | 0;
  label: string;
}> = ({ children, error, label }) => {
  return (
    <div className="mb-4">
      <label>
        <p className="mb-2 text-sm font-medium">{label}</p>
        {children}
        <small className="text-red-600">{error}</small>
      </label>
    </div>
  );
};

export default PekerjaanSebelumnya;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getWarehouseList = createAsyncThunk(
  'master/fetchWarehouse',
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/crm/master/warehouses`
      )

      return response.data
    } catch (error: any) {
      throw error?.response?.data
    }
  }
)
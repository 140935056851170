const cdnUrl = process.env.REACT_APP_CDN_URL

//icons
export const iconLocationBlue = cdnUrl + '/icon-map-location.svg';
export const iconMarkerOrange = cdnUrl + '/icon-alamat-marker.svg';
export const iconMarkerBlue = cdnUrl + '/icon-map-marker.svg';
export const iconSearchGrey = cdnUrl + '/icon-map-search.svg';
export const iconWarningBlue = cdnUrl + '/icon-map-instruction.svg';
export const iconWarningOrange = cdnUrl + '/iconWarningOrange.svg';
export const imageRekeningKoran = cdnUrl + '/imageRekeningKoran.svg';

export const iconBtnRefreshOrange = cdnUrl + '/iconBtnRefreshOrange.svg';
export const iconChecklistGreen = cdnUrl + '/iconChecklistGreen.svg';
export const iconDocumentBlue = cdnUrl + '/iconDocumentBlue.svg';
export const iconDocumentGray = cdnUrl + '/iconDocumentGray.svg';
export const iconWhatsappWhite = cdnUrl + '/iconWhatsappWhite.png';
export const imageAfterValidation = cdnUrl + '/imageAfterValidation.svg';
export const imageWaitingConsent = cdnUrl + '/imageWaitingConsent.svg';

//images
export const imageKtpGuideBlur = cdnUrl + '/photoGuide/ktp/photoktp1.png';
export const imageKtpGuideLightest = cdnUrl + '/photoGuide/ktp/photoktp2.png';
export const imageKtpGuideDarkest = cdnUrl + '/photoGuide/ktp/photoktp3.png';
export const imageKtpGuideClear = cdnUrl + '/photoGuide/ktp/photoktp4.png';
export const imageKtpAlamat = cdnUrl + '/photoGuide/ktp/photoktpalamat.png';
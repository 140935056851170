import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FormPage from './pages/Dealer/FormPage';
import RevisionForm from './pages/Revision/RevisionForm';
import HomePage from './pages/Dealer/HomePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormPageEmployee from './pages/Employee/FormPageEmployee';
import InformasiUsahaPage from './pages/Dealer/InformasiUsahaPage';
import WaitingPage from './pages/Dealer/WaitingPage';
import TnCPage from './pages/Dealer/TnCPage';
import ValidationPage from './pages/Dealer/ValidationPage';
import RejectedPage from './pages/Dealer/RejectedPage';
import NotFoundPage from './pages/Dealer/NotFoundPage';
import HomePageEmployee from './pages/Employee/HomePageEmployee';
import WelcomeScreenEmployee from './pages/Employee/WelcomeScreenEmployee';
import EmployeeWaitingPage from './pages/Employee/WaitingPage';
import EmployeeTnCPage from './pages/Employee/TnCPage';
import EmployeeValidationPage from './pages/Employee/ValidationPage';
import InformasiRekeningEmployee from './components/Section/employee/InformasiRekeningEmployee';
import InformasiRekeningEmployeeBerhasil from './components/Section/employee/InformasiRekeningEmployeeBerhasil';
import MenungguPersetujuan from './pages/Revision/MenungguPersetujuan';
import Sukses from './pages/Revision/Sukses'
import RevisiExpired from './pages/Revision/revisiExpired'
import LandingPageRevisi from './pages/Revision/landingPage'
import Attendance from './pages/Attendance';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Dealer Routes */}
        <Route path="/dealer/homepage/:uuid" element={<HomePage />} />
        <Route
          path="/dealer/homepage-usaha/:uuid"
          element={<InformasiUsahaPage />}
        />
        <Route
          path="/dealer/registration/:uuid/:section_type/"
          element={<FormPage />}
        />
        <Route path="/dealer/waiting-page/:uuid" element={<WaitingPage />} />
        <Route path="/dealer/tnc/:uuid" element={<TnCPage />} />
        <Route path="/dealer/validation/:uuid" element={<ValidationPage />} />
        <Route
          path="/dealer/revision/:uuid" // type = md | dc
          element={<LandingPageRevisi />}
        />
        <Route
          path="/dealer/revision/:type/:uuid/form" // type = md | dc
          element={<RevisionForm />}
        />
        <Route
          path="/dealer/revision/:type/:uuid/waiting-approval" // type = md | dc
          element={<MenungguPersetujuan />}
        />
        <Route
          path="/dealer/revision/:type/:uuid/success" // type = md | dc
          element={<Sukses />}
        />
        <Route
          path="/dealer/revision/:type/:uuid/expired" // type = md | dc
          element={<RevisiExpired />}
        />

        {/* Employee Routes */}
        <Route path="/employee/homepage/:uuid" element={<HomePageEmployee />} />
        <Route
          path="/employee/registration/:uuid/:section_type/"
          element={<FormPageEmployee />}
        />
        <Route
          path="/employee/waiting-page/:uuid"
          element={<EmployeeWaitingPage />}
        />
        <Route path="/employee/tnc/:uuid" element={<EmployeeTnCPage />} />
        <Route
          path="/employee/validation/:uuid"
          element={<EmployeeValidationPage />}
        />

        <Route
          path="/employee/welcome/:uuid"
          element={<WelcomeScreenEmployee />}
        />
        <Route
          path="/employee/rekening/:uuid"
          element={<InformasiRekeningEmployee />}
        />
        <Route
          path="/employee/rekening-berhasil"
          element={<InformasiRekeningEmployeeBerhasil />}
        />

        {/* Attendance form */}
        <Route
          path="/attendence/:eventUuid"
          element={<Attendance />}
        />
        <Route
          path="/attendence/:eventUuid/:type"
          element={<Attendance />}
        />

        {/* Common Url */}
        <Route path="/rejected" element={<RejectedPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;

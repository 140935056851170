import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Refresh from '../../assets/icons/refresh.svg'
import Modal from '../../components/atom/Modal'
import CheckList from '../../assets/icons/checklist.svg';
import Alert from '../../assets/icons/alert.svg'

export default function LandingPageRevisi() {
    const [showSuccess, setShowSuccess] = useState<boolean>(true)
    const [count, setCount] = useState(10);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    const { type, uuid } = useParams();

    const closeSuccess = (): void => {
        setShowSuccess(false)
    }

    const handleButton = () => {
        navigate(`/dealer/revision/${type}/${uuid}/success`)
    }

    useEffect(() => {
        // Function to decrement the count and update every second
        const timer = setInterval(() => {
          setCount((prevCount) => prevCount - 1);
        }, 1000);
    
        // Clear the interval when the component is unmounted or count reaches 0
        return () => clearInterval(timer);
      }, []);
    
    const alreadySend = searchParams.get('already-send')

    return (
        <>
            <div className='p-5'>
                <div className='pt-5 pb-5 bg-secondary-pure-white'>
                    <img src='https://moladin.com/Logo.svg' />
                </div>
                {/* <div
                    onClick={handleButton}
                    className="bg-secondary rounded-lg pt-1 pb-4 w-full text-white mt-10"
                >
                    <div className='flex justify-center mt-5'>
                        <img src={Refresh} className="mr-3"/>
                        <p className="!mb-0">Perbarui Status</p>
                    </div>
                </div>
                <div>
                    <div className='text-center mt-3'>{count > 0 ? `${count} Detik sebelum bisa mengirim ulang` : ''}</div>
                </div> */}
            </div>
            {alreadySend ?
                <Modal 
                showModal={showSuccess}
                title="Revisi dokumen tidak bisa dikirim"
                icon={Alert}
                description="Sudah ada yang mengirimkan revisi dokumen"
                withButton
                handleClickButton={handleButton}
                handleClickBackground={handleButton}
                buttonTitle="Tutup"
                iconStyle="bg-[#FEEAEA] rounded-full w-[60px] object-cover p-2"
            />
            :
            <Modal 
                showModal={showSuccess}
                title="Revisi berhasil dikirim"
                icon={CheckList}
                description="Dokumen akan direview kembali oleh Credit Analyst"
                withButton
                handleClickButton={handleButton}
                handleClickBackground={handleButton}
                buttonTitle="Tutup"
                iconStyle="bg-[#eafaf1] rounded-full w-[60px] object-cover"
            />
            }
        </>
    )
}
import React, { useEffect, useState } from 'react';
import InputTakePhotoWithDrawer from '../../FotoIdentitas/InputTakePhotoWithDrawer';
import { uploadTakenImageFile, uploadTakenImageFileEmployee } from '../../../services';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  saveCV,
  saveCVV2,
  sendActiveConsent,
} from '../../../redux/slices/employee/employeeThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TakePhotoGeneral from '../../Camera/TakePhotoGeneral';
import InputTakePhotoWithDrawerEmployee from '../../FotoIdentitas/InputTakePhotoWithDrawerEmployee';
import { PAGE_SECTION_FORM } from '../../../utils/constants';

const CV = () => {
  const [dontHaveCV, setDontHaveCV] = useState<boolean | null>(false);
  const [showCamera, setShowCamera] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const dispatch = useAppDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { data: employee, loading: isLoading } = useAppSelector(
    (state) => state.employee.employee
  );
  const isV2 = employee?.last_section_id > 8

  const handleSubmit = async () => {
    const res = await dispatch(
      isV2 ? saveCVV2({
        data: { cv: dontHaveCV ? undefined : imageUrl },
        uuid: uuid as string,
      })
        : saveCV({
          data: { cv: dontHaveCV ? undefined : imageUrl },
          uuid: uuid as string,
        })
    );

    const requestStatus = res.meta.requestStatus;
    if (requestStatus === 'fulfilled') {
      isV2 ? navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.TNC}`) : handleSendActiveConsent();
    }

    if (requestStatus === 'rejected') {
      toast.error('Failed submit registration');
    }
  };

  const handleSendActiveConsent = async () => {
    const res = await dispatch(sendActiveConsent({ uuid: uuid as string }));
    const requestStatus = res.meta.requestStatus;
    if (requestStatus === 'fulfilled') {
      navigate(`/employee/waiting-page/${uuid}`);
    }
    if (requestStatus === 'rejected') {
      toast.error('Failed submit registration');
    }
  };

  useEffect(() => {
    if (!employee?.cv) return;

    let isDontHaveCV = null;
    switch (employee?.cv) {
      case null:
        break;
      case '':
        isDontHaveCV = true;
        break;
      default:
        isDontHaveCV = false;
        setImageUrl(employee?.cv || '');
        break;
    }
    setDontHaveCV(isDontHaveCV);
  }, [employee]);

  return (
    <>
      <div className="flex-1 pb-5">
        <h3 className="font-semibold mb-4">
          CV <span className="font-normal">(Opsional)</span>
        </h3>
        <p className="text-xs">
          Jika ada, sertakan CV dengan pengalaman kerja terbarumu
        </p>
        <div className="flex items-center mb-4">
          <input
            id="cv-check"
            type="checkbox"
            className="checked:bg-blue-500 mr-2"
            checked={!!dontHaveCV}
            onChange={(e) => setDontHaveCV(e.target.checked)}
          />
          <label htmlFor="cv-check" className="text-sm font-medium">
            Saya tidak punya CV
          </label>
        </div>

        {!dontHaveCV && (
          <InputTakePhotoWithDrawerEmployee
            imageLabel="CV"
            placeholder="Upload CV"
            onSelectCamera={() => setShowCamera(true)}
            srcUri={imageUrl}
            handleRemovePhoto={() => setImageUrl('')}
            onBrowseFile={() => null}
            onSelectFile={(src) => setImageUrl(src)}
            allowPDF
          />
        )}
        {showCamera && (
          <div className="fixed h-full w-full top-0 left-0">
            <TakePhotoGeneral
              handleConfirmPhoto={(src) => {
                setImageUrl(src);
                setShowCamera(false);
              }}
              handleBack={() => setShowCamera(false)}
              cameraProperty={{ facing_mode: 'environment' }}
              onUploadPhotoTaken={uploadTakenImageFileEmployee}
              title="CV"
              previewDescription="Foto CV Kamu"
            />
          </div>
        )}
      </div>

      <button
        type="submit"
        disabled={!dontHaveCV && !imageUrl && isLoading}
        className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out disabled:bg-[#9CA3AF]"
        onClick={handleSubmit}
      >
        Simpan dan Lanjut
      </button>
    </>
  );
};

export default CV;

import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { getWarehouseList } from "./warehouseThunk";
import { Warehouse, WarehouseDropdown } from "../../../types/WarehouseTypes";

export const warehouseSlice = createSlice({
  name: 'wareHouse',
  initialState: {
    data: [] as WarehouseDropdown[],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouseList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWarehouseList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.map((item: Warehouse) => ({label: item.name, value: item.warehouse_id}));
      });
  },
});

export const reducer = combineReducers({
  warehouse: warehouseSlice.reducer
});

export default reducer
import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import {
  CameraProperty,
  CommonTypesFotoIdentitas,
  VideoConstraints,
} from '../../../types/FotoIdentitasTypes';
import Canvas from './Canvas';
import { LIMIT_UPLOAD_FILE_SIZE } from '../../../utils/constants';
import Camera from '../../Camera/Camera';

interface Props {
  handleConfirmPhoto: (srcUri: string) => void;
  handleBack: () => void;
  cameraProperty: CameraProperty;
  cameraType: string;
  onUploadPhotoTaken: (data: FormData) => Promise<AxiosResponse>;
}

const titleHeader: CommonTypesFotoIdentitas = {
  ktp_photo_url: 'Ambil Foto KTP',
  family_card_url: 'Ambil Foto KK',
  selfie_url: 'Ambil Foto Selfie',
  npwp_url: 'Ambil Foto NPWP',
};

export default function TakePhoto({
  handleConfirmPhoto,
  handleBack,
  cameraProperty,
  cameraType,
  onUploadPhotoTaken,
}: Props) {
  const dispatch = useAppDispatch();
  const [videoConstraints, setVideoConstraints] = useState<VideoConstraints>({
    facingMode: cameraProperty.facing_mode,
  });
  const [loadingUpload, setLoadingUpload] = useState(Boolean);
  const [url, setUrl] = useState<string | null>(null);
  const onHandleTakePhoto = (srcUri: string) => {
    setUrl(srcUri);
  };

  const handleRetakePicture = () => {
    setUrl('');
  };

  const onConfirmPhotoTaken = (url: string) => {
    const formData = new FormData();
    const base64String = url.split(',')[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    if (blob.size <= LIMIT_UPLOAD_FILE_SIZE) {
      formData.append('image', blob, `${cameraType}.jpg`);
      setLoadingUpload(true);
      onUploadPhotoTaken(formData)
        .then((res) => {
          handleConfirmPhoto(res.data.data.url);
        })
        .catch((err) => {
          console.error(err);
          toast.error(`error upload photo: ${err?.data?.message}`);
        })
        .finally(() => {
          setLoadingUpload(false);
        });
    } else {
      toast.error('Ukuran File tidak boleh lebih besar dari 2.5 Mb');
    }
  };

  return (
    <>
      {url ? (
        <Canvas
          title={titleHeader[cameraType as keyof CommonTypesFotoIdentitas]}
          imageSrc={url}
          type={cameraType}
          handleRetakePicture={handleRetakePicture}
          onConfirmPhoto={onConfirmPhotoTaken}
          disabledButtonUpload={loadingUpload}
        />
      ) : (
        <Camera
          title={titleHeader[cameraType as keyof CommonTypesFotoIdentitas]}
          cameraOptions={videoConstraints}
          cameraType={cameraType}
          handleTakePhoto={onHandleTakePhoto}
          handleBack={handleBack}
        />
      )}
    </>
  );
}

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDealerDetail } from '../../redux/slices/dealer/dealerThunk';
import Header from '../../components/Header/Header';
import AlamatUsaha from '../../components/Section/dealer/AlamatUsaha';
import { PAGE_SECTION_FORM, WaitingStatus } from '../../utils/constants';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import InformasiPribadi from '../../components/Section/dealer/InformasiPribadi';
import FotoIdentitas from '../../components/Section/dealer/FotoIdentitas';
import InformasiAlamat from '../../components/Section/dealer/InformasiAlamat';
import InformasiRekening from '../../components/Section/dealer/InformasiRekening';
import InformasiDealer from '../../components/Section/dealer/InformasiUsaha';
import DokumenPendukung from '../../components/Section/dealer/DokumenPendukung';
import LimitPembiayaan from '../../components/Section/dealer/LimitPembiayaan';
import { featureFlag } from '../../utils/helper';
import InformasiPribadiV2 from '../../components/Section/dealer/InformasiPribadiV2';
import InformasiAlamatV2 from '../../components/Section/dealer/InformasiAlamatV2';
import DataPelengkap from '../../components/Section/dealer/DataPelengkap';

const flagOcrKkCycle = featureFlag('REACT_APP_OCR_KK_CYCLE');

const FormPage = () => {
  const { section_type, uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.dealer);

  const latestStatus = data.dealer.data?.latest_status;
  const lastSection = data.dealer.data?.last_section;

  const getDetaiDealer = async () => {
    try {
      const dealer = await dispatch(getDealerDetail(uuid as string));
      if (dealer.meta.requestStatus === 'rejected') {
        toast.error('Failed to load dealer detail');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    if (
      latestStatus &&
      latestStatus !== WaitingStatus.WAITING_REGISTRATION_FORM &&
      latestStatus !== WaitingStatus.DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT
    ) {
      navigate(`/dealer/waiting-page/${uuid}`);
    }
  }, [data]);

  useEffect(() => {
    getDetaiDealer();
  }, [navigate]);

  let page = undefined;
  let percentage = 0;

  switch (section_type) {
    case PAGE_SECTION_FORM.INFORMASI_PRIBADI:
      page = flagOcrKkCycle ? <InformasiPribadiV2 /> : <InformasiPribadi />;
      percentage = 0;
      break;
    case PAGE_SECTION_FORM.FOTO_IDENTITAS:
      page = flagOcrKkCycle ? <DataPelengkap /> : <FotoIdentitas />;
      percentage = 15;
      break;
    case PAGE_SECTION_FORM.ALAMAT:
      page = flagOcrKkCycle ? <InformasiAlamatV2 /> : <InformasiAlamat />;
      percentage = 30;
      break;
    case PAGE_SECTION_FORM.INFORMASI_REKENING:
      page = <InformasiRekening />;
      percentage = 45;
      break;
    case PAGE_SECTION_FORM.INFORMASI_USAHA:
      page = <InformasiDealer />;
      percentage = 60;
      break;
    case PAGE_SECTION_FORM.ALAMAT_USAHA:
      page = <AlamatUsaha />;
      percentage = 75;
      break;
    case PAGE_SECTION_FORM.DOKUMEN_PENDUKUNG:
      page = <DokumenPendukung />;
      percentage = 85;
      break;
    case PAGE_SECTION_FORM.LIMIT_PEMBIAYAAN:
      page = <LimitPembiayaan />;
      percentage = 100;
      break;
    default:
      page = <div>404 Not Found</div>;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header percentage={percentage} activeSession={section_type as string} />
      <div className="p-4 flex-1 flex flex-col">{page}</div>
    </div>
  );
};

export default FormPage;

import { RootState } from '../../store';
import { combineReducers, createSlice } from '@reduxjs/toolkit';
import {
  getDropdownProvinceAddress,
  getDropdownCityAddress,
  getDropdownSubdistrictAddress,
  getDropdownVillageAddress,
  getParentAddressByVillage,
} from './informasiAlamatThunk';
import { DropdownMasterInformasiAlamat } from '../../../types/InformasiAlamatTypes';

export const dropdownAddressSlice = createSlice({
  name: 'getDropdownAddressSlice',
  initialState: {
    dropdownProvince: [] as DropdownMasterInformasiAlamat[],
    dropdownProvinceLoading: false,
    dropdownCity: [] as DropdownMasterInformasiAlamat[],
    dropdownCityLoading: false,
    dropdownSubdistrict: [] as DropdownMasterInformasiAlamat[],
    dropdownSubdistrictLoading: false,
    dropdownVillage: [] as DropdownMasterInformasiAlamat[],
    dropdownVillageLoading: false,

    dropdownProvinceDom: [] as DropdownMasterInformasiAlamat[],
    dropdownProvinceDomLoading: false,
    dropdownCityDom: [] as DropdownMasterInformasiAlamat[],
    dropdownCityDomLoading: false,
    dropdownSubdistrictDom: [] as DropdownMasterInformasiAlamat[],
    dropdownSubdistrictDomLoading: false,
    dropdownVillageDom: [] as DropdownMasterInformasiAlamat[],
    dropdownVillageDomLoading: false,
    loadingParentAddress: false,
    parentAddressData: [] as DropdownMasterInformasiAlamat[],
  },
  reducers: {
    resetNextDropdownAddress: (state, action) => {
      const data = action.payload.location;
      const type = action.payload.type;
      const dropdownCity = type === 'ktp' ? 'dropdownCity' : 'dropdownCityDom'
      const dropdownSubdistrict = type === 'ktp' ? 'dropdownSubdistrict' : 'dropdownSubdistrictDom'
      const dropdownVillage = type === 'ktp' ? 'dropdownVillage' : 'dropdownVillageDom'
      switch (data) {
        case 'all':
          state[dropdownCity] = [];
          state[dropdownSubdistrict] = [];
          state[dropdownVillage] = [];
          break;
        case 'province':
          state[dropdownSubdistrict] = [];
          state[dropdownVillage] = [];
          break;
        case 'city':
          state[dropdownVillage] = [];
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDropdownProvinceAddress.pending, (state) => {
        state.dropdownProvinceLoading = true;
        state.dropdownProvinceDomLoading = true;
      })
      .addCase(getDropdownProvinceAddress.fulfilled, (state, action) => {
        state.dropdownProvinceLoading = false;
        state.dropdownProvince = action.payload;
        state.dropdownProvinceDomLoading = false;
        state.dropdownProvinceDom = action.payload;
      })
      .addCase(getDropdownCityAddress.pending, (state, action) => {
        const type = action.meta.arg.type
        const dropdownCityLoading = type === 'ktp' ? 'dropdownCityLoading' : 'dropdownCityDomLoading'
        state[dropdownCityLoading] = true;
      })
      .addCase(getDropdownCityAddress.fulfilled, (state, action) => {
        const type = action.meta.arg.type
        const dropdownCityLoading = type === 'ktp' ? 'dropdownCityLoading' : 'dropdownCityDomLoading'
        const dropdownCity = type === 'ktp' ? 'dropdownCity' : 'dropdownCityDom'
        state[dropdownCityLoading] = false;
        state[dropdownCity] = action.payload;
      })
      .addCase(getDropdownSubdistrictAddress.pending, (state, action) => {
        const type = action.meta.arg.type
        const dropdownSubdistrictLoading = type === 'ktp' ? 'dropdownSubdistrictLoading' : 'dropdownSubdistrictDomLoading'
        state[dropdownSubdistrictLoading] = true;
      })
      .addCase(getDropdownSubdistrictAddress.fulfilled, (state, action) => {
        const type = action.meta.arg.type
        const dropdownSubdistrictLoading = type === 'ktp' ? 'dropdownSubdistrictLoading' : 'dropdownSubdistrictDomLoading'
        const dropdownSubdistrict = type === 'ktp' ? 'dropdownSubdistrict' : 'dropdownSubdistrictDom'
        state[dropdownSubdistrictLoading] = false;
        state[dropdownSubdistrict] = action.payload;
      })
      .addCase(getDropdownVillageAddress.pending, (state, action) => {
        const type = action.meta.arg.type
        const dropdownVillageLoading = type === 'ktp' ? 'dropdownVillageLoading' : 'dropdownVillageDomLoading'
        state[dropdownVillageLoading] = true;
      })
      .addCase(getDropdownVillageAddress.fulfilled, (state, action) => {
        const type = action.meta.arg.type
        const dropdownVillageLoading = type === 'ktp' ? 'dropdownVillageLoading' : 'dropdownVillageDomLoading'
        const dropdownVillage = type === 'ktp' ? 'dropdownVillage' : 'dropdownVillageDom'
        state[dropdownVillageLoading] = false;
        state[dropdownVillage] = action.payload;
      })
      .addCase(getParentAddressByVillage.pending, (state, action) => {
        state.loadingParentAddress = true;
      })
      .addCase(getParentAddressByVillage.fulfilled, (state, action) => {
        state.loadingParentAddress = false;
        state.parentAddressData = action.payload;
      });
  },
});

export const { resetNextDropdownAddress } = dropdownAddressSlice.actions;

export const reducer = combineReducers({
  dropdownLocationAddress: dropdownAddressSlice.reducer,
});

export default reducer;

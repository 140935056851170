import React from 'react';
import dayjs from 'dayjs';
import { Dealer } from '../../types/Dealer';
import '../../assets/css/headerEvent.css';

interface HeaderEventProps {
  data: Dealer;
  isHomePage?: boolean;
}

const HeaderEvent: React.FC<HeaderEventProps> = ({ data, isHomePage }) => {
  return (
    <div className={`w-full px-4 ${isHomePage ? 'shadow-md custom-shadow' : ''}`}>
      {data?.event ? (
        <div
          className={
            isHomePage
              ? 'w-full bg-white flex-col justify-start items-start gap-4 m-0 px-1 pt-4'
              : 'w-full bg-white flex-col justify-start items-start gap-4 p-4 inline-flex h-[75px] rounded-xl shadow mb-4'
          }
        >
          <div className="flex-col justify-start items-start gap-1 flex">
            <div className="w-[296px] text-gray-800 text-base font-semibold font-['Metropolis'] leading-snug capitalize">
              {data?.event?.name || ""}{' '}
            </div>
            <div className="self-stretch justify-start items-center gap-1 inline-flex">
              <div className="text-gray-500 text-xs font-medium font-['Metropolis'] leading-none">
                {data?.event?.city || ""}
              </div>
              <div className="text-gray-500 text-xs font-medium font-['Metropolis'] leading-none">
                •
              </div>
              <div className="text-gray-500 text-xs font-medium font-['Metropolis'] leading-none">
                {dayjs(data?.event?.startDate).format('DD MMMM YYYY') || ''}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default HeaderEvent;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { DropdownTypes, TierRequestTypes } from '../../../types/commonTypes';
import {
  Dealer,
  FotoIdentitasRequest,
  InformasiAlamatRequest,
  InformasiPribadiRequest,
  InformasiUsahaRequest,
  InformasiRekeningRequest,
  AlamatUsahaRequest,
  DokumenPendukungRequest,
  InformasiPribadiRequestV2,
  InformasiAlamatRequestV2,
} from '../../../types/Dealer';
import { featureFlag } from '../../../utils/helper';
import { DataPelengkapRequest } from '../../../types/DataPelengkap';

export const getDealerDetail = createAsyncThunk(
  'dealerDetail/fetchData',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const v2Prefix = featureFlag('REACT_APP_X_MEN_10_1_PENTEST_REMEDIATION_10') ? 'v2/' : '';
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/web/onboarding/md/${v2Prefix}detail/${uuid}`,
        config
      );
      return response.data.data as Dealer;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveDraft = createAsyncThunk(
  'dealerDetail/saveDraft',
  async (
    request: {
      data:
        | InformasiPribadiRequest
        | InformasiAlamatRequest
        | InformasiUsahaRequest
        | InformasiRekeningRequest
        | AlamatUsahaRequest
        | FotoIdentitasRequest
        | DokumenPendukungRequest
        | InformasiPribadiRequestV2
        | InformasiAlamatRequestV2
        | DataPelengkapRequest;
      uuid: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/ecosystem/onboarding/md/registration/draft/${request.uuid}`,
        request.data,
        config
      );
      return response.data.data as Dealer;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDropdown = createAsyncThunk(
  'dropdown/fetchData',
  async (request: undefined, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/master-data/dropdown`,
        config
      );
      return response.data.data as DropdownTypes;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkConsent = createAsyncThunk(
  'dealerDetail/checkConsent',
  async (request: Dealer, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const data = {
        uuid: request.uuid,
        nik: request.informasi_pribadi.ktp_number,
        full_name: request.informasi_pribadi.full_name,
        dob: request.informasi_pribadi.dob,
        ktp_photo_url: request.foto_identitas.ktp_photo_url,
        selfie_url: request.foto_identitas.selfie_url,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ecosystem/onboarding/md/registration/nik-checking-consent`,
        data,
        config
      );
      return response.data.data as Dealer;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTierRequest = createAsyncThunk(
  'dropdown/fetchData',
  async (request: undefined, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ecosystem/onboarding/tier-request`,
        config
      );

      const result = response.data.data as TierRequestTypes[];

      result.push({
        uuid: '-',
        limit: 'Masukan limit sendiri',
      })

      return result;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const insertState = createAsyncThunk(
  'dealer/inserState',
  async (request: { uuid: string, contextName: string, stateName: string}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ecosystem/dealer/insert-state/${request.uuid}`,
        {
          contextName: request.contextName,
          stateName: request.stateName
        },
        config
      );

      const result = response.data.data;

      return result;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header/HeaderRevision';
import RevisiDokumen from './RevisiDokumen';

const RevisionForm = () => {
  const { section_type } = useParams();

  const iconInfoBlue = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7.99935 14.6667C11.6727 14.6667 14.666 11.6733 14.666 7.99999C14.666 4.32666 11.6727 1.33332 7.99935 1.33332C4.32602 1.33332 1.33268 4.32666 1.33268 7.99999C1.33268 11.6733 4.32602 14.6667 7.99935 14.6667ZM8.49935 10.6667C8.49935 10.94 8.27268 11.1667 7.99935 11.1667C7.72602 11.1667 7.49935 10.94 7.49935 10.6667L7.49935 7.33332C7.49935 7.05999 7.72602 6.83332 7.99935 6.83332C8.27268 6.83332 8.49935 7.05999 8.49935 7.33332L8.49935 10.6667ZM7.38602 5.07999C7.41935 4.99332 7.46602 4.92666 7.52602 4.85999C7.59268 4.79999 7.66602 4.75332 7.74602 4.71999C7.82602 4.68666 7.91268 4.66666 7.99935 4.66666C8.08602 4.66666 8.17268 4.68666 8.25268 4.71999C8.33268 4.75332 8.40602 4.79999 8.47268 4.85999C8.53268 4.92666 8.57935 4.99332 8.61268 5.07999C8.64602 5.15999 8.66602 5.24666 8.66602 5.33332C8.66602 5.41999 8.64602 5.50666 8.61268 5.58666C8.57935 5.66666 8.53268 5.73999 8.47268 5.80666C8.40602 5.86666 8.33268 5.91332 8.25268 5.94666C8.09268 6.01332 7.90602 6.01332 7.74602 5.94666C7.66602 5.91332 7.59268 5.86666 7.52602 5.80666C7.46602 5.73999 7.41935 5.66666 7.38602 5.58666C7.35268 5.50666 7.33268 5.41999 7.33268 5.33332C7.33268 5.24666 7.35268 5.15999 7.38602 5.07999Z" fill="#002DB4" />
    </svg>
  )

  return (
    <div className="min-h-screen flex flex-col">
      <Header percentage={0} activeSession={section_type as string} />
      <div className={'p-3 mb-3 border text-sm border-1 border-blue-300 rounded-md bg-blue-50 m-4'}>
            <div className='flex justify-between'>
              <div className='flex'>
                <div className='items-center'>
                  {iconInfoBlue}
                </div>
                <div className='ml-2'>
                  Upload seluruh dokumen berikut untuk mengaktifkan tombol ‘simpan dan lanjut’
                </div>
              </div>
              <div>
                X
              </div>
            </div>
          </div>
      <div className="p-4 flex-1 flex flex-col"><RevisiDokumen /></div>
    </div>
  );
};

export default RevisionForm;

import React, { useEffect, useState } from 'react';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import KTP from '../../assets/icons/ktp.svg';
import Rekening from '../../assets/icons/rekening.svg';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getEmployeeDetailV2 } from '../../redux/slices/employee/employeeThunk';
import { WaitingStatus } from '../../utils/constants';
import { employeeV2BackwardCompability } from '../../utils/helper';
import { PAGE_SECTION_FORM } from '../../utils/constants';

const HomePageEmployee = () => {
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();

  const navigate = useNavigate();
  const data = useAppSelector((state) => state.employee);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const lastSectionId = data.employee.data?.last_section_id;
  const isV2 = employeeV2BackwardCompability(lastSectionId)

  const handleStart = async () => {
    // handling backward compatibility OCR flow v1 or v2
    setIsLoading(true)
    const lastSection = data.employee.data.last_section
    const isWaitingForm = lastSection === PAGE_SECTION_FORM.MENUNGGU_PENGISIAN_DATA
    const latestSectionId = data.employee.data?.last_section_id;

    const employee = await dispatch(getEmployeeDetailV2(uuid as string));
    if (employee.meta.requestStatus === 'rejected') {
      setIsLoading(false)
      return toast.error('Failed to load data, please try again later');
    }
    setIsLoading(false)
    if (isV2) {
      if (latestSectionId === 14) return navigate(`/employee/registration/${uuid}/tnc`);
      isWaitingForm ?
        navigate(`/employee/registration/${uuid}/foto_hasil_ktp_v2`) :
        navigate(`/employee/registration/${uuid}/${lastSection}`);
    } else {
      navigate(`/employee/registration/${uuid}/informasi_pribadi/`);
    }
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') || '');
    }
    setIsLoading(true)
    try {
      dispatch(getEmployeeDetailV2(uuid as string)).then(
        () => setIsLoading(false)
      )
    } catch (error) {
      toast.error('Failed to load data, please try again later');
      setIsLoading(true)
    }
  }, []);

  useEffect(() => {
    const latestStatus = data.employee.data?.latest_status;
    if (
      isV2 &&
      latestStatus &&
      latestStatus !== WaitingStatus.WAITING_REGISTRATION_FORM
    ) {
      return navigate(`/employee/waiting-page/${uuid}`);
    }

    if (
      latestStatus &&
      latestStatus !== WaitingStatus.WAITING_REGISTRATION_FORM &&
      latestStatus !== WaitingStatus.DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT && 
      latestStatus !== WaitingStatus.DUKCAPIL_VALIDATION_REJECTED
    ) {
      navigate(`/employee/waiting-page/${uuid}`);
    }
  }, [data]);

  return (
    <div className="px-4 h-screen flex flex-col">
      <div>
        <img
          src={MoladinIcon}
          alt="moladin"
          className="my-[22px] cursor-pointer"
        />
        <hr className="-mx-4" />
      </div>

      <h1 className="font-bold text-lg mt-6 mb-7">
        Yang perlu kamu siapkan untuk daftar jadi Karyawan Moladin
      </h1>

      <div className="flex flex-col h-full pb-4">
        <div className="flex-1">
          <div className="flex items-center mb-10">
            <img src={KTP} alt="ktp" className="mr-3" />
            <p className="text-xs">
              <span className="font-bold">KTP dan Kartu Keluarga</span> untuk
              verifikasi data diri
            </p>
          </div>

          <div className="flex items-center mb-10">
            <img src={Rekening} alt="ktp" className="mr-3" />
            <p className="text-xs">
              <span className="font-bold">CV (Curriculum Vitae)</span> untuk
              memelajari kualifikasi dan riwayat kerjamu (opsional)
            </p>
          </div>
        </div>

        {localStorage.getItem('token') && uuid ? (
          <div className="items-center justify-center flex flex-col">
            <small className="text-[#1F2937] text-xs mt-10 mb-5 text-center">
              Estimasi waktu pengisian{' '}
              <span className="font-semibold"> 5 menit</span>
            </small>

            <button
              onClick={handleStart}
              disabled={isLoading}
              className="bg-secondary rounded-lg h-11 w-full text-white disabled:bg-[#9CA3AF]"
            >
              Mulai
            </button>
          </div>
        ) : (
          <button
            disabled
            className="bg-secondary rounded-lg h-11 w-full text-white disabled:bg-[#9CA3AF] mt-10 cursor-not-allowed"
          >
            Mulai
          </button>
        )}
      </div>
    </div>
  );
};

export default HomePageEmployee;

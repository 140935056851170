import React from 'react';
import { ChevronLeft } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  percentage: number;
  activeSession: string;
};

const Header = ({ percentage, activeSession }: Props) => {
  const { uuid } = useParams();

  const navigate = useNavigate();

  let scroll = 0;

  const sections = [
    { name: 'Revisi Dokumen', slug: 'revisi_dokumen' },
  ];

  switch (activeSession) {
    case 'revisi_dokumen':
      scroll = 120;
      break;
    default:
      break;
  }

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className="h-[106px] bg-primary">
      <div className="flex items-center justify-around text-white pt-6 mb-6">
        <ChevronLeft
          onClick={handleBack}
          width={24}
          height={24}
          className="cursor-pointer"
        />
        <div className="shadow w-[140px] bg-white/50 rounded-[99px] ">
          <div
            style={{ width: `${percentage}%` }}
            className={`bg-[#E6EAF8] text-xs leading-none py-1 text-center rounded-[99px] text-white `}
          ></div>
        </div>
        <span className="text-xs">{percentage}% Selesai</span>
      </div>

      <div
        style={
          activeSession !== 'informasi_pribadi'
            ? { marginLeft: -scroll }
            : { marginLeft: scroll }
        }
        className="flex items-center justify-start justify-center mx-[10px] overflow-hidden whitespace-nowrap"
      >
        {sections.map((section, index) => (
          <>
            <div
              key={section.slug}
              className="flex justify-center items-center"
            >
              <div
                className={`rounded-full flex items-center justify-center ${
                  section.slug === activeSession ? 'bg-white' : 'bg-white/50'
                } text-primary w-[18px] h-[18px] text-xs`}
              >
                {index + 1}
              </div>
              <span
                className={`font-bold ${
                  section.slug === activeSession
                    ? 'text-white'
                    : 'text-white/50'
                } ml-2 text-xs `}
              >
                {section.name}
              </span>
            </div>
            <span className="text-white mx-2 last:hidden">---</span>
          </>
        ))}
      </div>
    </div>
  );
};

export default Header;

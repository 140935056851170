import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MoladinIcon from '../../assets/icons/moladinIcon.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getEmployeeDetail } from '../../redux/slices/employee/employeeThunk';
import { agreeTnC, getTnC } from '../../redux/slices/TnC/tncThunk';
import '../../assets/css/tnc.css';
import { EmployeeWaitingStatus } from '../../utils/constants';
import { toast } from 'react-toastify';

const TnCPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [content, setContent] = useState('');
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();

  const dispatch = useAppDispatch();
  const tnc = useAppSelector((state) => state.tnc);
  const employee = useAppSelector((state) => state.employee.employee.data);
  const navigate = useNavigate();
  const isV2 = employee.last_section_id > 8

  const handleAgree = async () => {
    const firstPhoneNumber = employee?.informasi_pribadi?.phone_number?.slice(0, 2)
    const isValidPhone = firstPhoneNumber?.includes('0') || firstPhoneNumber?.includes('8')
    if (!isValidPhone) {
      return toast.error('Format nomor HP salah. Silahkan cek kembali');
    }
    const res = await dispatch(
      agreeTnC({ userId: employee?.informasi_pribadi?.id, tncId: tnc.data?.id })
    );

    if (res.meta.requestStatus === 'fulfilled') {
      navigate(`/employee/validation/${uuid}`);
    }
  };

  useEffect(() => {
    const res = tnc?.data?.content
      ?.replace('{name}', employee?.informasi_pribadi?.full_name || '')
      ?.replace('{nik}', employee?.informasi_pribadi?.ktp_number || '')
      ?.replace('{phone}', employee?.informasi_pribadi?.phone_number || '');

    setContent(res);
  }, [tnc, employee]);

  useEffect(() => {
    dispatch(getTnC('general_employee_onboarding'));
    dispatch(getEmployeeDetail(uuid as string));

    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') || '');
      dispatch(getEmployeeDetail(uuid as string));
    }
  }, []);

  useEffect(() => {
    if (!isV2 && employee.latest_status) {
      employee?.latest_status !==
        EmployeeWaitingStatus.WAITING_FOR_ACTIVE_CONSENT &&
        navigate(`/employee/waiting-page/${uuid}`);
    }
  }, [employee]);

  return (
    <div className={`${isV2 && '!px-0'} px-4`}>
      <div className="flex flex-col items-center justify-center text-center space-y-2 mt-4">
        <img
          src={MoladinIcon}
          alt="moladin"
          className="w-[120px] h-[40px] cursor-pointer"
        />
        <h1 className={`${isV2 && 'text-xl'} font-semibold`}>
          {isV2 ? 'Surat Pernyataan' : 'FORMULIR APLIKASI DAN PERNYATAAN KANDIDAT KARYAWAN/MITRA'}
        </h1>
        <span className="text-xs">
          Versi {tnc.data?.version} diperbarui tanggal{' '}
          {dayjs(tnc.data?.created_at).format('DD MMMM YYYY')}
        </span>
      </div>

      <div
        className="bg-[#F7F7F7] rounded-2xl px-4 py-[9px] mt-[30px] mb-4"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>

      <div className="flex items-center justify-center text-sm mb-3">
        <input
          type="checkbox"
          id="check"
          onChange={(e) => setIsChecked(e.target.checked)}
          className="rounded-lg"
        />
        <label htmlFor="check" className="text-xs ml-[10px]">
          Saya telah membaca dan setuju dengan <b>Syarat dan Ketentuan</b> yang
          berlaku di Moladin
        </label>
      </div>

      <div>
        <button
          onClick={handleAgree}
          disabled={isChecked === false}
          className="bg-secondary disabled:bg-disabled disabled:cursor-not-allowed rounded-lg h-11 w-full text-white my-[18px]"
        >
          Setujui
        </button>
      </div>
    </div>
  );
};

export default TnCPage;

import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getTnC } from './tncThunk';

const initialState = {
  data: {} as TnCType,
  loading: false,
};

export const tncSlice = createSlice({
  name: 'tncSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTnC.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTnC.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
  },
});

export const selectTnC = (state: RootState) => state.tnc;

export const reducer = combineReducers({
  tnc: tncSlice.reducer,
});

export default tncSlice.reducer;

import React from 'react';

export type ButtonProps = {
  name: string;
  withIcon: boolean;
  icon?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  customClassName?: boolean;
  className?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
};

const Button = (props: ButtonProps) => {
  const {
    name,
    withIcon,
    icon,
    onClick,
    customClassName = false,
    className,
    disabled = false,
    type
  } = props;
  return (
    <button
      type={type || 'submit'}
      className={
        customClassName
          ? className
          : `font-semibold rounded-lg h-11 w-full text-white ${
              disabled ? 'bg-disabled' : 'bg-secondary'
            }`
      }
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex justify-center items-center">
        {withIcon && <img src={icon} {...(name ? {className: "mr-2"} : {})}/>}
        <span
          className={`${
            withIcon ? 'text-xs text-secondary' : 'text-sm text-white'
          }`}
        >
          {name}
        </span>
      </div>
    </button>
  );
};

export default Button;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-decimal {
  list-style-type: decimal !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
}

.list-alpha {
  list-style-type: lower-alpha !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
}

p {
  margin-bottom: 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

ul {
  list-style-type: disc !important;
  margin-left: 20px !important;
  margin-bottom: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/tnc.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;EACvC,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gCAAgC;EAChC,4BAA4B;EAC5B,8BAA8B;AAChC","sourcesContent":[".list-decimal {\n  list-style-type: decimal !important;\n  margin-left: 20px !important;\n  margin-bottom: 10px !important;\n}\n\n.list-alpha {\n  list-style-type: lower-alpha !important;\n  margin-left: 20px !important;\n  margin-bottom: 10px !important;\n}\n\np {\n  margin-bottom: 10px !important;\n}\n\n.mb-0 {\n  margin-bottom: 0 !important;\n}\n\nul {\n  list-style-type: disc !important;\n  margin-left: 20px !important;\n  margin-bottom: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react';
import {
  WaitingModalMessageType,
  WaitingConsentCountdownType,
} from '../../types/WaitingPage';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { resendWhatsappNotif } from '../../services';
import { WaitingStatus } from '../../utils/constants';
import { iconChecklistGreen } from '../../utils/assets';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDealerDetail } from '../../redux/slices/dealer/dealerThunk';
import { DukcapilValidationRejectedFirstAttempt } from './DukcapilValidationRejected';
import DukcapilValidationRejectedSecondAttempt from './DukcapilValidationRejectedSecondAttempt';
import RejectedPage from './RejectedPage';
import Modal from '../../components/atom/Modal';
import DukcapilValidationRejected from './DukcapilValidationRejected';
import AfterValidation from '../../components/WaitingPage/dealer/AfterValidation';
import Loading from '../../components/WaitingPage/dealer/Loading';
import WaitingConsent from '../../components/WaitingPage/dealer/WaitingConsent';

const WaitingPage = () => {
  const dispatch = useAppDispatch();
  const { uuid } = useParams();

  const dataDealer = useAppSelector((state) => state.dealer.dealer);
  const [showModal, setShowModal] = useState(false);
  const [activePage, setActivePage] = useState<string>('loading');
  const [restartCountdown, setRestartCountdown] = useState(false);
  const [pageDisplay, setPageDisplay] = useState(<Loading />);
  const initialStateModalMessage = {
    title: '',
    description: '',
  };
  const [modalMessage, setModalMessage] = useState<WaitingModalMessageType>(
    initialStateModalMessage
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage(initialStateModalMessage);
    setRestartCountdown(true);
  };

  const handleRefreshStatus = async () => {
    getDataDealer();
  };

  const handleResendTnc = async () => {
    try {
      await resendWhatsappNotif(uuid as string);

      setModalMessage({
        title: 'Berhasil mengirim ulang',
        description: 'Link Syarat & Ketentuan telah dikirim ke nomor WA Dealer',
      });
      setShowModal(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.message);
      }
    }
  };

  const handleResendAgreement = () => {
    setModalMessage({
      title: 'Berhasil mengirim ulang',
      description:
        'Link Perjanjian telah dikirim ke nomor WA Dealer untuk ditandatangani',
    });
    setShowModal(true);
  };

  const handleCallCS = () => {
    window.location.href = 'mailto:hello@mofi.id';
    return;
  };

  const getDataDealer = () => {
    dispatch(getDealerDetail(uuid as string));
  };

  useEffect(() => {
    getDataDealer();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (dataDealer?.data?.latest_status) {
        setActivePage(dataDealer?.data?.latest_status);
      }
    }
  }, [dataDealer.data.latest_status]);

  useEffect(() => {
    if (dataDealer.loading) {
      setPageDisplay(<Loading />);
    } else {
      switch (activePage) {
        case WaitingStatus.PRINCIPAL_APPROVED:
        case WaitingStatus.REVIEW_DATA:
        case WaitingStatus.DUKCAPIL_VALIDATION_REJECTED_SECOND_ATTEMPT:
        case WaitingStatus.BUSINESS_SURVEY:
        case WaitingStatus.VERIFIKASI_TELEPON:
        case WaitingStatus.HOME_SURVEY:
        case WaitingStatus.PROSES_HOME_SURVEY:
        case WaitingStatus.FINAL_REVIEW:
        case WaitingStatus.MANAGER_APPROVAL:
        case WaitingStatus.CONTRACT_SIGNING:
        case WaitingStatus.WAITING_FOR_JOIN_DATE:
        case WaitingStatus.ACTIVE:
          setPageDisplay(
            <AfterValidation
              handleRefreshStatus={handleRefreshStatus}
              handleResendAgreement={handleResendAgreement}
              handleCallCS={handleCallCS}
            />
          );
          break;
        case WaitingStatus.DOCUMENT_SUBMITTED:
          setPageDisplay(
            <Loading isDocumentSubmitted={true} getDataDealer={getDataDealer} />
          );
          break;
        case WaitingStatus.WAITING_FOR_ACTIVE_CONSENT:
          setPageDisplay(
            <WaitingConsent
              handleRefreshStatus={handleRefreshStatus}
              handleResendTnc={handleResendTnc}
              restartCountdown={restartCountdown}
              setRestartCountdown={setRestartCountdown}
            />
          );
          break;
        case WaitingStatus.DUKCAPIL_VALIDATION_REJECTED:
          setPageDisplay(
            <DukcapilValidationRejected handleTryAgain={handleRefreshStatus} />
          );
          break;
        case WaitingStatus.DUKCAPIL_VALIDATION_REJECTED_FIRST_ATTEMPT:
          setPageDisplay(<DukcapilValidationRejectedFirstAttempt />);
          break;
        case WaitingStatus.REJECTED:
        case WaitingStatus.INTERNAL_VALIDATION_REJECTED:
          setPageDisplay(<RejectedPage />);
          break;
      }
    }
  }, [activePage, dataDealer, restartCountdown]);

  return (
    <>
      {pageDisplay}
      {showModal && (
        <Modal
          icon={iconChecklistGreen}
          iconStyle="h-60 py-4"
          title={modalMessage.title}
          description={modalMessage.description}
          showModal={showModal}
          withButton={true}
          buttonTitle="Tutup"
          handleClickButton={handleCloseModal}
        />
      )}
    </>
  );
};

export default WaitingPage;

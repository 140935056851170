import React, { useRef } from "react";
import Copy from '../../assets/icons/mico_copy.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type SuccessProp = {
  code: string;
};

const Success = ({ code }: SuccessProp) => {
  const codeRef = useRef<HTMLInputElement | null>(null);

  const copyCodeToClipboard = () => {
    if (codeRef.current) {
      const range = document.createRange();
      const codeWithoutSpaces = codeRef.current?.innerText?.replace(/\s/g, '');

      const tempElement = document.createElement('div');
      tempElement.innerText = codeWithoutSpaces;
      document.body.appendChild(tempElement);

      range.selectNode(tempElement);
      const selection = window.getSelection();

      selection?.removeAllRanges();
      selection?.addRange(range);
      document.execCommand('copy');
      selection?.removeAllRanges();

      document.body.removeChild(tempElement);

      toast.success('Kode disalin ke clipboard!', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  return (
    <div className="bg-white shadow p-4 rounded-lg">
      <div className="flex flex-col justify-center items-center mb-20 pt-16">
        <div className="text-sm text-neutral-800 font-semibold mb-4">Kode unik event:</div>
        <div
          ref={codeRef}
          className="text-3xl text-primary mb-4 font-bold cursor-pointer"
          onClick={copyCodeToClipboard}
        >
          {code || '-'}
        </div>
        <div className="text-xs font-normal text-center text-neutral-500">
          Silahkan screenshot atau salin kode untuk mendaftar di aplikasi Moladin Dealer
        </div>
      </div>
      <button
        className="bg-secondary p-3 text-center flex justify-center gap-x-2 items-center text-white rounded-lg w-full"
        onClick={copyCodeToClipboard}
      >
        <img src={Copy} alt="copy-icon" />Salin Kode
      </button>
    </div>
  );
};

export default Success;

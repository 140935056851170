import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorAlert from '../../atom/ErrorAlert';
import FormGroup from '../../atom/FormGroup';
import Label from '../../atom/Label';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ErrorFieldObject } from '../../../types/Employee';
import TakePhoto from '../../FotoIdentitas/TakePhoto/TakePhoto';
import {
  CameraProperty,
  CommonTypesFotoIdentitas,
  ContentControler,
} from '../../../types/FotoIdentitasTypes';
import { uploadTakenImageFile, uploadTakenImageFileEmployee } from '../../../services';
import PopupGuide from '../../FotoIdentitas/PopupGuide/PopupGuide';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { saveDraft } from '../../../redux/slices/employee/employeeThunk';
import { PAGE_SECTION_FORM } from '../../../utils/constants';
import { toast } from 'react-toastify';
import InputTakePhotoWithDrawerEmployee from '../../FotoIdentitas/InputTakePhotoWithDrawerEmployee';

const initialFormData: CommonTypesFotoIdentitas = {
  ktp_photo_url: '',
  family_card_url: '',
  selfie_url: '',
  npwp_url: '',
};

const initialContentController: ContentControler = {
  showCamera: false,
  showPopup: false,
  contentType: '',
};

const initialErrorSelfieValidation = {
  isError: false,
  title: '',
  message: '',
};

const initialCameraProperty: CameraProperty = {
  facing_mode: 'environment',
};

interface ErrorSelfieValidation {
  isError: boolean;
  title: string;
  message: string;
}

interface ErrorsAttemptObject {
  selfie_url?: {
    title: string;
    message: string;
  } | null;
  ktp_photo_url?: {
    title: string;
    message: string;
  } | null;
}

const FotoIdentitas = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dataEmployee = useAppSelector((state) => state.employee.employee);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormData,
  });
  const [errorSelfieValidation, setErrorSelfieValidation] =
    useState<ErrorSelfieValidation>(initialErrorSelfieValidation);
  const [contentController, setContentController] = useState(
    initialContentController
  );
  const [cameraProperty, setCameraProperty] = useState(initialCameraProperty);

  const handleClickShowPopupGuide = (type: string) => {
    setContentController({
      ...contentController,
      showPopup: true,
      contentType: type,
    });
  };

  const handleChangePhoto = (type: string) => {
    setContentController({
      ...contentController,
      showPopup: true,
      contentType: type,
    });
  };

  const handleRemovePhoto = (type: string, callback: any) => {
    callback(type, '');
  };

  const handleConfirmPhotoTaken = (srcUri: string, callback: any) => {
    if (errors[contentController.contentType as keyof ErrorsAttemptObject]) {
      if (
        errors[contentController.contentType as keyof ErrorsAttemptObject]
          ?.type === 'custom'
      ) {
        clearErrors(
          contentController.contentType as keyof CommonTypesFotoIdentitas
        );
      }
    }

    if (
      errors[contentController.contentType as keyof CommonTypesFotoIdentitas]
    ) {
      clearErrors(
        contentController.contentType as keyof CommonTypesFotoIdentitas
      );
    }

    callback(contentController.contentType, srcUri);
    setContentController({
      ...contentController,
      ...initialContentController,
    });
  };

  const handleClickClosePopupGuide = () => {
    setContentController({
      ...contentController,
      showPopup: false,
      contentType: '',
    });
  };

  const handleConfirmPopupGuide = () => {
    setContentController({
      ...contentController,
      showPopup: false,
      showCamera: true,
    });
  };

  const handleBackToPopupGuide = () => {
    setContentController({
      ...contentController,
      showPopup: true,
      showCamera: false,
    });
  };

  const onSubmit: SubmitHandler<CommonTypesFotoIdentitas> = async (data) => {
    const payload = {
      foto_identitas: {
        ...data,
      },
    };

    const res = await dispatch(
      saveDraft({ data: payload, uuid: uuid as string })
    );

    const requestStatus = res.meta.requestStatus;

    if (requestStatus === 'fulfilled') {
      navigate(`/employee/registration/${uuid}/${PAGE_SECTION_FORM.ALAMAT}`);
    }

    if (requestStatus === 'rejected') {
      const errMessage = (res.payload as any)?.errors[0]?.message;
      toast.error(errMessage || 'Failed submit registration');
    }
  };

  const handleConfirmDrawerFileTaken = (
    srcUri: string,
    setValue: any,
    contentType: string
  ) => {
    if (errors[contentType as keyof ErrorsAttemptObject]) {
      // error vida clear
      if (errors[contentType as keyof ErrorsAttemptObject]?.type == 'custom') {
        clearErrors(contentType as keyof CommonTypesFotoIdentitas);
      }
    }
    if (errors[contentType as keyof CommonTypesFotoIdentitas]) {
      // error basic clear
      clearErrors(contentType as keyof CommonTypesFotoIdentitas);
    }
    setValue(contentType, srcUri);
    setContentController({
      ...contentController,
      ...initialContentController,
    });
  };

  useEffect(() => {
    const employee = dataEmployee.data;
    const fotoIdentitasData = employee.foto_identitas;
    fotoIdentitasData &&
      Object.keys(fotoIdentitasData).map((value: string) => {
        setValue(
          value as keyof CommonTypesFotoIdentitas,
          fotoIdentitasData[value as keyof CommonTypesFotoIdentitas]
        );
      });
    if (employee.error_field) {
      const isEmptyErrorField = Array.isArray(employee.error_field);
      if (!isEmptyErrorField) {
        const section = Object.keys(employee.error_field)[0];
        if (section === 'foto_identitas') {
          const errorData =
            employee.error_field[`${section}` as keyof ErrorFieldObject];
          setErrorSelfieValidation({
            isError: true,
            title: errorData['selfie_url']['title'] || '',
            message: errorData['selfie_url']['message'] || '',
          });
        }
      }
    }
  }, [dataEmployee.data]);

  useEffect(() => {
    switch (contentController.contentType) {
      case 'selfie_url':
        setCameraProperty({
          ...cameraProperty,
          facing_mode: 'user',
        });

        break;

      default:
        setCameraProperty({
          ...cameraProperty,
          ...initialCameraProperty,
        });
        break;
    }
  }, [contentController]);

  useEffect(() => {
    if (errorSelfieValidation.isError) {
      setError('selfie_url', {
        type: 'custom',
        message: errorSelfieValidation.title,
      });
    }
  }, [errorSelfieValidation]);

  return (
    <form
      className="flex-1 flex flex-col justify-between"
      onSubmit={handleSubmit(onSubmit)}
    >
      {!contentController.showPopup && !contentController.showCamera && (
        <>
          <div>
            {errorSelfieValidation.isError && (
              <div className="mb-5">
                <ErrorAlert
                  title={errorSelfieValidation.title}
                  message={errorSelfieValidation.message}
                />
              </div>
            )}
            <FormGroup className="mb-4">
              <Label name="Foto KTP" />
              <Controller
                rules={{ required: 'Foto KTP harus diisi' }}
                control={control}
                name="ktp_photo_url"
                render={({ field }) => (
                  <InputTakePhotoWithDrawerEmployee
                    imageLabel="Foto KTP"
                    placeholder="Ambil foto Ktp"
                    onSelectCamera={() =>
                      handleClickShowPopupGuide('ktp_photo_url')
                    }
                    srcUri={field.value}
                    handleRemovePhoto={() =>
                      handleRemovePhoto('ktp_photo_url', setValue)
                    }
                    onBrowseFile={() =>
                      setContentController({
                        ...contentController,
                        contentType: 'ktp_photo_url',
                      })
                    }
                    onSelectFile={(src) => {
                      handleConfirmDrawerFileTaken(
                        src,
                        setValue,
                        'ktp_photo_url'
                      );
                    }}
                  />
                )}
              />
              <small className="text-red-600">
                {errors.ktp_photo_url?.message}
              </small>
            </FormGroup>

            <FormGroup className="mb-4">
              <Label name="Kartu Keluarga" />
              <Controller
                rules={{ required: 'Foto KK harus diisi' }}
                control={control}
                name="family_card_url"
                render={({ field }) => (
                  <InputTakePhotoWithDrawerEmployee
                    imageLabel="Foto KK"
                    placeholder="Ambil foto KK"
                    onSelectCamera={() =>
                      handleClickShowPopupGuide('family_card_url')
                    }
                    srcUri={field.value}
                    handleRemovePhoto={() =>
                      handleRemovePhoto('family_card_url', setValue)
                    }
                    onBrowseFile={() =>
                      setContentController({
                        ...contentController,
                        contentType: 'family_card_url',
                      })
                    }
                    onSelectFile={(src) => {
                      handleConfirmDrawerFileTaken(
                        src,
                        setValue,
                        'family_card_url'
                      );
                    }}
                  />
                )}
              />
              <small className="text-red-600">
                {errors.family_card_url?.message}
              </small>
            </FormGroup>

            <FormGroup className="mb-4">
              <Label name="Foto Selfie Wajah" />
              <Controller
                rules={{ required: 'Foto Selfie harus diisi' }}
                control={control}
                name="selfie_url"
                render={({ field }) => (
                  <InputTakePhotoWithDrawerEmployee
                    imageLabel="Foto Selfie"
                    placeholder="Ambil foto selfie"
                    onSelectCamera={() =>
                      handleClickShowPopupGuide('selfie_url')
                    }
                    srcUri={field.value}
                    handleRemovePhoto={() =>
                      handleRemovePhoto('selfie_url', setValue)
                    }
                    onBrowseFile={() =>
                      setContentController({
                        ...contentController,
                        contentType: 'selfie_url',
                      })
                    }
                    onSelectFile={(src) => {
                      handleConfirmDrawerFileTaken(src, setValue, 'selfie_url');
                    }}
                  />
                )}
              />
              <small className="text-red-600">
                {errors.selfie_url?.message}
              </small>
            </FormGroup>

            <FormGroup className="mb-4">
              <Label name="Foto NPWP" />
              <Controller
                rules={{ required: 'Foto NPWP harus diisi' }}
                control={control}
                name="npwp_url"
                render={({ field }) => (
                  <InputTakePhotoWithDrawerEmployee
                    imageLabel="Foto NPWP"
                    placeholder="Ambil foto NPWP"
                    onSelectCamera={() => handleClickShowPopupGuide('npwp_url')}
                    srcUri={field.value}
                    handleRemovePhoto={() =>
                      handleRemovePhoto('npwp_url', setValue)
                    }
                    onBrowseFile={() =>
                      setContentController({
                        ...contentController,
                        contentType: 'npwp_url',
                      })
                    }
                    onSelectFile={(src) => {
                      handleConfirmDrawerFileTaken(src, setValue, 'npwp_url');
                    }}
                  />
                )}
              />
              <small className="text-red-600">
                {errors.selfie_url?.message}
              </small>
            </FormGroup>
          </div>
          <button
            type="submit"
            disabled={dataEmployee.loading}
            className="bg-secondary rounded-lg h-11 w-full text-white hover:bg-secondary/90 transition-all duration-200 ease-in-out"
          >
            <p className="!mb-0">Simpan dan Lanjut</p>
          </button>
        </>
      )}

      {contentController.showPopup && (
        <PopupGuide
          handleClose={handleClickClosePopupGuide}
          handleConfirm={handleConfirmPopupGuide}
          contentType={contentController.contentType}
        />
      )}

      {contentController.showCamera && (
        <TakePhoto
          handleConfirmPhoto={(src) => handleConfirmPhotoTaken(src, setValue)}
          handleBack={handleBackToPopupGuide}
          cameraProperty={cameraProperty}
          cameraType={contentController.contentType}
          onUploadPhotoTaken={uploadTakenImageFileEmployee}
        />
      )}
    </form>
  );
};

export default FotoIdentitas;

import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropdownTypes, TierRequestTypes } from '../../../types/commonTypes';
import { Dealer } from '../../../types/Dealer';

import { RootState } from '../../store';
import { getDealerDetail, getDropdown, getTierRequest, saveDraft } from './dealerThunk';
import { decryptor, featureFlag } from '../../../utils/helper';

export const dealerDetailSlice = createSlice({
  name: 'dealerDetail',
  initialState: {
    data: {} as Dealer,
    loading: false,
    error: false,
  },
  reducers: {
    setDataUser: (state, action: PayloadAction<Dealer>) => {
      state.data = action.payload;
    },
    setDealerLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDealerDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDealerDetail.fulfilled, (state, action) => {
        const detailData = action.payload;
        const isInformasiRekeningString = typeof detailData.informasi_rekening === 'string';
        const isFotoIdentitasString = typeof detailData.foto_identitas === 'string';
        const isInformasiPribadiString = typeof detailData.informasi_pribadi === 'string';
        const isDokumenPendukungString = typeof detailData.dokumen_pendukung === 'string';

        if (featureFlag('REACT_APP_X_MEN_10_1_PENTEST_REMEDIATION_10') &&
          isInformasiRekeningString ||
          isFotoIdentitasString ||
          isInformasiPribadiString ||
          isDokumenPendukungString) {
          const decryptedInformasiRekening = decryptor(detailData.informasi_rekening);
          const decryptedFotoIdentitas = decryptor(detailData.foto_identitas);
          const decryptedInformasiPribadi = decryptor(detailData.informasi_pribadi);
          const decryptedDokumenPendukung = decryptor(detailData.dokumen_pendukung);
    
          detailData.informasi_rekening = JSON.parse(decryptedInformasiRekening);
          detailData.foto_identitas = JSON.parse(decryptedFotoIdentitas);
          detailData.informasi_pribadi = JSON.parse(decryptedInformasiPribadi);
          detailData.dokumen_pendukung = JSON.parse(decryptedDokumenPendukung);
          detailData.data_pelengkap = {
            family_card_url: JSON.parse(decryptedFotoIdentitas).family_card_url,
            selfie_url: JSON.parse(decryptedFotoIdentitas).selfie_url,
            education_id: JSON.parse(decryptedInformasiPribadi).education_id,
            income_id: JSON.parse(decryptedInformasiPribadi).income_id,
            email: JSON.parse(decryptedInformasiPribadi).email,
            personal_npwp: JSON.parse(decryptedInformasiPribadi).personal_npwp,
            kk_number: JSON.parse(decryptedInformasiPribadi).kk_number,
            phone_number: JSON.parse(decryptedInformasiPribadi).phone_number,
            spouse_ktp_name: JSON.parse(decryptedInformasiPribadi).spouse_ktp_name,
            spouse_dob: JSON.parse(decryptedInformasiPribadi).spouse_dob,
            spouse_phone_number: JSON.parse(decryptedInformasiPribadi).spouse_phone_number,
            spouse_ktp_number: JSON.parse(decryptedInformasiPribadi).spouse_ktp_number,
            spouse_email: JSON.parse(decryptedInformasiPribadi).spouse_email,
          };
        }

        state.loading = false;
        state.data = detailData;
      })
      .addCase(getDealerDetail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(saveDraft.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveDraft.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(saveDraft.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const dropdownSlice = createSlice({
  name: 'dropdownMaster',
  initialState: {
    data: {} as DropdownTypes,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
  },
});

export const tierRequestlice = createSlice({
  name: 'tierRequest',
  initialState: {
    data: [] as TierRequestTypes[],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTierRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTierRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
  },
});

export const { setDataUser, setDealerLoading } = dealerDetailSlice.actions;

export const selectData = (state: RootState) => state.dealer;

export const reducer = combineReducers({
  dealer: dealerDetailSlice.reducer,
  dropdown: dropdownSlice.reducer,
  tierRequest: tierRequestlice.reducer,
});

export default reducer;

import React, { useEffect } from 'react';
import People from '../../assets/icons/people.svg';
import Npwp from '../../assets/icons/npwp.svg';
import Location from '../../assets/icons/location.svg';
import Sku from '../../assets/icons/sku.svg';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { getDealerDetail } from '../../redux/slices/dealer/dealerThunk';
import { ArrowLeft } from 'react-feather';

const InformasiUsahaPage = () => {
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleStart = () => {
    navigate(`/dealer/registration/${uuid}/informasi_usaha`);
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('token', searchParams.get('token') || '');
    }
    dispatch(getDealerDetail(uuid as string));
  }, []);

  return (
    <div className="px-4 h-screen flex flex-col">
      <div className="my-[30px]">
        <ArrowLeft
          onClick={() =>
            navigate(`/dealer/registration/${uuid}/informasi_rekening`)
          }
          width={24}
          height={24}
          className="cursor-pointer"
        />
      </div>
      <hr className="-mx-4" />

      <h1 className="font-bold text-lg mt-6 mb-2">
        Sedikit lagi! Siapkan ini untuk melengkapi data usaha
      </h1>

      <div className="text-xs mb-7">
        Jika ada data ini, <strong>limit pembiayaan bisa lebih tinggi</strong>{' '}
        dan <strong>pendaftaran Dealer lebih mudah disetujui</strong>{' '}
        (opsional):
      </div>

      <div className="flex flex-col h-full pb-4">
        <div className="flex-1">
          <div className="flex items-center mb-10">
            <img src={People} alt="ktp" className="mr-3" />
            <p className="text-xs">
              <span className="font-bold">
                Informasi Kontak Pembeli (2-3 orang){' '}
              </span>
              yang terakhir bertransaksi, untuk referensi usaha
            </p>
          </div>

          <div className="flex items-center mb-10">
            <img src={Npwp} alt="ktp" className="mr-3" />
            <p className="text-xs">
              <span className="font-bold">NPWP Usaha </span> untuk verifikasi
              data usaha
            </p>
          </div>

          <div className="flex items-center mb-10">
            <img src={Sku} alt="ktp" className="mr-3" />
            <p className="text-xs">
              <span className="font-bold">Foto NIB/SIUP/SKU</span> sebagai bukti
              kegiatan usaha
            </p>
          </div>

          <div className="flex items-center">
            <img src={Location} alt="ktp" className="mr-3" />
            <p className="text-xs">
              <span className="font-bold">Foto Lokasi Usaha</span> untuk
              verifikasi lokasi usaha
            </p>
          </div>
        </div>

        {localStorage.getItem('token') ? (
          <div className="items-center justify-center flex flex-col">
            <button
              onClick={handleStart}
              className="bg-secondary rounded-lg h-11 w-full text-white"
            >
              Lengkapi Data Usaha
            </button>
          </div>
        ) : (
          <button
            disabled
            className="bg-secondary rounded-lg h-11 w-full text-white disabled:bg-[#9CA3AF] mt-10 cursor-not-allowed"
          >
            Lengkapi Data Usaha
          </button>
        )}
      </div>
    </div>
  );
};

export default InformasiUsahaPage;
